interface FileUploadError {
  title: string
  description: string
}

export const generateErrorDescription = (errorCode: string, fileName: string, maxFileSize: string): FileUploadError => {
  switch (errorCode) {
    case 'file-too-large':
      return {
        title: 'File too large.',
        description: `[${fileName}] could not be uploaded because it is too large. Please ensure your file size is less than ${maxFileSize}.`
      }
    case 'file-invalid-type':
      return {
        title: 'Unrecognized Format.',
        description: `[${fileName}] could not be uploaded because it is in an unrecognized format. Please ensure your file follows accepted formats as specified in our docs.`
      }
    default:
      return {
        title: 'File rejected.',
        description: 'An error occurred while uploading your file. Please try again.'
      }
  }
}

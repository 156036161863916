import { Center, chakra, Heading, Text, VStack } from '@chakra-ui/react'

interface Props {
  msg: string
}

export function ErrorPanel({ msg }: Props) {
  return (
    <Center>
      <VStack>
        <Center h={14} w={14} bg="red.50" borderRadius="50%" color="bad">
          <chakra.svg
            width={8}
            height={8}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </chakra.svg>
        </Center>
        <Heading size="md">Oops! Something went wrong :(</Heading>
        <Text color="faded" textAlign="center">
          {msg}
        </Text>
      </VStack>
    </Center>
  )
}

import { Button } from '@chakra-ui/react'
import DownloadIcon from '@material-design-icons/svg/sharp/download.svg?react'

import TextWithTooltip from '@/components/TextWithTooltip'
import { getRunNumberUrl } from '@/features/runs/utils'

export default function RunCoverageButton({
  owner,
  projectSlug,
  targetSlug,
  runNumber,
  fullButton = true,
  disabled = false
}: {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  fullButton?: boolean
  disabled?: boolean
}): JSX.Element {
  const text = fullButton ? 'Coverage Report Package' : ''
  const button = (
    <a href={`${getRunNumberUrl(owner, projectSlug, targetSlug, runNumber)}/artifact/coverage.tgz`} download>
      <Button leftIcon={<DownloadIcon />} disabled={disabled}>
        {text}
      </Button>
    </a>
  )

  return disabled ? (
    <TextWithTooltip showQuestionMark={false} text={button} tooltip="Coverage report will be available upon completion of this run." />
  ) : (
    button
  )
}

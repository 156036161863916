import { Card, CardBody, CardFooter, CardHeader, Flex, Heading, Skeleton, Text } from '@chakra-ui/react'
import { useLocation } from 'wouter'

import { FilterMenuButton } from '../../components/FilterMenuButton'
import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'
import { useQuery } from '../../hooks'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { setNewPageFilters } from '../../util/location'
import { cleanInteger } from '../../util/numbers'

import { useGetMdsbomImageRepoTagsQuery, useGetMdsbomWorkspaceImageReposQuery, useGetMdsbomWorkspaceReportsQuery } from '../../redux/api/mdsbom'

import { DsbomReportRow } from './DsbomReportRow'

interface Props {
  workspace: string
  imageRepoId?: string
  defectId?: string
}

export function DsbomReportsList({ workspace, imageRepoId, defectId }: Props) {
  const [location, setLocation] = useLocation()
  const query = useQuery()

  const filters = {
    tag: query.get('tag') || 'all',
    image: query.get('image') || 'all'
  }
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)
  const page = cleanInteger(query.get('page'), 1)

  const { data: reportsData, isFetching: isFetchingReports } = useGetMdsbomWorkspaceReportsQuery({
    workspace,
    imageRepoId: imageRepoId || (filters.image === 'all' ? undefined : filters.image),
    imageTag: filters.tag === 'all' ? undefined : filters.tag,
    perPage: perPage,
    offset: (page - 1) * perPage
  })

  const { data: tagsData, isFetching: isFetchingTags } = useGetMdsbomImageRepoTagsQuery(
    { workspace, imageRepoId: imageRepoId || '' },
    { skip: !imageRepoId }
  )

  const { data: imageReposData, isFetching: isFetchingRepos } = useGetMdsbomWorkspaceImageReposQuery({ workspace }, { skip: !!imageRepoId })

  const imageOptions =
    imageReposData?.image_repos.map((imageRepo) => ({
      key: imageRepo.image_repo_id,
      text: imageRepo.image_name,
      value: imageRepo.image_repo_id
    })) || []

  const imageFilterOptionsBase = [
    {
      key: 'All Images',
      text: 'All Images',
      value: 'all'
    }
  ]
  const imageFilterOptions = imageFilterOptionsBase.concat(imageOptions)

  const tagOptions =
    tagsData?.tags.map((tag) => ({
      key: tag,
      text: tag,
      value: tag
    })) || []

  const tagFilterOptionsBase = [
    {
      key: 'All Tags',
      text: 'All Tags',
      value: 'all'
    }
  ]
  const tagFilterOptions = tagFilterOptionsBase.concat(tagOptions)

  const selectImageFilter = (newImageFilter: unknown): void => {
    setLocation(setNewPageFilters('image', newImageFilter, filters, location), { replace: true })
  }
  const selectTagFilter = (newTagFilter: unknown): void => {
    setLocation(setNewPageFilters('tag', newTagFilter, filters, location), { replace: true })
  }

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Reports</Heading>
      </CardHeader>
      {/* Only show filters on the Workspace and Image level reports lists */}
      {!defectId && (
        <CardHeader>
          <Flex wrap="wrap" align="baseline" gap={4}>
            <Text fontSize="xs" fontWeight="semibold" color="faded">
              FILTER:
            </Text>

            {/* Only show the Images filter on the Workspace level reports list */}
            {!imageRepoId && (
              <Skeleton isLoaded={!isFetchingRepos}>
                <FilterMenuButton
                  defaultText="All Images"
                  options={imageFilterOptions}
                  onValueChange={selectImageFilter}
                  value={filters.image}
                  textTransform="none"
                  menuItemOptions={{ textTransform: 'none' }}
                />
              </Skeleton>
            )}

            {/* Only show the Tags filter on the Image level reports list */}
            {imageRepoId && (
              <Skeleton isLoaded={!isFetchingTags}>
                <FilterMenuButton
                  defaultText="All Tags"
                  options={tagFilterOptions}
                  onValueChange={selectTagFilter}
                  value={filters.tag}
                  textTransform="none"
                  menuItemOptions={{ textTransform: 'none' }}
                />
              </Skeleton>
            )}
          </Flex>
        </CardHeader>
      )}
      <CardBody>
        {!isFetchingReports && reportsData?.reports.map((report) => <DsbomReportRow key={report.id} workspace={workspace} report={report} />)}
        <ListDefaults isLoading={isFetchingReports} nItems={reportsData?.count || 0} itemName="report" />
      </CardBody>
      <CardFooter justifyContent="center">
        <MayhemPagination isLoading={isFetchingReports} total={reportsData?.count || 0} pageSize={perPage} />
      </CardFooter>
    </Card>
  )
}

import { skipToken } from '@reduxjs/toolkit/query'
import { useLocation, useRoute } from 'wouter'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'

import { useEffect, useMemo, useState } from 'react'

import { useGetAccountQuery, useGetOrgMemberQuery, useWhoamiQuery } from '../../redux/api/workspace'
import { WorkspaceSidebar } from '../workspace-sidebar/WorkspaceSidebar'

import { Shell } from '../../components/Shell/Shell'

import { clearMruWorkspace } from '../workspace-dashboard/mru.slice'

import { WorkspaceDefaultRedirect } from './WorkspaceDefaultRedirect'

export const WorkspaceLayout = ({ children }: WorkspaceLayoutProps) => {
  const dispatch = useDispatch()
  const [location] = useLocation()

  const [_, workspaceParams] = useRoute('/:workspace/*?')
  const [__, projectParams] = useRoute('/:workspace/:project/*?')
  const matchDsbomCompare = useRoute('/:workspace/-/dynamic-sbom-compare')[0]

  // used for determining if the Settings subnav should be shown
  const [settingsManuallyExpanded, setSettingsManuallyExpanded] = useState(false)
  const matchUserSettings = useRoute('/-/settings/user/*?')[0]
  const matchWorkspaceSettings = useRoute('/:workspace/-/settings/*?')[0]
  const matchIntegrations = useRoute('/:workspace/-/integrations')[0]
  const matchAdminSettings = useRoute('/-/settings/admin/*?')[0]
  const matchPlan = useRoute('/:workspace/-/plan')[0]
  const matchSettings =
    matchUserSettings || matchAdminSettings || matchWorkspaceSettings || matchIntegrations || matchPlan || settingsManuallyExpanded

  if (!workspaceParams) {
    // this should only happen if the workspace layout is rendered without matching any workspace
    throw new Error('Workspace parameter was not matched')
  }
  const currentUserSlug = useSelector((state: RootState) => state.auth.user.userSlug)
  const defaultWorkspace = useSelector((state: RootState) => state.workspace.mru) || currentUserSlug
  const workspace = workspaceParams?.workspace === '-' ? defaultWorkspace : workspaceParams?.workspace
  const project = workspaceParams?.workspace === '-' ? '-' : projectParams?.project

  useEffect(() => {
    setSettingsManuallyExpanded(false)
  }, [location])

  const submenu = matchSettings ? 'settings' : project && project !== '-' ? 'project' : undefined

  const workspaceSidebar = useMemo(
    () => (
      <WorkspaceSidebar
        workspace={workspace}
        project={project}
        submenu={submenu}
        settingsManuallyExpanded={settingsManuallyExpanded}
        setSettingsManuallyExpanded={setSettingsManuallyExpanded}
      />
    ),
    [workspace, project, submenu, settingsManuallyExpanded]
  )

  const { isError } = useGetAccountQuery(workspace ? { owner: workspace } : skipToken)
  const { data: profile } = useWhoamiQuery()
  const { isError: orgMemberError } = useGetOrgMemberQuery(
    !profile
      ? skipToken
      : {
          owner: workspace,
          userSlug: profile.slug as string
        }
  )
  const { is_admin: isAdmin, slug: userSlug } = { ...profile }

  // Only admins or workspace members can view the workspace page. If viewing a project,
  // this check is skipped, since projects may be shared beyond a workspace with external
  // collaborators.
  const isWorkspaceViewBlocked = !project && !isAdmin && userSlug !== workspace && !!orgMemberError

  if (isError || isWorkspaceViewBlocked) {
    dispatch(clearMruWorkspace())
    return <WorkspaceDefaultRedirect />
  }

  return (
    <Shell sidebar={workspaceSidebar} submenuOpen={!!submenu} showPageAccentBackground={matchDsbomCompare}>
      {children}
    </Shell>
  )
}

export interface WorkspaceLayoutProps {
  children: JSX.Element
}

import { TableCard } from '@/components/Card/TableCard'

interface Props {
  children: React.ReactNode
  actions: React.ReactNode
}

export function TargetsTableCard({ actions, children }: Props) {
  return (
    <TableCard actions={actions} title="Targets">
      {children}
    </TableCard>
  )
}

import { Redirect, Route, RouteProps, DefaultParams, Path, useLocation } from 'wouter'

import { useAppSelector } from '../../hooks'
import { useSearch } from '../../hooks.wouter'
import { MayhemPageLoader } from '../../components/MayhemPageLoader'

export function PrivateMatch<T extends DefaultParams | undefined = undefined, RoutePath extends Path = Path>(
  props: RouteProps<T, RoutePath>
): JSX.Element {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)
  const isFetching = useAppSelector((state) => state.auth.isFetching)
  const logout = useAppSelector((state) => state.auth.logout)
  const [location] = useLocation()
  const search = useSearch()

  if (isFetching) {
    return <MayhemPageLoader />
  }

  if (isAuthenticated) {
    return <Route {...props} />
  }

  const referrerUrl = `${location}${search}`
  const to = logout || referrerUrl === '/' ? `/-/sign-in` : `/-/sign-in?referrer=${encodeURIComponent(referrerUrl)}`

  return <Redirect to={to} />
}

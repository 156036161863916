import { Center, Heading, Spinner, VStack } from '@chakra-ui/react'

import { Page } from './Page'

export function MayhemPageLoader() {
  return (
    <Page height="full" color="faded">
      <Center height="100vh">
        <VStack>
          <Spinner size="xl" />
          <Heading>Loading Mayhem...</Heading>
          <Heading size="md">Hang on for a second</Heading>
        </VStack>
      </Center>
    </Page>
  )
}

import { Icon, IconProps } from '@chakra-ui/react'

export function CloseIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 17 17" {...props}>
      <path
        d="M11.3775 8.25L16.0683 3.55922C16.6439 2.98359 16.6439 2.05031 16.0683 1.47422L15.0258 0.431719C14.4502 -0.143906 13.5169 -0.143906 12.9408 0.431719L8.25 5.1225L3.55922 0.431719C2.98359 -0.143906 2.05031 -0.143906 1.47422 0.431719L0.431719 1.47422C-0.143906 2.04984 -0.143906 2.98312 0.431719 3.55922L5.1225 8.25L0.431719 12.9408C-0.143906 13.5164 -0.143906 14.4497 0.431719 15.0258L1.47422 16.0683C2.04984 16.6439 2.98359 16.6439 3.55922 16.0683L8.25 11.3775L12.9408 16.0683C13.5164 16.6439 14.4502 16.6439 15.0258 16.0683L16.0683 15.0258C16.6439 14.4502 16.6439 13.5169 16.0683 12.9408L11.3775 8.25Z"
        fill="currentColor"
      />
    </Icon>
  )
}

import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['api'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      version: build.query<VersionApiResponse, VersionApiArg>({
        query: () => ({ url: `/api/` }),
        providesTags: ['api']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type VersionApiResponse = /** status 200
    Base/top-level API endpoint that returns info about this deployment of Mayhem,
    including the version and basic configuration.

    Returns:
        An `APIVersion` containing version and basic config info about this deployment.
     */ ApiVersion
export type VersionApiArg = void
export type MayhemBuildVersion = {
  major: number
  minor: number
  patch: number
  build_type: string
  build_number?: number | null
  commit_hash?: string | null
  build_date?: string | null
}
export type ApiVersion = {
  name: string
  environment: string
  offline: boolean
  public: boolean
  version: string
  build_version: MayhemBuildVersion
  atlassian_enabled: boolean
  atlassian_client_id?: string | null
}
export type ApiError = {
  message?: string
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  passwordReset: boolean
}

const initialState: State = {
  passwordReset: false
}

const resetPasswordSlice = createSlice({
  name: 'reset-password',
  initialState,
  reducers: {
    reset: (state) => {
      state.passwordReset = false
    },
    onPasswordReset: (state, action: PayloadAction<boolean>) => {
      state.passwordReset = action.payload
    }
  }
})

export const { reset, onPasswordReset } = resetPasswordSlice.actions
export default resetPasswordSlice.reducer

import { AccordionItem, AccordionButton, AccordionPanel, HStack, Button, AccordionItemProps } from '@chakra-ui/react'

import { useLocation } from 'wouter'

import { setQueryParam } from '../../util/location'
import { useQuery } from '../../hooks'

interface DsbomComparisonStepProps {
  title: string
  children: string | JSX.Element
  index: number
  nextValidator: () => boolean
  previousValidator: () => boolean
}

export function DsbomComparisonStep({
  title,
  children,
  index,
  previousValidator,
  nextValidator,
  ...props
}: DsbomComparisonStepProps & AccordionItemProps) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const setActiveStep = (index: number) => setLocation(setQueryParam({ location, queryParams, param: 'step', value: index.toString() }))

  return (
    <AccordionItem paddingX={6} paddingY={4} borderTop={index === 0 ? 'none' : undefined} {...props}>
      <AccordionButton color="faded" _expanded={{ color: 'primaryText' }}>
        {title}
      </AccordionButton>
      <AccordionPanel>
        {children}
        <HStack justifyContent="center" marginTop="4">
          {index > 0 && (
            <Button onClick={() => setActiveStep(index - 1)} variant="outline" paddingX={16} isDisabled={!previousValidator()}>
              Back
            </Button>
          )}
          <Button onClick={() => setActiveStep(index + 1)} paddingX={16} isDisabled={nextValidator()}>
            Next
          </Button>
        </HStack>
      </AccordionPanel>
    </AccordionItem>
  )
}

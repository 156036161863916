import { Box, Flex, Spacer } from '@chakra-ui/react'
import { Card, CardBody } from '@chakra-ui/react'
import { useLocation } from 'wouter'

import { Suspense, lazy, useCallback } from 'react'

import { stringifyUrl } from '../../util/location'
import { useQuery } from '../../hooks'

import { ReportingTimeFilterType } from '../admin/types'

import { FilterMenuButton } from '../../components/FilterMenuButton'

const ExportToPdfButton = lazy(() => import('./ExportToPdfButton'))

type FilterOption = {
  key: 'All Time' | 'Today' | 'Yesterday' | 'This Week' | 'Last Week' | 'This Month' | 'Last Month' | 'Last Quarter' | 'This Year' | 'Last Year'
  text: string
  value: ReportingTimeFilterType
}
const filterOptions: FilterOption[] = [
  {
    key: 'All Time',
    text: 'All Time',
    value: 'all_time'
  },
  {
    key: 'Today',
    text: 'Today',
    value: 'today'
  },
  {
    key: 'Yesterday',
    text: 'Yesterday',
    value: 'yesterday'
  },
  {
    key: 'This Week',
    text: 'This Week',
    value: 'this_week'
  },
  {
    key: 'Last Week',
    text: 'Last Week',
    value: 'last_week'
  },
  {
    key: 'This Month',
    text: 'This Month',
    value: 'this_month'
  },
  {
    key: 'Last Month',
    text: 'Last Month',
    value: 'last_month'
  },
  {
    key: 'Last Quarter',
    text: 'Last Quarter',
    value: 'last_quarter'
  },
  {
    key: 'This Year',
    text: 'This Year',
    value: 'this_year'
  },
  {
    key: 'Last Year',
    text: 'Last Year',
    value: 'last_year'
  }
]

export function FilterBar() {
  const [location, setLocation] = useLocation()
  const query = useQuery()
  const timeRange = query.get('t') || 'all_time'

  const selectTimeRange = useCallback(
    (timeRange: string | string[]) => {
      if (timeRange instanceof Array) {
        timeRange = timeRange[0]
      }
      setLocation(stringifyUrl(location, { t: timeRange }), { replace: true })
    },
    [location, setLocation]
  )

  return (
    <Card>
      <CardBody p={4}>
        <Flex alignItems="center" padding={4}>
          <Box>
            Show me data in Mayhem from&nbsp;&nbsp;
            <FilterMenuButton options={filterOptions} defaultText="All Time" value={timeRange} onValueChange={selectTimeRange} variant="outline" />
          </Box>
          <Spacer />
          <Suspense fallback={<></>}>
            <ExportToPdfButton />
          </Suspense>
        </Flex>
      </CardBody>
    </Card>
  )
}

import { useEffect } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip
} from '@chakra-ui/react'
import { Link as WouterLink, useLocation } from 'wouter'

import { getProjectsSettingsPageTitle } from '../../titles'
import { getCurrentUserInfo } from '../auth/utils'
import { useAppSelector } from '../../hooks'
import { ProjectVisibilityToggle } from '../project-settings/ProjectVisibilityToggle'
import { useGetAccountQuery } from '../../redux/api/workspace'
import { useGetProjectMemberPermissionQuery } from '../../redux/api/projects'

import { ContributorsTab } from './ContributorsTab'
import { TeamsTab } from './TeamsTab'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'

type Props = {
  workspace: string
  project: string
}

export function AccessControlPage({ workspace: owner, project: projectSlug }: Props) {
  const [location, setLocation] = useLocation()

  const { userSlug: currentUserSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { data: workspace, isLoading: isWorkspaceLoading } = useGetAccountQuery({ owner })
  const { data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner,
    projectSlug,
    userSlug: currentUserSlug
  })

  const canManageTeams = workspace?.is_org

  useEffect(() => {
    document.title = getProjectsSettingsPageTitle(owner, projectSlug, 'Access Control')
  }, [location, owner, projectSlug, setLocation])

  const isAtLeastWritePermission = isMayhemAdmin || (projectPermission && projectPermission.permission !== 'READ')

  return (
    <Page>
      <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }} mb={8}>
        <BreadcrumbItem>
          <WouterLink to={`/${workspace}/${projectSlug}`}>
            <BreadcrumbLink>
              <TruncatedTextWithTooltip text={projectSlug} />
            </BreadcrumbLink>
          </WouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Access Control</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Heading mb={8}>Access Control</Heading>
      <Stack spacing={8}>
        {isAtLeastWritePermission && (
          <Card mb={8}>
            <CardHeader>
              <Heading variant="cardHeading">Project Visibility</Heading>
            </CardHeader>
            <CardBody p={4}>
              <ProjectVisibilityToggle workspace={owner} projectSlug={projectSlug} isAdmin={isAtLeastWritePermission} />
            </CardBody>
          </Card>
        )}
        <Card>
          <CardHeader>
            <Heading variant="cardHeading">Contributors</Heading>
          </CardHeader>
          <CardBody padding={8}>
            <Tabs variant="line">
              <TabList>
                <Tab>Users</Tab>
                <Tab isDisabled={isWorkspaceLoading || !canManageTeams}>
                  <Tooltip
                    isDisabled={canManageTeams}
                    label={`The project "${projectSlug}" is a part of the ${owner} personal account. Teams can only be created and added to projects belonging to organizations.`}
                  >
                    Teams
                  </Tooltip>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <ContributorsTab workspace={owner} projectSlug={projectSlug} />
                </TabPanel>
                <TabPanel>
                  <TeamsTab workspace={owner} projectSlug={projectSlug} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </CardBody>
        </Card>
      </Stack>
    </Page>
  )
}

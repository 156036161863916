/**
 * Utility to avoid writing routes on template literals
 * Usage:
 * `/${workspace}/${project}/${target}`
 * become
 * link(workspace, project, target)
 * @param parts
 */
export const link = (...parts: string[]) => {
  return '/' + parts.join('/')
}

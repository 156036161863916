import { FieldPath, useFormContext } from 'react-hook-form'
import { useCallback, useMemo } from 'react'
import { Box, Button, Input, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'

import { FormCodeRunConfig } from './utils'

export interface CodeRunConfigCommandEnvironmentProps {
  cmdIndex: number
}

export const CodeRunConfigCommandEnvironment = ({ cmdIndex }: CodeRunConfigCommandEnvironmentProps) => {
  const { register, getValues, setValue, watch } = useFormContext<FormCodeRunConfig>()

  // re-render whenever the env variables are updated
  // without this, re-renders do not happen when the form value is set!
  watch(`cmdSettings.${cmdIndex}.env`)

  const formValues = getValues()
  const cmdSettings = formValues.cmdSettings && formValues.cmdSettings[cmdIndex]
  const envVariables = useMemo(() => cmdSettings?.env || [], [cmdSettings?.env])

  const handleAddEnvironmentVariable = useCallback((): void => {
    setValue(`cmdSettings.${cmdIndex}.env.${envVariables.length}`, { key: '', value: '' })
  }, [cmdIndex, envVariables.length, setValue])

  const handleRemoveEnvironmentVariable = useCallback(
    (envIndex: number): void => {
      const newEnvVars = [...envVariables]
      if (newEnvVars.length > envIndex) {
        newEnvVars.splice(envIndex, 1)
        setValue(`cmdSettings.${cmdIndex}.env`, newEnvVars)
      }
    },
    [cmdIndex, envVariables, setValue]
  )

  return (
    <Box>
      <Button variant="outline" onClick={handleAddEnvironmentVariable}>
        Add Environment Variable
      </Button>
      <Table>
        {envVariables.length !== 0 && (
          <Thead>
            <Tr>
              <Th width={7}>Key</Th>
              <Th width={7}>Value</Th>
              <Th width={2} textAlign="right">
                Delete
              </Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          {envVariables.map((envVar, envIndex) => {
            const keyField: FieldPath<FormCodeRunConfig> = `cmdSettings.${cmdIndex}.env.${envIndex}.key`
            const valueField: FieldPath<FormCodeRunConfig> = `cmdSettings.${cmdIndex}.env.${envIndex}.value`
            return (
              <Tr key={`cmd-env-var-${cmdIndex}-${envIndex}`}>
                <Td data-heading="Key">
                  <Input placeholder="Name of an environment variable" {...register(keyField)} />
                </Td>
                <Td data-heading="Value">
                  <Input placeholder="Value of an environment variable" {...register(valueField)} />
                </Td>
                <Td data-heading="Delete" textAlign="right">
                  <Button variant="outline" onClick={() => handleRemoveEnvironmentVariable(envIndex)}>
                    x
                  </Button>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      {envVariables.length === 0 && (
        <Text textAlign="center" fontWeight="semibold">
          No environment variables set.
        </Text>
      )}
    </Box>
  )
}

import { Box, Card, HStack, StatLabel, Stat, StatNumber, Center, Icon, Skeleton } from '@chakra-ui/react'
import BoltIcon from '@material-design-icons/svg/sharp/bolt.svg?react'

import { StatusHits, ResponseHitsPanel } from './ResponsePanel'
import { LatencyPanel, Latencies } from './LatencyPanel'

export function EndpointSynopsis({
  numDefects,
  statusBucketHits,
  latencies
}: {
  numDefects?: number
  statusBucketHits?: StatusHits
  latencies?: Latencies
}) {
  return (
    <Card p={4} borderWidth={0}>
      <HStack w="100%" justifyContent="space-between" alignItems="begin">
        <Box>
          <Stat border="0" colorScheme="transparent">
            <StatLabel fontSize="14">Defects</StatLabel>
            <Skeleton isLoaded={numDefects !== undefined}>{numDefects !== undefined && <StatNumber>{numDefects}</StatNumber>}</Skeleton>
          </Stat>
        </Box>
        <Box>
          {statusBucketHits && (
            <Stat border="0" colorScheme="transparent">
              <Center pb={4}>
                <StatLabel fontSize="14">Response Status Codes</StatLabel>
              </Center>
              <Skeleton isLoaded={statusBucketHits !== undefined}>
                {statusBucketHits && <ResponseHitsPanel status_hits={statusBucketHits as StatusHits} />}
              </Skeleton>
            </Stat>
          )}
        </Box>
        <Box>
          {latencies && (
            <Stat border="0" colorScheme="transparent">
              <Center pb={4}>
                <StatLabel fontSize="14">
                  <Icon as={BoltIcon} /> Latency
                </StatLabel>
              </Center>

              <Skeleton isLoaded={latencies !== undefined}>{latencies !== undefined && <LatencyPanel latencies={latencies as Latencies} />}</Skeleton>
            </Stat>
          )}
        </Box>
      </HStack>
    </Card>
  )
}

// MRU = Most Recently Used
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const mruSlice = createSlice({
  name: 'mru',
  initialState: '',
  reducers: {
    setMruWorkspace: (state, action: PayloadAction<string>) => {
      return action.payload
    },
    clearMruWorkspace: (_) => {
      return ''
    }
  }
})

export const { setMruWorkspace, clearMruWorkspace } = mruSlice.actions

export default mruSlice.reducer

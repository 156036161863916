import { Center, Heading, HStack, Icon, Text, useMultiStyleConfig, VStack, chakra } from '@chakra-ui/react'
import SettingsIcon from '@material-design-icons/svg/sharp/settings.svg?react'
import { useEffect, useState } from 'react'

function randStr(len: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789<>\'"'
  let symbols = Array.from(characters)
  symbols = symbols.concat(['🔥', '❤️', '🤖', '💥', '的', 'フ', 'ァ', '퍼', '징'])
  const charactersLength = symbols.length

  const result = []
  for (let i = 0; i < len; i++) {
    result.push(symbols[Math.floor(Math.random() * charactersLength)])
  }
  return result.join('')
}

export function FuzzingLoader() {
  const styles = useMultiStyleConfig('FuzzingLoader')

  const [randomStr, setRandomStr] = useState('')
  useEffect(() => {
    const interval = setInterval(() => {
      setRandomStr(randStr(15))
    }, 100)
    return () => clearInterval(interval)
  }, [])

  return (
    <Center paddingY={20}>
      <VStack>
        <Heading>
          <HStack>
            <Icon __css={styles.icon} as={SettingsIcon} />
            <chakra.p __css={styles.header}>Testing in progress...</chakra.p>
          </HStack>
        </Heading>
        <chakra.p __css={styles.randomText}>{randomStr}</chakra.p>
        <Text>Defects will appear here as soon as we find any!</Text>
      </VStack>
    </Center>
  )
}

import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getDockerRepositories: build.query<GetDockerRepositoriesApiResponse, GetDockerRepositoriesApiArg>({
        query: () => ({ url: `/api/v2/docker/repository` }),
        providesTags: ['internal']
      }),
      getDockerTags: build.query<GetDockerTagsApiResponse, GetDockerTagsApiArg>({
        query: (queryArg) => ({ url: `/api/v2/docker/tag`, params: { repository: queryArg.repository } }),
        providesTags: ['internal']
      }),
      getDockerImageConfig: build.query<GetDockerImageConfigApiResponse, GetDockerImageConfigApiArg>({
        query: (queryArg) => ({ url: `/api/v2/docker/config`, params: { image: queryArg.image } })
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetDockerRepositoriesApiResponse = /** status 200 Fetches the list of repositories hosted on mayhem docker registry.

    Returns:
        Response - a list of repositories hosted on mayhem docker registry. */ GetDockerRepositories
export type GetDockerRepositoriesApiArg = void
export type GetDockerTagsApiResponse = /** status 200 Fetches the list of tags for given repository hosted on mayhem docker registry.

    Returns:
        Response - a list of tags for the given repository hosted on mayhem docker
        registry.
     */ GetDockerTags
export type GetDockerTagsApiArg = {
  repository: string
}
export type GetDockerImageConfigApiResponse = /** status 200 OK */ BaseSchemaWithoutTimestamps
export type GetDockerImageConfigApiArg = {
  image: string
}
export type GetDockerRepositories = {
  repositories?: string[]
}
export type ApiError = {
  message?: string
}
export type GetDockerTags = {
  tags?: string[]
}
export type BaseSchemaWithoutTimestamps = {}

// From https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
//
// While it's possible to import the RootState and AppDispatch types into
// each component, it's better to create pre-typed versions of the useDispatch
// and useSelector hooks for usage in your application. This is important for
// a couple of reasons:
//
// * For "useSelector", it saves you the need to type (state: RootState) every time
// * For "useDispatch", the default Dispatch type does not know about thunks or
//   other middleware. In order to correctly dispatch thunks, you need to use
//   the specific customized AppDispatch type from the store that includes the
//   thunk middleware types, and use that with useDispatch. Adding a pre-typed
//   "useDispatch" hook keeps you from forgetting to import AppDispatch where it's needed.
//
// Since these are actual variables, not types, it's important to define them in
// a separate file such as app/hooks.ts, not the store setup file. This allows you
// to import them into any component file that needs to use the hooks, and avoids
// potential circular import dependency issues.

import { DependencyList, useEffect } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { AUTOREFRESH_INTERVAL } from './redux/api/mayhemApi'
import { useSearch } from './hooks.wouter'

import type { RootState, AppDispatch } from './redux/store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useQuery() {
  const search = useSearch()
  return new URLSearchParams(search)
}

export enum IntervalLifecycle {
  Init = 'init',
  Interval = 'interval'
}

/**
 * @param action The action to perform at an interval.
 * @param interval The number of milliseconds between executions of action.
 * @param deps If present, effect will only activate if the values in the list change.
 */
export const useInterval = ({
  action,
  interval = AUTOREFRESH_INTERVAL,
  deps = []
}: {
  action: (lifecycle: IntervalLifecycle) => void
  interval?: number
  deps?: DependencyList
}) => {
  useEffect(() => {
    action(IntervalLifecycle.Init)

    const handle = window.setInterval(() => {
      action(IntervalLifecycle.Interval)
    }, interval)

    return () => {
      window.clearInterval(handle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, ...deps])
}

import React, { useState } from 'react'

import { Button, ButtonGroup, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from '@chakra-ui/react'

export default function ProjectDeleteModal(props: {
  open: boolean
  deleteHandler: (owner: string, projectSlug: string, projectName: string) => Promise<void>
  closeHandler: () => void
  owner: string
  projectSlug: string
  projectName: string
}) {
  const { open, deleteHandler, closeHandler, owner, projectSlug, projectName } = props
  const [projectNameInput, setProjectNameInput] = useState('')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectNameInput(e.target.value)
  }

  const onKeyUp = async (e: React.KeyboardEvent) => {
    const ENTER_KEY_CODE = 13
    if (e.keyCode === ENTER_KEY_CODE && projectNameInput === projectName) {
      await deleteHandler(owner, projectSlug, projectName)
      closeHandler()
    }
  }

  return (
    <Modal isOpen={open} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Delete Project {owner}/{projectName}
        </ModalHeader>
        <ModalBody>
          <Stack gap={4}>
            <Text>Type the name of the project ({projectName}) in the box below to confirm deletion.</Text>
            <Text as="i">Note: this will also delete all runs for this project (including any active runs).</Text>
            <Input type="text" defaultValue="" placeholder={projectName} onChange={handleInputChange} onKeyUp={onKeyUp} />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="outline" onClick={closeHandler}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isDisabled={projectNameInput !== projectName}
              onClick={async () => {
                await deleteHandler(owner, projectSlug, projectName)
                closeHandler()
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

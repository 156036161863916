import { Center, Heading, Stack, StackDivider, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'

import { skipToken } from '@reduxjs/toolkit/query'

import ListFooter from '../../components/ListFooter'
import { cleanInteger } from '../../util/numbers'
import { useAppSelector, useQuery } from '../../hooks'
import { useGetProjectTeamMembersQuery } from '../../redux/api/teams'

import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetProjectMemberPermissionQuery } from '../../redux/api/projects'

import { getCurrentUserInfo } from '../auth/utils'

import { useGetOrgMemberQuery } from '../../redux/api/workspace'

import { TeamRow } from './TeamRow'
import { AddTeamToProjectForm } from './AddTeamToProjectForm'

type Props = {
  workspace: string
  projectSlug: string
}

export function TeamsTab({ workspace, projectSlug }: Props) {
  const { userSlug: currentUserSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const query = useQuery()
  const page = cleanInteger(query.get('teamspage'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)

  const { data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner: workspace,
    projectSlug,
    userSlug: currentUserSlug
  })

  const { data: currentMember } = useGetOrgMemberQuery(
    !currentUserSlug
      ? skipToken
      : {
          owner: workspace,
          userSlug: currentUserSlug
        }
  )

  const {
    data: teamsResponse,
    refetch,
    isFetching,
    isError
  } = useGetProjectTeamMembersQuery({ owner: workspace, projectSlug, perPage, offset: (page - 1) * perPage })

  const isAtLeastWritePermission = isMayhemAdmin || (currentMember && projectPermission && projectPermission.permission !== 'READ')

  const teams = teamsResponse?.teams || []
  const count = teamsResponse?.count || 0

  return (
    <Stack spacing={8} mt={8} divider={<StackDivider />}>
      <Heading size="md">Existing Teams Contributors</Heading>
      {isAtLeastWritePermission && <AddTeamToProjectForm workspace={workspace} projectSlug={projectSlug} />}
      <TableContainer>
        <Table layout="fixed">
          <Thead>
            <Tr>
              <Th width="37.5%" textAlign="left">
                Team
              </Th>
              <Th width="25%" textAlign="left">
                Permission
              </Th>
              <Th width="12.5%" textAlign="center">
                Remove
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {teams.map((team) => (
              <TeamRow team={team} key={team.slug} workspace={workspace} projectSlug={projectSlug} onDelete={refetch} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Center>
        <ListFooter isLoading={isFetching} nItems={teams.length} total={count} perPage={perPage} itemName="team" isFail={isError} prefix="teams" />
      </Center>
    </Stack>
  )
}

import slugify from 'slugify'

export function mayhemParseFloat(str: string | undefined, fallback: number | undefined) {
  if (str === undefined) {
    return fallback
  }
  const value = Number.parseFloat(str)
  if (Number.isNaN(value)) {
    return fallback
  }
  return value
}

export const mayhemLoader = {
  open: (): void => {
    const mainContent = document.body

    const elem = document.createElement('div')
    elem.classList.add('mh-loader')
    elem.addEventListener('click', (e) => e.stopPropagation())

    const loader = document.createElement('div')
    loader.classList.add('ui', 'active', 'text', 'centered', 'inline', 'loader')
    elem.prepend(loader)

    if (mainContent) {
      mainContent.prepend(elem)
    }
  },
  close: (): void => {
    const elem = document.getElementsByClassName('mh-loader')[0]
    if (elem) {
      elem.remove()
    }
  }
}

/**
 * Default `slugify` function to use across the codebase.
 */
export function slugifyFull(string: string) {
  // Ensure underscores are replaced with hyphens and not silently removed,
  // matching the slugification behavior on the Python side (API/CLI). Idea
  // from: https://github.com/simov/slugify/issues/64
  slugify.extend({ _: '-' })
  return slugify(string || '', { strict: true, replacement: '-', lower: true })
}

type SetLike<T> = Set<T> | T[]

export function areSetEqual<T>(a: SetLike<T>, b: SetLike<T>) {
  if (a instanceof Array) {
    a = new Set(a)
  }
  if (b instanceof Array) {
    b = new Set(b)
  }
  const aSet: Set<T> = a
  const bSet: Set<T> = b
  return aSet.size === bSet.size && [...bSet].every((x) => aSet.has(x))
}

export const skipIfDefault = <T>(value: T, defaultValue: T, compare?: (a: T, b: T) => boolean): T | undefined => {
  if (!compare) {
    compare = (a, b) => a === b
  }
  if (compare(value, defaultValue)) {
    return undefined
  }
  return value
}

export const pruneIf = <T extends Record<string, unknown>>(value: T, condition: <K extends keyof T>(key: K, value: T[K]) => boolean) => {
  const clean = { ...value }
  for (const field of Object.keys(clean)) {
    if (condition(field, value[field] as T[keyof T])) {
      delete clean[field]
    }
  }
  return clean
}
export const pruneUndefined = <T extends Record<string, unknown>>(value: T, optional?: (keyof T)[]): T => {
  if (optional === undefined) {
    optional = Object.keys(value)
  }

  const clean = { ...value }
  for (const field of optional) {
    if (clean[field] === undefined) {
      delete clean[field]
    }
  }
  return clean
}

export const zip = <A, B>(a: A[], b: B[]): [A, B][] => {
  const result: [A, B][] = []
  for (let i = 0; i < a.length; i++) {
    result.push([a[i], b[i]])
  }
  return result
}

import { useEffect } from 'react'

import { Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Stack, useToast } from '@chakra-ui/react'

import { FormProvider, useForm } from 'react-hook-form'

import { useGetUserSettingQuery, usePutUserSettingMutation } from '../../redux/api/workspace'

type Form = {
  mbotUtilization: number
}

export function MayhemBotSettingsForm() {
  const toast = useToast()

  const { data: userSetting, isLoading, refetch } = useGetUserSettingQuery()
  const [putUserSetting] = usePutUserSettingMutation()

  const methods = useForm<Form>({ defaultValues: { mbotUtilization: userSetting?.mbot_utilization ?? undefined } })
  const {
    handleSubmit,
    register,
    resetField,
    setError,
    formState: { errors, isValid, isSubmitting }
  } = methods

  useEffect(() => {
    if (!isLoading && userSetting) {
      resetField('mbotUtilization', { defaultValue: userSetting.mbot_utilization ?? undefined })
    }
  }, [resetField, userSetting, isLoading])

  const onSubmit = async ({ mbotUtilization }: Form) => {
    try {
      await putUserSetting({ userSetting: { mbot_utilization: mbotUtilization } })
      await refetch()
      toast({
        title: 'Workspace Updated',
        description: `Mayhem bot worker count has been updated`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (err: unknown) {
      const message = (err as Error)?.message || 'An error occurred. Please try again.'
      setError('mbotUtilization', { type: 'custom', message: message })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.mbotUtilization}>
            <FormLabel>The maximum number of workers to be used by mayhem bot</FormLabel>
            <Input type="number" {...register('mbotUtilization', { min: 0 })} />
            <FormHelperText>
              Use value 0 to <strong>disable</strong> this service.
            </FormHelperText>
            {errors?.mbotUtilization && <FormErrorMessage>{errors.mbotUtilization.message}</FormErrorMessage>}
          </FormControl>

          <Flex justify="flex-end">
            <Button type="submit" isLoading={isLoading || isSubmitting} isDisabled={!isValid}>
              Save
            </Button>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  )
}

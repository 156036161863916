import React from 'react'
import { Link } from 'wouter'
import {
  Box,
  Heading,
  Link as ChakraLink,
  Stack,
  Icon,
  Alert,
  AlertIcon,
  Button,
  Text,
  Skeleton,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  chakra,
  AlertDescription,
  Flex,
  VStack,
  HStack
} from '@chakra-ui/react'
import ArrowCircleRightIcon from '@material-design-icons/svg/sharp/arrow_circle_right.svg?react'
import LightbulbIcon from '@material-design-icons/svg/sharp/lightbulb.svg?react'

import { Feature } from 'flagged'

import { useGetDefectByTargetQuery, useGetIssueRuleQuery, useGetProjectRestApiIssuesQuery, useGetRestApiIssueQuery } from '../../redux/api/defects'
import { CWEBadge } from '../../components/CWEBadge'
import { ProjectPermissionGuardText } from '../project/ProjectPermissionGuard'

import { JiraIssue } from '../defect-jira/JiraIssue'

import { Markdown } from '../../components/Markdown/Markdown'
import { getDocsUrl } from '../../urls'

import { ErrorPanel } from '../../components/ErrorPanel'

import { FEATURE_LIFECYCLE_MANAGEMENT } from '../../featureFlags'

import { ApiDefectMetadata } from './ApiDefectMetadata'
import { ApiDefectSampleRequestResponse } from './ApiDefectSampleRequestResponse'

import { DefectStatusControls } from './DefectStatusControls'

// Requests may include base64(user:<REDACTED>) after auth redaction. The frontend replaces that
// string with <REDACTED> to make it clear to the user that the auth was redacted.
export const BASE64_ENCODED_BASIC_AUTH_REDACTION = 'dXNlcjo8UkVEQUNURUQ+'

type Props = {
  owner: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  runNumber: number | null | undefined
}

export function RestIssue({ owner, projectSlug, targetSlug, runNumber, defectNumber }: Props) {
  // use the defect for details available on public projects
  const { data: defect } = useGetDefectByTargetQuery({ owner, projectSlug, targetSlug, defectNumber })

  const {
    isLoading: isIssueLoading,
    issue: runIssue,
    isError: isRunIssueError
  } = useGetRestApiIssueQuery(
    {
      owner,
      projectSlug,
      targetSlug,
      runNumber: runNumber || 0,
      defectNumber
    },
    { skip: runNumber === undefined || runNumber === null || defectNumber === undefined || defectNumber === null }
  )

  const {
    isLoading: isProjectIssueLoading,
    issue: projectIssue,
    isError: isProjectIssueError
  } = useGetProjectRestApiIssuesQuery(
    {
      owner,
      projectSlug,
      defectNumber
    },
    { skip: runNumber !== undefined && runNumber !== null && defectNumber !== undefined && defectNumber !== null }
  )

  const issue = projectIssue || runIssue
  const isIssueError = isProjectIssueError || isRunIssueError

  const { isLoading: isRuleLoading, data: rule } = useGetIssueRuleQuery(
    { owner, projectSlug, issueRuleId: issue?.issue_rule_id || '' },
    { skip: !issue }
  )

  if (isIssueLoading || isRuleLoading || isProjectIssueLoading) {
    return <Skeleton />
  }
  if (!defect) {
    return <ErrorPanel msg="We can't find that defect for you. Sorry about that!" />
  }

  return (
    <Stack spacing={8} marginTop={8}>
      <Stack>
        <Flex flexDirection={{ base: 'column', lg: 'row' }} gap="2" justifyContent="space-between">
          <VStack alignItems="start">
            <Heading size="lg">{defect.title}</Heading>
            <JiraIssue owner={owner} projectSlug={projectSlug} defectNumber={defectNumber} targetSlug={targetSlug} />
            <Stack direction={{ base: 'column', sm: 'row' }} alignItems="flex-start">
              {rule && rule.cwes?.map((cwe) => <CWEBadge key={cwe.cwe_id} cwe={cwe} />)}
              {!rule && <CWEBadge cwe={{ cwe_id: `CWE-${defect.cwe_number}`, link: defect.cwe_link }} />}
            </Stack>
          </VStack>
          <Feature name={FEATURE_LIFECYCLE_MANAGEMENT}>
            <DefectStatusControls owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
          </Feature>
        </Flex>

        {isIssueError && (
          <Alert status="warning">
            <AlertIcon />
            <AlertDescription>
              <ProjectPermissionGuardText />
            </AlertDescription>
          </Alert>
        )}

        <ApiDefectMetadata issue={issue} />
      </Stack>
      <Stack spacing={12} maxWidth="full">
        {issue?.issue_rule_id === 'internal-server-error' && issue?.stacktrace == null && (
          <Alert width="auto">
            <AlertIcon />
            <AlertDescription>
              This report isn&apos;t as detailed as it could be.{'   '}
              <ChakraLink isExternal href={getDocsUrl('api-testing/tutorials/optimizing-test-coverage/configure-your-api/')}>
                <Button variant="link">Learn More</Button>
              </ChakraLink>
            </AlertDescription>
          </Alert>
        )}
        <React.Suspense fallback={<Text>Loading...</Text>}>
          {rule && (
            <Box>
              <chakra.pre fontSize="md" fontFamily="body" lineHeight="1.75" marginY={4}>
                {rule.full_description}
              </chakra.pre>

              <ChakraLink as={Link} to={`/${owner}/${projectSlug}/-/api-issue-rule/${rule.issue_rule_id}`}>
                <Button variant="link" rightIcon={<Icon as={ArrowCircleRightIcon} />}>
                  More details
                </Button>
              </ChakraLink>
            </Box>
          )}

          {rule?.hint && (
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <HStack>
                        <Icon as={LightbulbIcon} />
                        <Text>Hint</Text>
                      </HStack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <Markdown>{rule.hint}</Markdown>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}

          <ApiDefectSampleRequestResponse owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} issue={issue} isIssueError={isIssueError} />
        </React.Suspense>
      </Stack>
    </Stack>
  )
}

import dayjs, { Dayjs } from 'dayjs'

import { chartAxis } from './chartAxis'

export type TimeUnit = 'month' | 'day' | 'hour' | 'minute'

export interface ChartTimeAxisProps {
  start: number
  end: number
  axis?: 'x' | 'y'
  field?: string
  unit?: TimeUnit
  step?: number
}

export function chartAxisTime({ start, end, axis = 'x', field = 'timestamp', unit = 'day', step = 1 }: ChartTimeAxisProps) {
  return chartAxis({
    axis,
    dataKey: field,
    scale: 'time',
    type: 'number',
    domain: [start, end],
    ticks: getTicks(start, end, unit, step),
    tickFormatter: (timestamp: number) => formatTimeAxisTick(timestamp, unit)
  })
}

export const formatTimeAxisTick = (date: Dayjs | Date | string | number, unit: TimeUnit) => {
  if (!dayjs.isDayjs(date)) {
    date = dayjs(date)
  }
  if (unit === 'day') {
    return date.format('DD MMM')
  }
  if (unit === 'hour' || unit === 'minute') {
    return date.format('HH:mm')
  }
  return date.format('MMM YY')
}

function getTicks(start: number, end: number, unit: TimeUnit, step: number) {
  if (!start || !end) {
    return []
  }

  let current = dayjs(start)
  const ticks = [current.valueOf()]

  while (current.isBefore(end)) {
    current = current.add(step, unit)
    ticks.push(current.valueOf())
  }

  return ticks
}

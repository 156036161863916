import { Button, ButtonProps, Menu, MenuButton, MenuItemOption, MenuItemOptionProps, MenuList, MenuOptionGroup, Portal } from '@chakra-ui/react'
import ArrowDropDownIcon from '@material-design-icons/svg/sharp/arrow_drop_down.svg?react'

type Props<TValue extends string, TOption extends { key: string; value: TValue | undefined; text: string }> = ButtonProps & {
  options: TOption[]
  defaultText: string
  value: TValue | undefined
  onValueChange: (selectedValue: TValue | TValue[]) => void
  menuItemOptions?: MenuItemOptionProps
  capitalize?: boolean
}

export function FilterMenuButton<TValue extends string, TOption extends { key: string; value: TValue | undefined; text: string }>({
  defaultText,
  onValueChange,
  options,
  value,
  menuItemOptions,
  capitalize = true,
  ...rest
}: Props<TValue, TOption>) {
  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ArrowDropDownIcon />} variant="ghost" textTransform={capitalize ? 'capitalize' : undefined} {...rest}>
        {options.find((option) => option.value === value)?.text || defaultText}
      </MenuButton>
      <Portal>
        <MenuList maxHeight={64} overflowY="auto">
          <MenuOptionGroup value={value} onChange={(value) => onValueChange(value as TValue | TValue[])}>
            {options.map((option) => (
              <MenuItemOption key={option.key} value={option.value} {...menuItemOptions}>
                {option.text}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}

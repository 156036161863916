import { UnitType } from './DefectTrendChart'

export type FilterBarOption = '12 months' | '3 months' | '30 days' | '7 days' | '24 hours'

export function toCountAndUnit(option: FilterBarOption) {
  let count: number | null = null
  let unit: UnitType | null = null

  if (option === '12 months') {
    count = 12
    unit = 'month'
  }

  if (option === '3 months') {
    count = 3
    unit = 'month'
  }

  if (option === '30 days') {
    count = 30
    unit = 'day'
  }

  if (option === '7 days') {
    count = 7
    unit = 'day'
  }

  if (option === '24 hours') {
    count = 24
    unit = 'hour'
  }

  return { count, unit }
}

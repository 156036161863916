import { opaque } from '../../opaque'
import { FormCodeRunConfig } from '../run-code-config/utils'

export const DefaultValues = opaque<FormCodeRunConfig>({
  project: '',
  target: '',
  dockerImage: '',
  cmds: '',
  duration: 300,
  tasks: ['exploitability_factors', 'behavior_testing', 'regression_testing'],
  advanced_triage: false
})
export const DemoValues = opaque<FormCodeRunConfig>({
  project: 'My Awesome Project',
  target: 'My Target',
  dockerImage: 'forallsecure/lighttpd:vulnerable',
  cmds: '$ /usr/local/sbin/lighttpd -D -f /usr/local/etc/lighttpd.conf',
  duration: 300,
  tasks: ['exploitability_factors', 'behavior_testing', 'regression_testing', 'coverage_analysis'],
  advanced_triage: true,
  cmdSettings: [
    {
      network: {
        url: 'tcp://localhost:80',
        timeout: '2'
      },
      afl: 'automatic',
      honggfuzz: 'automatic',
      libfuzzer: 'automatic',
      sanitizer: 'automatic'
    }
  ]
})

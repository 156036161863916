import { CircularProgress, CircularProgressProps } from '@chakra-ui/react'

import { getBackgroundColorFromPercent } from '@/theming/status'

export type PercentProgressStatusIndicatorProps = CircularProgressProps & {
  percent: number
}

export function PercentProgressStatusIndicator({ percent, children, ...rest }: PercentProgressStatusIndicatorProps) {
  return (
    <CircularProgress value={percent} color={getBackgroundColorFromPercent(percent)} {...rest}>
      {children}
    </CircularProgress>
  )
}

import { ComponentStyleConfig } from '@chakra-ui/react'

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 0,
    paddingInline: 1.5,
    paddingTop: 1,
    paddingBottom: 1
  },
  variants: {
    secondary: () => ({
      border: '1px',
      borderColor: 'chakra-border-color',
      color: 'primaryText'
    }),
    faded: () => ({
      border: '1px',
      borderColor: 'chakra-border-color',
      color: 'faded'
    })
  },
  defaultProps: {
    variant: 'solid'
  }
}

import { ChangeEvent, useCallback } from 'react'
import {
  Box,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Spacer,
  Spinner
} from '@chakra-ui/react'
import FilterListIcon from '@material-design-icons/svg/sharp/filter_list.svg?react'
import SearchIcon from '@material-design-icons/svg/sharp/search.svg?react'

export type ProjectFilterValue = 'all' | 'defects-high' | 'defects-medium' | 'defects-low' | 'failing'
export interface ProjectListingControlsProps {
  isFetching: boolean

  search: string | undefined
  onSearchChange: (value: string) => void

  filter: ProjectFilterValue
  onFilterChange: (value: ProjectFilterValue) => void
}

export const ProjectListingControls = ({ isFetching, search, onSearchChange, filter, onFilterChange }: ProjectListingControlsProps) => {
  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onSearchChange(event.target.value)
    },
    [onSearchChange]
  )
  const handleFilterChange = useCallback(
    (value: string | string[]) => {
      if (value instanceof Array) {
        value = value[0]
      }
      onFilterChange(value as ProjectFilterValue)
    },
    [onFilterChange]
  )

  return (
    <Box display="flex" flexDirection="row" flex="1">
      <InputGroup flex="0 0 20rem">
        <InputLeftElement pointerEvents="none">
          <Icon as={SearchIcon} />
        </InputLeftElement>
        <Input placeholder="Search..." defaultValue={search} onChange={handleSearchChange} />
        {isFetching && (
          <InputRightElement>
            <Spinner />
          </InputRightElement>
        )}
      </InputGroup>
      <Spacer />
      <Menu>
        <MenuButton as={IconButton} variant="ghost" aria-label="Filter" icon={<FilterListIcon />} />
        <Portal>
          <MenuList>
            <MenuOptionGroup value={filter} onChange={handleFilterChange}>
              <MenuItemOption value="all">All</MenuItemOption>
              <MenuItemOption value="defects-high">High</MenuItemOption>
              <MenuItemOption value="defects-medium">Medium</MenuItemOption>
              <MenuItemOption value="defects-low">Low</MenuItemOption>
              <MenuItemOption value="failing">Failing</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )
}

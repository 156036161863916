import { Icon } from '@chakra-ui/react'
import PriorityHighIcon from '@material-design-icons/svg/sharp/priority_high.svg?react'
import CancelIcon from '@material-design-icons/svg/sharp/cancel.svg?react'
import AssignmentLateIcon from '@material-design-icons/svg/sharp/assignment_late.svg?react'
import InfoIcon from '@material-design-icons/svg/sharp/info.svg?react'
import SearchIcon from '@material-design-icons/svg/sharp/search.svg?react'

export type LevelFilterOption = {
  key: 'All Levels' | 'Critical' | 'Error' | 'Warning' | 'Info' | 'Debug'
  text: 'All Levels' | 'Critical' | 'Error' | 'Warning' | 'Info' | 'Debug'
  value: '0' | '1' | '2' | '3' | '4' | 'all'
  icon: JSX.Element | null
}

export const levelFilters: Record<string, LevelFilterOption> = {
  all: {
    key: 'All Levels',
    text: 'All Levels',
    value: 'all',
    icon: null
  },
  4: {
    key: 'Critical',
    text: 'Critical',
    value: '4',
    icon: <Icon as={PriorityHighIcon} boxSize={6} />
  },
  3: {
    key: 'Error',
    text: 'Error',
    value: '3',
    icon: <Icon as={CancelIcon} boxSize={6} />
  },
  2: {
    key: 'Warning',
    text: 'Warning',
    value: '2',
    icon: <Icon as={AssignmentLateIcon} boxSize={6} />
  },
  1: {
    key: 'Info',
    text: 'Info',
    value: '1',
    icon: <Icon as={InfoIcon} boxSize={6} />
  },
  0: {
    key: 'Debug',
    text: 'Debug',
    value: '0',
    icon: <Icon as={SearchIcon} boxSize={6} />
  }
}

export const LevelFilterArray = [
  // formatter: no-inline
  levelFilters.all,
  levelFilters[0],
  levelFilters[1],
  levelFilters[2],
  levelFilters[3]
]

import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      searchEverything: build.query<SearchEverythingApiResponse, SearchEverythingApiArg>({
        query: (queryArg) => ({ url: `/api/v2/search/all`, params: { q: queryArg.q } }),
        providesTags: ['internal']
      }),
      searchUsers: build.query<SearchUsersApiResponse, SearchUsersApiArg>({
        query: (queryArg) => ({ url: `/api/v2/search/user`, params: { offset: queryArg.offset, per_page: queryArg.perPage } }),
        providesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type SearchEverythingApiResponse = /** status 200 Universal search for all items */ SearchAll
export type SearchEverythingApiArg = {
  q?: string
}
export type SearchUsersApiResponse = /** status 200 Search for users (available to everyone). */ UsersSearch
export type SearchUsersApiArg = {
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type SimpleProject = {
  created_at?: string
  updated_at?: string
  project_slug?: string
  project_name?: string
  owner?: string
  orgname?: string
}
export type SimpleTarget = {
  created_at?: string
  updated_at?: string
  project_name?: string
  project_slug?: string
  target_name?: string
  target_slug?: string
  owner?: string
  orgname?: string
}
export type SimpleUser = {
  created_at?: string
  updated_at?: string
  owner?: string
  orgname?: string
  first_name?: string | null
  last_name?: string | null
  active?: boolean
  is_admin?: boolean
  avatar?: string | null
}
export type SimpleOrg = {
  created_at?: string
  updated_at?: string
  owner?: string
  orgname?: string
  slug?: string
}
export type SimpleTeam = {
  created_at?: string
  updated_at?: string
  owner?: string
  teamname?: string
  slug?: string
}
export type SearchAll = {
  created_at?: string
  updated_at?: string
  projects?: SimpleProject[]
  targets?: SimpleTarget[]
  users?: SimpleUser[]
  orgs?: SimpleOrg[]
  teams?: SimpleTeam[]
}
export type ApiError = {
  message?: string
}
export type UserSearch = {
  created_at?: string
  updated_at?: string
  first_name?: string | null
  last_name?: string | null
  slug?: string
  is_org?: boolean
  active?: boolean
  email_verified?: boolean
}
export type UsersSearch = {
  created_at?: string
  updated_at?: string
  users?: UserSearch[]
}

import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack
} from '@chakra-ui/react'
import VisibilityOffIcon from '@material-design-icons/svg/sharp/visibility_off.svg?react'
import VisibilityIcon from '@material-design-icons/svg/sharp/visibility.svg?react'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { useResetPasswordMutation } from '../../redux/api/token'
import { getMessageFromRtkError } from '../../util/errors'

import { PasswordStrengthField } from './PasswordStrengthField'
import { isPasswordValid } from './utils'
import { onPasswordReset } from './resetPassword.slice'

type Params = {
  token: string
}

const validatePass = async (value: string | undefined, passwordStrengthEnabled: boolean) => {
  return !value || !(await isPasswordValid(value, passwordStrengthEnabled)) ? `Password must pass strength requirements` : undefined
}

type Form = { newPassword: string }

export function ResetPasswordForm({ token }: Params) {
  const dispatch = useAppDispatch()
  const [resetPassword, { isLoading: isResettingPassword }] = useResetPasswordMutation()

  const methods = useForm<Form>()
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isValid, isSubmitting }
  } = methods

  const passwordStrengthEnabled = useAppSelector((state) => !!state.config.passwordStrengthEnabled)

  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const onSubmit = async ({ newPassword }: Form) => {
    try {
      await resetPassword({ passwordResetTokenPost: { password: newPassword, reset_token: token } }).unwrap()
      dispatch(onPasswordReset(true))
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      setError('root', { type: 'custom', message })
    }
  }

  const onShowPasswordClicked = () => {
    setPasswordVisible(!isPasswordVisible)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <FormControl isRequired isInvalid={!!errors.newPassword}>
            <FormLabel>New Password</FormLabel>
            <InputGroup>
              <Input
                placeholder="Password"
                autoComplete="new-password"
                type={isPasswordVisible ? 'text' : 'password'}
                {...register('newPassword', {
                  maxLength: 128,
                  validate: (password) => validatePass(password, passwordStrengthEnabled)
                })}
              />
              <InputRightElement>
                <IconButton
                  aria-label="show password"
                  variant="ghost"
                  icon={<Icon as={isPasswordVisible ? VisibilityIcon : VisibilityOffIcon} />}
                  color="gray.500"
                  onClick={onShowPasswordClicked}
                />
              </InputRightElement>
            </InputGroup>
            <PasswordStrengthField passwordField="newPassword" email={undefined} firstName={undefined} lastName={undefined} username={undefined} />
            <FormErrorMessage>{errors.newPassword?.message}</FormErrorMessage>
          </FormControl>
          {errors.root?.message && !isValid && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>{errors.root?.message}</AlertTitle>
            </Alert>
          )}
          <Box>
            <Button type="submit" isLoading={isSubmitting || isResettingPassword}>
              Set Password
            </Button>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}

import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      verifyEmailToken: build.mutation<VerifyEmailTokenApiResponse, VerifyEmailTokenApiArg>({
        query: (queryArg) => ({ url: `/api/v2/token/email_verification`, method: 'POST', body: queryArg.emailVerificationTokenPost }),
        invalidatesTags: ['internal']
      }),
      requestPasswordReset: build.mutation<RequestPasswordResetApiResponse, RequestPasswordResetApiArg>({
        query: (queryArg) => ({ url: `/api/v2/token/request_password_reset`, method: 'POST', body: queryArg.passwordResetRequestPost }),
        invalidatesTags: ['internal']
      }),
      checkPasswordResetToken: build.query<CheckPasswordResetTokenApiResponse, CheckPasswordResetTokenApiArg>({
        query: (queryArg) => ({ url: `/api/v2/token/check_reset_token/${queryArg.resetToken}` }),
        providesTags: ['internal']
      }),
      resetPassword: build.mutation<ResetPasswordApiResponse, ResetPasswordApiArg>({
        query: (queryArg) => ({ url: `/api/v2/token/handle_password_reset`, method: 'POST', body: queryArg.passwordResetTokenPost }),
        invalidatesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type VerifyEmailTokenApiResponse = /** status 200 Attempt to verify an email address with a verification token.

    :returns: respond({"result": "ok"}) if the email has been verified
     */ Ok
export type VerifyEmailTokenApiArg = {
  emailVerificationTokenPost: EmailVerificationTokenPost
}
export type RequestPasswordResetApiResponse = /** status 200 Request a password reset email */ Ok
export type RequestPasswordResetApiArg = {
  passwordResetRequestPost: PasswordResetRequestPost
}
export type CheckPasswordResetTokenApiResponse = /** status 200 Check if a given reset token is valid. */ Ok
export type CheckPasswordResetTokenApiArg = {
  resetToken: string
}
export type ResetPasswordApiResponse = /** status 200 Reset a user's password with a reset token. */ Ok
export type ResetPasswordApiArg = {
  passwordResetTokenPost: PasswordResetTokenPost
}
export type Ok = {
  result?: string
}
export type ApiError = {
  message?: string
}
export type EmailVerificationTokenPost = {
  created_at?: string
  updated_at?: string
  email_token: string
}
export type PasswordResetRequestPost = {
  created_at?: string
  updated_at?: string
  email: string
}
export type PasswordResetTokenPost = {
  created_at?: string
  updated_at?: string
  password: string
  reset_token: string
}

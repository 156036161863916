import { useEffect } from 'react'

import { Heading } from '@chakra-ui/react'

import { getWorkspacePageTitle } from '../../titles'

import { Page } from '../../components/Page'
import { ProjectListing } from '../project-listing/ProjectListing'

type Props = { workspace: string }

export function OrganizationProjectsPage({ workspace }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Projects')
  }, [workspace])

  return (
    <Page>
      <Heading mb={8}>Projects</Heading>
      <ProjectListing workspace={workspace} />
    </Page>
  )
}

import { useState } from 'react'

import TargetFilterDropdown from './TargetFilterDropdown'

import { cleanInteger } from '@/util/numbers'
import { DEFAULT_PER_PAGE } from '@/redux/api/mayhemApi'
import { useQuery } from '@/hooks'
import { useGetAllTargetsQuery } from '@/redux/api/targets'

const DEFAULT_PAGE = 1

export default function TargetAdminFilterDropdown() {
  const query = useQuery()

  const [search, setSearch] = useState('')

  const perPage = cleanInteger(query.get('perPage') || DEFAULT_PER_PAGE)
  const [page, setPage] = useState(DEFAULT_PAGE)

  const {
    data = {},
    isFetching,
    isError
  } = useGetAllTargetsQuery({
    perPage,
    q: search,
    offset: (page - 1) * perPage
  })

  return (
    <TargetFilterDropdown
      isFetching={isFetching}
      isError={isError}
      data={data}
      onQueryInputChange={(query: string) => {
        setPage(DEFAULT_PAGE)
        setSearch(query)
      }}
      onScrollEnd={() => setPage((page) => page + 1)}
    />
  )
}

import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react'

const toast = defineStyle({
  color: 'gray.800',
  _hover: { color: 'gray.900' },
  _dark: {
    color: 'gray.600',
    _hover: { color: 'gray.500' }
  }
})

export const Link: ComponentStyleConfig = {
  variants: {
    primary: ({ colorScheme = null }) =>
      colorScheme
        ? {
            color: `${colorScheme}.500`,
            _hover: {
              color: `${colorScheme}.600`,
              textDecoration: 'underline'
            },
            _dark: {
              color: `${colorScheme}.400`,
              _hover: { color: `${colorScheme}.300` }
            }
          }
        : {
            color: 'gray.800',
            _hover: { color: 'gray.900' },
            _dark: {
              color: 'whiteAlpha.900',
              _hover: { color: 'whiteAlpha.800' }
            }
          },
    toast
  },
  defaultProps: {
    variant: 'primary'
  }
}

import { useEffect } from 'react'
import { Link } from 'wouter'
import { Button, Heading, Icon, Link as ChakraLink, Text, VStack } from '@chakra-ui/react'

import MayhemTableLoader from '../../components/MayhemTableLoader'
import EmailFailedToVerify from '../../images/email-failed-to-verify.svg?react'
import EmailVerified from '../../images/email-verified.svg?react'
import { getVerifyEmailPageTitle } from '../../titles'
import { useAppDispatch } from '../../hooks'
import { useVerifyEmailTokenMutation } from '../../redux/api/token'

type MatchParams = {
  token?: string
}

export function EmailVerificationPage({ token }: MatchParams) {
  const dispatch = useAppDispatch()

  const [verifyEmailToken, { isLoading, isSuccess, isUninitialized }] = useVerifyEmailTokenMutation()

  useEffect(() => {
    document.title = getVerifyEmailPageTitle()

    if (token && token !== 'sent') {
      verifyEmailToken({ emailVerificationTokenPost: { email_token: token } })
    }
  }, [dispatch, verifyEmailToken, token])

  if (isUninitialized || isLoading) {
    return <MayhemTableLoader />
  } else if (!isSuccess) {
    return (
      <VStack>
        <Heading>Unable to verify email address.</Heading>
        <Text>Please refresh to try again.</Text>
        <Icon as={EmailFailedToVerify} aria-label="Email address failed to verify." boxSize={72} />
        <Text>
          If you verified your email already, please
          <ChakraLink as={Link} to="/sign-in">
            <Button variant="link" pl={1}>
              Sign In.
            </Button>
          </ChakraLink>
        </Text>
        <Text>If you continue to encounter issues, please contact your administrator.</Text>
      </VStack>
    )
  }

  return (
    <VStack>
      <Heading>Email successfully verified!</Heading>
      <Text>
        Click{' '}
        <ChakraLink as={Link} to="/-/sign-in">
          <Button variant="link" px={1}>
            here
          </Button>
        </ChakraLink>
        to return to the sign in page and begin using Mayhem!
      </Text>
      <Icon as={EmailVerified} aria-label="Email address successfully verified." boxSize={72} />
    </VStack>
  )
}

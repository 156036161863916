import { Text, TextProps, Tooltip } from '@chakra-ui/react'

interface Props extends TextProps {
  text: string
  maxCharacters?: number
}

export function TruncatedTextWithTooltip({ text, maxCharacters = 28, ...props }: Props) {
  // Determine if the text should be truncated
  const isTruncated = text.length > maxCharacters
  const displayText = isTruncated ? `${text.substring(0, maxCharacters)}...` : text

  return (
    <Tooltip label={text} isDisabled={!isTruncated} hasArrow>
      <Text isTruncated position="relative" zIndex={1} {...props}>
        {displayText}
      </Text>
    </Tooltip>
  )
}

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Spacer, Text } from '@chakra-ui/react'

import { CodeRunConfigCommandEnvironment } from './CodeRunConfigCommandEnvironment'
import { CodeRunConfigCommandNetwork } from './CodeRunConfigCommandNetwork'
import { CodeRunConfigCommandFuzzers } from './CodeRunConfigCommandFuzzers'
import { CodeRunConfigCommandOther } from './CodeRunConfigCommandOther'

export interface CodeRunConfigCommandProps {
  cmdIndex: number
  environmentVariableCount: number
}
export const CodeRunConfigCommand = ({ cmdIndex, environmentVariableCount }: CodeRunConfigCommandProps): JSX.Element => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton>
          <Text>Environment Variables ({environmentVariableCount})</Text>
          <Spacer />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <CodeRunConfigCommandEnvironment cmdIndex={cmdIndex} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton>
          <Text>Network Settings</Text>
          <Spacer />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <CodeRunConfigCommandNetwork cmdIndex={cmdIndex} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton>
          <Text>Fuzzing Settings</Text>
          <Spacer />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <CodeRunConfigCommandFuzzers cmdIndex={cmdIndex} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton>
          <Text>Other Settings</Text>
          <Spacer />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <CodeRunConfigCommandOther cmdIndex={cmdIndex} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

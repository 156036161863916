import { Icon, IconProps } from '@chakra-ui/react'

export function MinusIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 34 9" {...props}>
      <path
        d="M33.1641 2.25C33.1641 1.00781 32.1562 0 30.9141 0H2.41406C1.17187 0 0.164062 1.00781 0.164062 2.25V6.75C0.164062 7.99219 1.17187 9 2.41406 9H30.9141C32.1562 9 33.1641 7.99219 33.1641 6.75V2.25Z"
        fill="currentColor"
      />
    </Icon>
  )
}

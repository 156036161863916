import { ComponentStyleConfig } from '@chakra-ui/react'

import { StyleFunctionProps } from '@chakra-ui/theme-tools'

import { codeBlockAnatomy } from '../../components/CodeBlock/anatomy'

export const CodeBlock: ComponentStyleConfig = {
  parts: codeBlockAnatomy.keys,
  baseStyle: (props: StyleFunctionProps) => ({
    code: {},
    button: {
      bg: 'transparent',
      fontSize: 'xs',
      textAlign: 'center'
    },
    copyToClipboard: {
      _hover: { bg: props.colorMode === 'dark' ? `whiteAlpha.200` : `gray.100` },
      _active: { bg: props.colorMode === 'dark' ? `whiteAlpha.300` : `gray.200` },
      position: 'absolute',
      right: '1',
      top: '1',
      minWidth: '6',
      height: '6',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 'md'
    }
  })
}

/*
 * Copyright (C) 2016 ForAllSecure, Inc. - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
  SetMayhemBotSettingsActionType,
  SET_ENROLLMENT_CONFIG,
  SET_MAYHEM_BOT_SETTINGS,
  SET_SYSTEM_RESOURCE_QUOTA,
  SystemResourceQuotaActionType,
  UsersActionsType
} from '../admin/types'

import { ConfigAction, SET_API_INFO, SET_KEYCLOAK_INFO, SET_MAYHEM_INFO } from './actions'

type Config = {
  apiVersion?: string
  emailSendingAvailable: boolean | undefined
  environment?: string
  githubOAuthEnabled: boolean | undefined
  googleOAuthEnabled: boolean | undefined
  isOfflineMode: boolean | undefined
  isPublicDeployment: boolean | undefined
  keycloakEnabled: boolean | undefined
  keycloakInitialized: boolean | undefined
  localEnrollmentEnabled: boolean | undefined
  maxActiveRuns: number | undefined
  maxRunDuration: number | undefined
  mayhemVersion: string | undefined
  mbotUtilization?: unknown
  mbotTaskDuration?: number
  mbotReserve?: number
  mbotOwner?: string
  mbotSlug?: string
  oktaOAuthEnabled: boolean | undefined
  passwordStrengthEnabled: boolean | undefined
}

export default function configReducer(
  state: Config = {
    emailSendingAvailable: undefined,
    isOfflineMode: true,
    isPublicDeployment: undefined,
    maxActiveRuns: undefined,
    maxRunDuration: undefined,
    localEnrollmentEnabled: undefined,
    googleOAuthEnabled: undefined,
    oktaOAuthEnabled: undefined,
    githubOAuthEnabled: undefined,
    keycloakEnabled: undefined,
    keycloakInitialized: undefined,
    passwordStrengthEnabled: undefined,
    mayhemVersion: undefined
  },
  action: ConfigAction | UsersActionsType | SystemResourceQuotaActionType | SetMayhemBotSettingsActionType
): Config {
  switch (action.type) {
    case SET_API_INFO:
      return {
        ...state,
        apiVersion: action.api.version,
        environment: action.api.environment,
        isOfflineMode: action.api.offline,
        isPublicDeployment: action.api.public
      }

    case SET_MAYHEM_INFO:
      return {
        ...state,
        mayhemVersion: action.mayhem.mayhemVersion
      }

    case SET_KEYCLOAK_INFO:
      return {
        ...state,
        keycloakInitialized: action.info.isInitialized,
        keycloakEnabled: action.info.userSettings.keycloakEnabled
      }

    case SET_ENROLLMENT_CONFIG:
      return {
        ...state,
        localEnrollmentEnabled: action.enrollmentSettings.localEnrollmentEnabled,
        oktaOAuthEnabled: action.enrollmentSettings.oktaOauthEnabled,
        googleOAuthEnabled: action.enrollmentSettings.googleOauthEnabled,
        githubOAuthEnabled: action.enrollmentSettings.githubOauthEnabled,
        keycloakEnabled: action.enrollmentSettings.keycloakEnabled,
        emailSendingAvailable: action.enrollmentSettings.emailSendingAvailable,
        passwordStrengthEnabled: action.enrollmentSettings.passwordStrengthEnabled,
        maxActiveRuns: action.enrollmentSettings.maxActiveRuns,
        maxRunDuration: action.enrollmentSettings.maxRunDuration,
        mbotUtilization: action.enrollmentSettings.mbotUtilization,
        mbotTaskDuration: action.enrollmentSettings.mbotTaskDuration,
        mbotReserve: action.enrollmentSettings.mbotReserve,
        mbotOwner: action.enrollmentSettings.mbotOwner,
        mbotSlug: action.enrollmentSettings.mbotSlug
      }

    case SET_SYSTEM_RESOURCE_QUOTA:
      return {
        ...state,
        maxActiveRuns: action.quota.maxActiveRuns,
        maxRunDuration: action.quota.maxRunDuration
      }

    case SET_MAYHEM_BOT_SETTINGS:
      return {
        ...state,
        mbotUtilization: action.payload.mbotUtilization
      }

    default:
      return state
  }
}

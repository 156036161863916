import { LinkBoxProps, LinkBox, useStyleConfig } from '@chakra-ui/react'

type CardRowProps = LinkBoxProps & { disabled?: boolean }

/**
 * A row displayed in the content section of a card.
 */
export function CardRow(props: CardRowProps): JSX.Element {
  const styles = useStyleConfig('CardRow')
  const { disabled = false } = props
  return <LinkBox sx={styles} disabled={disabled} padding={4} {...props} />
}

import { useEffect } from 'react'
import { Container, ContainerProps } from '@chakra-ui/react'

import { useQuery } from '@/hooks'

export function Page(props: ContainerProps): JSX.Element {
  const queryParams = useQuery()
  const jumpTo = queryParams.get('jumpTo')
  const scroll = queryParams.get('scroll')

  useEffect(() => {
    // jump to specified section (but make sure it's not interfering with the scroll behavior)
    if (jumpTo && !scroll) {
      const element = document.getElementById(jumpTo)
      if (element) {
        element.scrollIntoView({ behavior: 'instant', block: 'start' })
      }
    }
  }, [jumpTo, scroll])

  useEffect(() => {
    // jump to the specific section on initial page load (which can ignore the scroll behavior)
    if (jumpTo) {
      const element = document.getElementById(jumpTo)
      if (element) {
        element.scrollIntoView({ behavior: 'instant', block: 'start' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Container maxW="full" padding={0} overflowY="auto" {...props} />
}

import { useEffect, useState } from 'react'

/**
 *
 * @param value The debounce value.
 * @param delay The delay in milliseconds.
 */
export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handle = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handle)
    }
  }, [value, delay])

  return debouncedValue
}

import { FunctionComponent } from 'react'

import GitBranchIcon from '../../images/git-branch.svg?react'
import GitHubLogoIocn from '../../images/github-logo.svg?react'
import GitLabLogoIcon from '../../images/gitlab-logo.svg?react'
import JenkinsLogoIcon from '../../images/jenkins-logo.svg?react'

export function getFavicon(url: string): FunctionComponent & { title?: string } {
  if (url.includes('github.com')) {
    return GitHubLogoIocn
  }
  if (url.includes('jenkins')) {
    return JenkinsLogoIcon
  }
  if (url.includes('gitlab')) {
    return GitLabLogoIcon
  }
  return GitBranchIcon
}

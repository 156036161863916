import { useState } from 'react'

import { Icon, IconButton, Spacer, Stack, Text } from '@chakra-ui/react'
import PlayIcon from '@material-design-icons/svg/sharp/play_arrow.svg?react'

import { DockerRepositoryTagMenu } from './DockerRepositoryTagMenu'

export interface DockerRepositoryRowProps {
  repository: string
  onImageSelect?: (image: string) => void
}

export const DockerRepositoryRow = ({ repository, onImageSelect }: DockerRepositoryRowProps) => {
  const [selectedTag, setSelectedTag] = useState<string>()

  return (
    <Stack gap={2}>
      <Stack direction="row" alignItems="center">
        <Text size="md">{repository}</Text>
        <Spacer />
        <DockerRepositoryTagMenu repository={repository} selected={selectedTag} onSelect={(value) => setSelectedTag(value)} />
        <IconButton
          aria-label="select-tag"
          icon={<Icon as={PlayIcon} />}
          isDisabled={!selectedTag}
          onClick={() => onImageSelect?.(`${repository}:${selectedTag}`)}
        />
      </Stack>
    </Stack>
  )
}

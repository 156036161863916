import { createElement } from 'react'
import { XAxis, XAxisProps, YAxis } from 'recharts'

type AxisCommonProps = Omit<XAxisProps, 'xAxisId' | 'height' | 'width' | 'orientation' | 'textAnchor' | 'padding'>

export type ChartAxisProps = AxisCommonProps & {
  axis: 'x' | 'y'
}

export function chartAxis({ axis, ...props }: ChartAxisProps) {
  props = {
    stroke: 'var(--chakra-colors-chartAxis)',
    ...props
  }
  if (axis === 'x') {
    return createElement(XAxis, props)
  } else {
    return createElement(YAxis, props)
  }
}

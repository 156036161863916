import { Badge, Icon, Link } from '@chakra-ui/react'

import { HStack } from '@chakra-ui/layout'

import { useGetDefectByTargetQuery } from '../../redux/api/defects'
import JiraIcon from '../../images/integrations/jira_logo.svg?react'

import { useVersionQuery } from '../../redux/api/version'

import { CreateJiraIssueButton } from './CreateJiraIssueButton'
import { UnlinkJiraIssueButton } from './UnlinkJiraIssueButton'

type Props = {
  defectNumber: number
  owner: string
  projectSlug: string
  targetSlug: string
}

export function JiraIssue({ owner, projectSlug, targetSlug, defectNumber }: Props) {
  const { data: defect } = useGetDefectByTargetQuery({ owner, projectSlug, targetSlug, defectNumber })
  const { data: apiData } = useVersionQuery()

  if (!defect) {
    return <></>
  }

  const renderJiraIssueButton = () => {
    if (apiData && apiData?.atlassian_enabled && apiData?.atlassian_client_id) {
      return <CreateJiraIssueButton owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
    }
    return <></>
  }
  return (
    <>
      {defect.jira_issue_id && defect.jira_issue_url ? (
        <HStack>
          <Badge variant="outline">
            <Link href={defect.jira_issue_url} isExternal={true} colorScheme="brand" display="flex" alignItems="center" gap={2} width="fit-content">
              <Icon as={JiraIcon} />
              {defect.jira_issue_id}
            </Link>
          </Badge>
          <UnlinkJiraIssueButton owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
        </HStack>
      ) : (
        renderJiraIssueButton()
      )}
    </>
  )
}

import { useEffect } from 'react'

import { Box, Button, Card, CardBody, CardHeader, Flex, Heading, Link, Skeleton, Stack, StackDivider, Text, useDisclosure } from '@chakra-ui/react'

import BillingUsageViz from './BillingUsageViz'

import TextWithTooltip from '@/components/TextWithTooltip'
import { useAppSelector } from '@/hooks'
import { useGetBillingSubscriptionQuery, useGetBillingUsageQuery } from '@/redux/api/billing'
import { getWorkspacePlanPageTitle } from '@/titles'

import { getCurrentUserInfo } from '@/features/auth/utils'

import { WorkspaceSubscriptionModal } from '@/features/workspace/WorkspaceSubscriptionModal'

interface Props {
  workspace: string
}

export function PlanPage({ workspace }: Props) {
  const { isLoading, data } = useGetBillingUsageQuery({ owner: workspace })
  const { isLoading: isLoadingSubscriptionData, data: subscriptionData } = useGetBillingSubscriptionQuery({ owner: workspace })
  const { isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    document.title = getWorkspacePlanPageTitle(workspace)
  }, [workspace])

  if (isLoading || data === undefined || isLoadingSubscriptionData || subscriptionData === undefined) {
    return <Skeleton />
  }

  return (
    <Stack>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Plan Details</Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4" p="4">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Current Plan
              </Heading>
              <Text pt="2" fontSize="sm">
                {`${data?.plan_description} ${data?.is_trial ? '(Free Trial)' : ''}`}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Expires
              </Heading>
              <Text pt="2" fontSize="sm">
                {data?.expires_at === null ? 'Never' : new Date(data?.expires_at).toLocaleDateString()}
              </Text>
              {isMayhemAdmin && (
                <>
                  <Button marginTop={4} onClick={onOpen}>
                    Edit Subscription
                  </Button>
                  <WorkspaceSubscriptionModal workspace={workspace} isOpen={isOpen} onClose={onClose} />
                </>
              )}
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Usage
              </Heading>
              <Stack p={4} spacing={8}>
                <Flex alignItems="flex-start" justifyContent="center" flexDirection="column" gap={2}>
                  <TextWithTooltip
                    text="Monthly Scans"
                    tooltip="The number of scans performed in the current month (starting from the first of the month)."
                    showQuestionMark={true}
                  />
                  <BillingUsageViz
                    current_usage={data.scans_used_in_month}
                    current_description={data.scans_used_in_month == 1 ? 'Scan' : 'Scans'}
                    total_allowed={data.scans_allowed_in_month}
                  />
                </Flex>
                <Flex alignItems="flex-start" justifyContent="center" flexDirection="column" gap={2}>
                  <TextWithTooltip
                    text="Contributors"
                    tooltip="An active contributor is anyone who has made at least one commit to a project under test in the last 90 days of the project you are testing with Mayhem."
                    showQuestionMark={true}
                  />
                  <BillingUsageViz
                    current_usage={data.contributors_last_90days}
                    current_description={data.contributors_last_90days == 1 ? 'Contributor' : 'Contributors'}
                    total_allowed={data.contributors_allowed}
                  />
                </Flex>
              </Stack>
            </Box>
          </Stack>
        </CardBody>
      </Card>
      <Stack direction="row" alignItems="center" marginTop={4}>
        <Text>Want more Mayhem?</Text>
        <Link href="https://www.mayhem.security/contact" isExternal>
          <Button>Contact Us!</Button>
        </Link>
      </Stack>
    </Stack>
  )
}

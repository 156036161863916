// Remove undefined or null fields from typescript object
export type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> }

export function excludeNullUndefinedFields<T>(obj: T): NoUndefinedField<T> {
  const cleanObj = {} as NoUndefinedField<T>

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      cleanObj[key as keyof T] = obj[key] as NoUndefinedField<T>[keyof T]
    }
  }

  return cleanObj
}

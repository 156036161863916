import React, { useContext, RefObject } from 'react'

import { Box, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Text, TextProps } from '@chakra-ui/react'
import HelpIcon from '@material-design-icons/svg/sharp/help.svg?react'

import { RunDetailsModalContext } from '../features/run-code/RunOptionsButton'

interface TextWithTooltipProps {
  text?: string | React.ReactNode
  tooltip: string | React.ReactNode
  showQuestionMark?: boolean
  disabled?: boolean
  containerRef?: RefObject<HTMLDivElement>
}

export default function TextWithTooltip({
  text = <></>,
  tooltip,
  showQuestionMark = true,
  disabled = false,
  containerRef,
  ...props
}: TextWithTooltipProps & TextProps): JSX.Element {
  const modalRef = useContext(RunDetailsModalContext)

  return disabled ? (
    <Text as="span" cursor="pointer" display="inline-block" {...props}>
      {text}
    </Text>
  ) : (
    <Popover trigger="hover" key={(tooltip || '').toString()}>
      <PopoverTrigger>
        <Box as="span">
          <Text as="span" cursor="pointer" display="inline-block" {...props}>
            {text}
          </Text>
          {showQuestionMark && (
            <Text as="sup">
              <Icon display="inline-block" as={HelpIcon} />
            </Text>
          )}
        </Box>
      </PopoverTrigger>
      <Portal containerRef={containerRef ?? modalRef ?? undefined}>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody maxW={96} id="here">
            {tooltip}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

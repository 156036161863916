import { Box } from '@chakra-ui/react'

interface Props {
  isApi: boolean
}

export function RunTypeLabel({ isApi }: Props) {
  const bgColor = isApi ? 'purpleBg' : 'labelBg'
  const textColor = isApi ? 'purpleColor' : 'selectedColor'
  return (
    <Box width={8} height={120} backgroundColor={bgColor} alignContent="center" textAlign="center">
      <Box fontSize={12} width="100%" fontWeight={500} color={textColor} transform="rotate(-90deg)">
        {isApi ? 'API' : 'Code'}
      </Box>
    </Box>
  )
}

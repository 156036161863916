import { useCallback } from 'react'

import { useLocation } from 'wouter'

import { HStack, Spacer, Text, Skeleton, Card, CardHeader, CardFooter, ButtonGroup, Button, Show, Box, CardBody } from '@chakra-ui/react'

import { LinkBoxListContainer } from '../../components/LinkBoxListContainer'
import { FilterMenuButton } from '../../components/FilterMenuButton'
import ListFooter from '../../components/ListFooter'
import { useQuery } from '../../hooks'
import ProjectPermissionGuard from '../project/ProjectPermissionGuard'
import { AUTOREFRESH_INTERVAL, DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetRunQuery, useGetTestcaseResultsByRunQuery } from '../../redux/api/runs'
import { setQueryParam } from '../../util/location'
import { cleanInteger } from '../../util/numbers'

import { TestCaseReportModal } from '../run-code-testcasemodal/TestCaseReportModal'
import { TestCaseReportPreview } from '../run-code-testcasemodal/TestCaseReportPreview'

import { RunAnalysisFilters, RunTestCaseFilters, TestcaseFilter } from '../run-code/utils'

import { RunTestcaseRow } from './RunTestcaseRow'

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
}

export function RunTestcases(props: Props) {
  const { owner, projectSlug, targetSlug, runNumber } = props

  const [location, setLocation] = useLocation()

  const query = useQuery()
  const testcaseFilter = (query.get('testcases') as TestcaseFilter | null) || RunTestCaseFilters.ALL
  const analysisFilter = query.get('analysis') || RunAnalysisFilters.ALL
  const page = cleanInteger(query.get('page'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)
  const sort = query.get('sort') || 'new'
  const selectedTestcaseSha = query.get('testcase')

  const { data: run } = useGetRunQuery(props)
  const isRunning = !!run?.started && !run.ended_at
  const {
    currentData: testCaseResults,
    isFetching,
    isError
  } = useGetTestcaseResultsByRunQuery(
    {
      owner,
      projectSlug,
      targetSlug,
      runNumber,
      sort,
      resultFilter: testcaseFilter === RunTestCaseFilters.ALL ? undefined : testcaseFilter,
      phase: analysisFilter === RunAnalysisFilters.ALL ? undefined : analysisFilter,
      perPage,
      offset: (page - 1) * perPage
    },
    {
      pollingInterval: selectedTestcaseSha !== null || !isRunning ? 0 : AUTOREFRESH_INTERVAL
    }
  )
  const isLoading = isFetching && testCaseResults === undefined

  const selectAnalysisFilter = (newSelectedAnalysisFilter: string | null): void => {
    if (analysisFilter !== newSelectedAnalysisFilter) {
      const newUrl = setQueryParam({ location, queryParams: query, param: 'analysis', value: newSelectedAnalysisFilter })
      setLocation(newUrl, { replace: true })
    }
  }

  const handleTestCaseFilterChange = useCallback(
    (newSelectedFilter: string | string[]): void => {
      if (newSelectedFilter instanceof Array) {
        newSelectedFilter = newSelectedFilter[0]
      }
      if (testcaseFilter !== newSelectedFilter) {
        const newUrl = setQueryParam({ location, queryParams: query, param: 'testcases', value: newSelectedFilter })
        setLocation(newUrl, { replace: true })
      }
    },
    [setLocation, testcaseFilter, location, query]
  )
  const handleTestCasesSortChange = useCallback(
    (newSort: string | string[]): void => {
      if (newSort instanceof Array) {
        newSort = newSort[0]
      }
      if (sort !== newSort) {
        const newUrl = setQueryParam({ location, queryParams: query, param: 'sort', value: newSort })
        setLocation(newUrl, { replace: true })
      }
    },
    [setLocation, sort, location, query]
  )

  const { testcase_reports: testcases = [], count } = testCaseResults || { count: 0, testcase_reports: [] }

  const calculatePreviousAndNextTestcases = () => {
    if (!selectedTestcaseSha || testcases.length === 0) {
      return []
    }
    const currentTestcaseIdx = testcases.findIndex((testcase) => testcase.sha256 === selectedTestcaseSha)

    if (currentTestcaseIdx === -1) {
      return []
    }

    const maxIdx = testcases.length - 1
    const previousTestcaseSha = currentTestcaseIdx > 0 ? testcases[currentTestcaseIdx - 1].sha256 : null
    const nextTestcaseSha = currentTestcaseIdx < maxIdx ? testcases[currentTestcaseIdx + 1].sha256 : null
    return [previousTestcaseSha, nextTestcaseSha]
  }

  const [previousTestcaseSha, nextTestcaseSha] = calculatePreviousAndNextTestcases()

  const filterOptions: Array<{ key: string; text: string; value: TestcaseFilter }> = [
    {
      key: 'All Test Cases',
      value: RunTestCaseFilters.ALL,
      text: 'All Test Cases'
    },
    {
      key: 'Defects Found',
      value: RunTestCaseFilters.NEW_DEFECTS_FOUND,
      text: 'Defects Found'
    },
    {
      key: 'Defects Fixed',
      value: RunTestCaseFilters.REGRESSION_FIXED,
      text: 'Defects Fixed'
    },
    {
      key: 'Regressions',
      value: RunTestCaseFilters.REGRESSION_DEFECTS,
      text: 'Regressions'
    },
    {
      key: 'No Defects',
      value: RunTestCaseFilters.NEW_NO_DEFECTS,
      text: 'No Defects'
    }
  ]

  const orderOptions: Array<{ key: string; text: string; value: string }> = [
    {
      key: 'new',
      text: 'New',
      value: 'new'
    },
    {
      key: 'execution_time',
      text: 'Execution Time',
      value: 'execution_time'
    }
  ]

  return (
    <ProjectPermissionGuard owner={owner} projectSlug={projectSlug}>
      <HStack align="start" gap={4}>
        <Card
          w={{ base: '100%', '2xl': selectedTestcaseSha ? '50%' : '100%' }}
          minW={{ base: '100%', '2xl': selectedTestcaseSha ? '50%' : '100%' }}
          maxHeight="95vh"
        >
          <CardHeader>
            <ButtonGroup>
              <Button variant={analysisFilter === 'all' ? 'solid' : 'outline'} onClick={() => selectAnalysisFilter(null)}>
                All Tests
              </Button>
              <Button
                variant={analysisFilter === 'regression_testing' ? 'solid' : 'outline'}
                onClick={() => selectAnalysisFilter('regression_testing')}
              >
                Regression Tests
              </Button>
            </ButtonGroup>
          </CardHeader>
          <CardHeader>
            <HStack alignItems="baseline">
              <Text fontSize="xs" fontWeight="semibold" color="faded">
                FILTER:
              </Text>
              <FilterMenuButton
                options={filterOptions}
                defaultText="All Test Cases"
                value={testcaseFilter}
                onValueChange={handleTestCaseFilterChange}
              />
            </HStack>
            <Spacer />
            <HStack alignItems="baseline">
              <Text fontSize="xs" fontWeight="semibold" color="faded">
                SORT:
              </Text>
              <FilterMenuButton options={orderOptions} defaultText="Sort Test Cases" value={sort} onValueChange={handleTestCasesSortChange} />
            </HStack>
          </CardHeader>
          <CardBody overflowY="auto">
            <Skeleton isLoaded={!isLoading}>
              <LinkBoxListContainer>
                {testcases.map((testcase) => (
                  <RunTestcaseRow
                    key={testcase.testcase_report_id}
                    {...{
                      owner,
                      projectSlug,
                      targetSlug,
                      runNumber,
                      testcaseReportId: testcase.testcase_report_id.toString(),
                      startedAt: run?.started_at ? new Date(run?.started_at).getTime() : null,
                      testcase
                    }}
                  />
                ))}
              </LinkBoxListContainer>
            </Skeleton>
          </CardBody>
          <CardFooter justifyContent="center">
            <ListFooter isLoading={isLoading} nItems={testcases.length} total={count} perPage={perPage} itemName="test case" isFail={isError} />
          </CardFooter>
        </Card>
        <Show above="2xl">
          <Box width="50%">
            {selectedTestcaseSha !== null && (
              <TestCaseReportPreview
                owner={owner}
                projectSlug={projectSlug}
                targetSlug={targetSlug}
                runNumber={runNumber}
                testcaseSha={selectedTestcaseSha || ''}
              />
            )}
          </Box>
        </Show>
        <Show below="2xl">
          {selectedTestcaseSha !== null && (
            <TestCaseReportModal
              owner={owner}
              projectSlug={projectSlug}
              targetSlug={targetSlug}
              runNumber={runNumber}
              testcaseSha={selectedTestcaseSha || ''}
              previousTestcaseSha={previousTestcaseSha}
              nextTestcaseSha={nextTestcaseSha}
            />
          )}
        </Show>
      </HStack>
    </ProjectPermissionGuard>
  )
}

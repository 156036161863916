import { Box, BoxProps } from '@chakra-ui/react'

import { getBackgroundColorFromPercent } from '@/theming/status'

export type PercentStatusIndicatorProps = BoxProps & {
  percent: number
}

export function PercentStatusIndicator({ percent, ...rest }: PercentStatusIndicatorProps) {
  return <Box borderRadius="full" backgroundColor={getBackgroundColorFromPercent(percent)} {...rest} />
}

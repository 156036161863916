import React from 'react'
import { LinkOverlay, LinkOverlayProps } from '@chakra-ui/react'
import { useLocation } from 'wouter'

type Props = LinkOverlayProps & {
  to: string
  cmdTo?: string // the link to navigate to if the user cmd/ctrl clicks on the link (e.g. the 'full' page of an item, instead of the preview)
  replace?: boolean
}

function WouterLinkOverlayInner({ to, cmdTo = '', replace = false, children, ...props }: Props): JSX.Element {
  const [, setLocation] = useLocation()

  const handleClick = (e: React.MouseEvent) => {
    if (e.metaKey || e.ctrlKey) {
      // allow default behavior (i.e. open in new tab)
      return
    }
    e.preventDefault()
    setLocation(to, { replace: replace })
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setLocation(to, { replace: replace })
    }
  }

  return (
    <LinkOverlay href={cmdTo ? cmdTo : to} onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={0} {...props}>
      {children}
    </LinkOverlay>
  )
}

const WouterLinkOverlay = React.memo(WouterLinkOverlayInner)
export default WouterLinkOverlay

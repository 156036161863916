import { useFormContext } from 'react-hook-form'
import { Checkbox, FormControl, FormHelperText, FormLabel, Input, Stack } from '@chakra-ui/react'

import { helpSections } from './helpSections'

import { FormCodeRunConfig } from './utils'

export interface CodeRunConfigCommandNetworkProps {
  cmdIndex: number
}

export const CodeRunConfigCommandNetwork = ({ cmdIndex }: CodeRunConfigCommandNetworkProps) => {
  const { register } = useFormContext<FormCodeRunConfig>()

  return (
    <Stack>
      <FormControl>
        <FormLabel>Network URL</FormLabel>
        <Input {...register(`cmdSettings.${cmdIndex}.network.url`)} placeholder={helpSections.url.other?.format.at(0)} />
      </FormControl>

      <FormControl>
        <FormLabel>Network Timeout</FormLabel>
        <Input type="number" {...register(`cmdSettings.${cmdIndex}.network.timeout`)} placeholder="Time in seconds ie: 2" />
      </FormControl>

      <FormControl>
        <FormLabel>Is Client</FormLabel>
        <Checkbox {...register(`cmdSettings.${cmdIndex}.network.client`)} />
        <FormHelperText>A client initiates the network connection</FormHelperText>
      </FormControl>
    </Stack>
  )
}

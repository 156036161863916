import { useCallback } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  HStack,
  Input,
  SimpleGrid,
  Skeleton,
  Stack,
  Switch,
  useToast
} from '@chakra-ui/react'

import { FormProvider, useForm, Controller } from 'react-hook-form'

import { Feature } from 'flagged'

import { FEATURE_NOTIFICATION_SETTINGS } from '../../featureFlags'

import { useGetUserSettingQuery, usePutUserMutation, useWhoamiQuery } from '../../redux/api/workspace'
import { useVersionQuery } from '../../redux/api/version'

import { getAccountType } from '../auth/utils'

type Form = {
  userSlug: string
  firstName: string
  lastName: string
  emailAddress: string
  weeklyProjectSummaries: boolean
  freeEventsAndTraining: boolean
  dailyDefectUpdates: boolean
}

export function UpdateInfoForm() {
  const { isLoading: isLoadingProfile, data: profile, refetch: refetchUserInfo } = useWhoamiQuery()
  const { isLoading: isLoadingVersion, data: versionInfo } = useVersionQuery()
  const { data: userSettings } = useGetUserSettingQuery()

  const accountType = getAccountType(userSettings, profile)
  const canChangeName = accountType === 'Local' || accountType === 'Keycloak'
  const userSlug = profile?.slug

  const defaultValues = {
    userSlug: userSlug,
    firstName: profile?.first_name ?? undefined,
    lastName: profile?.last_name ?? undefined,
    emailAddress: profile?.email_address ?? undefined,
    weeklyProjectSummaries: !!profile?.notification_preferences?.V1.weekly_project_summaries,
    freeEventsAndTraining: !!profile?.notification_preferences?.V1.free_events_and_training,
    dailyDefectUpdates: !!profile?.notification_preferences?.V1.daily_defect_updates
  }

  const methods = useForm<Form>({
    defaultValues
  })

  const toast = useToast()
  const [putUser] = usePutUserMutation()
  const {
    handleSubmit,
    register,
    reset,
    setError,
    control,
    formState: { isSubmitting, isDirty, errors }
  } = methods

  const onSubmitUser = useCallback(
    async (form: Form) => {
      try {
        // the slug should be set before this can be called, but if it isn't display an error to the user
        if (!userSlug) {
          setError('root', { type: 'custom', message: 'An unexpected error has occurred' })
          return
        }
        await putUser({
          userSlug,
          putUser: {
            first_name: form.firstName,
            last_name: form.lastName,
            notification_preferences: {
              V1: {
                free_events_and_training: form.freeEventsAndTraining,
                weekly_project_summaries: form.weeklyProjectSummaries,
                daily_defect_updates: form.dailyDefectUpdates
              }
            }
          }
        }).unwrap()
        await refetchUserInfo().unwrap()
        reset({
          firstName: form.firstName,
          lastName: form.lastName,
          emailAddress: form.emailAddress,
          freeEventsAndTraining: form.freeEventsAndTraining,
          weeklyProjectSummaries: form.weeklyProjectSummaries,
          dailyDefectUpdates: form.dailyDefectUpdates
        })
        toast({
          title: 'Profile Updated',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        setError('root', { type: 'custom', message: (err as Error)?.message || 'Something went wrong' })
      }
    },
    [toast, refetchUserInfo, setError, reset, putUser, userSlug]
  )

  if (isLoadingProfile || isLoadingVersion || profile === undefined) {
    return <Skeleton />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitUser)}>
        <Stack gap={4}>
          <FormControl>
            <SimpleGrid columns={3}>
              <Stack gridRow={1} gridColumn={1}>
                <FormLabel>Username</FormLabel>
                <FormHelperText width="100%">Unique identifier for your personal workspace.</FormHelperText>
              </Stack>
              <GridItem p={2} gridRow={1} gridColumnStart={2} gridColumnEnd={4} rowSpan={2}>
                <Input id="username" aria-label="Username" isDisabled={true} {...register('userSlug')} />
              </GridItem>
            </SimpleGrid>
          </FormControl>
          <FormControl isInvalid={!!errors.root}>
            <SimpleGrid columns={3}>
              <Stack gridRow={1}>
                <FormLabel>Name</FormLabel>
                <FormHelperText width="100%">{`We'll use this in emails sent to you.`}</FormHelperText>
              </Stack>
              <GridItem p={2} gridRow={1} rowSpan={2} gridColumn={2}>
                <Input
                  id="firstName"
                  aria-label="First Name"
                  isDisabled={!canChangeName || isSubmitting}
                  placeholder="First Name"
                  {...register('firstName')}
                />
              </GridItem>
              <GridItem p={2} gridRow={1} rowSpan={2} gridColumn={3}>
                <Input
                  id="lastName"
                  aria-label="Last Name"
                  isDisabled={!canChangeName || isSubmitting}
                  placeholder="Last Name"
                  {...register('lastName')}
                />
              </GridItem>
              <FormErrorMessage>{errors?.root?.message}</FormErrorMessage>
            </SimpleGrid>
          </FormControl>
          <FormControl>
            <SimpleGrid columns={3}>
              <Stack gridRow={1} gridColumn={1}>
                <FormLabel>Email</FormLabel>
                <FormHelperText>Notifications are sent to this address.</FormHelperText>
              </Stack>
              <GridItem p={2} gridRow={1} gridColumnStart={2} gridColumnEnd={4} rowSpan={2}>
                <Input isDisabled={true} {...register('emailAddress')} />
              </GridItem>
            </SimpleGrid>
          </FormControl>
          {!versionInfo?.offline && (
            <Feature name={FEATURE_NOTIFICATION_SETTINGS}>
              <FormControl>
                <SimpleGrid columns={3}>
                  <Stack gridRow={1} gridColumn={1}>
                    <FormLabel>Notification Preferences</FormLabel>
                    <FormHelperText>The reports and information we send to you periodically.</FormHelperText>
                  </Stack>
                  <Stack p={2} gridRow={1} gridColumnStart={2} gridColumnEnd={4}>
                    <HStack p={2}>
                      <Controller
                        control={control}
                        name="weeklyProjectSummaries"
                        render={({ field: { onChange, value, ref } }) => (
                          <Switch id="weeklyProjectSummaries" isChecked={value} onChange={onChange} ref={ref} isDisabled={isSubmitting}>
                            Weekly project summaries
                          </Switch>
                        )}
                      />
                    </HStack>
                    <HStack p={2}>
                      {/* Use a controller to prevent errors with the chakra Switch and react hook form */}
                      {/* See https://github.com/chakra-ui/chakra-ui/issues/7286 */}
                      <Controller
                        control={control}
                        name="freeEventsAndTraining"
                        render={({ field: { onChange, value, ref } }) => (
                          <Switch id="freeEventsAndTraining" isChecked={value} onChange={onChange} ref={ref} isDisabled={isSubmitting}>
                            Free events and training
                          </Switch>
                        )}
                      />
                    </HStack>
                    <HStack p={2}>
                      {/* Use a controller to prevent errors with the chakra Switch and react hook form */}
                      {/* See https://github.com/chakra-ui/chakra-ui/issues/7286 */}
                      <Controller
                        control={control}
                        name="dailyDefectUpdates"
                        render={({ field: { onChange, value, ref } }) => (
                          <Switch id="dailyDefectUpdates" isChecked={value} onChange={onChange} ref={ref} isDisabled={isSubmitting}>
                            Daily defect updates
                          </Switch>
                        )}
                      />
                    </HStack>
                  </Stack>
                </SimpleGrid>
              </FormControl>
            </Feature>
          )}
          <Flex gap={4} justify="flex-end">
            <Button
              variant="outline"
              isDisabled={isSubmitting || !isDirty}
              onClick={(): void => {
                reset(defaultValues)
              }}
            >
              Cancel
            </Button>
            <Button variant="solid" type="submit" isDisabled={isSubmitting || !isDirty} isLoading={isSubmitting}>
              Update
            </Button>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  )
}

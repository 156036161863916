import { Box, LinkBox, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Spinner, Text, Tooltip } from '@chakra-ui/react'

import { CopyToClipboardButton } from '../../components/CopyToClipboardButton/CopyToClipboardButton'
import WouterLinkOverlay from '../../components/WouterLinkOverlay'

const TestcaseShaDisplay = ({ sha256 = '', link = '', cmdLink = '', showCopyButton = true, fontSize = 16 }: Props): JSX.Element => {
  // As of 1.9, testcase SHAs can be null while we wait for the API to finish storing it.
  // See https://forallsecure.atlassian.net/browse/MH-7718
  if (sha256 === null) {
    return (
      <Tooltip label="This testcase is currently being saved in our servers and is unavailable">
        <Box>
          Test Case Loading&nbsp;
          <Spinner size="xs" />
        </Box>
      </Tooltip>
    )
  }

  return (
    <>
      <Popover trigger="hover">
        <PopoverTrigger>
          <Text as="span">
            {link ? (
              <LinkBox>
                <WouterLinkOverlay to={link} cmdTo={cmdLink}>
                  <Text as="span" fontFamily="mono" fontSize={fontSize}>
                    {sha256.substring(0, 8)}
                  </Text>
                </WouterLinkOverlay>
              </LinkBox>
            ) : (
              <Text as="span" fontFamily="mono" fontSize={fontSize}>
                {sha256.substring(0, 8)}
              </Text>
            )}
          </Text>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Text as="span" fontFamily="mono">
                {sha256}
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      {showCopyButton && <CopyToClipboardButton size="xs" text={sha256} preCopyTooltip="Copy full SHA to clipboard" />}
    </>
  )
}

interface Props {
  sha256?: string
  link?: string
  cmdLink?: string
  showCopyButton?: boolean
  inline?: boolean
  fontSize?: number
}

export default TestcaseShaDisplay

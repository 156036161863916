import { quote } from 'shlex'

import { CoreCodeRunConfig, DefaultTasks } from '../run-code-config/CoreCodeRunConfig'
import { areSetEqual, slugifyFull } from '../../utils'
import { stringifyUrl } from '../../util/location'

export function goToCodeRunConfigPage(
  workspace: string,
  mayhemfile: string,
  rootSha?: string,
  projectSlug?: string,
  targetSlug?: string,
  runNumber?: number
) {
  const encodedMayhemfile = encodeURIComponent(btoa(mayhemfile))
  return stringifyUrl(`/${workspace}/-/create-code-run`, {
    mayhemfile: encodedMayhemfile,
    root: rootSha,
    project: projectSlug,
    target: targetSlug,
    run: runNumber
  })
}

const mayhemCommand = (command: string, args: string[], flags: string[]): string => {
  const simple = `mayhem ${command} ${args.join(' ')} ${flags.join(' ')}`
  return simple
}
export const mayhemRunCommand = (config: CoreCodeRunConfig) => {
  // TODO(kostas): Support various command configurations
  const args: string[] = []
  const flags: string[] = [
    // format: no-inline
    `--owner ${slugifyFull(config.workspace)}`,
    `--project ${slugifyFull(config.project)}`,
    `--target ${slugifyFull(config.target)}`
  ]
  if (config.image) {
    args.push(quote(config.image))
    flags.push(`--docker`)
  }
  if (config.duration) {
    flags.push(`--duration ${config.duration}`)
  }

  if (config.advancedTriage) {
    flags.push(`--advanced-triage true`)
  }

  // Define explicit tasks if the defaults are not used
  if (!areSetEqual(DefaultTasks, config.tasks)) {
    if (config.tasks.indexOf('exploitability_factors') !== -1) {
      flags.push('--static')
    }
    if (config.tasks.indexOf('behavior_testing') !== -1) {
      flags.push('--dynamic')
    }
    if (config.tasks.indexOf('regression_testing') !== -1) {
      flags.push('--regression')
    }
    if (config.tasks.indexOf('coverage_analysis') !== -1) {
      flags.push('--coverage')
    }
  }
  if (config.uid) {
    flags.push(`--uid ${config.uid}`)
  }
  if (config.gid) {
    flags.push(`--gid ${config.gid}`)
  }

  if (config.commands.length > 0) {
    const command = config.commands[0]
    flags.push(`--cmd ${quote(command.command)}`)
    if (command.network?.url) {
      flags.push(`--network-url ${quote(command.network.url)}`)
    }
    if (command.network?.timeout) {
      flags.push(`--network-timeout ${command.network.timeout}`)
    }
    if (command.network?.client) {
      flags.push(`--network-client true`)
    }
    if (command.libfuzzer !== undefined) {
      flags.push(`--libfuzzer ${command.libfuzzer}`)
    }
    if (command.honggfuzz !== undefined) {
      flags.push(`--honggfuzz ${command.honggfuzz}`)
    }
    if (command.sanitizer !== undefined) {
      flags.push(`--sanitizer ${command.sanitizer}`)
    }
    if (command.maxLength) {
      flags.push(`--max-length ${command.maxLength}`)
    }
    if (command.memoryLimit) {
      flags.push(`--memory-limit ${command.memoryLimit}`)
    }
    if (command.timeout) {
      flags.push(`--timeout ${command.timeout}`)
    }
    if (command.cwd) {
      flags.push(`--cwd ${quote(command.cwd)}`)
    }
    if (command.filepath) {
      flags.push(`--filepath ${quote(command.filepath)}`)
    }
    for (const [key, value] of Object.entries(command.environment)) {
      const escapedEnv = quote(`${key}=${value}`)
      flags.push(`--env ${escapedEnv}`)
    }
  }

  return mayhemCommand('run', args, flags)
}

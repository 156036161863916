import { useCallback, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useLocation } from 'wouter'
import debounce from 'lodash/debounce'

import { useQuery } from '../../hooks'
import { setQueryParam } from '../../util/location'
import { mayhemfileYamlSerialize } from '../run-code-create/Mayhemfile'

import { fromForm, FormCodeRunConfig, FormCodeRunConfigContext } from './utils'

export function useCodeRunFormQueryEncoder(
  { getValues, watch, formState: { isSubmitting } }: UseFormReturn<FormCodeRunConfig>,
  context: FormCodeRunConfigContext
) {
  const [location, setLocation] = useLocation()

  const query = useQuery()
  const mayhemfileQuery = query.get('mayhemfile')
  const mayhemfileYaml = mayhemfileQuery ? decodeMayhemFile(mayhemfileQuery) : undefined

  const formValues = getValues()
  const formConfig = fromForm(formValues, context)
  const formYaml = mayhemfileYamlSerialize(formConfig)

  // Subscribe to all form changes, this will allow the component to re-render
  //    whenever a field is changed allowing to update the query parameter
  watch()

  // Create a unique and throttled callback to execute whenever there is a change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateQueryParams = useCallback(
    debounce((location, setLocation, query, previousYaml, currentYaml) => {
      if (previousYaml === currentYaml) {
        return
      }
      const currentYamlEncoded = encodeMayhemFile(currentYaml)
      const newUrl = setQueryParam({ location, queryParams: query, param: 'mayhemfile', value: currentYamlEncoded })
      // Make sure to use { replace: true } so history forwards / backwards
      //  navigation works. Otherwise, each user keystroke generates a new
      //  history record.
      setLocation(newUrl, { replace: true })
    }, 750),
    []
  )

  useEffect(() => {
    if (!window.location.pathname.includes('/-/create-code-run')) {
      // Prevent a condition where using browser navigation will trigger
      //  a change in search before a location change. The search change
      //  triggers updateQueryParams cancelling any navigation actions.
      return
    }

    // Avoid setLocation race condition where onSubmit may change the location
    //   but that change is not accessible to this hook until the next render.
    if (!isSubmitting) {
      updateQueryParams(location, setLocation, query, mayhemfileYaml, formYaml)
    }
  }, [isSubmitting, location, setLocation, formYaml, mayhemfileYaml, updateQueryParams, query])
}

export function encodeMayhemFile(mayhemFile: string): string {
  return encodeURIComponent(btoa(escape(mayhemFile)))
}

export function decodeMayhemFile(encodedMayhemFile: string): string {
  return unescape(atob(encodedMayhemFile))
}

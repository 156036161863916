import { FieldPath, FormProvider, useForm } from 'react-hook-form'
import {
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalHeader,
  useDisclosure,
  useToast,
  ModalFooter,
  Text
} from '@chakra-ui/react'
import AccountIcon from '@material-design-icons/svg/sharp/account_circle.svg?react'
import MailIcon from '@material-design-icons/svg/sharp/mail.svg?react'
import PersonIcon from '@material-design-icons/svg/sharp/person.svg?react'
import PersonAddIcon from '@material-design-icons/svg/sharp/person_add.svg?react'

import { validateEmail } from '../auth/AuthForm'
import { getMessageFromRtkError } from '../../util/errors'
import { usePostUserAdminModeMutation } from '../../redux/api/admin'

type Form = { username: string; email: string; firstName: string; lastName: string }

export function AddUserPopup() {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [postUserAdminMode] = usePostUserAdminModeMutation()

  const methods = useForm<Form>()
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting }
  } = methods

  const onSubmit = async ({ username, email, firstName, lastName }: Form) => {
    try {
      await postUserAdminMode({ adminAddUser: { email, username, first_name: firstName, last_name: lastName } }).unwrap()
      toast({
        title: 'User Added',
        description: `${username} was added successfully.`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      onClose()
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      let errLocation: FieldPath<Form> | 'root' = 'root'
      if (message.includes('user or organization with the same name')) {
        errLocation = 'username'
      } else if (message.toLowerCase().includes('email')) {
        errLocation = 'email'
      }
      setError(errLocation, { type: 'custom', message })
      toast({
        title: 'Add User Failed',
        description: message,
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
  }

  return (
    <>
      <Button variant="outline" leftIcon={<PersonAddIcon />} onClick={onOpen}>
        Add User
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered={false}>
        <ModalOverlay />
        <ModalContent>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalHeader>Add New User</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack spacing={4}>
                  <Text>Users will be sent an email with a link to a page to set their password.</Text>

                  <Stack spacing={4}>
                    <FormControl isRequired isInvalid={!!errors.firstName}>
                      <InputGroup>
                        <Input
                          type="text"
                          placeholder="First Name"
                          autoComplete="given-name"
                          data-selenium-id="firstName"
                          {...register('firstName', { maxLength: 128 })}
                        />
                        <InputRightElement>
                          <Icon as={PersonIcon} color="gray.500" />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={!!errors.lastName}>
                      <InputGroup>
                        <Input
                          type="text"
                          placeholder="Last Name"
                          autoComplete="family-name"
                          data-selenium-id="lastName"
                          {...register('lastName', { maxLength: 128 })}
                        />
                        <InputRightElement>
                          <Icon as={PersonIcon} color="gray.500" />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={!!errors.username}>
                      <InputGroup>
                        <Input
                          type="text"
                          placeholder="Username"
                          autoComplete="username"
                          data-selenium-id="username"
                          {...register('username', { maxLength: 128 })}
                        />
                        <InputRightElement>
                          <Icon as={AccountIcon} color="gray.500" />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={!!errors.email}>
                      <InputGroup>
                        <Input
                          type="text"
                          placeholder="Email"
                          autoComplete="email"
                          data-selenium-id="email"
                          {...register('email', { maxLength: 128, validate: validateEmail })}
                        />
                        <InputRightElement>
                          <Icon as={MailIcon} color="gray.500" />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" isLoading={isSubmitting} data-selenium-id="docker-flow-start-run-button">
                  Add User
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  )
}

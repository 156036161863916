import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Spacer,
  Stack,
  StackDivider,
  Text
} from '@chakra-ui/react'
import CloseIcon from '@material-design-icons/svg/sharp/close.svg?react'
import RightArrowIcon from '@material-design-icons/svg/sharp/keyboard_arrow_right.svg?react'

import { Link as WouterLink, useLocation } from 'wouter'

import { Feature } from 'flagged'

import { CWEBadge } from '../../components/CWEBadge'
import { ErrorPanel } from '../../components/ErrorPanel'
import { FEATURE_LIFECYCLE_MANAGEMENT } from '../../featureFlags'
import { useQuery } from '../../hooks'
import { useGetDefectByTargetQuery, useGetIssueRuleQuery, useGetProjectRestApiIssuesQuery, useGetRestApiIssueQuery } from '../../redux/api/defects'
import { setQueryParams } from '../../util/location'

import { JiraIssue } from '../defect-jira/JiraIssue'

import { RunApiDefectPreviewTitle } from '../run-api-defects/RunApiDefectPreviewTitle'

import { ApiDefectMetadata } from './ApiDefectMetadata'
import { ApiDefectSampleRequestResponse } from './ApiDefectSampleRequestResponse'
import { DefectSeverityBadge } from './DefectSeverityBadge'
import { DefectSeverityExplainer } from './DefectSeverityExplainer'
import { DefectStatusBadge } from './DefectStatusBadge'

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  runNumber: number | null | undefined
  isModal?: boolean
}

export function ApiDefectPreview({ owner, projectSlug, targetSlug, defectNumber, runNumber, isModal = false }: Props) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const {
    isFetching: isDefectFetching,
    data: defect,
    isError: defectIsError
  } = useGetDefectByTargetQuery({ owner, projectSlug, targetSlug, defectNumber })

  const {
    isLoading: isIssueLoading,
    issue: runIssue,
    isError: isRunIssueError
  } = useGetRestApiIssueQuery(
    {
      owner,
      projectSlug,
      targetSlug,
      runNumber: runNumber || 0,
      defectNumber
    },
    { skip: runNumber === undefined || runNumber === null || defectNumber === undefined || defectNumber === null }
  )

  const {
    isLoading: isProjectIssueLoading,
    issue: projectIssue,
    isError: isProjectIssueError
  } = useGetProjectRestApiIssuesQuery(
    {
      owner,
      projectSlug,
      defectNumber
    },
    { skip: runNumber !== undefined && runNumber !== null && defectNumber !== undefined && defectNumber !== null }
  )

  const issue = projectIssue || runIssue
  const isIssueError = isProjectIssueError || isRunIssueError

  const { isLoading: isRuleLoading, data: rule } = useGetIssueRuleQuery(
    { owner, projectSlug, issueRuleId: issue?.issue_rule_id || '' },
    { skip: !issue }
  )

  if (isIssueLoading || isRuleLoading || isProjectIssueLoading) {
    return <Skeleton height={80} borderRadius={isModal ? 'md' : '2xl'} />
  }

  if (defectIsError) {
    return <ErrorPanel msg="We can't find that defect for you. Sorry about that!" />
  }

  const defectPageUrl = `/${owner}/${projectSlug}/${targetSlug}/-/defects/${defectNumber}${runNumber ? '/runs/' + runNumber : ''}`
  const closeDefectPreview = (): void => {
    const newUrl = setQueryParams({
      location,
      queryParams: queryParams,
      params: [
        { param: 'defect', value: null },
        { param: 'exampleTestcase', value: null }
      ]
    })
    setLocation(newUrl, { replace: true })
  }

  const { severity_level: severityLevel, severity, title, cwe_number: cweNumber, cwe_link: cweLink, state } = defect || {}

  return (
    <Card borderWidth={isModal ? 0 : undefined} borderRadius={isModal ? 'md' : undefined} maxHeight="90vh">
      <CardHeader>
        <HStack>
          <RunApiDefectPreviewTitle cweNumber={cweNumber} defectPageUrl={defectPageUrl} title={title || ''} />
          <IconButton variant="outline" aria-label="close test case" icon={<CloseIcon />} onClick={closeDefectPreview} />
        </HStack>
      </CardHeader>
      <CardBody borderBottomWidth={1}>
        <Flex p={4}>
          <HStack>
            <Text color="faded">Defect #{defectNumber}</Text>
            <DefectSeverityBadge severityLevel={severityLevel} severity={severity} />
            <DefectSeverityExplainer />
            {rule && rule.cwes?.map((cwe) => <CWEBadge key={cwe.cwe_id} cwe={cwe} />)}
            {!rule && <CWEBadge cwe={{ cwe_id: `CWE-${cweNumber}`, link: cweLink }} />}
            <Feature name={FEATURE_LIFECYCLE_MANAGEMENT}>
              <DefectStatusBadge state={state} />
            </Feature>
          </HStack>
          <Spacer />
          <JiraIssue defectNumber={defectNumber} owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} />
        </Flex>
      </CardBody>
      <CardBody p={4} height="full" overflowY="auto">
        <Skeleton isLoaded={!isDefectFetching}>
          <Stack gap={4} divider={<StackDivider />}>
            <ApiDefectMetadata issue={issue} />
            <ApiDefectSampleRequestResponse
              owner={owner}
              projectSlug={projectSlug}
              targetSlug={targetSlug}
              issue={issue}
              isIssueError={isIssueError}
              hideRunLink={!!runNumber}
            />
          </Stack>
        </Skeleton>
      </CardBody>
      <CardFooter justifyContent="end">
        <WouterLink to={defectPageUrl}>
          <Button rightIcon={<RightArrowIcon />}>View Full Defect Details</Button>
        </WouterLink>
      </CardFooter>
    </Card>
  )
}

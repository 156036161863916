import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['mapi'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMapiRequestLogs: build.query<GetMapiRequestLogsApiResponse, GetMapiRequestLogsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/api/request-log`,
          params: {
            endpoints: queryArg.endpoints,
            mapi_request_log_ids: queryArg.mapiRequestLogIds,
            methods: queryArg.methods,
            offset: queryArg.offset,
            order_by_non_2xx_first: queryArg.orderByNon2XxFirst,
            path: queryArg.path,
            per_page: queryArg.perPage,
            response_status_codes: queryArg.responseStatusCodes
          }
        }),
        providesTags: ['mapi']
      }),
      deleteMapiRequestLogs: build.mutation<DeleteMapiRequestLogsApiResponse, DeleteMapiRequestLogsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/api/request-log`,
          method: 'DELETE'
        }),
        invalidatesTags: ['mapi']
      }),
      getMapiRequestLogsFilters: build.query<GetMapiRequestLogsFiltersApiResponse, GetMapiRequestLogsFiltersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/api/request-log/filters`
        }),
        providesTags: ['mapi']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetMapiRequestLogsApiResponse = /** status 200 Get mapi requests logs */ MapiRequestLogsPage
export type GetMapiRequestLogsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  endpoints?: string
  mapiRequestLogIds?: number[]
  methods?: string[]
  /** Offset in the result. */
  offset?: number
  orderByNon2XxFirst?: boolean
  path?: string
  /** Number of elements per page. */
  perPage?: number
  responseStatusCodes?: number[]
}
export type DeleteMapiRequestLogsApiResponse = /** status 200 Delete mapi request logs */ Ok
export type DeleteMapiRequestLogsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetMapiRequestLogsFiltersApiResponse = /** status 200 Fetches the different filterable sets of values for the underlying mapi request
    log entries */ MapiRequestLogsFilters
export type GetMapiRequestLogsFiltersApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type HttpHeader = {
  key?: string
  value?: string
}
export type MapiRequestLog = {
  service_url: string
  path: string
  rendered_path: string
  method: string
  rendered_method: string
  request_headers: HttpHeader[]
  request_body?: string
  response_headers?: HttpHeader[]
  response_body?: string
  response_status?: number
  request_time_ms?: number
  mapi_request_log_id: number
  target_id: number
  run_id: number
}
export type MapiRequestLogsPage = {
  requests: MapiRequestLog[]
  count: number
}
export type ApiError = {
  message?: string
}
export type Ok = {
  result?: string
}
export type MapiRequestLogsEndpoint = {
  method: string
  path: string
}
export type MapiRequestLogsFilters = {
  response_status_codes: number[]
  endpoints: MapiRequestLogsEndpoint[]
}

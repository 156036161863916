import { useEffect } from 'react'

import { ScrollableSettingsPage } from '../settings/ScrollableSettingsPage'
import { getWorkspacePageTitle } from '../../titles'

import { WebhooksTab } from '../workspace-settings/WebhooksTab'

import { Category } from './integration'
import { IntegrationList } from './IntegrationList'
import { MAYHEM_INTEGRATIONS } from './MayhemIntegrations'

interface Props {
  workspace: string
}

export const IntegrationsPage = ({ workspace }: Props) => {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Integrations')
  }, [workspace])

  const languages = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.LANGUAGE === item))
  const apis = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.API === item))
  const cicd = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.CICD === item))
  const notifications = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.NOTIFICATION === item))
  const buildtools = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.BUILDTOOL === item))

  const sections = [
    { id: 'languages', component: <IntegrationList title={`Languages (${languages.length})`} integrations={languages} /> },
    { id: 'apis', component: <IntegrationList title={`API (${apis.length})`} integrations={apis} /> },
    { id: 'cicd', component: <IntegrationList title={`CI/CD (${cicd.length})`} integrations={cicd} /> },
    { id: 'buildtools', component: <IntegrationList title={`Build Tools (${buildtools.length})`} integrations={buildtools} /> },
    { id: 'notifications', component: <IntegrationList title={`Notifications (${notifications.length})`} integrations={notifications} /> },
    { id: 'webhooks', component: <WebhooksTab workspace={workspace} /> }
  ]

  return <ScrollableSettingsPage title="Integrations" sections={sections} />
}

import { Center, CircularProgress, Text } from '@chakra-ui/react'

import MayhemTableLoader from './MayhemTableLoader'

export default function ListDefaults(props: Props): JSX.Element {
  const {
    isLoading = false,
    itemName = '',
    itemNamePlural = '',
    nItems = 0,
    isFail = false,
    noResultsMsg = null,
    hideNoResultsMsg = false,
    failureMsg = '',
    mini = false
  } = props
  const showNoResults = !isLoading && nItems === 0 && !isFail && !hideNoResultsMsg
  const showFailure = !isLoading && nItems === 0 && isFail
  const plural = itemNamePlural || `${itemName}s`
  const _loader = mini ? <CircularProgress isIndeterminate color="accent" /> : <MayhemTableLoader />

  if (!(showNoResults || showFailure || isLoading)) {
    return <></>
  }

  return (
    <Center padding={8} minH={0}>
      {showNoResults && <Text margin={8}>{noResultsMsg || `No ${plural} found.`}</Text>}
      {showFailure && <Text margin={8}>{failureMsg || `There was an error fetching ${plural}. Please hit refresh to try again.`}</Text>}
      {isLoading && _loader}
    </Center>
  )
}

interface Props {
  isLoading?: boolean
  itemName?: string
  itemNamePlural?: string
  nItems?: number
  isFail?: boolean
  noResultsMsg?: string | JSX.Element
  hideNoResultsMsg?: boolean
  failureMsg?: string
  mini?: boolean
}

import { Flex, Spinner } from '@chakra-ui/react'

// loading spinner, formerly contained Mayhem logo (may once again someday soon!)
export default function MayhemTableLoader(): JSX.Element {
  return (
    <Flex align="center" justify="center">
      <Spinner size="xl" data-testid="table-loader" />
    </Flex>
  )
}

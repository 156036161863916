import { useFormContext } from 'react-hook-form'
import { FormControl, FormLabel, Select, Stack } from '@chakra-ui/react'

import { FormCodeRunConfig } from './utils'

export interface CodeRunConfigCommandFuzzersProps {
  cmdIndex: number
}

export const CodeRunConfigCommandFuzzers = ({ cmdIndex }: CodeRunConfigCommandFuzzersProps) => {
  const { register } = useFormContext<FormCodeRunConfig>()

  const fuzzingSettingOptions = [
    {
      key: 'automatic',
      text: 'Automatic',
      value: 'automatic'
    },
    {
      key: 'enabled',
      text: 'Enabled',
      value: 'enabled'
    },
    {
      key: 'disabled',
      text: 'Disabled',
      value: 'disabled'
    }
  ]

  return (
    <Stack>
      <FormControl>
        <FormLabel>AFL ++</FormLabel>
        <Select defaultValue="automatic" {...register(`cmdSettings.${cmdIndex}.afl`)}>
          {fuzzingSettingOptions.map((o) => (
            <option key={o.key} value={o.value}>
              {o.text}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Sanitizer</FormLabel>
        <Select defaultValue="automatic" {...register(`cmdSettings.${cmdIndex}.sanitizer`)}>
          {fuzzingSettingOptions.map((o) => (
            <option key={o.key} value={o.value}>
              {o.text}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Honggfuzz</FormLabel>
        <Select defaultValue="automatic" {...register(`cmdSettings.${cmdIndex}.honggfuzz`)}>
          {fuzzingSettingOptions.map((o) => (
            <option key={o.key} value={o.value}>
              {o.text}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Libfuzzer</FormLabel>
        <Select defaultValue="automatic" {...register(`cmdSettings.${cmdIndex}.libfuzzer`)}>
          {fuzzingSettingOptions.map((o) => (
            <option key={o.key} value={o.value}>
              {o.text}
            </option>
          ))}
        </Select>
      </FormControl>
    </Stack>
  )
}

import AdaLogo from '../../images/integrations/ada_logo.svg?react'
import AndroidLogo from '../../images/integrations/android_logo.svg?react'
import AzureLogo from '../../images/integrations/azure_logo.svg?react'
import BazelLogo from '../../images/integrations/bazel_logo.svg?react'
import BinaryCodeLogo from '../../images/integrations/binarycode_logo.svg?react'
import CLogo from '../../images/integrations/c_logo.svg?react'
import CPlusPlusLogo from '../../images/integrations/cplusplus_logo.svg?react'
import CSharpLogo from '../../images/integrations/csharp_logo.svg?react'
import CircleCILogo from '../../images/integrations/circleci_logo.svg?react'
import FlaskLogo from '../../images/integrations/flask_logo.svg?react'
import FortranLogo from '../../images/integrations/fortran_logo.svg?react'
import GRPCLogo from '../../images/integrations/grpc_logo.svg?react'
import GitHubLogo from '../../images/integrations/github_logo.svg?react'
import GitLabLogo from '../../images/integrations/gitlab_logo.svg?react'
import GoLogo from '../../images/integrations/go_logo.svg?react'
import GoogleChatLogo from '../../images/integrations/googlechat_logo.svg?react'
import HarfileLogo from '../../images/integrations/harfile_logo.svg?react'
import JavaLogo from '../../images/integrations/java_logo.svg?react'
import JenkinsLogo from '../../images/integrations/jenkins_logo.svg?react'
import KotlinLogo from '../../images/integrations/kotlin_logo.svg?react'
import MatlabLogo from '../../images/integrations/matlab_logo.svg?react'
import OCamlLogo from '../../images/integrations/ocaml_logo.svg?react'
import ObjectiveCLogo from '../../images/integrations/objectivec_logo.svg?react'
import OpenAPILogo from '../../images/integrations/openapi_logo.svg?react'
import PostmanLogo from '../../images/integrations/postman_logo.svg?react'
import PythonLogo from '../../images/integrations/python_logo.svg?react'
import RustLogo from '../../images/integrations/rust_logo.svg?react'
import SlackLogo from '../../images/integrations/slack_logo.svg?react'
import SpringBootLogo from '../../images/integrations/springboot_logo.svg?react'
import SwiftLogo from '../../images/integrations/swift_logo.svg?react'
import TravisCILogo from '../../images/integrations/travisci_logo.svg?react'
import WebhooksLogo from '../../images/integrations/webhooks_logo.svg?react'

import { Integration, Category } from './integration'

export const MAYHEM_INTEGRATIONS: Integration[] = [
  {
    id: 0,
    categories: [Category.LANGUAGE],
    name: 'Binary code',
    description: 'Compiled x86, x64, MIPS, or PowerPC Linux binary',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/c/base-executable',
    href_button_text: 'See example',
    icon: BinaryCodeLogo
  },
  {
    id: 1,
    categories: [Category.LANGUAGE],
    name: 'Ada',
    description: 'Ada programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/ada',
    href_button_text: 'See example',
    icon: AdaLogo
  },
  {
    id: 2,
    categories: [Category.LANGUAGE],
    name: 'AFL binary',
    description: 'AFL and AFL++ compiled binary',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/c/afl',
    href_button_text: 'See example',
    icon: BinaryCodeLogo
  },
  {
    id: 3,
    categories: [Category.LANGUAGE],
    name: 'C',
    description: 'C programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/c',
    href_button_text: 'See example',
    icon: CLogo
  },

  {
    id: 4,
    categories: [Category.LANGUAGE],
    name: 'Android',
    description: 'Android development',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/android',
    href_button_text: 'See example',
    icon: AndroidLogo
  },
  {
    id: 5,
    categories: [Category.LANGUAGE],
    name: 'C++',
    description: 'C++ programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/cpp',
    href_button_text: 'See example',
    icon: CPlusPlusLogo
  },
  {
    id: 6,
    categories: [Category.LANGUAGE],
    name: 'C#',
    description: 'C# programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/csharp',
    href_button_text: 'See example',
    icon: CSharpLogo
  },
  {
    id: 7,
    categories: [Category.LANGUAGE],
    name: 'Fortran',
    description: 'Fortran programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/fortran',
    href_button_text: 'See example',
    icon: FortranLogo
  },
  {
    id: 8,
    categories: [Category.LANGUAGE],
    name: 'golang',
    description: 'Go programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/go',
    href_button_text: 'See example',
    icon: GoLogo
  },
  {
    id: 9,
    categories: [Category.LANGUAGE],
    name: 'hongfuzz binary',
    description: 'hongfuzz compiled binary',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/c/honggfuzz',
    href_button_text: 'See example',
    icon: BinaryCodeLogo
  },

  {
    id: 10,
    categories: [Category.LANGUAGE],
    name: 'Java',
    description: 'Java programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/java',
    href_button_text: 'See example',
    icon: JavaLogo
  },
  {
    id: 11,
    categories: [Category.LANGUAGE],
    name: 'Kotlin',
    description: 'Kotlin programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/kotlin',
    href_button_text: 'See example',
    icon: KotlinLogo
  },
  {
    id: 12,
    categories: [Category.LANGUAGE],
    name: 'libfuzzer binary',
    description: 'libfuzzer binary',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/c/libfuzzer/c-libfuzzer',
    href_button_text: 'See example',
    icon: BinaryCodeLogo
  },
  {
    id: 13,
    categories: [Category.LANGUAGE],
    name: 'Objective C',
    description: 'Objective C programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/objective-c',
    href_button_text: 'See example',
    icon: ObjectiveCLogo
  },
  {
    id: 14,
    categories: [Category.LANGUAGE],
    name: 'OCaml',
    description: 'OCaml programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/ocaml',
    href_button_text: 'See example',
    icon: OCamlLogo
  },
  {
    id: 15,
    categories: [Category.LANGUAGE],
    name: 'Python',
    description: 'Python programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/python',
    href_button_text: 'See example',
    icon: PythonLogo
  },
  {
    id: 16,
    categories: [Category.LANGUAGE],
    name: 'Rust',
    description: 'Rust programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/rust',
    href_button_text: 'See example',
    icon: RustLogo
  },
  {
    id: 17,
    categories: [Category.LANGUAGE],
    name: 'Swift',
    description: 'Swift programming language',
    href: 'https://github.com/ForAllSecure/mayhem-examples/tree/main/swift',
    href_button_text: 'See example',
    icon: SwiftLogo
  },
  {
    id: 18,
    categories: [Category.CICD],
    name: 'Azure Pipelines',
    description: 'Azure Pipelines',
    href: '/docs/integrations/ci-integrations/azure/',
    href_button_text: 'Learn how',
    icon: AzureLogo
  },
  {
    id: 19,
    categories: [Category.CICD],
    name: 'CircleCI Pipelines',
    description: 'CircleCI',
    href: '/docs/integrations/ci-integrations/circleci/',
    href_button_text: 'Learn how',
    icon: CircleCILogo
  },
  {
    id: 20,
    categories: [Category.CICD],
    name: 'Github Actions',
    description: 'Github Actions',
    href: '/docs/integrations/ci-integrations/github/',
    href_button_text: 'Learn how',
    icon: GitHubLogo
  },
  {
    id: 21,
    categories: [Category.CICD],
    name: 'Jenkins',
    description: 'Jenkins',
    href: '/docs/integrations/ci-integrations/jenkins/',
    href_button_text: 'Learn how',
    icon: JenkinsLogo
  },
  {
    id: 22,
    categories: [Category.NOTIFICATION],
    name: 'Google Chat',
    description: 'Google Chat',
    href_button_text: 'Learn how',
    href: '/docs/integrations/webhooks/google-chat/',
    icon: GoogleChatLogo
  },
  {
    id: 23,
    categories: [Category.NOTIFICATION],
    name: 'Slack',
    description: 'Slack',
    href_button_text: 'Learn how',
    href: '/docs/integrations/webhooks/slack/',
    icon: SlackLogo
  },
  {
    id: 24,
    categories: [Category.NOTIFICATION, Category.OTHER],
    name: 'Webhooks',
    description: 'Webhooks',
    href_button_text: 'Learn how',
    href: '/docs/integrations/webhooks/overview/',
    icon: WebhooksLogo
  },
  {
    id: 25,
    categories: [Category.LANGUAGE],
    name: 'Matlab',
    description: 'Matlab programming language',
    href: 'https://github.com/ForAllSecure/mayhem-matlab',
    href_button_text: 'See example',
    icon: MatlabLogo
  },
  {
    id: 26,
    categories: [Category.BUILDTOOL],
    name: 'Bazel',
    description: 'Bazel build system',
    href_button_text: 'Learn how',
    href: 'https://github.com/bazelbuild/rules_fuzzing',
    icon: BazelLogo
  },
  {
    id: 27,
    categories: [Category.BUILDTOOL],
    name: 'Cargo Fuzz',
    description: 'Cargo build system',
    href_button_text: 'Learn how',
    href: 'https://rust-fuzz.github.io/book/cargo-fuzz.html',
    icon: RustLogo
  },
  {
    id: 28,
    categories: [Category.API],
    name: 'OpenAPI',
    description: 'OpenAPI',
    href_button_text: 'Learn how',
    href: '/docs/api-testing/tutorials/testing-apis-with-mayhem/test-your-own-api/#api-specification',
    icon: OpenAPILogo
  },
  {
    id: 29,
    categories: [Category.API],
    name: 'HTTP Archive',
    description: 'HTTP Archive',
    href_button_text: 'Learn how',
    href: '/docs/api-testing/tutorials/testing-apis-with-mayhem/har-support/',
    icon: HarfileLogo
  },
  {
    id: 30,
    categories: [Category.API],
    name: 'Postman',
    description: 'Postman',
    href_button_text: 'Learn how',
    href: '/docs/api-testing/tutorials/testing-apis-with-mayhem/postman-support/',
    icon: PostmanLogo
  },
  {
    id: 31,
    categories: [Category.API],
    name: 'gRPC',
    description: 'gRPC',
    href_button_text: 'Learn how',
    href: '/docs/api-testing/guides/grpc/',
    icon: GRPCLogo
  },
  {
    id: 32,
    categories: [Category.API],
    name: 'Flask',
    description: 'Flask (Python)',
    href_button_text: 'Learn how',
    href: '/docs/api-testing/tutorials/optimizing-test-coverage/configure-your-api/#flask-python',
    icon: FlaskLogo
  },
  {
    id: 33,
    categories: [Category.API],
    name: 'Springboot',
    description: 'Spring Boot (Java)',
    href_button_text: 'Learn how',
    href: '/docs/api-testing/tutorials/optimizing-test-coverage/configure-your-api/#spring-boot-java',
    icon: SpringBootLogo
  },
  {
    id: 34,
    categories: [Category.CICD],
    name: 'GitLab',
    description: 'GitLab CI/CD',
    href_button_text: 'Learn how',
    href: '/docs/integrations/ci-integrations/gitlab/',
    icon: GitLabLogo
  },
  {
    id: 35,
    categories: [Category.CICD],
    name: 'TravisCI',
    description: 'TravisCI',
    href_button_text: 'Learn how',
    href: '/docs/integrations/ci-integrations/travisci/',
    icon: TravisCILogo
  }
]

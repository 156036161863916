import { useEffect } from 'react'
import { Link as WouterLink } from 'wouter'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading } from '@chakra-ui/react'

import { getProjectBuildsPageTitle } from '../../titles'

import { ProjectBuildsList } from '../project-builds/ProjectBuildsList'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'

type Props = {
  workspace: string
  project: string
}

export function ProjectBuildsPage(props: Props) {
  const { workspace, project } = props

  useEffect(() => {
    document.title = getProjectBuildsPageTitle(workspace, project)
  }, [workspace, project])

  return (
    <Page>
      <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }} mb={8}>
        <BreadcrumbItem>
          <WouterLink to={`/${workspace}/${project}`}>
            <BreadcrumbLink>
              <TruncatedTextWithTooltip text={project} />
            </BreadcrumbLink>
          </WouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Builds</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Heading mb={8}>Builds</Heading>
      <ProjectBuildsList owner={workspace} projectSlug={project} />
    </Page>
  )
}

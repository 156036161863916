import { Card, CardBody, Link, Stack, Text } from '@chakra-ui/react'

export function FilterBar() {
  return (
    <Card>
      <CardBody p={4}>
        <Stack align="center">
          <Text>Think this is great? Got an idea about how to improve it? Missing something you want?</Text>
          <Text>
            We&apos;d love to hear from you! Email feedback to us at&nbsp;
            <Link href="mailto:product@forallsecure.com?subject=Mayhem Dashboard Feedback" textDecoration="underline">
              product@forallsecure.com
            </Link>
            .
          </Text>
        </Stack>
      </CardBody>
    </Card>
  )
}

export default FilterBar

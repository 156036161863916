/**
 * Returns the path of a package URL (purl) without the query parameters
 * eg. stripPurlParams('https://example.com?param1=1&param2=2') => 'https://example.com'
 * @param purl
 * @returns
 */

export function stripPurlParams(purl: string): string {
  return purl.split('?')[0]
}

/**
 * Returns the URL download link for a report file based on the workspace and report ID
 */
export function constructReportFileDownloadUrl(workspace: string, reportId: string | undefined | null): string | undefined {
  if (!reportId) {
    return undefined
  }
  return `/api/v2/workspace/${workspace}/dsbom/report/${reportId}/download`
}

/**
 * Convert an image repo ID to it's name.
 *
 * NB: We should remove this, and rely on the API to provide an appropriate name.
 */
export function imageRepoIdToName(imageRepoId: string): string {
  try {
    return atob(imageRepoId)
  } catch (e) {
    return imageRepoId
  }
}

import { Text, Stack, Menu, MenuButton, Button, Portal, MenuList, MenuOptionGroup, MenuItemOption } from '@chakra-ui/react'

import ArrowDropDownIcon from '@material-design-icons/svg/sharp/arrow_drop_down.svg?react'

import { useLocation } from 'wouter'

import { useQuery } from '../../hooks'
import { setQueryParam } from '../../util/location'

type InputOptionKeys = 'anchore' | 'aquasec' | 'blackduck' | 'cyclonedx' | 'snyk' | 'spdx' | 'trivy' | 'veracode' | 'other'

export function DsbomComparisonFileTypeStep() {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const selectedInputs = queryParams.get('input')

  const inputOptions: Record<InputOptionKeys, { text: string; shortText?: string; type: string }> = {
    anchore: {
      text: 'Anchore',
      type: 'file'
    },
    aquasec: {
      text: 'Aquasec',
      type: 'file'
    },
    blackduck: {
      text: 'Black Duck',
      type: 'file'
    },
    cyclonedx: {
      text: 'CycloneDX',
      type: 'file'
    },
    snyk: {
      text: 'Snyk',
      type: 'file'
    },
    spdx: {
      text: 'SPDX',
      type: 'file'
    },
    trivy: {
      text: 'Trivy',
      type: 'file'
    },
    veracode: {
      text: 'Veracode',
      type: 'file'
    },
    other: {
      text: "Don't know one or both? Mayhem can probably figure it out.",
      shortText: 'Other',
      type: 'file'
    }
  }

  const selectedInputTypesText = selectedInputs
    ? (selectedInputs || '')
        .split(',')
        .map((inputType) => inputOptions[inputType as InputOptionKeys].shortText || inputOptions[inputType as InputOptionKeys].text)
        .join(', ')
    : undefined

  const handleFileInputTypesChange = (values: string | string[]) => {
    const valuesArray = Array.isArray(values) ? values : [values]
    setLocation(setQueryParam({ location, queryParams, param: 'input', value: valuesArray.length > 0 ? valuesArray.join(',') : undefined }))
  }

  return (
    <Stack alignItems="center">
      <Menu closeOnSelect={false} matchWidth>
        <MenuButton
          as={Button}
          rightIcon={<ArrowDropDownIcon />}
          variant="outline"
          colorScheme="gray"
          width="full"
          textAlign="left"
          whiteSpace="normal"
        >
          <Text noOfLines={2}>{selectedInputTypesText || 'Select joint SBOM/SCA tool, or your SBOM tool and SCA tool...'}</Text>
        </MenuButton>
        <Portal>
          <MenuList>
            <MenuOptionGroup type="checkbox" onChange={handleFileInputTypesChange}>
              {Object.keys(inputOptions).map((inputOption) => (
                <MenuItemOption key={`dsbom-select-input-type-${inputOption}`} value={inputOption}>
                  {inputOptions[inputOption as InputOptionKeys].text}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    </Stack>
  )
}

import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { Redirect } from 'wouter'

import { useGetOrgsByUserQuery, useWhoamiQuery } from '../../redux/api/workspace'

import { MayhemPageLoader } from '../../components/MayhemPageLoader'

import { link } from './utils'

export const WorkspaceDefaultRedirect = () => {
  const { isLoading: isLoadingProfile, data: profile } = useWhoamiQuery()
  const { isLoading, data: orgResponse } = useGetOrgsByUserQuery(
    profile
      ? {
          userSlug: profile.slug as string
        }
      : skipToken
  )

  const workspaces = useMemo(() => orgResponse?.orgs || [], [orgResponse])
  // `mru` could be a workspace that the user does not belong to, but the call
  // to fetch the workspace will fail and clear the redux store of that value
  // eventually.
  //
  // This assumes that there is an RTK query middleware that clears the `mru`
  // if any GET account response returned 403 or 404 (see /src/redux/middelware.ts).
  const myDest = useSelector((state: RootState) => state.workspace.mru)

  if (isLoadingProfile || isLoading) {
    return <MayhemPageLoader />
  }

  if (workspaces.length === 0) {
    return <Redirect to="/-/settings/create-workspace" />
  }

  /*
  Decide where to redirect the user:
  1. state.workspace.mru (persisted in localStorage), or
  2. user's "home" workspace, or
  3. "first" org from query.
  */
  let dest = myDest

  if (dest === '') {
    if (profile !== undefined && profile.slug) {
      dest = profile.slug
    } else {
      dest = workspaces[0].slug as string
    }
  }
  return <Redirect to={link(dest)} />
}

import { useEffect } from 'react'

import { Heading } from '@chakra-ui/react'

import { getWorkspacePageTitle } from '../../titles'

import { Page } from '../../components/Page'

import { WorkersFullNotice } from '../runs/WorkersFullNotice'

import { OrganizationRunsList } from './OrganizationRunsList'

interface Props {
  workspace: string
}

export function OrganizationRunsPage({ workspace }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Runs')
  }, [workspace])

  return (
    <Page>
      <WorkersFullNotice />
      <Heading mb={8}>Runs</Heading>
      <OrganizationRunsList owner={workspace} />
    </Page>
  )
}

import { Card, CardBody, CardFooter, CardHeader, Heading } from '@chakra-ui/react'

import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'
import { useQuery } from '../../hooks'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetMdsbomWorkspaceImageReposQuery } from '../../redux/api/mdsbom'
import { cleanInteger } from '../../util/numbers'

import { DsbomImageRow } from './DsbomImageRow'

interface Props {
  workspace: string
}

export function DsbomImagesList({ workspace }: Props) {
  const query = useQuery()

  const page = cleanInteger(query.get('page'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)

  const { data: imagesData, isFetching } = useGetMdsbomWorkspaceImageReposQuery({ workspace, offset: (page - 1) * perPage, perPage })

  const { image_repos: imageRepos = [], count = 0 } = imagesData || {}

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Images</Heading>
      </CardHeader>
      <CardBody>
        {imageRepos?.map((imageRepo) => <DsbomImageRow key={imageRepo.image_repo_id} workspace={workspace} imageRepo={imageRepo} />)}
        <ListDefaults isLoading={isFetching} nItems={count} itemName="image" />
      </CardBody>
      <CardFooter justifyContent="center">
        <MayhemPagination isLoading={isFetching} total={count} pageSize={perPage} />
      </CardFooter>
    </Card>
  )
}

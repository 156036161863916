import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'

import ArrowDropDownIcon from '@material-design-icons/svg/sharp/arrow_drop_down.svg?react'

import { getRunNumberUrl } from '@/features/runs/utils'
import { stringifyUrl } from '@/util/location'

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  level: string
}

const EventsListDownloadMenuButton = ({ owner, projectSlug, targetSlug, runNumber, level }: Props) => {
  const runNumberUrl = getRunNumberUrl(owner, projectSlug, targetSlug, runNumber)

  const downloadCsvLink = stringifyUrl(`${runNumberUrl}/events.csv`, {
    min_event_level: level === 'all' ? null : level
  })

  const downloadArchiveLink = stringifyUrl(`${runNumberUrl}/events.tgz`, {
    min_event_level: level === 'all' ? null : level
  })

  const archiveDownloadName = `${targetSlug}_${runNumber}_${new Date().toISOString()}_debug.tgz`

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ArrowDropDownIcon />}>
        Download
      </MenuButton>
      <MenuList>
        <MenuItem as="a" href={downloadCsvLink} download>
          Event Log (csv)
        </MenuItem>
        <MenuItem as="a" href={downloadArchiveLink} download={archiveDownloadName}>
          Event Log + Debug Artifacts (tgz)
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default EventsListDownloadMenuButton

import { combineReducers } from 'redux'

import mruReducer from '../workspace-dashboard/mru.slice'

import createCodeRunReducer from './create-code-run.slice'
import createApiRunReducer from './create-api-run.slice'

const workspaceReducer = combineReducers({
  new: combineReducers({
    code: createCodeRunReducer,
    api: createApiRunReducer
  }),
  mru: mruReducer
})

export default workspaceReducer

import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'

import { useLocation } from 'wouter'

import { useState } from 'react'

import { useQuery } from '../../hooks'
import { setQueryParams } from '../../util/location'

import { DsbomDefectPreview } from './DsbomDefectPreview'

interface Props {
  workspace: string
  imageRepoId: string
  defectId: string
}

export function DsbomDefectModal({ workspace, imageRepoId, defectId }: Props) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const [parentPageTitle] = useState(document.title)

  const goBackSafely = (): void => {
    const newUrl = setQueryParams({
      location,
      queryParams: queryParams,
      params: [
        { param: 'defect', value: null },
        { param: 'defectImage', value: null }
      ]
    })
    setLocation(newUrl, { replace: true })
  }

  return (
    <Modal
      isCentered={false}
      size="5xl"
      scrollBehavior="inside"
      isOpen
      onClose={() => {
        document.title = parentPageTitle
        goBackSafely()
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <DsbomDefectPreview {...{ workspace, imageRepoId, defectId }} isModal />
      </ModalContent>
    </Modal>
  )
}

import { Text, Center, Heading, HStack, Icon, useMultiStyleConfig, VStack } from '@chakra-ui/react'

import UrlIcon from '../../images/url.svg?react'
import { useQuery } from '../../hooks'

import { decodeUniqueImageReferenceKey } from './utils'
export function DsbomComparisonSpinner() {
  const styles = useMultiStyleConfig('FuzzingLoader')
  const query = useQuery()
  const encodedImageReference = query.get('selectedImageReference')
  const decodedImageReference = encodedImageReference ? decodeUniqueImageReferenceKey(encodedImageReference) : ''

  return (
    <Center paddingY={20}>
      <VStack>
        <Heading>
          <HStack>
            <Icon __css={styles.icon} as={UrlIcon} display="inline-block" height={63} width={66} />
          </HStack>
        </Heading>
        <Text as="b">Preparing your Mayhem DSBOM for {decodedImageReference}</Text>
      </VStack>
    </Center>
  )
}

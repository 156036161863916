import { Box, HStack, useMultiStyleConfig, useRadio, useRadioGroup } from '@chakra-ui/react'
import { DOMAttributes } from 'react'

import { FilterBarOption } from './filterBarOption'

export type DefectTrendFilterBarProps = {
  defaultFilterOption: FilterBarOption
  isDisabled?: boolean
  onChange?: (nextValue: FilterBarOption) => void
}

function DefectTrendButton(props: DOMAttributes<HTMLInputElement> & { isDisabled: boolean }) {
  const { getInputProps, getRadioProps } = useRadio(props)
  const styles = useMultiStyleConfig('DefectTrendChart', props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label">
      <input {...input} aria-label={input.value} disabled={props.isDisabled} />
      <Box {...checkbox} __css={styles.filterBarButton} noOfLines={1}>
        {props.children}
      </Box>
    </Box>
  )
}

export function DefectTrendFilterBar({ isDisabled = false, onChange, defaultFilterOption }: DefectTrendFilterBarProps) {
  const options: FilterBarOption[] = ['12 months', '3 months', '30 days', '7 days', '24 hours']
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'defectTrend',
    defaultValue: defaultFilterOption,
    onChange: onChange,
    isDisabled: isDisabled
  })

  const group = getRootProps()

  return (
    <HStack {...group} spacing={2}>
      {options.map((value) => {
        const radio = getRadioProps({ value })
        return (
          <DefectTrendButton key={value} isDisabled={isDisabled} {...radio}>
            {value}
          </DefectTrendButton>
        )
      })}
    </HStack>
  )
}

import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link as ChakraLink,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react'
import CloseIcon from '@material-design-icons/svg/sharp/close.svg?react'
import RightArrowIcon from '@material-design-icons/svg/sharp/keyboard_arrow_right.svg?react'

import { Link as WouterLink, useLocation } from 'wouter'

import { useQuery } from '../../hooks'
import { useGetMdsbomDefectQuery } from '../../redux/api/mdsbom'
import { setQueryParams } from '../../util/location'

import { getDefectIcon } from '../defect-report/utils'
import { DefectSeverityBadge, SeverityLevelLowerCase } from '../defect/DefectSeverityBadge'
import { DefectSeverityExplainer } from '../defect/DefectSeverityExplainer'

interface Props {
  workspace: string
  imageRepoId: string
  defectId: string
  isModal?: boolean
}

export function DsbomDefectPreview({ workspace, imageRepoId, defectId, isModal = false }: Props) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const { data: defectData, isFetching } = useGetMdsbomDefectQuery({ workspace, imageRepoId, defectId })
  const {
    title,
    severity,
    severity_level: severityLevel,
    image_repo: imageRepo,
    image_registry: imageRegistry,
    affected_packages: affectedPackages = [],
    cve_url: cveUrl
  } = defectData || {}

  const defectPageUrl = `/${workspace}/-/dynamic-sbom/${imageRepoId}/-/defects/${defectId}`
  const closeDefectPreview = (): void => {
    const newUrl = setQueryParams({
      location,
      queryParams: queryParams,
      params: [
        { param: 'defect', value: null },
        { param: 'defectImage', value: null }
      ]
    })
    setLocation(newUrl, { replace: true })
  }

  return (
    <Card borderWidth={isModal ? 0 : undefined} borderRadius={isModal ? 'md' : undefined} maxHeight="95vh">
      <CardHeader>
        <VStack>
          <WouterLink to={defectPageUrl}>
            <ChakraLink>
              <VStack>
                <HStack>
                  <Icon as={getDefectIcon(undefined)} boxSize={8} />
                  <HStack gap={0}>
                    <Heading variant="cardHeading">{title}</Heading>
                    <Icon as={RightArrowIcon} boxSize={8} />
                  </HStack>
                </HStack>
              </VStack>
            </ChakraLink>
          </WouterLink>
        </VStack>
        <IconButton variant="outline" aria-label="close test case" icon={<CloseIcon />} onClick={closeDefectPreview} />
      </CardHeader>
      <CardHeader>
        <HStack>
          <Text color="faded">Defect {defectId}</Text>
          <DefectSeverityBadge severityLevel={severityLevel as SeverityLevelLowerCase} severity={severity} />
          <DefectSeverityExplainer />
        </HStack>
      </CardHeader>
      <CardBody p={4} overflow="auto">
        <Skeleton isLoaded={!isFetching}>
          <Stack gap={4} divider={<StackDivider />}>
            <Box>
              <Text>For additional information about this defect, visit:</Text>
              <ChakraLink href={cveUrl || ''} isExternal>
                {cveUrl}
              </ChakraLink>
            </Box>
            <Box>
              <Text fontWeight="semibold">Image</Text>
              <Text>
                {imageRegistry}
                {imageRegistry !== null ? '/' : ''}
                {imageRepo}
              </Text>
            </Box>
            {affectedPackages.length > 0 && (
              <Box>
                <Text fontWeight="semibold">Affected Packages</Text>
                <Stack>
                  {affectedPackages.map((pkg) => (
                    <Text key={`defect-preview-package-${pkg.package_name}`}>
                      {pkg.package_name} - {pkg.package_url}
                    </Text>
                  ))}
                </Stack>
              </Box>
            )}
          </Stack>
        </Skeleton>
      </CardBody>
      <CardFooter justifyContent="end">
        <WouterLink to={defectPageUrl}>
          <Button rightIcon={<RightArrowIcon />}>View Full Defect Details</Button>
        </WouterLink>
      </CardFooter>
    </Card>
  )
}

import { Text, useToast } from '@chakra-ui/react'
import { useCallback } from 'react'

import { useLocation } from 'wouter'
import { Button, Modal, ModalOverlay, ModalCloseButton, ModalContent, ModalBody, ModalHeader, ModalFooter } from '@chakra-ui/react'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { logout } from '../auth/actions'

import { useDeleteUserMutation } from '../../redux/api/user'

import { getCurrentUserInfo } from '../auth/utils'
import { getMessageFromRtkError } from '../../util/errors'

interface Props {
  open: boolean
  onOpen: () => void
  onClose: () => void

  userSlug: string
}

export function DeleteUserModal({ open, onClose, userSlug }: Props) {
  const dispatch = useAppDispatch()
  const [_location, setLocation] = useLocation()
  const [deleteUserMutation] = useDeleteUserMutation()
  const toast = useToast()

  const currentUser = useAppSelector((state) => getCurrentUserInfo(state))

  const isSelf = userSlug === currentUser?.slug

  const deleteHandler = useCallback(() => {
    return async () => {
      try {
        await deleteUserMutation({ userSlug }).unwrap()
        if (isSelf) {
          try {
            await dispatch(logout())
          } finally {
            setLocation('/-/sign-in', { replace: true })
          }
        } else {
          toast({
            title: 'Account Deleted',
            description: `Account "${userSlug}" successfully deleted!`,
            status: 'success',
            duration: 5000,
            isClosable: true
          })
        }
        onClose()
      } catch (err: unknown) {
        const message = getMessageFromRtkError(err)
        toast({
          title: `Failed to delete account "${userSlug}".`,
          description: message,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }, [dispatch, isSelf, onClose, setLocation, userSlug, toast, deleteUserMutation])

  return (
    <>
      <Modal isOpen={open} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isSelf ? 'Delete my account' : 'Delete account'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{isSelf ? 'Are you sure you want to delete your account?' : `Are you sure you want to delete account "${userSlug}"?`} </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={deleteHandler()} marginLeft={4}>
              Delete Account
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

import React from 'react'

import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts'
import { Box, GridItem, SimpleGrid, Popover, PopoverTrigger, PopoverContent, Spinner, Center, Text, Flex, Divider } from '@chakra-ui/react'

import OverflowPopupText from '../../components/OverflowPopupText'
import { getGraphColors } from '../../theming/graphColors'
import { formatNumber } from '../../util/numbers'
import { respace } from '../../util/respace'

import { DefectType } from './defectType'

const graphColors = getGraphColors()
function getFill(typeIndex: number): string {
  return graphColors.at(typeIndex) || graphColors.at(-1) || 'var(--chakra-colours-brand-25)'
}

interface PieSlice {
  name: string
  value: number
  fullName?: string
  cweNumber?: string | null
  fill?: string
}

export default function DefectsPieViz({
  nDefects = 0,
  defectTypes,
  maxBuckets = 8
}: {
  nDefects?: number
  defectTypes: DefectType[] | undefined
  maxBuckets?: number
}) {
  if (defectTypes === undefined) {
    return (
      <Center minW={{ base: undefined, lg: 'xl' }}>
        <Spinner size="xl" />
      </Center>
    )
  }
  const checkedDefectTypes = defectTypes || []

  const differentTypes = checkedDefectTypes.length
  let defectResults = checkedDefectTypes.map(
    (value) => ({ name: value.name, value: value.count, fullName: value.fullName, cweNumber: value.cweNumber }) as PieSlice
  )
  const sortedDefectData = defectResults.sort((a, b) => b.value - a.value)

  // Split away the maxBuckets and lesser values into an "Other Defects" category if there's more than maxBuckets
  let otherDefectData: undefined | PieSlice[]
  if (differentTypes > maxBuckets) {
    otherDefectData = sortedDefectData.slice(maxBuckets - 1)
    const sumOfOtherDefects = otherDefectData.reduce((result, value) => result + value.value, 0)
    defectResults = sortedDefectData.slice(0, maxBuckets - 1) // Show only first non-other results on graph
    defectResults.push({ name: 'Other Defects', value: sumOfOtherDefects, fullName: 'Other Defects' }) // Add "Other Defects" data
  } else {
    defectResults = sortedDefectData
  }

  // Map with fill color
  defectResults = defectResults.map((stat, index) => ({ ...stat, fill: getFill(index + 1) }))

  // Handle no results
  let noResults = false
  if (differentTypes === 0) {
    noResults = true
    defectResults = [{ name: 'No Defects Found', value: 1, fill: getFill(0) }]
  }

  const legendItem = (name: string, value: number, fullName?: string, cweNumber?: string | null, fill?: string | null) => {
    const cweNumberPrefix = cweNumber ? `CWE ${cweNumber} - ` : ''
    return (
      <Popover trigger="hover" isOpen={!fullName ? false : undefined} key={name}>
        <PopoverTrigger>
          <Flex key={name} gap={4} align="center">
            <Text minWidth={16} textAlign="right">
              {formatNumber(value)}
            </Text>
            <Box width={4} height={4} backgroundColor={fill || undefined} />
            <OverflowPopupText>
              <Text>{respace(name)}</Text>
            </OverflowPopupText>
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <Box py={3} px={2}>
            <Text>{`${cweNumberPrefix}${fullName} : ${formatNumber(value, { shorten: false })}`}</Text>
            {name === 'Other Defects' && <hr />}
            {name === 'Other Defects' &&
              otherDefectData?.map(({ name, value, fullName, cweNumber }) => {
                const cweNumberPrefix = cweNumber ? `CWE ${cweNumber} - ` : ''
                return <Text key={name}>{`${cweNumberPrefix}${fullName || name} : ${formatNumber(value, { shorten: false })}`}</Text>
              })}
          </Box>
        </PopoverContent>
      </Popover>
    )
  }

  const CustomTooltip = ({ active, payload }: { active?: boolean; payload?: { payload: PieSlice }[] }) => {
    if (active && payload) {
      const pieSlice = payload[0].payload
      const label = pieSlice.name
      const value = pieSlice.value
      const fullName = pieSlice.fullName
      const cweNumberPrefix = pieSlice.cweNumber ? `CWE ${pieSlice.cweNumber} - ` : ''
      return (
        <Box backgroundColor="chakra-body-bg" borderWidth={1} borderColor="inherit" borderRadius="xl" padding={4}>
          <Text>{`${cweNumberPrefix}${fullName || label} : ${formatNumber(value, { shorten: false })}`}</Text>
          {label === 'Other Defects' && <Divider />}
          {label === 'Other Defects' &&
            otherDefectData?.map(({ name, value, fullName, cweNumber }) => {
              const cweNumberPrefix = cweNumber ? `CWE ${cweNumber} - ` : ''
              return <Text key={name}>{`${cweNumberPrefix}${fullName || name} : ${formatNumber(value, { shorten: false })}`}</Text>
            })}
        </Box>
      )
    }
    return <React.Fragment />
  }

  return (
    <SimpleGrid columns={2} alignItems="center">
      <GridItem justifySelf={{ base: 'center', lg: 'end' }} colSpan={{ base: 2, lg: 1 }} h={200} w={200}>
        <ResponsiveContainer>
          <PieChart width={730} height={250}>
            <Pie
              data={defectResults}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius="60%"
              outerRadius="100%"
              startAngle={450}
              endAngle={90}
              animationDuration={500}
              stroke="none"
            />
            {!noResults && <Tooltip content={<CustomTooltip />} />}
          </PieChart>
        </ResponsiveContainer>
      </GridItem>
      <GridItem colSpan={{ base: 2, lg: 1 }}>
        <Box>
          {legendItem('Total Defects', nDefects)}
          {!noResults && defectResults.map((stat) => legendItem(stat.name, stat.value, stat.fullName, stat.cweNumber, stat.fill))}
        </Box>
      </GridItem>
    </SimpleGrid>
  )
}

import { cloneElement, forwardRef, ReactElement, Ref, useImperativeHandle, useState } from 'react'

import {
  Divider,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import SearchIcon from '@material-design-icons/svg/sharp/search.svg?react'

import { useGetDockerRepositoriesQuery } from '../../redux/api/docker'

import { DockerRepositoryRow } from './DockerRepositoryRow'

export interface DialogControl {
  isOpen: boolean
  open(): void
  close(): void
}

export interface DialogTrigger {
  onClick?: () => void
}

export interface DockerImageSelectionModalProps {
  ref?: Ref<DialogControl>

  trigger: ReactElement<DialogTrigger>

  onImageSelect?: (image: string) => void
}

export const DockerImageSelectionModal = forwardRef<DialogControl, DockerImageSelectionModalProps>(function DockerImageSelectionModal(
  { trigger, onImageSelect }: DockerImageSelectionModalProps,
  ref
) {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState<string>()
  const { data } = useGetDockerRepositoriesQuery()

  useImperativeHandle(ref, () => ({
    isOpen: open,
    open: () => setOpen(true),
    close: () => setOpen(false)
  }))

  const repositories =
    data?.repositories
      ?.slice()
      ?.filter((repository) => {
        if (!query) {
          return true
        }
        return repository.includes(query)
      })
      ?.sort(compareDockerRepository) || []

  const handleImageSelection = (image: string) => {
    onImageSelect?.(image)
    // TODO(kostas): We want to make auto-close configurable at some point.
    setOpen(false)
  }

  return (
    <>
      {cloneElement(trigger, { onClick: () => setOpen(true) })}
      <Modal isOpen={open} onClose={() => setOpen(false)} size="lg" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stack gap={4}>
              <Heading size="md">Images</Heading>
              <InputGroup>
                <InputLeftElement>
                  <Icon as={SearchIcon} color="gray.500" />
                </InputLeftElement>
                <Input type="text" placeholder="Search image ..." value={query} onChange={(event) => setQuery(event.target.value)} />
              </InputGroup>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <Stack gap={1}>
              <Text size="xs" color="faded">
                Found {repositories.length || 0} {repositories.length === 1 ? 'image' : 'images'}.
              </Text>
              {repositories.map((repo) => (
                <DockerRepositoryRow key={repo} repository={repo} onImageSelect={handleImageSelection} />
              ))}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
})

export function compareDockerRepository(a: string, b: string): number {
  const pathA = a.split('/')
  const pathB = b.split('/')

  for (let i = 0; i < pathA.length; i++) {
    if (i >= pathB.length) {
      return 1
    }
    const cmp = pathA[i].localeCompare(pathB[i], undefined, { sensitivity: 'base' })
    if (cmp !== 0) {
      return cmp
    }
  }

  if (pathB.length > pathA.length) {
    return -1
  }

  return 0
}

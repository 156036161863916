import { Link as WouterLink } from 'wouter'
import { Stack } from '@chakra-ui/react'

import HomeIcon from '../../images/nav-home.svg?react'
import ProjectsIcon from '../../images/nav-projects.svg?react'
import DynamicSBOMIcon from '../../images/nav-dsbom.svg?react'
import RunsIcon from '../../images/nav-runs.svg?react'
import SettingsIcon from '../../images/nav-settings.svg?react'

import { NavButton } from '../../components/Shell/NavButton'

import { MenuSection } from './WorkspaceSidebar'
import { getSelectedRoute } from './utils'

export const WorkspaceMenu = ({ workspace, isCollapsed, submenu, settingsManuallyExpanded, setSettingsManuallyExpanded }: WorkspaceMenuProps) => {
  const sections: MenuSection[] = [
    {
      name: '',
      routes: [
        { path: `/${workspace}`, name: 'Dashboard', key: 'dashboard', icon: HomeIcon, extraPathMatches: [`/${workspace}/-/getting-started`] },
        {
          path: `/${workspace}/-/projects`,
          name: 'Projects',
          key: 'projects',
          icon: ProjectsIcon
        },
        { path: `/${workspace}/-/runs`, name: 'Runs', key: 'runs', icon: RunsIcon },
        {
          path: `/${workspace}/-/dynamic-sbom`,
          name: 'Dynamic SBOM',
          key: 'dsbom',
          icon: DynamicSBOMIcon,
          extraPathMatches: [`/${workspace}/-/dynamic-sbom/*?`]
        },
        {
          path: '',
          name: 'Settings',
          key: 'settings',
          icon: SettingsIcon,
          onClick: () => setSettingsManuallyExpanded(!settingsManuallyExpanded),
          extraPathMatches: [
            '/-/settings/user/*?',
            '/:workspace/-/settings/*?',
            '/:workspace/-/integrations',
            '/-/settings/admin/*?',
            '/:workspace/-/plan'
          ]
        }
      ],
      isShown: true
    }
  ]

  // highlight settings or project if those submenus are shown
  const autoSelectedRoute = getSelectedRoute(sections)
  const selectedRoute =
    settingsManuallyExpanded || submenu === 'settings' ? { key: 'settings' } : submenu === 'project' ? { key: 'projects' } : autoSelectedRoute

  return (
    <Stack spacing="1">
      {sections.map((section) =>
        section.isShown
          ? section.routes.map((route) => {
              const button = (
                <NavButton
                  key={route.key}
                  label={route.name}
                  icon={route.icon}
                  isActive={selectedRoute?.key === route.key}
                  isCollapsed={isCollapsed}
                  onClick={route.onClick}
                />
              )

              return route.onClick ? (
                button
              ) : (
                <WouterLink key={route.key} to={route.path}>
                  {button}
                </WouterLink>
              )
            })
          : null
      )}
    </Stack>
  )
}

export interface WorkspaceMenuProps {
  workspace: string
  isCollapsed: boolean
  submenu?: 'project' | 'settings'
  settingsManuallyExpanded: boolean
  setSettingsManuallyExpanded: (expanded: boolean) => void
}

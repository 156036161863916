import { b64EncodeUnicode } from '../../util/location'

const getStringFromKey = (key: string) => {
  switch (key) {
    case 'veracode':
      return 'Veracode'
    case 'trivy':
      return 'Trivy'
    case 'spdx':
      return 'SPDX'
    case 'snyk':
      return 'Snyk'
    case 'cyclonedx':
      return 'CycloneDX'
    case 'blackduck':
      return 'Black Duck'
    case 'aquasec':
      return 'Aquasec'
    case 'anchore':
      return 'Anchore'
    case 'other':
      return 'unknown'
    default:
      return ''
  }
}

export function generateUploadFilesStepTitle(keys: string[]) {
  let title = 'Please upload your'
  if (keys.length === 1) {
    title += ` ${getStringFromKey(keys[0])} file.`
  }
  if (keys.length === 2) {
    title += ` ${getStringFromKey(keys[0])} and ${getStringFromKey(keys[1])} files.`
  }
  if (keys.length > 2) {
    title += ` ${keys
      .slice(0, keys.length - 1)
      .map(getStringFromKey)
      .join(', ')} and ${getStringFromKey(keys[keys.length - 1])} files.`
  }
  return title
}

/**
 * Generate a unique key for an image reference using the registry, image name, tag and digest
 * the returned key is base64 encoded.
 * This is done since neither the registry, image name, tag or digest are guaranteed to be unique
 * instead we use a combination of all three to uniquely identify an image reference
 */
export function generateUniqueImageReferenceKey(
  registry: string | undefined | null,
  imageName: string,
  tag: string | undefined | null,
  hash: string | undefined | null
): string {
  const base = registry ? `${registry}/` : ''
  const name = tag ? `${imageName}:${tag}` : imageName
  const suffix = hash ? `@${hash}` : ''

  const key = `${base}${name}${suffix}`
  return b64EncodeUnicode(key)
}

/**
 * Decode the unique image reference key to get the image reference
 *
 */
export function decodeUniqueImageReferenceKey(key: string): string {
  const decodedKey = decodeURIComponent(atob(key))
  // We strip the image hash from the key
  const splitKey = decodedKey.split('@')
  return splitKey[0]
}

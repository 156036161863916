import React, { useState, useEffect, useRef } from 'react'
import debounce from 'lodash/debounce'
import { Box, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger } from '@chakra-ui/react'

function isOverflowing(element: HTMLElement | null): boolean {
  if (!element) {
    return false
  }
  return element.scrollWidth > element.clientWidth
}

interface Props {
  children: React.ReactNode
}

function OverflowPopupText({ children }: Props): JSX.Element {
  const [, setWidth] = useState<number>(0)
  const [, setHeight] = useState<number>(0)
  const [childClone, setChildClone] = useState<React.ReactNode>(children)

  const ref = useRef<HTMLElement | null>(null)
  const [isMounted, setIsMounted] = useState<boolean>(false)

  // Runs only when mounting
  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [setIsMounted])

  useEffect(() => {
    function updateWindowDimensions() {
      if (isMounted) {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
      }
    }

    window.addEventListener('resize', debounce(updateWindowDimensions, 1000))
    updateWindowDimensions()

    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [isMounted])

  useEffect(() => {
    function getCloneFromChild() {
      const childElement = React.Children.only(children) as React.ReactElement
      const childClone = React.cloneElement(childElement, {
        ref: ref
      })

      setChildClone(childClone)
    }
    getCloneFromChild()
  }, [children])

  const current = ref.current
  const innerText = current?.innerText || ''
  const showPopover = isOverflowing(current as HTMLElement)

  return showPopover ? (
    <Popover key={innerText} trigger="hover">
      <PopoverTrigger>{childClone}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Box dangerouslySetInnerHTML={{ __html: innerText }} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : (
    <>{childClone}</>
  )
}

export default OverflowPopupText

import { ComponentStyleConfig } from '@chakra-ui/react'

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'bold',
    fontSize: 'lg'
  },
  variants: {
    cardHeading: {
      fontSize: 'lg'
    }
  }
}

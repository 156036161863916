import { Box, HStack, Tooltip, useToken } from '@chakra-ui/react'

import { formatNumber } from '../../util/numbers'

export interface DefectsTagV2Props {
  count: number
  label?: string
  severity?: 'High' | 'Medium' | 'Low'
}

const computeSeverityScheme = (severity: string) => {
  if (severity === 'Low') {
    return '-severityLow'
  }

  const scheme = `severity${severity}`

  return `${scheme}.500`
}

export const DefectsTagV2 = ({ count, label, severity = 'High' }: DefectsTagV2Props) => {
  const formattedLongCount = formatNumber(count, { shorten: false })
  const labelWithCount = label ? `${formattedLongCount} ${label}` : `${formattedLongCount}`

  const [severityColor] = useToken('colors', [computeSeverityScheme(severity)])

  return (
    <Tooltip label={labelWithCount}>
      <HStack gap={0} fontSize={12}>
        <Box width={6} bg={severityColor} borderTopLeftRadius="lg" borderBottomLeftRadius="lg" color="white" textAlign="center">
          {severity.slice(0, 1)}
        </Box>
        <Box width="fit-content" paddingX={2} bg="subtleBg" borderTopRightRadius="lg" borderBottomRightRadius="lg" textAlign="center">
          {formatNumber(count)}
        </Box>
      </HStack>
    </Tooltip>
  )
}

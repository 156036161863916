import { Alert, AlertIcon, Text } from '@chakra-ui/react'
import React from 'react'

interface Props {
  nDefectsActiveHigh: number
  nDefectsActiveMedium: number
  nDefectsActiveLow: number
  children: React.ReactNode
}

export function DefectsNoSeverity({ nDefectsActiveHigh, nDefectsActiveMedium, nDefectsActiveLow, children }: Props) {
  if ([nDefectsActiveHigh, nDefectsActiveMedium, nDefectsActiveLow].every((defect) => defect === 0)) {
    return (
      <Alert status="success" borderRadius="lg" fontSize={12} width="fit-content" paddingY={0} paddingX={2}>
        <AlertIcon height={3} width={3} marginRight={2} color="semiBoldGreen" />
        <Text textColor="semiBoldGreen">No Defects</Text>
      </Alert>
    )
  }

  return children
}

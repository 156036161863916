import { load } from 'js-yaml'

import { cleanInteger } from '../../../util/numbers'

import {
  MayhemfileCmdType,
  MayhemfileFormType,
  MayhemfileType,
  MayhemfileFormCmdSettingsType,
  MayhemfileTaskType,
  MayhemfileTaskNameType
} from './types'

const decodeFuzzerSetting = (option: boolean | undefined): string => {
  return option === undefined ? 'automatic' : option ? 'enabled' : 'disabled'
}

/**
 * Turns the Mayhemfile cmd values into a redux-form friendly version
 * @param {MayhemfileCmdType[]} mayhemfileCmds - The Mayhemfile command objects
 * @returns {{cmds: string, cmdSettings: MayhemfileFormCmdSettingsType[]}} The redux-form friendly cmd form values
 */
const mayhemfileCmdsToFormCmds = (mayhemfileCmds: MayhemfileCmdType[]): { cmds: string; cmdSettings: MayhemfileFormCmdSettingsType[] } => {
  if (!mayhemfileCmds) {
    return { cmds: '', cmdSettings: [] }
  }

  return {
    cmds: mayhemfileCmds.map((thisCmd) => `$ ${thisCmd.cmd}`).join('\n'),
    cmdSettings: mayhemfileCmds.map((thisCmd) => ({
      ...thisCmd,
      env: thisCmd.env ? Object.keys(thisCmd.env).map((key) => ({ key, value: thisCmd.env ? thisCmd.env[key] : '' })) : [],
      mayhem_timeout: thisCmd.timeout,
      cmd: undefined,
      honggfuzz: decodeFuzzerSetting(thisCmd.honggfuzz),
      libfuzzer: decodeFuzzerSetting(thisCmd.libfuzzer),
      sanitizer: decodeFuzzerSetting(thisCmd.sanitizer),
      afl: decodeFuzzerSetting(thisCmd.afl)
    }))
  }
}

/**
 * Turns full Mayhemfile image string into redux-form fields
 * @param {string} mayhemfileBaseimage - The image from the Mayhemfile
 * @returns {{MayhemfileFormType['registry'], string, string}} The redux-form fields
 */
export const getMayhemDockerInfoFromImageString = (
  mayhemfileBaseimage?: string
): { registry?: MayhemfileFormType['registry']; image?: string; docker_tag?: string } => {
  if (!mayhemfileBaseimage) {
    return {}
  }
  let cleanedImage = mayhemfileBaseimage

  const isRegistryMayhem = mayhemfileBaseimage.includes('$MAYHEM_DOCKER_REGISTRY/')
  const isDockerTag = mayhemfileBaseimage.includes(':')

  if (isRegistryMayhem) {
    cleanedImage = cleanedImage.replace('$MAYHEM_DOCKER_REGISTRY/', '')
  }

  return {
    registry: isRegistryMayhem ? 'mayhem' : 'dockerhub',
    image: cleanedImage,
    docker_tag: isDockerTag ? mayhemfileBaseimage.split(':')[1] : ''
  }
}

const defaultTasks: MayhemfileTaskNameType[] = ['exploitability_factors', 'behavior_testing', 'regression_testing']

/**
 * Turns the redux-form tasks object into a Mayhemfile task object
 * @param {MayhemfileFormType['tasks']} tasks - The tasks from the redux-form
 * @returns {MayhemfileType['tasks']} The Mayhemfile yaml-friendly tasks
 */
export const formTasksToMayhemfileTasks = (tasks: MayhemfileTaskNameType[] | undefined): MayhemfileTaskType[] | undefined => {
  if (!tasks) {
    return undefined
  }

  const tasksSet = new Set(tasks)
  if (defaultTasks.every((task) => tasksSet.has(task)) && defaultTasks.length === tasksSet.size) {
    return undefined
  }

  return [...tasksSet].map((task) => {
    return {
      name: task
    }
  })
}

/**
 * Turns the Mayhemfile yaml into form values
 * @param {string} yaml - The Mayhemfile yaml
 * @returns {MayhemfileFormType} values - The full redux-form values
 */
export const getFormValuesFromYaml = (yaml: string | undefined): MayhemfileFormType => {
  if (!yaml) {
    return {}
  }

  const mayhemfileObj = load(yaml) as MayhemfileType

  const { cmds, cmdSettings } = mayhemfileCmdsToFormCmds(mayhemfileObj.cmds)

  return {
    version: mayhemfileObj.version,
    ...getMayhemDockerInfoFromImageString(mayhemfileObj.image),
    organization: mayhemfileObj.project?.includes('/') ? mayhemfileObj.project.split('/')[0] : undefined,
    project: mayhemfileObj.project?.includes('/') ? mayhemfileObj.project.split('/')[1] : mayhemfileObj.project,
    target: mayhemfileObj.target,
    duration: mayhemfileObj.duration ? cleanInteger(mayhemfileObj.duration) : undefined,
    uid: mayhemfileObj.uid,
    gid: mayhemfileObj.gid,
    tasks: mayhemfileObj.tasks?.map((task) => task.name) || ['exploitability_factors', 'behavior_testing', 'regression_testing'],
    testsuite: mayhemfileObj.testsuite,
    cmds,
    cmdSettings
  }
}

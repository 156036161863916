import { Link } from 'wouter'
import { Button, Heading, Icon, Link as ChakraLink, Text, VStack } from '@chakra-ui/react'

import EmailNeedsVerified from '../../images/email-needs-verified.svg?react'
import { useQuery } from '../../hooks'

export function EmailVerificationConfirmationPage() {
  const query = useQuery()
  const email = query.get('email')

  return (
    <VStack>
      <Heading>Great! You’re now signed up for Mayhem!</Heading>

      <Text>
        We&apos;ve sent you a link
        {email && (
          <>
            &nbsp;at{' '}
            <Text as="span" colorScheme="brand">
              {email}
            </Text>
          </>
        )}{' '}
        to verify your email address.
      </Text>

      <Icon as={EmailNeedsVerified} aria-label="Email address needs verified." boxSize={72} />
      <Text>
        Didn&apos;t receive the email?
        {email ? (
          <>
            &nbsp;Ensure that{' '}
            <Text as="span" color="brand.500">
              {email}
            </Text>{' '}
            is your correct email address, check your spam folder, or try
            <ChakraLink as={Link} to="/-/sign-up">
              <Button variant="link" mx={1}>
                Signing Up
              </Button>
            </ChakraLink>
            again.
          </>
        ) : (
          <>
            &nbsp;Check your spam folder, or try
            <ChakraLink as={Link} to="/-/sign-up">
              <Button variant="link" mx={1}>
                Signing Up
              </Button>
            </ChakraLink>
            again.
          </>
        )}
      </Text>
    </VStack>
  )
}

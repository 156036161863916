// chakra-ui Tooltip relies on the presence of a ref to anchor to. CopyToClipboard
//    does not provide that ref.
import React, { forwardRef, isValidElement, ReactNode, useState } from 'react'
import { BoxProps, Icon, IconButton, ResponsiveValue, Tooltip } from '@chakra-ui/react'
import ContentCopyIcon from '@material-design-icons/svg/sharp/content_copy.svg?react'

export interface CopyToClipboardButtonProps extends BoxProps {
  text: string
  colorScheme?: string
  preCopyTooltip?: string
  size?: ResponsiveValue<string | 'sm' | 'md' | 'lg' | 'xs'>
  variant?: ResponsiveValue<'link' | 'outline' | string | 'solid' | 'ghost' | 'unstyled'>
}

export const CopyToClipboardButton = forwardRef<HTMLButtonElement, CopyToClipboardButtonProps>(function CodeBlockCopyButton(
  { text, preCopyTooltip = 'Copy To Clipboard', colorScheme, size, variant = 'secondary', children, ...rest },
  ref
) {
  const [copied, setCopied] = useState(false)
  const handleCopy = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
    } else {
      // for older browsers
      document.execCommand('copy', true, text)
    }
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const defaultButton = (
    <IconButton aria-label="copy to clipboard" colorScheme={colorScheme} icon={<Icon as={ContentCopyIcon} />} size={size} variant={variant} />
  ) as ReactNode
  const child = React.Children.only(children || defaultButton)
  const tooltipLabel = copied ? 'Copied!' : preCopyTooltip

  return (
    <Tooltip label={tooltipLabel} closeOnClick={false}>
      {isValidElement(child) &&
        React.cloneElement(child, {
          ...child.props,
          ...rest,
          ref,
          onClick: handleCopy
        })}
    </Tooltip>
  )
})

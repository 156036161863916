import { useMemo } from 'react'

import { Button, Card, CardHeader, Heading, Spacer, useDisclosure } from '@chakra-ui/react'

import { WebhookList } from '../admin-webhooks/WebhookList'
import { CreateWebhookModal } from '../admin-webhooks/CreateWebhookModal'

type Props = {
  workspace: string
}

export function WebhooksCard({ workspace }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const subscription = useMemo(() => {
    return { owner: workspace }
  }, [workspace])

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Webhooks</Heading>
        <Spacer />
        <Button onClick={onOpen}>Create Webhook</Button>
      </CardHeader>
      <WebhookList workspace={workspace} />
      <CreateWebhookModal isOpen={isOpen} onClose={onClose} subscription={subscription} />
    </Card>
  )
}

import { Stack } from '@chakra-ui/react'

import { DataTab, DataTabs } from '../../components/DataTabs'
import { Page } from '../../components/Page'

import { DsbomDefectsList } from '../dsbom-defects/DsbomDefectsList'
import { DsbomImagesList } from '../dsbom-images/DsbomImagesList'
import { DsbomReportsList } from '../dsbom-reports/DsbomReportsList'

import { DsbomWorkspaceBigStats } from './DsbomWorkspaceBigStats'

interface Props {
  workspace: string
}

export function DsbomWorkspacePage({ workspace }: Props) {
  const tabData: DataTab[] = [
    {
      label: 'Defects',
      content: <DsbomDefectsList workspace={workspace} />,
      route: `/${workspace}/-/dynamic-sbom`
    },
    {
      label: 'Reports',
      content: <DsbomReportsList workspace={workspace} />,
      route: `/${workspace}/-/dynamic-sbom/-/reports`
    },
    {
      label: 'Images',
      content: <DsbomImagesList workspace={workspace} />,
      route: `/${workspace}/-/dynamic-sbom/-/images`
    }
  ]

  return (
    <Page>
      <Stack spacing={4}>
        <DsbomWorkspaceBigStats workspace={workspace} />
        <DataTabs isLazy variant="line" width="full" tabs={tabData} />
      </Stack>
    </Page>
  )
}

import { useMemo } from 'react'
import {
  Heading,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Skeleton,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  VStack,
  Link
} from '@chakra-ui/react'
import CloseIcon from '@material-design-icons/svg/sharp/close.svg?react'
import RightArrowIcon from '@material-design-icons/svg/sharp/keyboard_arrow_right.svg?react'

import { Link as WouterLink } from 'wouter'

import { RequestDetail } from '../requests/RequestDetail'
import { useGetMapiRequestLogsQuery } from '../../redux/api/mapi'
import { useGetDefectsByRunQuery, useGetRunQuery, useGetRunEndpointsQuery } from '../../redux/api/runs'
import { ErrorPanel } from '../../components/ErrorPanel'

import OutsideLink from '../../components/OutsideLink'

import { Latencies, latenciesFromRun } from './LatencyPanel'
import { endpointDetailUrl } from './EndpointList'
import { StatusHits, statusHitsFromRun } from './ResponsePanel'

import { EndpointSynopsis } from './EndpointSynopsis'

interface Props {
  workspace: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  endpointMethod: string
  endpointPath: string
  onClose: () => void
  isModal?: boolean
}

export function EndpointPreview(props: Props) {
  const { workspace: owner, projectSlug, targetSlug, runNumber, endpointMethod, onClose, isModal = false } = props
  const endpointPath = decodeURIComponent(props.endpointPath)

  const endpointMethodPath = { method: endpointMethod, path: endpointPath }

  const { data: defectsList } = useGetDefectsByRunQuery({
    owner,
    projectSlug,
    targetSlug,
    runNumber,
    perPage: 1,
    endpointMethodPaths: JSON.stringify([endpointMethodPath])
  })
  const numDefects = defectsList?.count

  const { isLoading: isRunLoading, data: run } = useGetRunQuery({ owner, projectSlug, targetSlug, runNumber })
  const { data: runEndpoints, isLoading: isRunEndpointLoading } = useGetRunEndpointsQuery({ owner, projectSlug, targetSlug, runNumber })

  const runEndpoint = runEndpoints?.endpoints.find((endpoint) => endpoint.method === endpointMethod && endpoint.path === endpointPath)

  const [latencies, statusBucketHits]: [Latencies | undefined, StatusHits | undefined] = useMemo(() => {
    return [latenciesFromRun(run, endpointMethod, endpointPath), statusHitsFromRun(run, endpointMethod, endpointPath)]
  }, [run, endpointMethod, endpointPath])

  const { isFetching: requestsPageFetching, data: requestsPage } = useGetMapiRequestLogsQuery({
    owner,
    projectSlug,
    targetSlug,
    runNumber,
    perPage: 1,
    orderByNon2XxFirst: true,
    ...(endpointMethodPath ? { endpoints: JSON.stringify([endpointMethodPath]) } : {})
  })

  if (isRunLoading || isRunEndpointLoading || requestsPageFetching) {
    return <Skeleton height={80} borderRadius={isModal ? 'md' : '2xl'} />
  }

  if (!runEndpoint) {
    return <ErrorPanel msg="We can't find that endpoint for you. Sorry about that!" />
  }

  const request = requestsPage?.requests[0]

  const endpointUrl = endpointDetailUrl(owner, projectSlug, targetSlug, runNumber, endpointMethod, endpointPath)
  const endpointTestsUrl = endpointDetailUrl(owner, projectSlug, targetSlug, runNumber, endpointMethod, endpointPath, 'tests')

  return (
    <Card borderWidth={isModal ? 0 : undefined} borderRadius={isModal ? 'md' : undefined} maxHeight="90vh">
      <CardHeader>
        <Heading variant="cardHeading" display="inline-block" noOfLines={1} wordBreak="break-all" textOverflow="ellipsis" maxW="90%">
          {endpointMethod} {endpointPath}
        </Heading>
        <IconButton variant="outline" aria-label="close test case" icon={<CloseIcon />} onClick={onClose} />
      </CardHeader>
      <CardBody borderBottomWidth={1}>
        {!runEndpoint?.covered && (
          <Alert status="warning">
            <AlertIcon />
            <VStack alignItems="begin">
              <AlertTitle>Shallow Coverage</AlertTitle>
              <AlertDescription>
                Mayhem was unable to get a 2xx response from this endpoint. Go to{' '}
                <Link as={WouterLink} to={endpointTestsUrl} textDecoration="underline">
                  sample requests
                </Link>{' '}
                to see requests to this endpoint. Refer to{' '}
                <OutsideLink href="https://app.mayhem.security/docs/api-testing/tutorials/optimizing-test-coverage/api-success/">
                  Successful Coverage in our docs
                </OutsideLink>{' '}
                for more details on how to increase your endpoint coverage with Mayhem.
              </AlertDescription>
            </VStack>
          </Alert>
        )}

        <EndpointSynopsis {...{ numDefects, latencies, statusBucketHits }} />
      </CardBody>

      {request && (
        <CardBody p={4} height="full" overflowY="auto">
          <Heading size="md" display="inline-block" noOfLines={1} wordBreak="break-all" textOverflow="ellipsis" pb={4}>
            Sample Request
          </Heading>
          <RequestDetail request={request} onClose={onClose} isModal={false} inlineRequestSample />
        </CardBody>
      )}

      <CardFooter justifyContent="end">
        <WouterLink to={endpointUrl}>
          <Button rightIcon={<RightArrowIcon />}>View Full Endpoint Details</Button>
        </WouterLink>
      </CardFooter>
    </Card>
  )
}

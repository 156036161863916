import { ReactNode } from 'react'

export interface JoinSpanProps {
  separator?: ReactNode
  children: ReactNode[]
}

export const JoinSpan = ({ separator = ', ', children }: JoinSpanProps) => {
  return <>{joinElements(children, separator)}</>
}

function joinElements(elements: ReactNode[], separator: ReactNode = ', ', filterEmpty = true): ReactNode {
  if (filterEmpty) {
    elements = elements.filter((x) => Boolean(x))
  }
  if (elements.length === 0) {
    return undefined
  }
  return elements.reduce((acc, curr) => [acc, separator, curr])
}

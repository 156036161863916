import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Modal,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  useDisclosure,
  useToast,
  ModalFooter,
  Text
} from '@chakra-ui/react'
import MailIcon from '@material-design-icons/svg/sharp/mail.svg?react'
import AddIcon from '@material-design-icons/svg/sharp/add.svg?react'

import { validateEmail } from '../auth/AuthForm'
import { getMessageFromRtkError } from '../../util/errors'
import { usePostGenerateInviteMutation } from '../../redux/api/admin'

type Form = { email: string }

interface Props {
  onInviteSent: () => void
}

export function UserGenerateInviteModal({ onInviteSent }: Props) {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [postGenerateInvite] = usePostGenerateInviteMutation()

  const methods = useForm<Form>()
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting }
  } = methods

  const onSubmit = useCallback(
    async ({ email }: Form) => {
      try {
        await postGenerateInvite({ adminSendInvite: { email } }).unwrap()
        onInviteSent()
        toast({
          title: `Invite Sent`,
          description: `${email} has been sent an invite`,
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        const message = getMessageFromRtkError(err)
        setError('email', { type: 'custom', message })
      }
    },
    [postGenerateInvite, toast, setError, onInviteSent]
  )

  const closeGenerateInviteModal = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <>
      <Button variant="outline" leftIcon={<AddIcon />} onClick={onOpen}>
        Invite User
      </Button>
      <Modal isOpen={isOpen} onClose={closeGenerateInviteModal} size="xl" isCentered={false}>
        <ModalOverlay />
        <ModalContent>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalHeader>Invite User</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>Generate a unique invite link a user can use to sign up for Mayhem.</Text>
                <Text>If your Mayhem instance has mail sending enabled, users will be sent an invite email as well.</Text>
                <Stack spacing={4} mt={4}>
                  <FormControl isRequired isInvalid={!!errors.email}>
                    <InputGroup>
                      <Input
                        type="text"
                        placeholder="Email"
                        autoComplete="email"
                        data-selenium-id="email"
                        {...register('email', { maxLength: 128, validate: validateEmail })}
                      />
                      <InputRightElement>
                        <Icon as={MailIcon} color="gray.500" />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" isLoading={isSubmitting}>
                  Invite User
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  )
}

/**
 * This code has been taken from the latest version of wouter (2.10).
 *
 * We are on 2.9.0 currently because > 2.9.1 has issues with Route typing.
 * https://github.com/molefrog/wouter/issues/291
 */
import { useSyncExternalStore } from 'react'

import { TSFixMe } from './types'

/**
 * History API docs @see https://developer.mozilla.org/en-US/docs/Web/API/History
 */
const eventPopstate = 'popstate'
const eventPushState = 'pushState'
const eventReplaceState = 'replaceState'
const eventHashchange = 'hashchange'
export const events = [eventPopstate, eventPushState, eventReplaceState, eventHashchange]

const subscribeToLocationUpdates = (callback: () => unknown) => {
  for (const event of events) {
    addEventListener(event, callback)
  }
  return () => {
    for (const event of events) {
      removeEventListener(event, callback)
    }
  }
}

export const useLocationProperty = (fn: () => TSFixMe) => useSyncExternalStore(subscribeToLocationUpdates, fn)

const currentSearch = () => location.search
export const useSearch = () => useLocationProperty(currentSearch)

const currentPathname = () => location.pathname
export const usePathname = () => useLocationProperty(currentPathname)

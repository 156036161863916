import { Box, Stack, Stat, StatLabel, StatNumber, Skeleton, StatGroup } from '@chakra-ui/react'

import { useGetWorkerStatsQuery } from '../../redux/api/worker'
import { formatMemory, formatNumber } from '../../util/numbers'

import { WorkerUsageChart } from './WorkerUsageChart'
import { WorkerPoolListing } from './WorkerPoolListing'

export function AdminWorkersPage() {
  const { isLoading, data } = useGetWorkerStatsQuery(undefined, {
    // Automatically refresh worker stats every minute.
    pollingInterval: 10 * 1000
  })

  const { n_active_workers = 0, n_total_workers = 0, n_per_worker_cpu = 0, n_per_worker_memory = 0 } = { ...data }

  return (
    <Stack gap={4}>
      <Box height={300}>
        <WorkerUsageChart />
      </Box>

      <StatGroup gap={4} justifyContent="start">
        <Skeleton isLoaded={!isLoading}>
          <Stat padding={4} flex="0 0 max-content">
            <StatLabel>Active Worker(s)</StatLabel>
            <StatNumber>{formatNumber(n_active_workers)}</StatNumber>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!isLoading}>
          <Stat padding={4} flex="0 0 max-content">
            <StatLabel>Total Worker(s)</StatLabel>
            <StatNumber>{formatNumber(n_total_workers)}</StatNumber>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!isLoading}>
          <Stat padding={4} flex="0 0 max-content">
            <StatLabel>Worker Profile</StatLabel>
            <StatNumber>
              {n_per_worker_cpu} cores: {formatMemory(n_per_worker_memory)}
            </StatNumber>
          </Stat>
        </Skeleton>
      </StatGroup>

      <WorkerPoolListing />
    </Stack>
  )
}

import { Button, Card, CardBody, Divider, Icon, Link, Stack, Text } from '@chakra-ui/react'

import { Integration } from './integration'

export interface IntegrationCardProps {
  integration: Integration
}

export function IntegrationCard(props: IntegrationCardProps) {
  const { integration } = props
  return (
    <Card maxW="sm">
      <CardBody padding="4">
        <Stack mt="6" spacing="3">
          <Icon as={integration.icon} boxSize={16} />
          <Text>{integration.description}</Text>
        </Stack>
      </CardBody>
      <Divider />
      <Link href={integration.href} isExternal>
        <Button m="4" variant="solid">
          {integration.href_button_text}
        </Button>
      </Link>
    </Card>
  )
}

import { Alert, Button, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'

interface MessageModalProps {
  open: boolean
  onClose: () => void
  title?: React.ReactNode
  message?: React.ReactNode
  negative: boolean
}

export default function MessageModal({ open, onClose, title, message, negative }: MessageModalProps) {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalCloseButton />
        {message && <Alert status={negative ? 'error' : 'info'}>{message}</Alert>}
        <ModalFooter>
          <Button onClick={onClose}>OK</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { Button, Modal, ModalOverlay, ModalCloseButton, ModalContent, ModalBody, ModalHeader, ModalFooter, Text } from '@chakra-ui/react'

const TaskStopModal = (props: Props): JSX.Element => {
  const { open, stopHandler, closeHandler } = props

  return (
    <Modal isOpen={open} onClose={() => closeHandler()} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Stop Task</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to stop this task?</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={() => closeHandler()}>
            No
          </Button>
          <Button
            colorScheme="green"
            onClick={() => {
              stopHandler()
              closeHandler()
            }}
            marginLeft={4}
          >
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface Props {
  open: boolean
  stopHandler: () => void
  closeHandler: () => void
}

export default TaskStopModal

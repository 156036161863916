export function redirectToJiraOauth(clientId: string | undefined) {
  if (!clientId) {
    throw new Error('An Atlassian App Client ID is not configured for this environment')
  }
  const encodedLocation = encodeURIComponent(window.location.href)
  const queryParameter = 'referrer'
  // this callback should match the atlassian marketplace app for this environment
  const encodedOAuthCallback = `${window.location.protocol}//` + encodeURIComponent(`${window.location.host}/api/v2/jira/oauth`)
  // these scopes should match the atlassian marketplace app
  const encodedScopes = encodeURIComponent(
    [
      'write:issue:jira',
      'write:comment:jira',
      'write:comment.property:jira',
      'write:attachment:jira',
      'read:application-role:jira',
      'read:avatar:jira',
      'read:field-configuration:jira',
      'read:group:jira',
      'read:issue:jira',
      'read:issue-details:jira',
      'read:issue-meta:jira',
      'read:project:jira',
      'read:user:jira',
      'read:user.property:jira'
    ].join(' ')
  )
  const next =
    'https://auth.atlassian.com/authorize?audience=api.atlassian.com' +
    `&client_id=${clientId}` +
    `&scope=${encodedScopes}` +
    `&redirect_uri=${encodedOAuthCallback}%3F${queryParameter}%3D${encodedLocation}` +
    `&response_type=code` +
    `&prompt=consent`

  window.location.href = next
}

import { Redirect, useLocation } from 'wouter'

import { Box } from '@chakra-ui/layout'

import { useAppSelector, useQuery } from '../../hooks'

export function RedirectIfAuthenticated({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [location] = useLocation()
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)
  const query = useQuery()
  const referrer = query.get('referrer')
  const referrerPath = referrer?.split('?').at(0)
  const redirectTo = referrerPath === location ? '/' : referrerPath || '/'

  return isAuthenticated ? <Redirect to={redirectTo} /> : <Box>{children}</Box>
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { RootState } from '../../redux/store'

interface CreateCodeRunState {
  dockerImage?: string
}

const slice = createSlice({
  name: 'create-code-run',
  initialState: {} as CreateCodeRunState,
  reducers: {
    setDockerImage(state, action: PayloadAction<string>) {
      // TODO(kostas): Dispatch an action to verify the image ??
      state.dockerImage = action.payload
    },
    reset() {
      return {}
    }
  }
})

export const selectNewCodeRun = createSelector(
  (state: RootState) => state,
  (state) => state.workspace.new.code
)

export const { setDockerImage, reset } = slice.actions

export default slice.reducer

import { stringifyUrl } from './util/location'

export type SearchQueryParams = { q: string; page: number; per_page: number }

export const SECRET_INTERNAL_REGISTRY_URL = 'MAYHEM-REGISTRY'
export const DOCKER_HUB_REGISTRY_URL = 'https://registry.hub.docker.com'

export function getDockerRegistryProxyEndpoint(params = {}) {
  const endpoint = '/api/v2/dockerhub'
  return stringifyUrl(endpoint, params)
}

export function getDockerRegistriesEndpoint(userSlug: string, registryId: string | null = null, params = {}) {
  let endpoint = `/api/v2/user/registry` // eventually will be endpoint of user-specific registries
  if (registryId !== null) {
    endpoint += `/${registryId}`
  }
  return stringifyUrl(endpoint, params)
}

export function getDockerRegistryReposEndpoint() {
  return getDockerRegistryProxyEndpoint({
    url: SECRET_INTERNAL_REGISTRY_URL,
    path: 'v2/_catalog',
    n: 5000
  })
}

export function getDockerRegistryRepoTagsEndpoint(repo: string) {
  return getDockerRegistryProxyEndpoint({
    url: SECRET_INTERNAL_REGISTRY_URL,
    path: `v2/${repo}/tags/list`
  })
}

export function getDockerRegistryRepoManifestEndpoint(registryUrl: string, repo: string, reference: string, params = {}) {
  return getDockerRegistryProxyEndpoint({
    url: registryUrl,
    path: `v2/${repo}/manifests/${reference}`,
    ...params
  })
}

export function getDockerRegistryRepoBlobEndpoint(registryUrl: string, repo: string, reference: string, params = {}) {
  return getDockerRegistryProxyEndpoint({
    url: registryUrl,
    path: `v2/${repo}/blobs/${reference}`,
    ...params
  })
}

export function getWebhooksEndpoint({ owner = '', projectSlug = '', webhookSlug = '', params = {} }) {
  if (owner && projectSlug) {
    return webhookSlug
      ? stringifyUrl(`/api/v2/owner/${owner}/project/${projectSlug}/webhook/${webhookSlug}`, params)
      : stringifyUrl(`/api/v2/owner/${owner}/project/${projectSlug}/webhook`, params)
  }

  if (owner) {
    return webhookSlug
      ? stringifyUrl(`/api/v2/owner/${owner}/webhook/${webhookSlug}`, params)
      : stringifyUrl(`/api/v2/owner/${owner}/webhook`, params)
  }
  return stringifyUrl(`/api/v2/webhook`, params)
}

export function getTestWebhookEndpoint({ owner = '', projectSlug = '', webhookSlug = '' }) {
  const webhookURL = getWebhooksEndpoint({ owner, projectSlug, webhookSlug })
  return `${webhookURL}/test`
}

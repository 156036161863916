export const parseToIntIfString = (v: number | undefined): number | undefined => {
  if (v === undefined) {
    return undefined
  }

  if (typeof v === 'string') {
    const res = Number.parseInt(v, 10)
    if (!Number.isInteger(res)) {
      return v
    }

    return res
  }

  return v
}

import { Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Stack, Text, useToast } from '@chakra-ui/react'

import { FormProvider, useForm } from 'react-hook-form'
import { useEffect } from 'react'

import { useGetUserSettingQuery, usePutUserSettingMutation } from '../../redux/api/workspace'

type Form = {
  maxActiveRuns: number
  maxRunDuration: number
}

export function SystemResourceQuotaForm() {
  const toast = useToast()

  const { data: userSetting, isLoading, refetch } = useGetUserSettingQuery()
  const [putUserSetting] = usePutUserSettingMutation()

  const methods = useForm<Form>()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid, isSubmitting }
  } = methods

  useEffect(() => {
    if (!isLoading && userSetting) {
      reset({ maxActiveRuns: userSetting.max_active_runs || 0, maxRunDuration: userSetting.max_run_duration || 0 })
    }
  }, [reset, userSetting, isLoading])

  const onSubmit = async ({ maxActiveRuns, maxRunDuration }: Form) => {
    try {
      await putUserSetting({ userSetting: { max_active_runs: maxActiveRuns, max_run_duration: maxRunDuration } })
      await refetch()
      toast({
        title: 'Workspace Updated',
        description: `Resource limits have been updated`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (err: unknown) {
      toast({
        title: 'Workspace Update Failed',
        description: (err as Error)?.message || 'An error occurred. Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Text>Resource limits can be overridden on a per-user basis via the Users page.</Text>
          <FormControl isInvalid={!!errors.maxActiveRuns}>
            <FormLabel>Max Active Runs</FormLabel>
            <Input type="number" {...register('maxActiveRuns', { min: 0 })} />
            <FormHelperText>Default run limit. Use 0 to disable restrictions.</FormHelperText>
            {errors?.maxActiveRuns && <FormErrorMessage>{errors.maxActiveRuns.message}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.maxActiveRuns}>
            <FormLabel>Default/Max Run Duration</FormLabel>
            <Input type="number" {...register('maxRunDuration', { min: 0 })} />
            <FormHelperText>Default duration limit for runs. Use 0 to disable restrictions.</FormHelperText>
            {errors?.maxRunDuration && <FormErrorMessage>{errors.maxRunDuration.message}</FormErrorMessage>}
          </FormControl>
          <Flex justify="flex-end">
            <Button type="submit" isLoading={isLoading || isSubmitting} isDisabled={!isValid}>
              Save
            </Button>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  )
}

interface FormatNumberOptions {
  /**
   * Whether to use compact or standard notation while formatting the number. Default value is true.
   */
  shorten?: boolean
}

/**
 * Formats a number according to internationalization standards.
 *
 * By default, shortens the number.
 *
 */
export const formatNumber = (value: number, options: FormatNumberOptions = {}): string => {
  const { shorten = true } = options

  let formatter
  if (shorten) {
    formatter = new Intl.NumberFormat('en', {
      notation: 'compact',
      maximumSignificantDigits: 3
    })
  } else {
    formatter = new Intl.NumberFormat('en', {
      notation: 'standard'
    })
  }

  return formatter.format(value)
}

export interface FormatPercentageOptions {
  base?: number
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}

export function formatPercentage(value: number, { base = 100, minimumFractionDigits = 0, maximumFractionDigits = 2 }: FormatPercentageOptions = {}) {
  const PercentageFormatter = new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits
  })
  return PercentageFormatter.format(value / base)
}

/**
 * Parses number as integer
 * @param {undefined | string | number | null} data - the number to parse into an int
 * @param {number} fallback - fallback number in case of parsing failure
 * @returns {number} the parsed integer value
 */
export function cleanInteger(data?: string | number | null, fallback = 1): number {
  if (data === undefined || data === null || Number.isNaN(parseInt(data.toString(), 10))) {
    return fallback
  }

  return parseInt(data.toString(), 10)
}

export function formatMemory(size: number): string {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0

  while (size > 1024 && unitIndex < units.length) {
    size = size / 1024
    unitIndex += 1
  }

  return `${formatNumber(size)} ${units[unitIndex]}`
}

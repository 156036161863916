import { Redirect } from 'wouter'

import { useQuery } from '../../hooks'
import { stringifyUrl } from '../../util/location'
import { useWhoamiQuery } from '../../redux/api/workspace'
import { MayhemPageLoader } from '../../components/MayhemPageLoader'
import { ErrorPage } from '../../components/layout/ErrorPage'

export interface RedirectToUserWorkspaceProps {
  /**
   * The path to redirect to. The user is redirected to '/<slug>/<path>'.
   */
  path: string
}

export const RedirectToUserWorkspace = ({ path }: RedirectToUserWorkspaceProps) => {
  const query = useQuery()

  const { isLoading, isError, data: profile } = useWhoamiQuery()

  if (isLoading) {
    return <MayhemPageLoader />
  }
  if (isError) {
    const errorMessage = `Failed to authenticate with mayhem`
    return <ErrorPage errorMessage={errorMessage} goBackLocation="/" />
  }
  if (path.startsWith('/')) {
    path = path.substring(1)
  }
  const destination = stringifyUrl(`/${profile?.slug}/${path}`, Object.fromEntries(query))
  return <Redirect to={destination} />
}

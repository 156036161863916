import { Link as WouterLink } from 'wouter'
import { Stack, Text, StatGroup, Stat, StatLabel, StatNumber, Icon, HStack, Link, StackDivider } from '@chakra-ui/react'
import RightArrowIcon from '@material-design-icons/svg/sharp/keyboard_arrow_right.svg?react'

import { prettyTime } from '../../util/time'
import StepsToReproduce from '../run-code/StepsToReproduce'

import { useGetTestcaseReportByRunAndChecksumQuery } from '../../redux/api/defects'
import { useGetRunQuery } from '../../redux/api/runs'

import TestCaseTriageReport from './TestCaseTriageReport'

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  testcaseSha: string
}

export function TestCaseReportPreviewContent({ owner, projectSlug, targetSlug, runNumber, testcaseSha }: Props) {
  const { data: runData } = useGetRunQuery({ owner, projectSlug, targetSlug, runNumber })
  const { data: testCaseReportData } = useGetTestcaseReportByRunAndChecksumQuery({
    owner,
    projectSlug,
    targetSlug,
    runNumber,
    sha256: testcaseSha
  })
  const {
    defects = [],
    crashed,
    output,
    backtrace,
    repro_cmdline: reproCmdline,
    sha256,
    n_valgrind_errors: nValgrindErrors,
    hexdump,
    execution_time: executionTime,
    timed_out: timedOut
  } = testCaseReportData || {}

  const downloadFileName = testCaseReportData?.sha256
  const startedAt = runData?.started_at ? new Date(runData?.started_at).getTime() : null
  let displayExecutionTime: JSX.Element | string = !startedAt || Number.isNaN(executionTime) || !executionTime ? '' : prettyTime(executionTime)
  if (displayExecutionTime !== '') {
    const timeoutLabel = <Text color="bad">timeout</Text>
    displayExecutionTime = timedOut ? (
      <>
        {timeoutLabel} <br /> ({displayExecutionTime})
      </>
    ) : (
      displayExecutionTime
    )
  }

  return (
    <Stack spacing={4} divider={<StackDivider />} height="full">
      <StatGroup>
        <Stat border={0}>
          <WouterLink to={`/${owner}/${projectSlug}/${targetSlug}/${runNumber}/testcases/${testcaseSha}?testcaseTab=related-defects`}>
            <Link>
              <HStack gap={0}>
                <StatLabel>Defects</StatLabel>
                <Icon as={RightArrowIcon} boxSize={6} />
              </HStack>
            </Link>
          </WouterLink>
          <StatNumber>{defects.length}</StatNumber>
        </Stat>
        <Stat border={0}>
          <WouterLink to={`/${owner}/${projectSlug}/${targetSlug}/${runNumber}/testcases/${testcaseSha}?testcaseTab=advanced-triage`}>
            <Link>
              <HStack gap={0}>
                <StatLabel>Runtime Errors</StatLabel>
                <Icon as={RightArrowIcon} boxSize={6} />
              </HStack>
            </Link>
          </WouterLink>
          <StatNumber>{nValgrindErrors}</StatNumber>
        </Stat>
        <Stat border={0}>
          <StatLabel>Execution Time</StatLabel>
          <StatNumber>{displayExecutionTime}</StatNumber>
        </Stat>
      </StatGroup>
      {reproCmdline && (
        <StepsToReproduce
          owner={owner}
          projectSlug={projectSlug}
          targetSlug={targetSlug}
          sha256={sha256}
          reproCmdline={reproCmdline}
          downloadFileName={downloadFileName}
        />
      )}
      <TestCaseTriageReport hexdump={hexdump} output={output} backtrace={crashed ? backtrace : undefined} />
    </Stack>
  )
}

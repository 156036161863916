import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'

import WarningAmberIcon from '@material-design-icons/svg/sharp/warning_amber.svg?react'
import SearchIcon from '@material-design-icons/svg/sharp/search.svg?react'

import { useFormControlValidation } from '../../components/input'
import { useWhoamiQuery } from '../../redux/api/workspace'
import { useGetBillingSubscriptionQuery } from '../../redux/api/billing'
import { prettyTimeElapsedString } from '../../util/time'
import { DockerImageSelectionModal } from '../docker-registry/DockerImageSelectionModal'
import { useAppSelector } from '../../hooks'

import { slugifyFull } from '../../utils'

import { CodeDurationOptions } from './CodeDurationOptions'
import { displayCommands, FormCodeRunConfig, splitProjectName } from './utils'

export const CodeRunConfigBasic = ({ workspace }: { workspace: string }) => {
  const { isPublicDeployment } = useAppSelector((state) => state.config)
  const { data: profile } = useWhoamiQuery()
  const { data: subscription } = useGetBillingSubscriptionQuery({ owner: workspace })
  const {
    register,
    getValues,
    setValue,
    watch,
    formState: { errors, touchedFields: touched },
    control
  } = useFormContext<FormCodeRunConfig>()

  const [project, projectStringTargetToken] = splitProjectName(getValues('project'))
  const projectSlug = slugifyFull(project)

  const target = projectStringTargetToken || getValues('target')
  const targetSlug = slugifyFull(target)

  // Add leading $ for each command line
  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name !== 'cmds' || !data.cmds) {
        return
      }
      const formatted = displayCommands(data.cmds)

      if (formatted !== data.cmds) {
        setValue('cmds', formatted)
      }
    })
    return () => subscription.unsubscribe()
  }, [setValue, watch])

  const validateDuration = useFormControlValidation(
    (duration: number | undefined) => {
      if (duration === undefined) {
        return
      }

      if (!subscription || !profile) {
        // Wait for subscription and profile to load
        return
      }

      const planAllowInfinite = subscription?.scan_max_duration_sec === null
      const planMaxDuration = subscription?.scan_max_duration_sec ?? 0
      if ((!planAllowInfinite && duration === 0) || (planMaxDuration && duration > planMaxDuration)) {
        return `Your workspace's current plan, ${subscription?.plan_description}, has a run duration limit of ${prettyTimeElapsedString(
          planMaxDuration
        )}.`
      }

      const limitAllowInfinite = profile?.max_run_duration === 0
      const userMaxRunDuration = profile?.max_run_duration ?? 0
      if ((!limitAllowInfinite && duration === 0) || (userMaxRunDuration && duration > userMaxRunDuration)) {
        return `Your account's run duration limit is ${prettyTimeElapsedString(userMaxRunDuration)}.`
      }
    },
    [profile, subscription]
  )

  const handleImageSelection = (image: string) => {
    setValue('dockerImage', `$MAYHEM_DOCKER_REGISTRY/${image}`)
  }

  return (
    <Grid templateColumns={{ base: '1fr', lg: '3fr 1fr' }} columnGap={8} rowGap={4}>
      <FormControl isRequired isInvalid={!!errors.project}>
        <FormLabel>Project Name</FormLabel>
        <Input {...register('project', { required: true })} />
        <FormHelperText>Your application name is always a good choice.</FormHelperText>
      </FormControl>

      <GridItem>
        {project !== projectSlug && (
          <Alert status="warning" variant="outlined">
            <AlertIcon as={WarningAmberIcon} />
            <Text>
              Your project will be named{' '}
              <Box style={{ display: 'inline-block' }}>
                <Text as="b">{projectSlug}</Text>
              </Box>
            </Text>
          </Alert>
        )}
      </GridItem>

      <FormControl isRequired isInvalid={!!errors.target}>
        <FormLabel>Target Name</FormLabel>
        <Input {...register('target', { required: true })} />
        <FormHelperText>The name of the specific configuration being tested.</FormHelperText>
      </FormControl>

      <GridItem>
        {target !== targetSlug && (
          <Alert status="warning" variant="outlined">
            <AlertIcon as={WarningAmberIcon} />
            <Text>
              Your target will be named{' '}
              <Box style={{ display: 'inline-block' }}>
                <Text as="b">{targetSlug}</Text>
              </Box>
            </Text>
          </Alert>
        )}
      </GridItem>

      <GridItem>
        <FormControl isRequired isInvalid={!!errors.dockerImage}>
          <FormLabel>Container Image</FormLabel>
          <Stack direction="row" gap={2}>
            <Input {...register('dockerImage', { required: true })} />
            {!isPublicDeployment && (
              <DockerImageSelectionModal
                trigger={
                  <IconButton
                    variant="outline"
                    color="gray.300"
                    icon={<Icon as={SearchIcon} color="gray.500" />}
                    aria-label="select-container-image"
                  />
                }
                onImageSelect={handleImageSelection}
              />
            )}
          </Stack>
          <FormErrorMessage>{errors?.dockerImage?.message ?? 'Mayhem requires a container image to run'}</FormErrorMessage>
        </FormControl>
      </GridItem>

      <GridItem>
        {/*  TODO(kostas): Uncomment once a warning mechanism is introduced. */}
        {/*{image && !errors.dockerImage && (*/}
        {/*  <Alert status="success" variant="outlined">*/}
        {/*    <AlertIcon as={MdCheckCircle} />*/}
        {/*    <Text>We found your container image and filled the entrypoint to get you started.</Text>*/}
        {/*  </Alert>*/}
        {/*)}*/}
        {/*{image && errors.dockerImage && (*/}
        {/*  <Alert status="warning" variant="outlined">*/}
        {/*    <AlertIcon as={MdWarningAmber} />*/}
        {/*    <Text>{errors.dockerImage.message}</Text>*/}
        {/*  </Alert>*/}
        {/*)}*/}
      </GridItem>

      <FormControl isRequired isInvalid={!!errors.cmds && touched.cmds}>
        <FormLabel>Command(s)</FormLabel>
        <Textarea
          required
          {...register('cmds', {
            required: true,
            validate: validateCommands
          })}
          spellCheck={false}
          placeholder="$ "
          fontFamily="mono"
          isInvalid={!!errors.cmds}
        />
        <FormErrorMessage>Mayhem requires commands to run.</FormErrorMessage>
      </FormControl>

      <GridItem colSpan={{ base: 1, lg: 2 }}>
        <FormControl isInvalid={!!errors.duration}>
          <FormLabel>Scan Duration</FormLabel>
          <Controller
            name="duration"
            control={control}
            rules={{
              validate: validateDuration
            }}
            render={({ field: { ref: _ref, ...rest } }) => <CodeDurationOptions isInvalid={!!errors.duration} {...rest} />}
          />
          <FormErrorMessage>{errors.duration?.message}</FormErrorMessage>
        </FormControl>
      </GridItem>
    </Grid>
  )
}

const validateCommands = (value: string) => {
  if (!value || value === '$') {
    return 'Mayhem requires a command to run.'
  }
}

import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import RenewIcon from '@material-design-icons/svg/sharp/autorenew.svg?react'
import ClearIcon from '@material-design-icons/svg/sharp/clear.svg?react'

import { useGetBillingSubscriptionQuery, usePutBillingSubscriptionMutation } from '@/redux/api/billing'
import { prettyTimeElapsedString } from '@/util/time'
import { formatNumber } from '@/util/numbers'

type PlanId = 'BASIC' | 'STANDARD' | 'ENTERPRISE'

const LIMIT_PLAN_DEFAULT = -1
const LIMIT_UNLIMITED = null

type PlanLimitValue = typeof LIMIT_PLAN_DEFAULT | typeof LIMIT_UNLIMITED | number

interface PlanInfo {
  title: string
  max_contributors: PlanLimitValue
  scan_limit_per_month: PlanLimitValue
  scan_max_duration_sec: PlanLimitValue
}

const Plans: Record<PlanId, PlanInfo> = {
  BASIC: {
    title: 'Basic',
    max_contributors: 5,
    scan_limit_per_month: 25,
    scan_max_duration_sec: 20 * 60
  },
  STANDARD: {
    title: 'Standard',
    max_contributors: 25,
    scan_limit_per_month: LIMIT_UNLIMITED,
    scan_max_duration_sec: 24 * 60 * 60
  },
  ENTERPRISE: {
    title: 'Enterprise',
    max_contributors: LIMIT_UNLIMITED,
    scan_limit_per_month: LIMIT_UNLIMITED,
    scan_max_duration_sec: LIMIT_UNLIMITED
  }
}

export interface WorkspaceSubscriptionModalProps extends Omit<ModalProps, 'children'> {
  workspace: string
}

/**
 * Provides plan management functionality for internal use only.
 *
 * A subscription is defined by the following attributes:
 * 1. `plan_id`: (BASIC or STANDARD) which defines default values for limits.
 *    There is also the ENTERPRISE plan, which does not concern this form as it's used
 *    only for on-prem deployments.
 * 2. `is_trial`: By default, when a "shared" workspace is created, it is assigned a 30 day
 *    trial subscription to the STANDARD plan. We allow administrators to extend the subscription
 *    by 30 days.
 * 3. `expires_at`: The expiration datetime of the subscription.
 * 4. Additionally, 3 limits can be configured; `max_contributors`, `scan_limit_per_month`,
 *    and `scan_max_duration_sec`.
 *
 * All of these limits are integer values that are inherited by the plan unless an override has been
 *  defined. The following special values are used:
 * 1. `-1`: Inherit from plan. This is the most common value, as it allows us to control subscription
 *    limits by changing the plan values.
 * 2. `null`: Unlimited use. Should be used by mayhem staff for demos or other special purposes.
 * 3. `0`: While, there is no special handling for 0, when it's defined it essential disables the use of
 *    resource.
 * Any other positive integer within the bounds of int32 is considered valid.
 *
 */
export function WorkspaceSubscriptionModal({ workspace, ...props }: WorkspaceSubscriptionModalProps) {
  const { isOpen, onClose } = props

  const [planId, setPlanId] = useState<PlanId>('BASIC')
  const [isTrial, setIsTrial] = useState(false)
  const [expiresAt, setExpiresAt] = useState<string | null>(null)
  const [maxContributors, setMaxContributors] = useState<PlanLimitValue>(LIMIT_PLAN_DEFAULT)
  const [scanLimitPerMonth, setScanLimitPerMonth] = useState<PlanLimitValue>(LIMIT_PLAN_DEFAULT)
  const [scanMaxDurationSec, setScanMaxDurationSec] = useState<PlanLimitValue>(LIMIT_PLAN_DEFAULT)

  const [upsertSubscription, { isLoading: isLoadingMutation }] = usePutBillingSubscriptionMutation()
  const {
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    data: subscription
  } = useGetBillingSubscriptionQuery(
    {
      owner: workspace as string
    },
    { skip: !isOpen }
  )

  useEffect(() => {
    if (subscription) {
      const plan = Plans[subscription.plan_id as PlanId]

      setMaxContributors(plan.max_contributors === subscription.max_contributors ? LIMIT_PLAN_DEFAULT : subscription.max_contributors)
      setScanLimitPerMonth(plan.scan_limit_per_month === subscription.scan_limit_per_month ? LIMIT_PLAN_DEFAULT : subscription.scan_limit_per_month)
      setScanMaxDurationSec(
        plan.scan_max_duration_sec === subscription.scan_max_duration_sec ? LIMIT_PLAN_DEFAULT : subscription.scan_max_duration_sec
      )

      setPlanId(subscription.plan_id as PlanId)
      setIsTrial(subscription.is_trial)
      setExpiresAt(subscription.expires_at)
    }
  }, [subscription])

  const plan = Plans[planId]

  const toast = useToast()
  const handleOnSubmit = async () => {
    try {
      await upsertSubscription({
        owner: workspace as string,
        accountSubscriptionUpsert: {
          plan_id: planId,
          is_trial: isTrial,
          expires_at: expiresAt ? dayjs(expiresAt).toISOString() : null,
          scan_max_duration_sec: scanMaxDurationSec,
          scan_limit_per_month: scanLimitPerMonth,
          max_contributors: maxContributors
        }
      }).unwrap()
      toast({
        title: 'Workspace subscription updated.',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
      onClose()
    } catch (e) {
      toast({
        title: 'Failed to update workspace subscription.',
        status: 'error',
        duration: 6000,
        isClosable: true
      })
    }
  }

  const handleOnRenew = () => {
    changePlan('STANDARD')
    setIsTrial(true)
    setExpiresAt(dayjs().add(30, 'days').toISOString())
  }

  const handleOnClearExpiration = () => {
    setIsTrial(false)
    setExpiresAt(null)
  }

  const changePlan = (value: PlanId) => {
    if (planId === value) {
      return
    }

    setPlanId(value)

    if (value === 'BASIC') {
      setExpiresAt(null)
      setIsTrial(false)
    }

    setMaxContributors(LIMIT_PLAN_DEFAULT)
    setScanLimitPerMonth(LIMIT_PLAN_DEFAULT)
    setScanMaxDurationSec(LIMIT_PLAN_DEFAULT)
  }

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Subscription</ModalHeader>
        <ModalBody>
          {(isUninitialized || isLoading) && (
            <Center>
              <Spinner />
            </Center>
          )}
          {isError && <Text>Oops, something wrong</Text>}
          {isSuccess && (
            <Stack>
              <FormControl>
                <ButtonGroup variant="outline" isAttached width="full">
                  <Button flex="1" isActive={planId === 'BASIC'} onClick={() => changePlan('BASIC')}>
                    Basic
                  </Button>
                  <Button flex="1" isActive={planId === 'STANDARD'} onClick={() => changePlan('STANDARD')}>
                    Standard
                  </Button>
                </ButtonGroup>
              </FormControl>
              <FormControl display="flex" flexDirection="row" justifyContent="space-between">
                <FormLabel>Trial:</FormLabel>
                <Switch isChecked={isTrial} onChange={() => setIsTrial((value) => !value)} isDisabled={planId === 'BASIC'} />
              </FormControl>
              <FormControl>
                <FormLabel>Expires at:</FormLabel>
                <Box display="flex" gap={2}>
                  <Input
                    type="date"
                    value={timestampToInput(expiresAt)}
                    onChange={(event) => setExpiresAt(inputToTimestamp(event.target.value))}
                    isDisabled={planId === 'BASIC'}
                    flex="1"
                  />
                  <Tooltip label="Renew trial (moves expiration to 30 days from now).">
                    <IconButton variant="ghost" aria-label="renew" icon={<Icon as={RenewIcon} />} onClick={handleOnRenew} />
                  </Tooltip>
                  <Tooltip label="Clears expiration (makes subscription last FOR EVER).">
                    <IconButton variant="ghost" aria-label="clear" icon={<Icon as={ClearIcon} />} onClick={handleOnClearExpiration} />
                  </Tooltip>
                </Box>
              </FormControl>
              <PlanLimitControl
                label="Monthly scans:"
                value={scanLimitPerMonth}
                onChange={setScanLimitPerMonth}
                defaultValue={plan.scan_limit_per_month}
                formatter={(value) => formatNumber(value, { shorten: false })}
                planName={planId === 'BASIC' ? 'Basic' : 'Standard'}
              />
              <PlanLimitControl
                label="Max scan duration:"
                value={scanMaxDurationSec}
                onChange={setScanMaxDurationSec}
                defaultValue={plan.scan_max_duration_sec}
                formatter={(value) => prettyTimeElapsedString(value, true) || '0s'}
                planName={planId === 'BASIC' ? 'Basic' : 'Standard'}
              />
              <PlanLimitControl
                label="Contributors:"
                value={maxContributors}
                onChange={setMaxContributors}
                defaultValue={plan.max_contributors}
                formatter={(value) => formatNumber(value, { shorten: false })}
                planName={planId === 'BASIC' ? 'Basic' : 'Standard'}
              />
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup alignSelf="flex-end">
            <Button variant="solid" onClick={handleOnSubmit} isLoading={isLoadingMutation}>
              Submit
            </Button>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export interface PlanLimitControlProps {
  label: string
  value: PlanLimitValue
  onChange: (value: PlanLimitValue) => void
  defaultValue: PlanLimitValue
  formatter: (value: number) => string
  planName: string
}

export function PlanLimitControl({ label, value, onChange, defaultValue, formatter, planName }: PlanLimitControlProps) {
  const [isFocused, setIsFocused] = useState(false)

  const handleOnFocus = useCallback(() => setIsFocused(true), [])
  const handleOnBlur = useCallback(() => setIsFocused(false), [])

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Stack>
        <ButtonGroup variant="outline" isAttached width="full">
          <Button flex="1" isActive={limitIsDefault(value)} onClick={() => onChange(LIMIT_PLAN_DEFAULT)}>
            Default
          </Button>
          <Button flex="1" isActive={limitIsUnlimited(value)} onClick={() => onChange(LIMIT_UNLIMITED)}>
            Unlimited
          </Button>
          <Button flex="1" isActive={limitIsCustom(value)} onClick={() => onChange(defaultValue ?? 0)}>
            Custom
          </Button>
        </ButtonGroup>
        <NumberInput
          value={!isFocused ? limitDisplay(value, defaultValue, formatter, planName) : value ?? 0}
          onChange={(_, value) => onChange(!Number.isNaN(value) ? value : 0)}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          isDisabled={!limitIsCustom(value)}
          allowMouseWheel
          min={0}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Stack>
    </FormControl>
  )
}

function limitDisplay(value: PlanLimitValue, defaultValue: PlanLimitValue, formatter: (value: number) => string, planName: string) {
  if (limitIsDefault(value)) {
    if (defaultValue === LIMIT_UNLIMITED) {
      return `${planName} plan default (Unlimited)`
    }
    return `${planName} plan default (${formatter(defaultValue)})`
  }
  if (value === LIMIT_UNLIMITED) {
    return 'Unlimited'
  }
  return formatter(value)
}

function limitIsCustom(value: PlanLimitValue) {
  return value !== LIMIT_UNLIMITED && value !== LIMIT_PLAN_DEFAULT
}

function limitIsUnlimited(value: PlanLimitValue) {
  return value === LIMIT_UNLIMITED
}

function limitIsDefault(value: PlanLimitValue) {
  return value === LIMIT_PLAN_DEFAULT
}

function timestampToInput(value: string | null): string {
  if (!value) {
    return ''
  }
  return dayjs(value).format('YYYY-MM-DD')
}

function inputToTimestamp(value: string | undefined): string | null {
  if (!value) {
    return null
  }

  return dayjs(value, 'YYYY-MM-DD').toISOString()
}

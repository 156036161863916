import { ProgressIndicator } from '@/components/ProgressIndicator/ProgressIndicator'

interface Props {
  failed: boolean
  stopped: boolean
  ended: boolean
  started: boolean
}

export function RunStatusIndicator({ failed, stopped, ended, started }: Props) {
  if (failed) {
    return <ProgressIndicator variant="failed" size="sm" />
  }

  if (stopped) {
    return <ProgressIndicator variant="stopped" size="sm" />
  }

  if (ended) {
    return <ProgressIndicator variant="ended" size="sm" />
  }

  if (started) {
    return <ProgressIndicator variant="running" size="sm" />
  }

  return '-'
}

import * as React from "react";
const SvgHarfileLogo = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: 256, height: 256, viewBox: "0 0 256 256", xmlSpace: "preserve", fill: "currentColor", ...props }, /* @__PURE__ */ React.createElement("defs", null), /* @__PURE__ */ React.createElement("g", { style: {
  stroke: "none",
  strokeWidth: 0,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "none",
  fillRule: "nonzero",
  opacity: 1
}, transform: "translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" }, /* @__PURE__ */ React.createElement("path", { d: "M 77.474 17.28 L 61.526 1.332 C 60.668 0.473 59.525 0 58.311 0 H 15.742 c -2.508 0 -4.548 2.04 -4.548 4.548 v 80.904 c 0 2.508 2.04 4.548 4.548 4.548 h 58.516 c 2.508 0 4.549 -2.04 4.549 -4.548 V 20.496 C 78.807 19.281 78.333 18.138 77.474 17.28 z M 61.073 5.121 l 12.611 12.612 H 62.35 c -0.704 0 -1.276 -0.573 -1.276 -1.277 V 5.121 z M 74.258 87 H 15.742 c -0.854 0 -1.548 -0.694 -1.548 -1.548 V 4.548 C 14.194 3.694 14.888 3 15.742 3 h 42.332 v 13.456 c 0 2.358 1.918 4.277 4.276 4.277 h 13.457 v 64.719 C 75.807 86.306 75.112 87 74.258 87 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M 68.193 33.319 H 41.808 c -0.829 0 -1.5 -0.671 -1.5 -1.5 s 0.671 -1.5 1.5 -1.5 h 26.385 c 0.828 0 1.5 0.671 1.5 1.5 S 69.021 33.319 68.193 33.319 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M 34.456 33.319 H 21.807 c -0.829 0 -1.5 -0.671 -1.5 -1.5 s 0.671 -1.5 1.5 -1.5 h 12.649 c 0.829 0 1.5 0.671 1.5 1.5 S 35.285 33.319 34.456 33.319 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1", gradientUnits: "userSpaceOnUse", x1: 21.8064, y1: 19.2332, x2: 42.2984, y2: 19.2332 }, /* @__PURE__ */ React.createElement("stop", { offset: "0%", style: {
  stopColor: "rgb(255,255,255)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: "100%", style: {
  stopColor: "currentColor",
  stopOpacity: 1
} })), /* @__PURE__ */ React.createElement("line", { x1: -10.246, y1: 0, x2: 10.246, y2: 0, style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "url(#SVGID_1)",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) " }), /* @__PURE__ */ React.createElement("path", { d: "M 42.298 20.733 H 21.807 c -0.829 0 -1.5 -0.671 -1.5 -1.5 s 0.671 -1.5 1.5 -1.5 h 20.492 c 0.829 0 1.5 0.671 1.5 1.5 S 43.127 20.733 42.298 20.733 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M 68.193 44.319 H 21.807 c -0.829 0 -1.5 -0.671 -1.5 -1.5 s 0.671 -1.5 1.5 -1.5 h 46.387 c 0.828 0 1.5 0.671 1.5 1.5 S 69.021 44.319 68.193 44.319 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M 48.191 55.319 H 21.807 c -0.829 0 -1.5 -0.672 -1.5 -1.5 s 0.671 -1.5 1.5 -1.5 h 26.385 c 0.828 0 1.5 0.672 1.5 1.5 S 49.02 55.319 48.191 55.319 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M 68.193 55.319 H 55.544 c -0.828 0 -1.5 -0.672 -1.5 -1.5 s 0.672 -1.5 1.5 -1.5 h 12.649 c 0.828 0 1.5 0.672 1.5 1.5 S 69.021 55.319 68.193 55.319 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M 68.193 66.319 H 21.807 c -0.829 0 -1.5 -0.672 -1.5 -1.5 s 0.671 -1.5 1.5 -1.5 h 46.387 c 0.828 0 1.5 0.672 1.5 1.5 S 69.021 66.319 68.193 66.319 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M 68.193 77.319 H 55.544 c -0.828 0 -1.5 -0.672 -1.5 -1.5 s 0.672 -1.5 1.5 -1.5 h 12.649 c 0.828 0 1.5 0.672 1.5 1.5 S 69.021 77.319 68.193 77.319 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" })));
export default SvgHarfileLogo;

import { useState, ChangeEvent, FocusEvent, useRef, forwardRef, useEffect, useCallback } from 'react'
import { Input, InputProps } from '@chakra-ui/react'

export interface FormattedInputProps extends InputProps {
  format: (value: string) => string
}

/**
 * Component that maintains two instances of an input element, one for
 *  display and one for editing. Input element is identical to not using
 *  FormattedInput with the that ref, onFocus, and onFocus are wrapped
 *  with handlers that allow this component to track input focus.
 */
export const FormattedInput = forwardRef<HTMLInputElement, FormattedInputProps>(function FormattedInput(props: FormattedInputProps, ref) {
  const { format, type, onChange, onFocus, onBlur, display, isReadOnly, ...rest } = props

  const [focused, setFocused] = useState(false)
  const displayRef = useRef<HTMLInputElement | null>()
  const focusRef = useRef(false)
  const inputRef = useRef<HTMLInputElement | null>()

  useEffect(() => {
    if (!displayRef.current || !inputRef.current) {
      return
    }
    const displayElement = displayRef.current
    const inputElement = inputRef.current

    // Workaround for development workflow, live reloads trigger this effect
    //  making it repeat itself. If the input element is already focused, there
    //  is no need to transfer focus.
    if (focused === focusRef.current) {
      return
    }
    focusRef.current = focused

    if (focused) {
      inputElement.style.display = displayElement.style.display
      displayElement.style.display = 'none'
      inputElement.focus()
    } else {
      displayElement.style.display = inputElement.style.display
      inputElement.style.display = 'none'
    }
  }, [focused])

  const handleOnDisplayFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.preventDefault()
    // Transfer focus to the input element.
    setFocused(true)
  }, [])

  const handleOnInputFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e)
      }

      // Switch to the display element, if focus has been prevented.
      if (e.isDefaultPrevented()) {
        setFocused(false)
      }
    },
    [onFocus]
  )

  const handleOnInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e)
      }

      // Switch to the display element, unless blur has been prevented.
      if (!e.isDefaultPrevented()) {
        setFocused(false)
      }
    },
    [onBlur]
  )

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e)
      }
      if (displayRef.current) {
        displayRef.current.value = format(e.target.value)
      }
    },
    [onChange, format]
  )

  const handleInputRef = useCallback(
    (element: HTMLInputElement | null): void => {
      inputRef.current = element
      if (ref instanceof Function) {
        ref(element)
      } else if (ref) {
        ref.current = element
      }

      if (displayRef.current && element) {
        displayRef.current.value = format(element.value)
      }
    },
    [ref, format]
  )

  return (
    <>
      <Input
        ref={displayRef}
        type="text"
        value={format(inputRef.current?.value ?? '')}
        isReadOnly
        display={focused ? 'none' : display}
        onFocus={handleOnDisplayFocus}
        {...rest}
      />
      <Input
        ref={handleInputRef}
        type={type}
        isReadOnly={isReadOnly}
        onChange={handleOnInputChange}
        onFocus={handleOnInputFocus}
        onBlur={handleOnInputBlur}
        display={focused ? display : 'none'}
        {...rest}
      />
    </>
  )
})

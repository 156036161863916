import { useCallback, useEffect, useState } from 'react'
import { Box, Center, Fade, Spinner, Stack, useMultiStyleConfig } from '@chakra-ui/react'

import { DefectTrendFilterBar } from './DefectTrendFilterBar'
import { DefectTrendChart, DefectTrendChartProps, UnitType } from './DefectTrendChart'
import { FilterBarOption, toCountAndUnit } from './filterBarOption'

export type NextValue = { nextValue: FilterBarOption; nextCount: number | null; nextUnit: UnitType | null }
export type DefectTrendProps = Omit<DefectTrendChartProps, 'count' | 'unit'> & {
  isLoading: boolean
  defaultFilterOption: FilterBarOption
  onChange?: (next: { nextValue: FilterBarOption; nextCount: number | null; nextUnit: UnitType | null }) => Promise<void>
}

export function DefectTrend({ data, startDate, endDate, onChange, isLoading, defaultFilterOption }: DefectTrendProps) {
  const [count, setCount] = useState<number | null>(null)
  const [unit, setUnit] = useState<UnitType | null>(null)

  const styles = useMultiStyleConfig('DefectTrendChart')

  const handleChange = useCallback(
    async (nextValue: FilterBarOption) => {
      const { count: nextCount, unit: nextUnit } = toCountAndUnit(nextValue)

      if (onChange) {
        await onChange({ nextValue, nextCount, nextUnit })
      }

      setCount(nextCount)
      setUnit(nextUnit)
    },
    [onChange, setCount, setUnit]
  )

  useEffect(() => {
    const { count, unit } = toCountAndUnit(defaultFilterOption)

    setCount(count)
    setUnit(unit)
  }, [setCount, setUnit, defaultFilterOption])

  return (
    <Stack height="full">
      <DefectTrendFilterBar onChange={handleChange} isDisabled={isLoading} defaultFilterOption={defaultFilterOption} />
      {count && unit && (
        <Box height="full" width="full" position="relative">
          <DefectTrendChart data={data} startDate={startDate} endDate={endDate} count={count} unit={unit} />
          {isLoading && (
            <Fade in={isLoading}>
              <Box __css={styles.loadingOverlay} position="absolute" top={0} bottom={0} left={0} right={0} height="full" opacity={1}>
                <Center height="full">
                  <Spinner />
                </Center>
              </Box>
            </Fade>
          )}
        </Box>
      )}
    </Stack>
  )
}

export function getBackgroundColorFromPercent(percent: number) {
  if (percent >= 80 && percent <= 100) {
    return 'good'
  }

  if (percent >= 60 && percent < 80) {
    return 'warning'
  }

  if (percent >= 40 && percent < 60) {
    return 'severityLow.200'
  }

  return 'bad'
}

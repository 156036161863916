import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import MoreHorizontalIcon from '@material-design-icons/svg/sharp/more_horiz.svg?react'

import { getRunNumberUrl } from '@/features/runs/utils'
import { DownloadFileButton } from '@/components/DownloadFileButton'

interface Props {
  eventAttributes: Record<string, string>
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  isButton?: boolean
}

export function EventArtifactsButton(props: Props) {
  const { eventAttributes = {}, owner, projectSlug, targetSlug, runNumber, isButton = true } = props

  if (Object.keys(eventAttributes).length === 1) {
    return (
      <DownloadFileButton
        href={`${getRunNumberUrl(owner, projectSlug, targetSlug, runNumber)}/artifact/${Object.values(eventAttributes)[0]}`}
        variant="secondary"
        aria-label="Download Artifact"
        border="none"
      />
    )
  }

  return (
    <Menu>
      {isButton ? (
        <MenuButton as={IconButton} icon={<MoreHorizontalIcon />} variant="outline" aria-label="Run Event Artifacts" title="Run Event Artifacts" />
      ) : (
        <MenuButton as={Button}>View Related Artifacts</MenuButton>
      )}
      <MenuList>
        {Object.entries(eventAttributes).map(([_key, _val]) => (
          <MenuItem as="a" key={_key} href={`${getRunNumberUrl(owner, projectSlug, targetSlug, runNumber)}/artifact/${_val}`} download>
            {_key}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

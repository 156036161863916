import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox, CheckboxGroup, FormControl, FormErrorMessage, FormHelperText, FormLabel, Grid, Input, Stack, Switch } from '@chakra-ui/react'

import { CoreCodeTask } from './CoreCodeRunConfig'
import { FormCodeRunConfig } from './utils'

const Tasks: Array<{ key: string; value: CoreCodeTask; default: boolean }> = [
  { key: 'Exploitability Factors', value: 'exploitability_factors', default: true },
  { key: 'Regression Testing', value: 'regression_testing', default: true },
  { key: 'Behavior Testing', value: 'behavior_testing', default: true },
  { key: 'Code Coverage', value: 'coverage_analysis', default: false }
]
export const CodeRunConfigAdvanced = () => {
  const {
    control,
    register,
    formState: { errors }
  } = useFormContext<FormCodeRunConfig>()

  return (
    <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap={4}>
      <FormControl isInvalid={!!errors.tasks}>
        <FormLabel>Tasks</FormLabel>
        <Controller
          name="tasks"
          control={control}
          render={({ field: { ref: _ref, onChange, ...rest } }) => (
            <CheckboxGroup
              {...rest}
              onChange={(value: CoreCodeTask[]) => {
                onChange(value)
              }}
            >
              <Stack pl={2}>
                {Tasks.map((task) => (
                  <Checkbox key={task.value} value={task.value}>
                    {task.key}&nbsp;{task.default && '(default)'}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          )}
          rules={{
            required: { value: true, message: 'You must supply at least one task for Mayhem to run.' }
          }}
        />
        <FormErrorMessage>{errors.tasks?.message}</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>Advanced Triage</FormLabel>
        <Switch {...register('advanced_triage')} />
        <FormHelperText>Extra testing but longer runs</FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel>UID</FormLabel>
        <Input type="number" placeholder="Numeric UNIX user id" {...register('uid')} />
      </FormControl>

      <FormControl>
        <FormLabel>GID</FormLabel>
        <Input type="number" placeholder="Numeric UNIX group id" {...register('gid')} />
      </FormControl>
    </Grid>
  )
}

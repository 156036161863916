function getPageTitleBase() {
  return ' - Mayhem'
}

export function getLoginPageTitle() {
  return `Sign In${getPageTitleBase()}`
}

export function getSignUpPageTitle() {
  return `Sign Up${getPageTitleBase()}`
}

export function getForgotPasswordPageTitle() {
  return `Forgot Password${getPageTitleBase()}`
}

export function getResetPasswordPageTitle() {
  return `Set Password${getPageTitleBase()}`
}

export function getVerifyEmailPageTitle() {
  return `Verify Email${getPageTitleBase()}`
}

export function getBecomeUserPageTitle() {
  return `Remote Access${getPageTitleBase()}`
}

export function getHomePageTitle() {
  return `Home${getPageTitleBase()}`
}

export function getOrganizationsPageTitle() {
  return `Organizations${getPageTitleBase()}`
}

export function getWorkspacePageTitle(owner: string, subPage: string) {
  return `${subPage} - ${owner}${getPageTitleBase()}`
}

export function getOwnerSettingsPageTitle(owner: string, subPage: string) {
  return `Settings - ${subPage} -  ${owner}${getPageTitleBase()}`
}

export function getTeamPageTitle(owner: string, teamSlug: string) {
  return `${teamSlug} - ${owner}${getPageTitleBase()}`
}

export function getRunsPageTitle() {
  return `All Runs${getPageTitleBase()}`
}

export function getProjectPageTitle(owner: string, project: string) {
  return `${owner}/${project}${getPageTitleBase()}`
}

export function getProjectBuildsPageTitle(owner: string, project: string) {
  return `Builds - ${owner}/${project}${getPageTitleBase()}`
}

export function getProjectRunsPageTitle(owner: string, project: string) {
  return `Runs - ${owner}/${project}${getPageTitleBase()}`
}

export function getProjectDefectsPageTitle(owner: string, project: string) {
  return `Defects - ${owner}/${project}${getPageTitleBase()}`
}

export function getProjectsSettingsPageTitle(owner: string, project: string, subPage: string) {
  return `${subPage} -  ${owner}/${project}${getPageTitleBase()}`
}

export function getTargetPageTitle(owner: string, project: string, target: string) {
  return `${target} - ${getProjectPageTitle(owner, project)}`
}

export function getRunPageTitle(owner: string, project: string, target: string, run: string | number) {
  return `Run ${run} - ${getTargetPageTitle(owner, project, target)}`
}

export function getRunEventsPageTitle(owner: string, project: string, target: string, run: string | number) {
  return `Events - ${getRunPageTitle(owner, project, target, run)}`
}

export function getTestCaseReportPageTitle(owner: string, project: string, target: string, run: string | number, testcase: string) {
  return `Test Case ${testcase} - ${getRunPageTitle(owner, project, target, run)}`
}

export function getRunDefectReportPageTitle(owner: string, project: string, target: string, run: string | number) {
  return `Defect Report - ${getRunPageTitle(owner, project, target, run)}`
}

export function getDefectPageTitle(owner: string, project: string, target: string, defect: string) {
  return `Defect ${defect} - ${getTargetPageTitle(owner, project, target)}`
}

export function getBuildPageTitle(owner: string, project: string, branchName: string, revision: string) {
  return `Build ${branchName}#${revision} - ${getProjectPageTitle(owner, project)}`
}

export function getErrorPageTitle() {
  return `Error${getPageTitleBase()}`
}

export function getUsersPageTitle() {
  return `Users${getPageTitleBase()}`
}

export function getAdminSettingsPageTitle(subPage: string) {
  return `Admin Settings - ${subPage}${getPageTitleBase()}`
}

export function getCLIInstallationPageTitle() {
  return `CLI Installation${getPageTitleBase()}`
}

export function getCreateWorkspacePageTitle() {
  return `Create a new Workspace${getPageTitleBase()}`
}

export function getJoinWorkspacePageTitle() {
  return `Join Workspace${getPageTitleBase()}`
}

export function getWorkspacePlanPageTitle(workspace: string) {
  return `Plan - ${workspace}${getPageTitleBase()}`
}

export function getReportingDashboardPageTitle() {
  return `Reporting${getPageTitleBase()}`
}

export function getTOSPageTitle() {
  return 'Mayhem Terms of Service'
}

export function getDsbomWorkspacePageTitle(subPage: string) {
  return `Dynamic SBOM - ${subPage}`
}

import { CSSProperties } from 'react'

export const PRE_STYLE: CSSProperties = {
  color: 'var(--chakra-colors-code)',
  background: 'var(--chakra-colors-body)',
  fontFamily: 'var(--chakra-fonts-mono)',
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  wordSpacing: 'normal',
  wordBreak: 'break-word',
  wordWrap: 'normal',
  lineHeight: '1.5',
  MozTabSize: '4',
  OTabSize: '4',
  tabSize: '4',
  WebkitHyphens: 'none',
  MozHyphens: 'none',
  msHyphens: 'none',
  hyphens: 'none',
  paddingLeft: '1em',
  paddingRight: '1em',
  paddingTop: '2em',
  paddingBottom: '1em',
  marginX: '0.5em 0',
  overflow: 'auto',
  borderRadius: '0.3em'
} as CSSProperties

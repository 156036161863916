import { useEffect } from 'react'

import { Button, Card, CardBody, CardHeader, Heading, useDisclosure } from '@chakra-ui/react'

import { getAdminSettingsPageTitle } from '../../titles'

import { CreateWebhookModal } from './CreateWebhookModal'
import { WebhookList } from './WebhookList'

export function AdminWebhooksPage() {
  const { isOpen, onClose, onOpen } = useDisclosure()

  useEffect(() => {
    document.title = getAdminSettingsPageTitle('Webhooks')
  }, [])

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Webhooks</Heading>
        <Button onClick={onOpen}>Create New Webhook</Button>
      </CardHeader>
      <CardBody>
        <WebhookList />
        <CreateWebhookModal isOpen={isOpen} onClose={onClose} />
      </CardBody>
    </Card>
  )
}

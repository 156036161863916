import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return typeof error === 'object' && error != null && 'message' in error && typeof (error as Record<string, unknown>).message === 'string'
}

/**
 * Returns an error message from an RTK mutation.
 */
export function getMessageFromRtkError(error: unknown): string {
  const defaultErrorMessage = 'Something went wrong.  Please try again later or contact support.'
  if (isFetchBaseQueryError(error)) {
    const errorData = (error.data as Record<string, string>) || {}
    if ('message' in errorData) {
      return errorData.message
    } else {
      return defaultErrorMessage
    }
  } else if (isErrorWithMessage(error)) {
    return error.message
  } else {
    return defaultErrorMessage
  }
}

import React from 'react'

import { Box, Flex, Heading, Spacer, Stack, Text } from '@chakra-ui/react'
import { Badge } from '@chakra-ui/layout'

import { Markdown } from '../../components/Markdown/Markdown'

import MayhemTableLoader from '../../components/MayhemTableLoader'

import { ErrorPanel } from '../../components/ErrorPanel'
import { useGetIssueRuleQuery } from '../../redux/api/defects'

interface Props {
  workspace: string
  project: string
  issueRuleId: string
}

export function RestIssueRulePage({ workspace, project, issueRuleId }: Props) {
  const { isLoading: isRuleLoading, data: rule, isError: ruleIsError } = useGetIssueRuleQuery({ owner: workspace, projectSlug: project, issueRuleId })

  if (ruleIsError) {
    return <ErrorPanel msg="We can't find that rule for you. Sorry about that!" />
  }

  if (isRuleLoading || rule === undefined) {
    return <MayhemTableLoader />
  }

  return (
    <Stack spacing={8} marginTop={8} lineHeight={1.75} fontSize="md">
      <Flex flexDirection={{ base: 'column', lg: 'row' }} width="full">
        <Heading size="lg">{rule.short_description}</Heading>
        <Spacer />
        <Stack direction={{ base: 'column', sm: 'row' }} alignItems="flex-start">
          {/* <SeverityBadge severityScore={rule.severity_score} ruleTags={rule.tags} /> */}
          {rule.tags?.map((tag) => (
            <Badge key={tag} variant="outline">
              {tag}
            </Badge>
          ))}
        </Stack>
      </Flex>
      <Stack spacing={4} paddingLeft={4}>
        <React.Suspense fallback={<Text>Loading...</Text>}>
          <Heading size="xl">Issue Overview</Heading>
          <Markdown>{rule.full_description ?? undefined}</Markdown>
          <Heading paddingTop={4} size="lg">
            Recommendation
          </Heading>
          <Box>
            <Markdown>{rule.recommendation ?? undefined}</Markdown>
          </Box>
          {rule.examples?.map((example, idx) => (
            <React.Fragment key={idx}>
              <Heading paddingTop={4} size="lg">
                Example
              </Heading>
              <Box>
                <Markdown>{example}</Markdown>
              </Box>
            </React.Fragment>
          ))}
          <Heading paddingTop={4} size="lg">
            References
          </Heading>
          <Box>
            <Markdown>{rule.references ?? undefined}</Markdown>
          </Box>
        </React.Suspense>
      </Stack>
    </Stack>
  )
}

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Stack } from '@chakra-ui/react'

import { CardDefectTrend } from '../../components/CardDefectTrend'
import { ProjectListing } from '../project-listing/ProjectListing'

import { setMruWorkspace } from './mru.slice'
import { WorkspaceStats } from './WorkspaceStats'

type Props = { workspace: string }

export function WorkspaceNonZeroDashboard({ workspace }: Props) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setMruWorkspace(workspace))
  }, [workspace, dispatch])

  return (
    <Stack spacing={8}>
      <CardDefectTrend workspace={workspace} />
      <WorkspaceStats workspace={workspace} />
      <ProjectListing workspace={workspace} title="Latest Results" />
    </Stack>
  )
}

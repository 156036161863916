import { TableRowProps, Tr, useStyleConfig } from '@chakra-ui/react'

type HoverTableRowProps = TableRowProps & { disabled?: boolean }

/**
 * A row displayed in a table, with card-like styling
 */
export function HoverTableRow(props: HoverTableRowProps): JSX.Element {
  const styles = useStyleConfig('HoverTableRow')

  const { disabled = false, tabIndex = 0 } = props

  return <Tr role="link" sx={styles} tabIndex={tabIndex} disabled={disabled} padding={2} {...props} />
}

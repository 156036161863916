import { Box, HStack, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react'

import GitBranchIcon from '../../images/git-branch.svg?react'
import OutsideLink from '../../components/OutsideLink'

interface Props {
  ciUrl?: string
  branchName: string
  revision: string
  color: string
}

export default function RunRowCIDisplay({ ciUrl, branchName, revision, color }: Props): JSX.Element {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <HStack gap={1}>
          <Icon as={GitBranchIcon} color={color} />
          {ciUrl && (
            <OutsideLink href={ciUrl} inline={false}>
              <Text fontSize="sm" color={color}>
                {branchName}/{revision.substring(0, 8)}
              </Text>
            </OutsideLink>
          )}
          {!ciUrl && (
            <Text fontSize="sm" color={color}>
              {branchName}/{revision.substring(0, 8)}
            </Text>
          )}
        </HStack>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Box>
            <Text>Branch: {branchName}</Text>
            <Text>Revision: {revision}</Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Box, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack, useToast } from '@chakra-ui/react'
import { useLocation } from 'wouter'

import { SearchResult, SelectableGlobalSearchField } from '../search/SelectableGlobalSearchField'

import { useBecomeUserMutation } from '../../redux/api/admin'

const validateUserSelection = (user: SearchResult | undefined) => (!user || !user.owner ? 'You must select a user.' : undefined)

type Form = { user?: SearchResult }

export function BecomeUserForm() {
  const toast = useToast()
  const [becomeUser] = useBecomeUserMutation()
  const [_location, setLocation] = useLocation()

  const methods = useForm<Form>()
  const {
    handleSubmit,
    setError,
    control,
    formState: { errors, isValid, isSubmitting }
  } = methods

  const onSubmit = async (form: Form) => {
    try {
      const owner = form.user?.owner
      await becomeUser({ becomeUser: { slug: owner || '' } }).unwrap()
      toast({
        title: 'Remote login successful',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      setLocation('/')
    } catch (err: unknown) {
      const message = (err as Error).message || 'An unexpected error has occurred. Please try again later.'
      setError('user', { type: 'custom', message })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl isRequired isInvalid={!!errors.user}>
            <FormLabel>Choose User Account</FormLabel>
            <Controller
              control={control}
              rules={{
                required: 'This is required',
                maxLength: { value: 128, message: 'Maximum Length is 128' },
                validate: (v) => validateUserSelection(v)
              }}
              name="user"
              render={({ field: { onChange, onBlur, ref } }) => (
                <SelectableGlobalSearchField
                  includeUsers
                  placeholder="Search for a user"
                  isDisabled={isSubmitting}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                />
              )}
            ></Controller>
            <FormHelperText>Please choose a user to sign in as for your remote support session.</FormHelperText>
            <FormErrorMessage>{errors.user?.message?.toString()}</FormErrorMessage>
          </FormControl>
          <Box>
            <Button data-selenium-id="submit-become-user" isLoading={isSubmitting} disabled={!isValid} type="submit">
              Sign In
            </Button>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}

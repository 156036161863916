/**
 * Helper for generating Opaque types.
 *
 * Use this to wrap a primitive to avoid problems that arise from
 * primitive obsession.
 *
 * ie:
 * type Int = Opaque<number, 'Int'>;
 * type ID = Opaque<number, 'ID'>;
 *
 * // works
 * const x: Int = 1 as Int;
 * const y: ID = 5 as ID;
 * const z = x + y;
 *
 * // doesn't work
 * const a: Int = 1;
 * const b: Int = x;
 *
 * // also works so beware
 * const f: Int = 1.15 as Int;
 * */
export type Opaque<T, K> = T & { __opaque__: K }

/**
 * Used for safely converting from non-opaque types to opaque. Specifically,
 * solves the issue of missing properties not being type checked.
 *
 * The value of __opaque__ is not an actual object property, it exists only
 * within the type system. This allows for type checking support without polluting
 * the objects.
 *
 * Usage:
 *  type Config = Opaque<{ value: string }, "config">
 *
 *  // O can be explicit
 *  const config = opaque<Config>({ value: 'test' })
 *
 *  // or implicit
 *  const config: Config = opaque({ value: 'test' })
 *
 *  // issue is not caught by the type system
 *  const config = {} as Config
 *
 *  // issue is caught by the type system
 *  const config = opaque<Config>({})
 *  const config = opaque<Config>({ value: 2 })
 *
 *
 * @param value
 */
export const opaque = <O extends Opaque<unknown, unknown>>(value: Omit<O, '__opaque__'>): O => value as O

export type PartialOpaque<O extends Opaque<unknown, unknown>> = Partial<Omit<O, '__opaque__'>> & {
  __opaque__: O['__opaque__']
}

import { HStack, Text } from '@chakra-ui/react'

import { DefectsTag } from './DefectsTag'

export interface RunDefectsSeverityBreakdownProps {
  high: number
  medium: number
  low: number
}

export const RunDefectsSeverityBreakdown = ({ high, medium, low }: RunDefectsSeverityBreakdownProps) => {
  return (
    <HStack spacing={8}>
      <HStack spacing={2}>
        <Text>High</Text>
        <DefectsTag severity="High" count={high} />
      </HStack>
      <HStack spacing={2}>
        <Text>Medium</Text>
        <DefectsTag severity="Medium" count={medium} />
      </HStack>
      <HStack spacing={2}>
        <Text>Low</Text>
        <DefectsTag severity="Low" count={low} />
      </HStack>
    </HStack>
  )
}

import { useLocation, Link as WouterLink } from 'wouter'
import { Card, CardHeader, Heading, Text, IconButton, CardBody, Icon, HStack, Link, Skeleton, CardFooter, Button } from '@chakra-ui/react'
import CloseIcon from '@material-design-icons/svg/sharp/close.svg?react'
import RightArrowIcon from '@material-design-icons/svg/sharp/keyboard_arrow_right.svg?react'

import { setQueryParam } from '../../util/location'

import { useQuery } from '../../hooks'

import { useGetTestcaseReportByRunAndChecksumQuery } from '../../redux/api/defects'

import { TestCaseReportPreviewContent } from './TestCaseReportPreviewContent'

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  testcaseSha: string
  isModal?: boolean
}

export function TestCaseReportPreview({ owner, projectSlug, targetSlug, runNumber, testcaseSha, isModal }: Props) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const { isFetching } = useGetTestcaseReportByRunAndChecksumQuery({
    owner,
    projectSlug,
    targetSlug,
    runNumber,
    sha256: testcaseSha
  })

  const testcaseReportPageUrl = `/${owner}/${projectSlug}/${targetSlug}/${runNumber}/testcases/${testcaseSha}`
  const closeTestCasePreview = (): void => {
    const newUrl = setQueryParam({ location, queryParams: queryParams, param: 'testcase', value: null })
    setLocation(newUrl, { replace: true })
  }

  return (
    <Card borderWidth={isModal ? 0 : undefined} borderRadius={isModal ? 'md' : undefined} maxHeight="95vh">
      <CardHeader>
        <WouterLink to={testcaseReportPageUrl}>
          <Link>
            <HStack gap={0}>
              <Heading variant="cardHeading" display="flex" gap={2}>
                Test Case <Text fontFamily="mono">{testcaseSha.substring(0, 8)}</Text>
              </Heading>
              <Icon as={RightArrowIcon} boxSize={8} />
            </HStack>
          </Link>
        </WouterLink>
        <IconButton variant="outline" aria-label="close test case" icon={<CloseIcon />} onClick={closeTestCasePreview} />
      </CardHeader>
      <CardBody p={4} overflowY="auto">
        <Skeleton isLoaded={!isFetching}>
          <TestCaseReportPreviewContent
            owner={owner}
            projectSlug={projectSlug}
            targetSlug={targetSlug}
            runNumber={runNumber}
            testcaseSha={testcaseSha}
          />
        </Skeleton>
      </CardBody>
      <CardFooter justifyContent="end">
        <WouterLink to={testcaseReportPageUrl}>
          <Button rightIcon={<RightArrowIcon />}>View Full Test Case Details</Button>
        </WouterLink>
      </CardFooter>
    </Card>
  )
}

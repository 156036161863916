import MarkdownReact from 'marked-react'
import { MarkdownProps } from 'marked-react/dist/Markdown'
import ReactRenderer, { ReactRendererOptions } from 'marked-react/dist/ReactRenderer'
import { createElement, ElementType, ReactNode } from 'react'
import { Box, Icon, Link, LinkProps, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react'
import LaunchIcon from '@material-design-icons/svg/sharp/launch.svg?react'

import { CodeBlock } from '../CodeBlock/CodeBlock'

/**
 * From https://github.com/sibiraj-s/marked-react/blob/7d4330557708df20acb627ca4f88504dcb36a4a1/src/helpers.ts
 *
 * Copied here because it is not exported from the package.
 */
const joinBase = (path: string, base?: string) => {
  if (!base) {
    return path
  }

  try {
    return new URL(path, base).href
  } catch {
    return path
  }
}

const renderer = (options: ReactRendererOptions): Partial<ReactRenderer> => {
  // start higher than the markdown-react likely will
  let elementId = 1000

  function h<T extends ElementType>(renderer: ReactRenderer, el: T, children: ReactNode = null, props = {}) {
    const elProps = {
      key: `marked-react-${elementId}`
    }

    elementId = elementId + 1
    return createElement(el, { ...props, ...elProps }, children)
  }

  return {
    code(this: ReactRenderer, snippet: string, _lang: string) {
      return <CodeBlock key={this.elementId}>{snippet}</CodeBlock>
    },
    link(this: ReactRenderer, href: string, text: ReactNode) {
      const url = joinBase(href, options.baseURL)
      const props: LinkProps = { href: url, isExternal: options.openLinksInNewTab }
      const children = (
        <>
          {text} <Icon boxSize={3} as={LaunchIcon} />
        </>
      )
      return h(this, Link, children, props)
    },
    list(this: ReactRenderer, children: ReactNode, ordered: boolean) {
      return h(this, ordered ? OrderedList : UnorderedList, children)
    },
    listItem(this: ReactRenderer, children: ReactNode[]) {
      return h(this, ListItem, children)
    },
    paragraph(this: ReactRenderer, children: ReactNode) {
      return h(this, Box, children, { my: 5, as: 'p', lineHeight: 'inherit' })
    }
  }
}

/**
 * Parses markdown, and renders a <CodeBlock> for each section contained within a ``` block.
 *
 * ``` blocks support a language identifier
 * @param children markdown to render
 * @param rest properties passed to the marked-react wrapper
 */
export function Markdown({ children, ...rest }: MarkdownProps) {
  return <MarkdownReact value={children} renderer={renderer(rest)} langPrefix="" {...rest} />
}

import { ElementType } from 'react'
import {
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  LinkBox,
  Card,
  StackDivider,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import PreviousRunArrow from '@material-design-icons/svg/sharp/north_west.svg?react'
import ErrorIcon from '@material-design-icons/svg/sharp/error.svg?react'

import { Link } from 'wouter'

import WouterLinkOverlay from '../../components/WouterLinkOverlay'
import { formatNumber, formatPercentage } from '../../util/numbers'

export type RunStatFormatOptions =
  | 'number'
  | 'percent'
  | {
      type: 'fraction'
      total: number
      previousTotal?: number
    }

export interface RunStatProps {
  label: string
  icon?: ElementType
  value: number
  description: string | React.ReactNode
  positiveDeltaColor?: 'good' | 'bad' | 'neutral' // color of the arrow for the diff when the difference(new - old) is positive
  previousValue: number | undefined
  formatOptions?: RunStatFormatOptions
  units?: string
  usePercentageForDiff?: boolean
  colorIconForGoodBad?: boolean // flag for making the big icon reflect the good/bad nature of the stat
  previousRunLink?: string
  showDashforZero?: boolean // flag to show "--" or "0" for a stat value of 0
  isFirstRun: boolean // flag to denote that this is the first run of a target
  link: string
  taskFailed?: boolean // flag to denote if the task associated with this stat failed/if a failure icon should show for that
}

export const RunStat = ({
  label,
  icon,
  value,
  description,
  previousValue,
  formatOptions = 'number',
  units = '',
  usePercentageForDiff = false,
  colorIconForGoodBad = false,
  previousRunLink,
  showDashforZero = true,
  isFirstRun,
  link,
  positiveDeltaColor = 'good',
  taskFailed = false
}: RunStatProps) => {
  const formatter = getFormatter(formatOptions)
  const previousFormatter = getFormatter(formatOptions, true)
  const diffFormatter = getDiffFormatter(formatOptions)

  const isFraction = typeof formatOptions === 'object' && formatOptions.type === 'fraction'
  const hasDiffValue = isFraction ? (formatOptions.previousTotal ?? 0) !== 0 : previousValue !== undefined

  const displayDiff = (previousValue: number, value: number, previousTotal: number) => {
    const arrowColor = getArrowColor(positiveDeltaColor, value - previousValue)

    if (previousTotal && previousValue - value != 0) {
      return (
        <HStack>
          <StatArrow type={value > previousValue ? 'increase' : 'decrease'} color={arrowColor} />
          <Text>
            {value > previousValue ? '+' : '-'}
            {usePercentageForDiff
              ? getFormatter('percent')((((value - previousValue) * 1.0) / ((previousValue || 1) * 1.0)) * 100)
              : diffFormatter(Math.abs(previousValue - value)) + units}
          </Text>
        </HStack>
      )
    }
    return <Text>--</Text>
  }

  return (
    // only show the popover if it's not the first run of the target
    <Popover trigger="hover" isOpen={isFirstRun ? undefined : undefined}>
      <PopoverTrigger>
        <LinkBox>
          <Card
            p={4}
            sx={{
              '&:hover .run-stat-value': {
                textDecoration: 'underline' // underline StatNumber when Card is hovered
              }
            }}
          >
            <WouterLinkOverlay to={link} replace>
              <Stack direction="row" alignItems="center" gap="2">
                {icon && <Icon as={icon} boxSize="12" fill={colorIconForGoodBad ? (value > 0 ? 'bad' : 'good') : 'faded'} />}
                <Stat border="0" colorScheme="transparent">
                  <StatLabel noOfLines={1} fontSize="14">
                    {label}
                    {taskFailed && <Icon as={ErrorIcon} color="bad" boxSize={4} />}
                  </StatLabel>
                  <StatNumber className="run-stat-value">
                    {showDashforZero && (isFraction ? !formatOptions.total : !value) ? '--' : formatter(value) + `${units}`}
                  </StatNumber>
                  {/* only show diff if there's a previous run to look at */}
                  {previousRunLink && (
                    <StatHelpText marginBottom={0} minH={5}>
                      {hasDiffValue && displayDiff(previousValue || 0, value, isFraction ? formatOptions.previousTotal || 0 : 1)}
                    </StatHelpText>
                  )}
                </Stat>
              </Stack>
            </WouterLinkOverlay>
          </Card>
        </LinkBox>
      </PopoverTrigger>
      <PopoverContent width="auto">
        <PopoverArrow />
        <PopoverBody>
          <Stack align="center" divider={<StackDivider />}>
            <Text align="center" maxWidth={72}>
              {description}
            </Text>
            {taskFailed && (
              <Alert status="error">
                <AlertIcon />
                Task failed to complete. See the Log for more details.
              </Alert>
            )}
            {hasDiffValue && previousValue !== undefined && (
              <Flex height={16} padding={2} align="center">
                <VStack>
                  <Heading size="sm">
                    <HStack>
                      <Text>Previous Value</Text>
                      {previousRunLink && (
                        <Link to={previousRunLink}>
                          {/* TODO fix navigation between run pages */}
                          <IconButton
                            display="none"
                            variant="link"
                            color="primaryText"
                            size="xs"
                            aria-label="previous run"
                            icon={<PreviousRunArrow />}
                          />
                        </Link>
                      )}
                    </HStack>
                  </Heading>
                  <Text>{showDashforZero && !hasDiffValue ? '--' : previousFormatter(previousValue) + `${units}`}</Text>
                </VStack>
                <Divider orientation="vertical" mx={4} />
                <VStack>
                  <Heading size="sm">Change</Heading>
                  <Text>
                    {value - previousValue === 0 ? '--' : `${value > previousValue ? '+' : '-'}${diffFormatter(Math.abs(previousValue - value))}`}
                  </Text>
                </VStack>
              </Flex>
            )}
            {!hasDiffValue && !isFirstRun && (
              <Flex height={16} padding={2} align="center">
                <Text>No previous value found</Text>
              </Flex>
            )}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

function getFormatter(options: RunStatFormatOptions, isPrevious = false) {
  if (options === 'number') {
    return formatNumber
  }

  if (options === 'percent') {
    return formatPercentage
  }

  if (typeof options === 'object' && options.type === 'fraction') {
    return (value: number) => `${value}/${isPrevious ? options.previousTotal : options.total}`
  }

  console.warn(`using fallback formatter, unsupported format options: ${options}`)
  return formatNumber
}

function getDiffFormatter(options: RunStatFormatOptions) {
  if (options === 'number') {
    return formatNumber
  }

  if (options === 'percent') {
    return formatPercentage
  }

  if (typeof options === 'object' && options.type === 'fraction') {
    return (value: number) => formatPercentage((value / (options.previousTotal ? options.previousTotal : options.total)) * 100)
  }

  console.warn(`using fallback formatter, unsupported format options: ${options}`)
  return formatNumber
}

function getArrowColor(positiveDeltaColor: 'good' | 'bad' | 'neutral', diff: number) {
  switch (positiveDeltaColor) {
    case 'good':
      return diff > 0 ? 'good' : 'bad'
    case 'bad':
      return diff < 0 ? 'good' : 'bad'
    case 'neutral':
      return 'faded'
  }
}

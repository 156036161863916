import { ComponentStyleConfig } from '@chakra-ui/react'

import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

import { defectTrendChartAnatomy } from '../../components/chart/DefectTrendChart/anatomy'

export const DefectTrendChart: ComponentStyleConfig = {
  parts: defectTrendChartAnatomy.keys,
  baseStyle: (props: StyleFunctionProps) => ({
    filterBarButton: {
      cursor: 'pointer',
      borderRadius: 'md',
      color: mode('gray.500', 'gray.500')(props),
      fontWeight: 'semibold',
      _checked: {
        bg: mode('gray.50', 'gray.700')(props),
        color: mode('gray.700', 'gray.100')(props)
      },
      _hover: {
        bg: mode('gray.100', 'gray.700')(props)
      },
      _disabled: {
        cursor: 'not-allowed',
        color: mode('gray.400', 'gray.600')(props)
      },
      px: 3,
      py: 2,
      transition: 'all 0.12s'
    },
    loadingOverlay: {
      bg: mode('blackAlpha.50', 'blackAlpha.300')(props),
      borderRadius: 'md',
      mx: 6
    }
  })
}

import { Area, AreaChart, CartesianGrid, DefaultTooltipContent, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Box, chakra, useStyleConfig } from '@chakra-ui/react'
import { SystemStyleObject } from '@chakra-ui/theme-tools'
import { createContext } from '@chakra-ui/react-context'
import dayjs from 'dayjs'

export type UnitType = 'month' | 'day' | 'hour'
export type DefectTrendPoint = { timestamp: Date; fixed: number; remaining: number }

export type DefectTrendChartProps = {
  count: number
  data: DefectTrendPoint[]
  startDate?: Date
  endDate?: Date
  unit: UnitType
}

export function DefectTrendChart({ count, data, endDate, startDate, unit }: DefectTrendChartProps) {
  if (count <= 0) {
    throw Error('count must be greater than 0')
  }

  const styles = useStyleConfig('DefectTrendChart')

  const { startDate: startDateFromData, endDate: endDateFromData } = computeStartAndEndDate(data)

  const dateFormatter = (date: Date) => {
    if (unit === 'day') {
      return dayjs(date).format('DD MMM')
    }
    if (unit === 'hour') {
      return dayjs(date).format('HH:mm')
    }
    return dayjs(date).format('MMM YY')
  }

  const tooltipLabelFormatter = (date: Date) => {
    if (unit === 'month') {
      if (count <= 3) {
        return dayjs(date).format('DD MMM YY')
      }
    }
    if (unit === 'day') {
      if (count <= 7) {
        return dayjs(date).format('DD MMM HH:mm')
      }
      return dayjs(date).format('DD MMM')
    }
    if (unit === 'hour') {
      return dayjs(date).format('HH:mm:ss')
    }
    return dayjs(date).format('MMM YY')
  }

  const ticks = getTicks(startDate || startDateFromData, endDate || endDateFromData, count, unit)
  const dataInEpochTime = dateToEpoch(data)

  return (
    <DefectTrendChartStylesProvider value={styles}>
      <Box as="span" color="chartAxis">
        <ResponsiveContainer width="95%">
          <AreaChart data={dataInEpochTime} margin={{ top: 5, bottom: 5, left: 5, right: 5 }}>
            <defs>
              <linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#7F56D9" stopOpacity={0.1} />
                <stop offset="100%" stopColor="#7F56D9" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} stroke="var(--chakra-colors-chartAxis)" />
            <XAxis
              dataKey="date"
              scale="time"
              tickFormatter={dateFormatter}
              ticks={ticks}
              type="number"
              domain={['dataMin', 'dataMax']}
              stroke="var(--chakra-colors-chartAxis)"
            />
            <YAxis tick={<></>} tickLine={false} stroke="var(--chakra-colors-chartAxis)" />
            <Tooltip
              labelFormatter={tooltipLabelFormatter}
              itemStyle={{ textTransform: 'capitalize' }}
              content={(props) => {
                return (
                  <chakra.div __css={styles}>
                    <DefaultTooltipContent {...props} />
                  </chakra.div>
                )
              }}
            />
            <Area
              type="monotone"
              dataKey="remaining"
              stroke="var(--chakra-colors-chartSeries1)"
              strokeWidth={2}
              fillOpacity={1}
              dot={false}
              fill="url(#remaining)"
            />
            <Area type="monotone" dataKey="fixed" stroke="var(--chakra-colors-chartSeries2)" strokeWidth={2} fillOpacity={0} dot={false} />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </DefectTrendChartStylesProvider>
  )
}

const [DefectTrendChartStylesProvider, useDefectTrendChartStyles] = createContext<SystemStyleObject>({
  name: `DefectTrendChartContext`,
  errorMessage: `useTagStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Tag />" `
})

export { useDefectTrendChartStyles }

/**
 * Get the dates between `startDate` and `endSate` with equal granularity
 */
function getTicks(startDate: Date | null, endDate: Date | null, num: number, unit: UnitType) {
  if (!startDate || !endDate) {
    return []
  }

  const current = dayjs(startDate)

  const ticks = [startDate.valueOf()]

  for (let i = 1; i < num + 1; i++) {
    ticks.push(current.add(i, unit).valueOf())
  }

  return ticks
}

/**
 * Add data of the date in ticks,
 * if there is no data in that date in `data`.
 */
function dateToEpoch(data: DefectTrendPoint[]) {
  return data.map((point) => {
    return { ...point, date: point.timestamp.valueOf() }
  })
}

function computeStartAndEndDate(data: DefectTrendPoint[]) {
  let startDate: Date | null = null
  let endDate: Date | null = null

  for (const point of data) {
    if (!startDate || point.timestamp < startDate) {
      startDate = point.timestamp
    }
    if (!endDate || point.timestamp > endDate) {
      endDate = point.timestamp
    }
  }

  return { startDate, endDate }
}

import { Card, CardBody, CardFooter, CardHeader, HStack, Heading, Switch, Text } from '@chakra-ui/react'

import { useLocation } from 'wouter'

import { useGetMdsbomWorkspacePackagesQuery } from '../../redux/api/mdsbom'
import { DsbomPackageRow } from '../dsbom-packages/DsbomPackageRow'
import { useQuery } from '../../hooks'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { cleanInteger } from '../../util/numbers'
import { setNewPageFilters } from '../../util/location'
import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'

interface Props {
  workspace: string
  imageRepoId: string
}

export function DsbomImagePackages({ workspace, imageRepoId }: Props) {
  const query = useQuery()
  const [location, setLocation] = useLocation()

  const filters = {
    observedOnly: query.get('observedOnly') === 'true',
    reportFilter: query.get('reportFilter') || 'latest'
  }
  const page = cleanInteger(query.get('page'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)

  const { data: packagesData, isFetching } = useGetMdsbomWorkspacePackagesQuery({
    workspace,
    imageRepoId,
    perPage,
    offset: (page - 1) * perPage,
    observedOnly: filters.observedOnly
  })

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Packages</Heading>
      </CardHeader>
      <CardHeader>
        <HStack>
          <Text color="mutedAccent">Observed Only:</Text>
          <Switch
            isChecked={filters.observedOnly}
            onChange={(event) => {
              const newObservedOnly = event.target.checked
              setLocation(setNewPageFilters('observedOnly', newObservedOnly.toString(), filters, location), { replace: true })
            }}
          />
        </HStack>
      </CardHeader>
      <CardBody>
        {!isFetching &&
          packagesData?.packages.map((pkg) => <DsbomPackageRow key={pkg.id} workspace={workspace} imageRepoId={imageRepoId} pkg={pkg} />)}
        <ListDefaults isLoading={isFetching} nItems={packagesData?.packages.length} itemName="package" />
      </CardBody>
      <CardFooter justifyContent="center">
        <MayhemPagination isLoading={isFetching} total={packagesData?.count || 0} pageSize={perPage} />
      </CardFooter>
    </Card>
  )
}

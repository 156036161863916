import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getApiTokens: build.query<GetApiTokensApiResponse, GetApiTokensApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/user/${queryArg.userSlug}/account/api_token`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['internal']
      }),
      postApiToken: build.mutation<PostApiTokenApiResponse, PostApiTokenApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/account/api_token`, method: 'POST', body: queryArg.apiTokenSchemaUser }),
        invalidatesTags: ['internal']
      }),
      deleteApiToken: build.mutation<DeleteApiTokenApiResponse, DeleteApiTokenApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/account/api_token/${queryArg.token}`, method: 'DELETE' })
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetApiTokensApiResponse = /** status 200 Get the list of API tokens for this account */ ApiTokens
export type GetApiTokensApiArg = {
  userSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type PostApiTokenApiResponse = /** status 200 Create a new API token */ ApiTokenSchemaUser
export type PostApiTokenApiArg = {
  userSlug: string
  apiTokenSchemaUser: ApiTokenSchemaUser
}
export type DeleteApiTokenApiResponse = /** status 200 OK */ BaseSchemaWithoutTimestamps
export type DeleteApiTokenApiArg = {
  token: string
  userSlug: string
}
export type ApiTokenSchemaUser = {
  created_at?: string
  updated_at?: string
  token_id?: string
  token?: string | null
  name?: string | null
  last_access?: string | null
}
export type ApiTokens = {
  api_tokens?: ApiTokenSchemaUser[]
  count?: number
}
export type ApiError = {
  message?: string
}
export type BaseSchemaWithoutTimestamps = {}

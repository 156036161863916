import { useEffect } from 'react'

import { getWorkspacePageTitle } from '../../titles'

import { WebhooksCard } from './WebhooksCard'

interface Props {
  workspace: string
}

export function WebhooksTab({ workspace }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Webhooks')
  }, [workspace])

  return <WebhooksCard workspace={workspace} />
}

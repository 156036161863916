import { useEffect } from 'react'

import { Stack } from '@chakra-ui/react'

import { useWhoamiQuery } from '../../redux/api/workspace'

import { getWorkspacePageTitle } from '../../titles'

import { GeneralCard } from './GeneralCard'
import { DangerZoneCard } from './DangerZoneCard'

interface Props {
  allowWrite: boolean
  workspace: string
}

export function GeneralTab({ allowWrite, workspace }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'General')
  }, [workspace])

  const { data: profile } = useWhoamiQuery()

  return (
    <Stack spacing={4}>
      <GeneralCard workspace={workspace} allowWrite={allowWrite || false} />
      {allowWrite && profile && profile.slug && <DangerZoneCard owner={profile?.slug} workspace={workspace} />}
    </Stack>
  )
}

import {
  Button,
  ButtonGroup,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { useCallback } from 'react'

import LinkOffIcon from '@material-design-icons/svg/sharp/link_off.svg?react'

import JiraIcon from '../../images/integrations/jira_logo.svg?react'
import { useDeleteJiraIssueMutation } from '../../redux/api/jira'
import { useGetDefectByTargetQuery } from '../../redux/api/defects'
import { getMessageFromRtkError } from '../../util/errors'

type Props = {
  defectNumber: number
  owner: string
  projectSlug: string
  targetSlug: string
}

export function UnlinkJiraIssueButton({ owner, projectSlug, targetSlug, defectNumber }: Props) {
  const toast = useToast()
  const [deleteJiraIssue, { isLoading: isDeleting }] = useDeleteJiraIssueMutation()
  const { data: defect } = useGetDefectByTargetQuery({ owner, projectSlug, targetSlug, defectNumber })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const jiraIssueId = defect?.jira_issue_id

  const handleDeleteJiraIssue = useCallback(async () => {
    if (!jiraIssueId) {
      throw new Error('Jira Issue ID was not set')
    }

    try {
      await deleteJiraIssue({ owner, projectSlug, targetSlug, defectNumber })
      toast({
        title: 'Jira Issue Unlinked',
        description: `Defect ${defectNumber} is no longer linked to ${jiraIssueId}`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      onClose()
    } catch (err: unknown) {
      toast({
        title: 'Unlinking Jira Issue Failed',
        description: getMessageFromRtkError(err),
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
  }, [jiraIssueId, deleteJiraIssue, owner, projectSlug, targetSlug, defectNumber, toast, onClose])

  if (!defect) {
    return <></>
  }

  return (
    <>
      <IconButton size="xs" aria-label="Unlink Jira Issue From Defect" icon={<LinkOffIcon />} variant="ghost" onClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Icon as={JiraIcon} />
              <Text>Unlink Jira Issue</Text>
            </HStack>
          </ModalHeader>
          <ModalBody>
            <Text>
              This will unlink Jira issue {jiraIssueId} from Defect {defectNumber}.
            </Text>
            <Text mt={2} as="i">
              Note: this will not delete the Jira issue.
            </Text>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup padding={4}>
              <Button variant="outline" isDisabled={isDeleting} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" isDisabled={isDeleting} onClick={handleDeleteJiraIssue}>
                Unlink
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

import { ComponentStyleConfig } from '@chakra-ui/react'

export const Stepper: ComponentStyleConfig = {
  baseStyle: () => ({
    stepper: {
      gap: 0
    },
    title: {
      fontSize: 'sm',
      '&[data-status="incomplete"]': {
        color: 'faded'
      }
    },
    indicator: {
      width: 6,
      height: 6,
      borderWidth: 16,
      borderColor: 'primary',
      '&[data-status="incomplete"]': {
        borderWidth: 8,
        borderColor: 'border',
        background: 'transparent'
      },
      '&[data-status="complete"]': {
        borderWidth: 8
      },
      '&[data-status="active"]': {
        borderWidth: 8
      }
    },
    separator: {
      marginInlineStart: 0,
      '&[data-orientation="horizontal"]': {
        marginInlineStart: 0,
        height: '4px'
      }
    }
  })
}

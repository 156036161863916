import { Card, CardBody, Flex, HStack, Heading, Spacer, Spinner } from '@chakra-ui/react'

import { cleanInteger, formatNumber } from '../../util/numbers'

import DefectsPieViz from './DefectsPieViz'

import { DefectType } from './defectType'

export default function ProjectDefectsHeader({
  nDefects,
  defectTypes,
  isFetching,
  defectFilter
}: {
  nDefects: number | undefined
  defectTypes: DefectType[] | undefined
  isFetching: unknown
  defectFilter: string | number | undefined
}) {
  let total = nDefects

  if (defectFilter !== 'all') {
    const defectInt = cleanInteger(defectFilter)
    const matchingFilterData = defectTypes?.find(
      (defectType) => (defectType.cweNumber === null && defectFilter === 'uncategorized') || defectType.cweNumber === defectInt
    )

    if (matchingFilterData !== undefined) {
      total = matchingFilterData.count
    }
  }

  return (
    <Card>
      <CardBody p={4}>
        <Flex minHeight={64} wrap="wrap" gap={8}>
          <HStack>
            <Heading size="md">Defects Found:</Heading>
            <Heading size="xl">{isFetching ? <Spinner /> : formatNumber(total || 0, { shorten: false })}</Heading>
          </HStack>
          <Spacer />
          <DefectsPieViz nDefects={nDefects} defectTypes={defectTypes} />
        </Flex>
      </CardBody>
    </Card>
  )
}

import { FormProvider, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link as ChakraLink,
  Stack,
  useToast
} from '@chakra-ui/react'
import { Link } from 'wouter'
import MailIcon from '@material-design-icons/svg/sharp/mail.svg?react'
import { useCallback } from 'react'
import ArrowCircleRightIcon from '@material-design-icons/svg/sharp/arrow_circle_right.svg?react'

import { useRequestPasswordResetMutation } from '../../redux/api/token'

import { getMessageFromRtkError } from '../../util/errors'

import { validateEmail } from './AuthForm'

type Form = { email: string }

export function ForgotPasswordForm() {
  const toast = useToast()
  const [requestPasswordReset] = useRequestPasswordResetMutation()

  const methods = useForm<Form>()
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isValid, isSubmitting }
  } = methods

  const onSubmit = useCallback(
    async ({ email }: Form) => {
      try {
        await requestPasswordReset({ passwordResetRequestPost: { email } }).unwrap()
        toast({
          title: 'Email Sent',
          description: `If your account is active, an email with a reset link should appear in your inbox within a few minutes.`,
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        const message = getMessageFromRtkError(err)
        setError('email', { type: 'custom', message })
      }
    },
    [requestPasswordReset, setError, toast]
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl isRequired isInvalid={!!errors.email}>
            <InputGroup>
              <Input
                type="text"
                placeholder="Email"
                autoComplete="email"
                data-selenium-id="email"
                {...register('email', { maxLength: 128, validate: validateEmail, required: true })}
              />
              <InputRightElement>
                <Icon as={MailIcon} color="gray.500" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <Button type="submit" isLoading={isSubmitting} isDisabled={!isValid}>
            Send Reset Link
          </Button>
          <Box mt={8}>
            Remember the password?{' '}
            <ChakraLink as={Link} to="/sign-in">
              <Button variant="link" rightIcon={<Icon as={ArrowCircleRightIcon} />}>
                Sign In
              </Button>
            </ChakraLink>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}

import CweDefaultIcon from '../../images/defaultissue.svg?react'
import Cwe787Icon from '../../images/cwe-787.svg?react'
import Cwe125Icon from '../../images/cwe-125.svg?react'
import Cwe476Icon from '../../images/cwe-476.svg?react'
import Cwe369Icon from '../../images/cwe-369.svg?react'
import Cwe20Icon from '../../images/cwe-20.svg?react'
import Cwe913Icon from '../../images/cwe-913.svg?react'
import Cwe131Icon from '../../images/cwe-131.svg?react'
import Cwe475Icon from '../../images/cwe-475.svg?react'
import Cwe457Icon from '../../images/cwe-457.svg?react'
import Cwe590Icon from '../../images/cwe-590.svg?react'
import Cwe763Icon from '../../images/cwe-763.svg?react'
import Cwe401Icon from '../../images/cwe-401.svg?react'

export function getDefectTitle(number: string | number | undefined, title?: string | undefined): string {
  const titleString = title ? `: ${title}` : ''
  return `Defect ${number}${titleString}`
}

export function getMitreCweLink(cweNumber: number): string | undefined {
  return cweNumber ? `https://cwe.mitre.org/data/definitions/${cweNumber}.html` : undefined
}

export function getDefectIcon(cweNumber: number | undefined) {
  switch (cweNumber) {
    case 787:
      return Cwe787Icon
    case 125:
      return Cwe125Icon
    case 476:
      return Cwe476Icon
    case 369:
      return Cwe369Icon
    case 20:
      return Cwe20Icon
    case 913:
      return Cwe913Icon
    case 131:
      return Cwe131Icon
    case 475:
      return Cwe475Icon
    case 457:
      return Cwe457Icon
    case 590:
      return Cwe590Icon
    case 763:
      return Cwe763Icon
    case 401:
      return Cwe401Icon
    default:
      return CweDefaultIcon
  }
}

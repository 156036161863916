import { ReactNode } from 'react'
import { Box, useStyleConfig } from '@chakra-ui/react'
import { AreaChart, CartesianGrid, ResponsiveContainer } from 'recharts'

import { ChartStylesProvider } from './ChartStylesContext'

export interface ChartProps {
  records: Record<string, unknown>[]
  children?: ReactNode
}

export function Chart({ records, children }: ChartProps) {
  const styles = useStyleConfig('Chart')

  return (
    <ChartStylesProvider value={styles}>
      <Box as="span" color="chartAxis">
        <ResponsiveContainer width="100%">
          <AreaChart data={records}>
            <CartesianGrid vertical={false} stroke="var(--chakra-colors-chartAxis)" />
            {children}
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </ChartStylesProvider>
  )
}

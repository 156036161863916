import { FunctionComponent, SVGProps, memo, useRef } from 'react'
import { Link as WouterLink, useRoute } from 'wouter'
import { AnimatePresence } from 'framer-motion'
import { Box, Divider, Link as ChakraLink, Spacer, Stack, Text, IconButton, Icon, HStack } from '@chakra-ui/react'

import { skipToken } from '@reduxjs/toolkit/query'

import DownloadIcon from '../../images/nav-download.svg?react'
import DocsIcon from '../../images/nav-docs.svg?react'

import { useGetOrgMemberQuery, useWhoamiQuery } from '../../redux/api/workspace'

import { NavButton } from '../../components/Shell/NavButton'
import { Sidebar } from '../../components/Shell/Sidebar'
import MayhemWordmark from '../../images/mayhem-wordmark.svg?react'
import UrlIcon from '../../images/url.svg?react'

import { WorkspaceWidget } from './WorkspaceWidget'
import { SettingsMenu } from './SettingsMenu'
import { ProjectMenu } from './ProjectMenu'
import { WorkspaceMenu } from './WorkspaceMenu'

export interface MenuItem {
  path: string
  onClick?: () => void // custom onClick handler, used for manually expanding the Settings submenu
  extraPathMatches?: string[] // additional path patterns to use for highlighting the active route
  name: string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  key: string
  scrollTo?: string // used for scrolling to a particular section on a page with a scrolling nav setup
}

export interface MenuSection {
  name: string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  routes: MenuItem[]
  isShown: boolean
}

export const SubnavSectionHeader = ({ name, icon }: { name: string; icon?: FunctionComponent<SVGProps<SVGSVGElement>> }) => (
  <HStack>
    {icon && <Icon as={icon} boxSize={6} color="subnav-section-header" />}
    <Text color="subnav-section-header" paddingY={4}>
      {name}
    </Text>
  </HStack>
)

const InstallationButton = ({ isCollapsed = false }: { isCollapsed?: boolean }) => {
  return (
    <WouterLink to="/-/installation">
      <NavButton fontSize="md" label="Install CLI" icon={DownloadIcon} isActive={useRoute('/-/installation')[0]} isCollapsed={isCollapsed} />
    </WouterLink>
  )
}

const WorkspaceSidebarInner = ({ workspace, project, submenu, settingsManuallyExpanded, setSettingsManuallyExpanded }: WorkspaceSidebarProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const { data: profile } = useWhoamiQuery()
  const { data: currentMember } = useGetOrgMemberQuery(
    !profile
      ? skipToken
      : {
          owner: workspace,
          userSlug: profile.slug as string
        }
  )
  const { is_admin: isAdmin, slug: userSlug } = { ...profile }
  const { owns: isOwner } = { ...currentMember }
  const showWorkspaceSettings = !!isOwner || !!isAdmin || userSlug === workspace
  const showAdminSettings = !!isAdmin

  const isCollapsed = !!submenu
  return (
    <Stack direction="row" spacing={0} height="full">
      <Sidebar key="workspace-sidebar" ref={containerRef} width={isCollapsed ? '4.5rem' : '12.5rem'}>
        <Stack flex="1" overflowX="auto" spacing={4}>
          <WouterLink to={`/${workspace}`}>
            <IconButton
              aria-label="Home"
              variant="sidenav-link"
              height="auto"
              width="full"
              justifyContent={!isCollapsed ? 'start' : 'center'}
              paddingX={2}
              icon={
                !isCollapsed ? (
                  <Icon as={MayhemWordmark} boxSize={48} height={16} fill="primaryText" paddingX={2} />
                ) : (
                  <Icon as={UrlIcon} boxSize={8} height={16} fill="primaryText" />
                )
              }
            />
          </WouterLink>
          <Box>
            <WorkspaceMenu
              workspace={workspace}
              isCollapsed={isCollapsed}
              submenu={submenu}
              settingsManuallyExpanded={settingsManuallyExpanded}
              setSettingsManuallyExpanded={setSettingsManuallyExpanded}
            />
          </Box>
          <Spacer />
          <Stack spacing={1}>
            <InstallationButton isCollapsed={isCollapsed} />
            <ChakraLink href="/docs/" isExternal>
              <NavButton fontSize="md" label="Documentation" icon={DocsIcon} isCollapsed={isCollapsed} />
            </ChakraLink>
          </Stack>
          <Divider />
          <WorkspaceWidget workspace={workspace} isCollapsed={isCollapsed} />
        </Stack>
      </Sidebar>
      {submenu === 'project' && (
        <AnimatePresence mode="wait">
          <Sidebar width="12.5rem">
            <ProjectMenu workspace={workspace} project={project as string} />
          </Sidebar>
        </AnimatePresence>
      )}
      {submenu === 'settings' && (
        <AnimatePresence mode="wait">
          <Sidebar width="12.5rem">
            <SettingsMenu workspace={workspace} showWorkspaceSettings={showWorkspaceSettings} showAdminSettings={showAdminSettings} />
          </Sidebar>
        </AnimatePresence>
      )}
    </Stack>
  )
}

export const WorkspaceSidebar = memo(WorkspaceSidebarInner)

export interface WorkspaceSidebarProps {
  workspace: string
  project?: string
  submenu?: 'project' | 'settings'
  settingsManuallyExpanded: boolean
  setSettingsManuallyExpanded: (expanded: boolean) => void
}

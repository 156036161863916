import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'

/**
 * Utility for handling the typing of mutation response results.
 *
 * @param response
 * @param onConflict
 */
export const unwrapMutation = <T, C>(
  response: { data: T } | { error: FetchBaseQueryError | SerializedError },
  onConflict: (error: FetchBaseQueryError) => C
):
  | { data: T; error: undefined; conflict: false }
  | { data: C; error: FetchBaseQueryError; conflict: true }
  | {
      data: undefined
      error: FetchBaseQueryError | SerializedError
      conflict: false
    } => {
  const { data, error } = {
    data: undefined,
    error: undefined,
    ...response
  }
  if (error && 'status' in error && error.status === 409) {
    return {
      data: onConflict(error),
      error: error as FetchBaseQueryError,
      conflict: true
    }
  }
  if (error) {
    return { data: undefined, error, conflict: false }
  }
  return { data, error: undefined, conflict: false }
}

import { Box, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react'

import { ProgressIndicatorProps } from '../../components/ProgressIndicator/ProgressIndicator'

import { RunStatusHover } from '../run/RunStatusHover'
import { RunStatusSpinner } from '../run/RunStatusSpinner'

export const RunRowStatus = ({ indicatorSize = 'sm', isProjectLatestActiveRun = false, ...params }: Props) => {
  const { owner, projectSlug, targetSlug, runNumber } = params

  return (
    <Popover key={`${owner}_${projectSlug}_${targetSlug}_${runNumber}`} trigger="hover">
      <PopoverTrigger>
        <Box data-testid="run-status">
          <RunStatusSpinner indicatorSize={indicatorSize} {...params} />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <RunStatusHover showLatestActiveRunHeader={isProjectLatestActiveRun} {...params} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  indicatorSize?: ProgressIndicatorProps['size']
  isProjectLatestActiveRun?: boolean
}

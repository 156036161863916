import { As, Button, ButtonProps, HStack, Icon, Text } from '@chakra-ui/react'

export interface NavButtonProps extends ButtonProps {
  icon?: As
  label: string
  isActive?: boolean
  isCollapsed?: boolean
  variant?: string
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, isActive, isCollapsed = false, variant, ...buttonProps } = props

  return (
    <Button
      variant={variant || 'sidenav-link'}
      justifyContent="start"
      width={!isCollapsed ? 'full' : 'auto'}
      aria-current={isActive ? 'page' : undefined}
      isActive={isActive}
      {...buttonProps}
    >
      <HStack spacing="3">
        {icon && <Icon as={icon} boxSize="6" color={isActive ? 'selectedColor' : 'primaryText'} />}
        {!isCollapsed && <Text>{label}</Text>}
      </HStack>
    </Button>
  )
}

/**
 * This file is shared among legacy redux reducers and is in this location
 * to break circular dependencies between js modules.
 *
 * https://redux-toolkit.js.org/usage/usage-guide#exporting-and-using-slices
 * */
import { Dispatch } from 'redux'
import TagManager from 'react-gtm-module'
import { jwtDecode, InvalidTokenError, JwtPayload } from 'jwt-decode'

import { ApiUserType } from '../features/admin/types'
import { get } from '../api'
import { GtmEvent } from '../util/gtmTags'
import UserService from '../features/auth/UserService'
import { IsomorphicFetchError } from '../types'

import { AppThunk } from './store'

export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE'
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS'
export const RECEIVE_LOGIN = 'auth/RECEIVE_LOGIN'
export const RECEIVE_ME = 'auth/RECEIVE_ME'
export const RECEIVE_KEYCLOAK = 'auth/RECEIVE_KEYCLOAK'
export const RECEIVE_JWT = 'auth/RECEIVE_JWT'

export type ReceiveLoginAction = {
  type: typeof RECEIVE_LOGIN
}

export type LoginFailureAction = {
  type: typeof LOGIN_FAILURE
}

export type LogoutSuccessAction = {
  type: typeof LOGOUT_SUCCESS
}

export type ReceiveJwtTokenAction = {
  type: typeof RECEIVE_JWT
  jwtToken: string
}

export function receiveLogin(): ReceiveLoginAction {
  return {
    type: RECEIVE_LOGIN
  }
}

export function loginFailure(): LoginFailureAction {
  return {
    type: LOGIN_FAILURE
  }
}

export function receiveLogout(): LogoutSuccessAction {
  return {
    type: LOGOUT_SUCCESS
  }
}

export function receiveMe(user: ApiUserType): ReceiveMeAction {
  return {
    type: RECEIVE_ME,
    user
  }
}

export function receiveKeycloak(keycloakUserId: string): ReceiveKeycloakAction {
  return {
    type: RECEIVE_KEYCLOAK,
    keycloakUserId
  }
}

export function receiveJwtToken(jwtToken: string): ReceiveJwtTokenAction {
  return {
    type: RECEIVE_JWT,
    jwtToken
  }
}

export type ReceiveMeAction = {
  type: typeof RECEIVE_ME
  user: ApiUserType
}

export type ReceiveKeycloakAction = {
  type: typeof RECEIVE_KEYCLOAK
  keycloakUserId: string
}

export function fetchMe(): AppThunk<Promise<void>> {
  const _doFetch = async (dispatch: Dispatch, isKeyCloak: boolean, keycloakId: string | undefined) => {
    try {
      const user: ApiUserType = await get('/api/v2/user/me')
      dispatch(receiveMe(user))
      const user_id = isKeyCloak ? keycloakId : user.userId

      try {
        TagManager.dataLayer({
          dataLayer: {
            event: GtmEvent.LoggedIn,
            user_id,
            product: 'mcode',
            segment: { user_id },
            emailAddress: user.emailAddress,
            firstName: user.firstName,
            lastName: user.lastName,
            isAdmin: user.isAdmin,
            username: user.username,
            invitedBy: user.invitedBy,
            mayhemLanguage: user.language,
            mayhemStatus: user.status,
            eventSource: 'web',
            keycloakRealm: UserService.getRealm()
          }
        })
      } catch (err) {
        if (err instanceof TypeError) {
          // Ignore this - this can happen if GTM is not properly configured
          // for a specific domain on the Google Tag Manager site. Letting this
          // fire to sentry will lead to accelerated alert fatigue otherwise.
        }
      }
    } catch (err) {
      dispatch(loginFailure())
      if ((err as IsomorphicFetchError).type !== 'APIWarning') {
        throw err
      }
    }
  }

  return async (dispatch, getState) => {
    try {
      const config = getState().config
      if (!!config.keycloakEnabled && !!config.keycloakInitialized) {
        if (!UserService.isLoggedInToKeycloak()) {
          dispatch(loginFailure())
        } else {
          try {
            // track KeyCloak ID for KeyCloak deployments like freemium
            const token = UserService.getKeycloakBearerToken()
            if (!token) {
              throw new InvalidTokenError('No token specified')
            }
            const keycloakId = jwtDecode<JwtPayload>(token).sub
            if (!keycloakId) {
              throw new InvalidTokenError('Keycloak ID not found')
            }
            dispatch(receiveKeycloak(keycloakId))
            await _doFetch(dispatch, !!config.keycloakEnabled, keycloakId)
          } catch (e) {
            if (e instanceof InvalidTokenError) {
              // eslint-disable-next-line no-console -- Trying to see what's going on
              console.error('Invalid JWT Error', e.message)
            } else {
              throw e
            }
          }
        }
      } else {
        await _doFetch(dispatch, !!config.keycloakEnabled, undefined)
      }
    } catch (err) {
      if ((err as IsomorphicFetchError).type !== 'APIWarning') {
        throw err
      }
    }
  }
}

import { FocusEvent, WheelEvent, useCallback, useMemo } from 'react'
import {
  Flex,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text
} from '@chakra-ui/react'

export interface DurationInputProps {
  value: number | undefined
  onChange: (value: number) => void

  min?: number
  max?: number

  isDisabled?: boolean
}

interface DurationUnits {
  hours: number
  minutes: number
  seconds: number
}

export const DurationInput = ({ value = 0, onChange, min = 0, max, isDisabled = false }: DurationInputProps) => {
  const { hours, minutes, seconds } = useMemo(() => calculateUnits(value), [value])

  const handleOnChange = useCallback(
    (value: number) => {
      // Validate that value respects min/max.
      if (value < min) {
        value = min
      }
      if (max !== undefined && value > max) {
        value = max
      }
      onChange(value)
    },
    [onChange, min, max]
  )

  const onHourChange = (_: string, value: number) => {
    if (Number.isNaN(value)) {
      value = 0
    }
    handleOnChange(value * 3600 + minutes * 60 + seconds)
  }
  const onMinuteChange = (_: string, value: number) => {
    if (Number.isNaN(value)) {
      value = 0
    }
    handleOnChange(hours * 3600 + value * 60 + seconds)
  }
  const onSecondChange = (_: string, value: number) => {
    if (Number.isNaN(value)) {
      value = 0
    }
    handleOnChange(hours * 3600 + minutes * 60 + value)
  }

  return (
    <Flex
      gap={{ base: 4, lg: 8 }}
      direction="row"
      sx={{
        '.chakra-numberinput > input': {
          borderColor: 'inherit',
          boxShadow: 'none'
        }
      }}
    >
      <HStack flex="0 0 6rem">
        <NumberInput value={hours} onChange={onHourChange} isDisabled={isDisabled} allowMouseWheel aria-label="duration hours">
          <NumberInputField onFocus={selectOnFocus} onWheel={focusOnMouseWheel} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text>h</Text>
      </HStack>

      <HStack flex="0 0 6rem">
        <NumberInput value={minutes} onChange={onMinuteChange} isDisabled={isDisabled} allowMouseWheel aria-label="duration minutes">
          <NumberInputField onFocus={selectOnFocus} onWheel={focusOnMouseWheel} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text>m</Text>
      </HStack>

      <HStack flex="0 0 6rem">
        <NumberInput value={seconds} onChange={onSecondChange} isDisabled={isDisabled} allowMouseWheel aria-label="duration seconds">
          <NumberInputField onFocus={selectOnFocus} onWheel={focusOnMouseWheel} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text>s</Text>
      </HStack>
    </Flex>
  )
}

function calculateUnits(value: number): DurationUnits {
  const hours = Math.floor(value / 3600)
  const minutes = Math.floor((value - hours * 3600) / 60)
  const seconds = value - hours * 3600 - minutes * 60
  return { hours, minutes, seconds }
}

function selectOnFocus(event: FocusEvent<HTMLInputElement>) {
  event.preventDefault()
  event.target.select()
}

function focusOnMouseWheel(event: WheelEvent<HTMLInputElement>) {
  if (document.activeElement !== event.target) {
    event.preventDefault()
    event.currentTarget.focus()
  }
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Text,
  VStack
} from '@chakra-ui/react'

import { useRef } from 'react'

import { CodeBlock } from '../../components/CodeBlock/CodeBlock'

interface Props {
  open: boolean
  onClose: () => void
  cmdString: string
  requestLabel: string
  footer?: string
}

export function ReproduceIssueModal({ open, onClose, cmdString, requestLabel, footer }: Props) {
  // Based on WAI-ARIA specifications, focus should be placed on the least destructive element
  // when the dialog opens, to prevent users from accidentally confirming the destructive action.
  const closeRef = useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog isOpen={open} onClose={onClose} isCentered leastDestructiveRef={closeRef} size="2xl">
      <AlertDialogOverlay />
      <AlertDialogContent minWidth="35em">
        <AlertDialogHeader>Reproduce {requestLabel}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <VStack spacing={4} align="start">
            <Text fontWeight="light">To reproduce the {requestLabel.toLowerCase()}, copy &amp; paste the curl command in your terminal.</Text>
            <Box width="full">
              <CodeBlock maxHeight={256}>{cmdString}</CodeBlock>
            </Box>
            {footer && <Text fontWeight="light">{footer}</Text>}
          </VStack>
        </AlertDialogBody>
        <AlertDialogFooter>
          <ButtonGroup>
            <Button variant="ghost" ref={closeRef} onClick={onClose}>
              Close
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

import { Route, RouteProps, DefaultParams, Path, useLocation, Redirect } from 'wouter'

import { useAppSelector } from '../../hooks'
import { useSearch } from '../../hooks.wouter'
import { MayhemPageLoader } from '../../components/MayhemPageLoader'

export function AdminRoute<T extends DefaultParams | undefined = undefined, RoutePath extends Path = Path>(
  props: RouteProps<T, RoutePath> & { fallbackPath?: string }
): JSX.Element {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)
  const isAdmin = useAppSelector((state) => state.auth.user.isAdmin)
  const isFetching = useAppSelector((state) => state.auth.isFetching)
  const logout = useAppSelector((state) => state.auth.logout)
  const [location] = useLocation()
  const search = useSearch()

  if (isFetching) {
    return <MayhemPageLoader />
  }

  if (isAuthenticated && isAdmin) {
    return <Route {...props} />
  }

  const referrerUrl = `${location}${search}`
  const logoutPage = logout || referrerUrl === '/' ? `/-/sign-in` : `/-/sign-in?referrer=${encodeURIComponent(referrerUrl)}`
  if (!isAuthenticated) {
    return <Redirect to={logoutPage} />
  }

  return <Redirect to={props.fallbackPath || '/'} />
}

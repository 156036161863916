export const globalKeyHandler = (event: KeyboardEvent, onOpen: () => void) => {
  if (event.ctrlKey && event.key === 'k') {
    event.stopPropagation()
    event.preventDefault()
    onOpen()
  }
  // Handle mac os version
  if (event.metaKey && event.key === 'k') {
    event.stopPropagation()
    event.preventDefault()
    onOpen()
  }
}

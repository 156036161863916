/**
 * This function first replaces all '_' and '-' characters with spaces, then separates
 * the input into words via a regex looking for changes in capitalization.
 *
 * The RegEx consists of two similar parts separated by OR operator. The first half:
 *
 * 1. ([A-Z]) - matches uppercase letters...
 * 2. (?=[A-Z][a-z]) - followed by a sequence of uppercase and lowercase letters.
 * When applied to sequence FOo, this effectively matches its F letter.
 *
 * Or the second scenario:
 *
 * 1. ([a-z]) - matches lowercase letters...
 * 2. (?=[A-Z]) - followed by an uppercase letter.
 * When applied to sequence barFoo, this effectively matches its r letter.
 *
 * When all replace candidates were found, the last thing to do is to replace
 * them with the same letter but with an additional space character. For this
 * we can use '$& ' as a replacement, and it will resolve to a matched
 * substring followed by a space character.
 * @param str
 */
export function respace(str: string) {
  if (!str) {
    return str
  }
  const regex = /([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g
  return str.replace(/[_-]+/, ' ').replace(regex, '$& ')
}

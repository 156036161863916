import { Flex } from '@chakra-ui/react'
import { forwardRef } from 'react'

export const Sidebar = forwardRef<HTMLDivElement, { children?: JSX.Element; width?: string | number }>(function SidebarInner(props, ref) {
  const { children, width } = props

  return (
    <Flex
      overflowY="auto"
      p={2}
      top={{ base: undefined, lg: 0 }}
      gridArea="sidebar"
      background="sidebarBg"
      sx={{
        '@media print': {
          display: 'none'
        }
      }}
      width={width}
      ref={ref}
    >
      {children}
    </Flex>
  )
})

import { useEffect } from 'react'

import { getSignUpPageTitle } from '../../titles'

import { AuthForm } from './AuthForm'
import AuthLayout from './AuthLayout'
import { RedirectIfAuthenticated } from './RedirectIfAuthenticated'

export function SignUpPage() {
  useEffect(() => {
    document.title = getSignUpPageTitle()
  }, [])

  return (
    <RedirectIfAuthenticated>
      <AuthLayout title="Sign Up">
        <AuthForm signUp />
      </AuthLayout>
    </RedirectIfAuthenticated>
  )
}

import { Card, CardHeader, CardBody, Heading, Table, TableContainer, Tbody, CardFooter } from '@chakra-ui/react'

import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetAccountInvitesQuery } from '../../redux/api/invites'

import { MayhemPagination } from '../../components/MayhemPagination'

import { cleanInteger } from '../../util/numbers'

import { useQuery } from '../../hooks'

import ListDefaults from '../../components/ListDefaults'

import { WorkspaceInviteListItem } from './WorkspaceInviteListItem'

interface WorkspaceInviteListProps {
  owner: string
}

const paginationPrefix = 'invites-'

export function WorkspaceInviteList({ owner }: WorkspaceInviteListProps) {
  const query = useQuery()
  const page = cleanInteger(query.get(`${paginationPrefix}page`), 1)
  const perPage = cleanInteger(query.get(`${paginationPrefix}perPage`), DEFAULT_PER_PAGE)

  const { currentData: invitesData, isFetching, isError } = useGetAccountInvitesQuery({ owner, perPage, offset: (page - 1) * perPage })

  const invitations = invitesData?.invitations.map((invitation) => (
    <WorkspaceInviteListItem key={invitation.account_invitation_id} owner={owner} {...invitation} />
  ))
  return (
    (!!invitations && invitations.length > 0 && (
      <Card>
        <CardHeader>
          <Heading size="md">Pending Invitations</Heading>
        </CardHeader>
        <CardBody>
          {!isFetching && invitations.length > 0 && (
            <TableContainer>
              <Table>
                <Tbody>{invitations}</Tbody>
              </Table>
            </TableContainer>
          )}
          <ListDefaults isLoading={isFetching} nItems={invitations.length} itemName="invitation" isFail={isError} />
        </CardBody>
        <CardFooter justifyContent="center">
          <MayhemPagination isLoading={isFetching} total={invitesData?.count || 0} pageSize={perPage} prefix={paginationPrefix} />
        </CardFooter>
      </Card>
    )) || <></>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { RootState } from '../../redux/store'

export const MapiUrlExample = 'https://demo-api.mayhem.security/api/v3'

export const MapiSpecTypeExample = 'openapi'
export const MapiSpecUrlExample = MapiUrlExample + '/openapi.json'

// TODO(kostas): Add remaining specification types #one-platform
export type ApiSpecificationType = 'openapi' | 'har' | 'postman'

interface CreateApiRunState {
  apiUrl?: string
  specType?: string
  specUrl?: string
}

const slice = createSlice({
  name: 'create-api-run',
  initialState: {} as CreateApiRunState,
  reducers: {
    setUrl(state, action: PayloadAction<string>) {
      // TODO(kostas): Check api connectivity ??
      state.apiUrl = action.payload
    },
    setSpecType(state, action: PayloadAction<string>) {
      state.specType = action.payload
      // TODO(kostas): Auto-fill specUrl if specType === OpenApi and apiUrl is not empty
    },
    setSpecUrl(state, action: PayloadAction<string>) {
      state.specUrl = action.payload
    },
    reset() {
      return {}
    }
  }
})

export const selectNewApiRun = createSelector(
  (state: RootState) => state,
  (state) => state.workspace.new.api
)

export const { setUrl, setSpecType, setSpecUrl, reset } = slice.actions

export default slice.reducer

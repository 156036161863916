import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getWebhooks: build.query<GetWebhooksApiResponse, GetWebhooksApiArg>({
        query: (queryArg) => ({ url: `/api/v2/webhook`, params: { offset: queryArg.offset, per_page: queryArg.perPage } }),
        providesTags: ['internal']
      }),
      getWebhooksByAccount: build.query<GetWebhooksByAccountApiResponse, GetWebhooksByAccountApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/webhook`, params: { offset: queryArg.offset, per_page: queryArg.perPage } }),
        providesTags: ['internal']
      }),
      postAccountWebhook: build.mutation<PostAccountWebhookApiResponse, PostAccountWebhookApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/webhook`, method: 'POST', body: queryArg.webhook }),
        invalidatesTags: ['internal']
      }),
      getAccountWebhook: build.query<GetAccountWebhookApiResponse, GetAccountWebhookApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/webhook/${queryArg.webhookSlug}` }),
        providesTags: ['internal']
      }),
      putAccountWebhook: build.mutation<PutAccountWebhookApiResponse, PutAccountWebhookApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/webhook/${queryArg.webhookSlug}`, method: 'PUT', body: queryArg.webhook1 }),
        invalidatesTags: ['internal']
      }),
      deleteAccountWebhook: build.mutation<DeleteAccountWebhookApiResponse, DeleteAccountWebhookApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/webhook/${queryArg.webhookSlug}`, method: 'DELETE' }),
        invalidatesTags: ['internal']
      }),
      getWebhooksByProject: build.query<GetWebhooksByProjectApiResponse, GetWebhooksByProjectApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/webhook`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['internal']
      }),
      postProjectWebhook: build.mutation<PostProjectWebhookApiResponse, PostProjectWebhookApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/webhook`,
          method: 'POST',
          body: queryArg.webhook
        }),
        invalidatesTags: ['internal']
      }),
      putProjectWebhook: build.mutation<PutProjectWebhookApiResponse, PutProjectWebhookApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/webhook/${queryArg.webhookSlug}`,
          method: 'PUT',
          body: queryArg.webhook1
        }),
        invalidatesTags: ['internal']
      }),
      deleteProjectWebhook: build.mutation<DeleteProjectWebhookApiResponse, DeleteProjectWebhookApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/webhook/${queryArg.webhookSlug}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetWebhooksApiResponse = /** status 200 Get all webhooks for the cluster. */ WebhooksPage
export type GetWebhooksApiArg = {
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type GetWebhooksByAccountApiResponse = /** status 200 Get all the webhooks for a owner. */ WebhooksPage
export type GetWebhooksByAccountApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type PostAccountWebhookApiResponse = /** status 200 Create a webhook for a owner. */ Webhook
export type PostAccountWebhookApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  webhook: Webhook
}
export type GetAccountWebhookApiResponse = /** status 200 Get a webhook for a owner by slug. */ Webhook
export type GetAccountWebhookApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the webhook. Note: the webhook name needs to be specified as a 'slug', a URL-friendly version of the webhook name. */
  webhookSlug: string
}
export type PutAccountWebhookApiResponse = /** status 200 Update a webhook for a owner by slug. */ Webhook
export type PutAccountWebhookApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the webhook. Note: the webhook name needs to be specified as a 'slug', a URL-friendly version of the webhook name. */
  webhookSlug: string
  webhook1: Webhook1
}
export type DeleteAccountWebhookApiResponse = /** status 200 Delete a webhook from a owner by slug. */ Ok
export type DeleteAccountWebhookApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the webhook. Note: the webhook name needs to be specified as a 'slug', a URL-friendly version of the webhook name. */
  webhookSlug: string
}
export type GetWebhooksByProjectApiResponse = /** status 200 Get all the webhooks for a project. */ WebhooksPage
export type GetWebhooksByProjectApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type PostProjectWebhookApiResponse = /** status 200 Create a webhook for a project. */ Webhook
export type PostProjectWebhookApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  webhook: Webhook
}
export type PutProjectWebhookApiResponse = /** status 200 Update a webhook for a project by slug. */ Webhook
export type PutProjectWebhookApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the webhook. Note: the webhook name needs to be specified as a 'slug', a URL-friendly version of the webhook name. */
  webhookSlug: string
  webhook1: Webhook1
}
export type DeleteProjectWebhookApiResponse = /** status 200 Delete a webhook from a project by slug. */ Ok
export type DeleteProjectWebhookApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the webhook. Note: the webhook name needs to be specified as a 'slug', a URL-friendly version of the webhook name. */
  webhookSlug: string
}
export type Subscription = {
  owner?: string
  project_slug?: string
}
export type Webhook = {
  created_at?: string
  updated_at?: string
  name: string
  enabled?: boolean
  slug?: string
  url: string
  format: 'json_v1' | 'slack' | 'gchat'
  last_triggered_at?: string | null
  subscription?: Subscription
  defect_new?: boolean
  defect_found?: boolean
  crash_new?: boolean
  testcase_new?: boolean
  run_start?: boolean
  run_complete?: boolean
}
export type WebhooksPage = {
  webhooks: Webhook[]
  count?: number
}
export type ApiError = {
  message?: string
}
export type Webhook1 = {
  created_at?: string
  updated_at?: string
  name?: string
  enabled?: boolean
  slug?: string
  url?: string
  format?: 'json_v1' | 'slack' | 'gchat'
  last_triggered_at?: string | null
  subscription?: Subscription
  defect_new?: boolean
  defect_found?: boolean
  crash_new?: boolean
  testcase_new?: boolean
  run_start?: boolean
  run_complete?: boolean
}
export type Ok = {
  result?: string
}

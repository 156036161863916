import { ComponentStyleConfig } from '@chakra-ui/react'
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

export const HoverTableRow: ComponentStyleConfig = {
  baseStyle: (props: StyleFunctionProps) => ({
    borderTop: '0.5px solid',
    borderColor: 'inherit',
    cursor: 'pointer',
    _first: { borderTop: 'none' },
    _last: { borderBottom: 'none' },
    _hover: {
      bg: mode(`blackAlpha.200`, `whiteAlpha.300`)(props)
    },
    _disabled: { bg: 'none', cursor: 'unset' },
    _focus: {
      boxShadow: 'outline',
      outline: 'none'
    }
  })
}

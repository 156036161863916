import { useEffect, useState } from 'react'

import { useLocation } from 'wouter'

import { FilterDropdown, FilterDropdownItem } from '@/components/Combobox/FilterDropdown'
import { useQuery } from '@/hooks'
import { setQueryParams } from '@/util/location'

interface Props {
  inputItems: FilterDropdownItem[]
  defaultInputPlaceholder: string
  param: string
  multiSelect?: boolean
}

export default function QueryParamFilterDropdown({ inputItems, defaultInputPlaceholder, param, multiSelect = false }: Props) {
  const [location, setLocation] = useLocation()
  const query = useQuery()

  const paramValue = query.get(param)

  const [selectedItems, setSelectedItems] = useState<FilterDropdownItem[]>([])

  useEffect(() => {
    if (paramValue) {
      setSelectedItems(inputItems.filter(({ id }) => id === paramValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLocation(
      setQueryParams({
        location,
        queryParams: query,
        params: [
          { param: 'page', value: undefined },
          { param, value: selectedItems.map(({ id }) => id).join(',') || undefined }
        ]
      }),
      { replace: true }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, param, selectedItems, setLocation])

  return (
    <FilterDropdown
      selectedItems={selectedItems}
      onSelectedItemsChange={(items) => setSelectedItems(items)}
      inputItems={inputItems}
      defaultInputPlaceholder={defaultInputPlaceholder}
      multiSelect={multiSelect}
    />
  )
}

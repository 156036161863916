import { useState } from 'react'
import { Flex, Text, useBreakpointValue, useRadioGroup } from '@chakra-ui/react'

import { RadioCard } from '../../components/RadioCard'
import { DurationInput } from '../../components/DurationInput'

import { CodeRunConfigDuration } from './CoreCodeRunConfig'

export interface CodeDurationOptionsProps {
  value: CodeRunConfigDuration
  onChange: (value: CodeRunConfigDuration) => void
  isInvalid?: boolean
  min?: number
  max?: number
}

export const CodeDurationOptions = ({ value, onChange, isInvalid, min, max }: CodeDurationOptionsProps) => {
  const [type, setType] = useState<null | 'quick' | 'custom' | 'infinite'>(null)
  const [customValue, setCustomValue] = useState(getCustomValue(value))
  const selectedType = (() => {
    if (type) {
      return type
    }
    if (value === 0) {
      return 'automatic'
    }
    if (value === 5 * 60) {
      return 'quick'
    }
    return 'custom'
  })()

  const onTypeChange = (value: 'quick' | 'custom' | 'infinite') => {
    setType(value)
    switch (value) {
      case 'quick':
        onChange(5 * 60)
        break
      case 'infinite':
        onChange(0)
        break
      case 'custom':
      default:
        onChange(customValue || 0)
        break
    }
  }
  const onCustomChange = (value: number) => {
    if (value === 0) {
      setType('infinite')
    } else {
      setType('custom')
    }
    setCustomValue(value)
    onChange(value)
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'duration-type',
    value: selectedType,
    onChange: onTypeChange
  })
  const root = getRootProps()

  const flex = useBreakpointValue({ base: '1 1 14rem', lg: '0 0 14rem' })
  const flexCustom = useBreakpointValue({ base: '1 1 26rem', lg: '0 0 26rem' })

  return (
    <Flex gap={4} direction="row" alignItems={{ xs: 'stretch', lg: undefined }} flexWrap="wrap" {...root}>
      <RadioCard title="Quick" flex={flex} radio={getRadioProps({ value: 'quick' })} isInvalid={isInvalid}>
        <Text fontSize="md">Run for 5 min</Text>
      </RadioCard>
      <RadioCard title="Custom" flex={flexCustom} flexWrap="wrap" radio={getRadioProps({ value: 'custom' })} isInvalid={isInvalid}>
        <DurationInput value={customValue} onChange={onCustomChange} min={min} max={max} />
      </RadioCard>
      <RadioCard title="Infinite" flex={flex} radio={getRadioProps({ value: 'infinite' })} isInvalid={isInvalid}>
        <Text fontSize="md">Run as long as needed</Text>
      </RadioCard>
    </Flex>
  )
}

function getCustomValue(value: CodeRunConfigDuration): number {
  if (value === undefined) {
    return 60
  }

  if (value === 300) {
    return 60
  }

  return value
}

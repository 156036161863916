import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Spinner,
  Stack,
  Switch,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { Link as WouterLink, useLocation } from 'wouter'

import { getProjectsSettingsPageTitle } from '../../titles'
import { getCurrentUserInfo } from '../auth/utils'

import { getMessageFromRtkError } from '../../util/errors'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { useGetProjectMemberPermissionQuery, useGetProjectQuery, usePutProjectMutation, useDeleteProjectMutation } from '../../redux/api/projects'
import MayhemTableLoader from '../../components/MayhemTableLoader'

import ProjectDeleteModal from '../project/ProjectDeleteModal'

import ProjectBadgeLink from './ProjectBadgeLink'
import { DefaultBranchForm } from './DefaultBranchForm'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'

type Props = {
  workspace: string
  project: string
}

export function ProjectSettingsGeneralPage({ workspace: owner, project: projectSlug }: Props) {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const [location, setLocation] = useLocation()

  const [concurrentRunsError, setConcurrentRunsError] = useState<string | undefined>()
  const [pruneBacklogError, setPruneBacklogError] = useState<string | undefined>()
  // MH-13902
  // const [testcaseLimitError, setTestcaseLimitError] = useState<string | undefined>()
  const [publicBadgeShareError, setPublicBadgeShareError] = useState<string | undefined>()

  const { isLoading: projectIsLoading, data: project, refetch: refetchProject } = useGetProjectQuery({ owner, projectSlug })

  const { userSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { isLoading: projectMemberPermissionIsLoading, data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner,
    projectSlug,
    userSlug
  })

  const [putProject, { isLoading: isPuttingProject }] = usePutProjectMutation()

  const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure()
  const [deleteProject] = useDeleteProjectMutation()
  const onDeleteProject = useCallback(async () => {
    try {
      await deleteProject({ owner: owner, projectSlug: projectSlug }).unwrap()
      toast({
        title: 'Project Deleted',
        description: `${projectSlug} was deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      setLocation(`/${owner}`)
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      toast({
        title: 'Delete Project Failed',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }, [toast, deleteProject, owner, projectSlug, setLocation])

  const onConcurrentRunsChanged = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        setConcurrentRunsError(undefined)
        await putProject({ owner, projectSlug, projectUpdate: { allow_concurrent_runs: e.target.checked } })
        await refetchProject()
        toast({
          title: 'Project Updated',
          description: `${projectSlug} concurrent runs setting updated`,
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        setConcurrentRunsError((err as Error)?.message || 'Something went wrong')
      }
    },
    [toast, refetchProject, putProject, owner, projectSlug]
  )

  const onPruneBacklogChanged = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        setPruneBacklogError(undefined)
        await putProject({ owner, projectSlug, projectUpdate: { prune_backlog: e.target.checked } })
        await refetchProject()
        toast({
          title: 'Project Updated',
          description: `${projectSlug} prune backlog setting updated`,
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        setPruneBacklogError((err as Error)?.message || 'Something went wrong')
      }
    },
    [toast, refetchProject, putProject, owner, projectSlug]
  )

  // MH-13902
  // const onTestCaseLimitChanged = useCallback(
  //   async (e: ChangeEvent<HTMLInputElement>) => {
  //     try {
  //       setTestcaseLimitError(undefined)
  //       await putProject({ owner, projectSlug, projectUpdate: { testcase_limit_enabled: e.target.checked } })
  //       await refetchProject()
  //       toast({
  //         title: 'Project Updated',
  //         description: `${projectSlug} test case limit setting updated`,
  //         status: 'success',
  //         duration: 5000,
  //         isClosable: true
  //       })
  //     } catch (err: unknown) {
  //       setTestcaseLimitError((err as Error)?.message || 'Something went wrong')
  //     }
  //   },
  //   [toast, refetchProject, putProject, owner, projectSlug]
  // )

  const onPublicBadgeShareChanged = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        setPublicBadgeShareError(undefined)
        await putProject({ owner, projectSlug, projectUpdate: { public_badge_share: e.target.checked } })
        await refetchProject()
        toast({
          title: 'Project Updated',
          description: `${projectSlug} public badge share setting updated`,
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        setPublicBadgeShareError((err as Error)?.message || 'Something went wrong')
      }
    },
    [toast, refetchProject, putProject, owner, projectSlug]
  )

  useEffect(() => {
    document.title = getProjectsSettingsPageTitle(owner, projectSlug, 'Settings')
  }, [dispatch, location, owner, projectSlug, setLocation])

  if (projectIsLoading || projectMemberPermissionIsLoading) {
    return <MayhemTableLoader />
  }

  const isAtLeastWritePermission = isMayhemAdmin || (projectPermission && projectPermission.permission !== 'READ')
  const projectBadgeSVGLink = `${window.location.origin}/api/v2/owner/${owner}/project/${projectSlug}/badge.svg`
  const projectBadgeLink = `![Mayhem](${projectBadgeSVGLink})`
  const { allow_concurrent_runs, prune_backlog, testcase_limit_enabled } = project || {}

  return (
    <Page>
      <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }} mb={8}>
        <BreadcrumbItem>
          <WouterLink to={`/${owner}/${projectSlug}`}>
            <BreadcrumbLink>
              <TruncatedTextWithTooltip text={projectSlug} />
            </BreadcrumbLink>
          </WouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Settings</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Heading mb={8}>General Settings</Heading>
      {isAtLeastWritePermission && (
        <Stack spacing={8}>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Repository Settings</Heading>
              {isPuttingProject && <Spinner size="xs" />}
            </CardHeader>
            <CardBody p={4}>
              <Stack spacing={8}>
                <DefaultBranchForm owner={owner} projectSlug={projectSlug} />
              </Stack>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Code Target Settings</Heading>
              {isPuttingProject && <Spinner size="xs" />}
            </CardHeader>
            <CardBody p={4}>
              <Stack spacing={8}>
                <FormControl display="grid" width="max-content" isInvalid={!!concurrentRunsError}>
                  <FormLabel gridRow={1} htmlFor="allow-concurrent-runs">
                    Allow concurrent analysis runs
                  </FormLabel>
                  <Switch
                    id="allow-concurrent-runs"
                    defaultChecked={!!allow_concurrent_runs}
                    onChange={onConcurrentRunsChanged}
                    disabled={!isAtLeastWritePermission || !!prune_backlog || isPuttingProject}
                    gridRow={1}
                  />
                  <FormHelperText maxW={96} gridRow={2}>
                    Concurrent runs for a particular target are permitted when enabled. When disabled, runs will execute one at a time in the order
                    they were started.
                  </FormHelperText>
                  <FormErrorMessage>{concurrentRunsError}</FormErrorMessage>
                </FormControl>
                <FormControl display="grid" width="max-content" isInvalid={!!pruneBacklogError}>
                  <FormLabel gridRow={1} htmlFor="prune-backlog">
                    Run most recent pending job only
                  </FormLabel>
                  <Switch
                    id="prune-backlog"
                    defaultChecked={prune_backlog}
                    onChange={onPruneBacklogChanged}
                    disabled={!isAtLeastWritePermission || !!allow_concurrent_runs || isPuttingProject}
                    gridRow={1}
                  />
                  <FormHelperText maxW={96} gridRow={2}>
                    By default, Mayhem keeps a queue of all submitted jobs and processes them in order. With this checked, Mayhem only keeps the last
                    submitted job for a code target in the queue, and will discard any intermediate pending jobs.
                  </FormHelperText>
                  <FormErrorMessage>{pruneBacklogError}</FormErrorMessage>
                </FormControl>
                {/* MH-13902 */}
                {/* <FormControl display="grid" width="max-content" isInvalid={!!testcaseLimitError}>
                  <FormLabel gridRow={1}>Test Case Limit</FormLabel>
                  <Switch
                    defaultChecked={!!testcase_limit_enabled}
                    onChange={onTestCaseLimitChanged}
                    disabled={!isAtLeastWritePermission || isPuttingProject}
                    gridRow={1}
                  />
                  <FormHelperText maxW={96} gridRow={2}>
                    Sets a maximum number of ~64k test cases for a run in this project when enabled.
                  </FormHelperText>
                  <FormErrorMessage>{testcaseLimitError}</FormErrorMessage>
                </FormControl> */}
              </Stack>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Project Badges</Heading>
              {isPuttingProject && <Spinner size="xs" />}
            </CardHeader>
            <CardBody p={4}>
              <Stack spacing={8}>
                <FormControl display="grid" width="max-content" isInvalid={!!publicBadgeShareError}>
                  <FormLabel gridRow={1}>Public Access to Badges</FormLabel>
                  <Switch
                    defaultChecked={!!testcase_limit_enabled}
                    onChange={onPublicBadgeShareChanged}
                    disabled={!isAtLeastWritePermission || isPuttingProject}
                    gridRow={1}
                  />
                  <FormHelperText maxW={96} gridRow={2}>
                    When disabled, badges can be viewed only by{' '}
                    <WouterLink to={`/${owner}/${projectSlug}/-/settings/access-control`}>
                      those with write permission to this project (including project admins).
                    </WouterLink>
                  </FormHelperText>
                  <FormErrorMessage>{publicBadgeShareError}</FormErrorMessage>
                </FormControl>
                {projectBadgeLink && <ProjectBadgeLink svgLink={projectBadgeSVGLink} link={projectBadgeLink} />}
              </Stack>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Danger Zone</Heading>
            </CardHeader>
            <CardBody p={4}>
              <Button colorScheme="red" onClick={onDeleteConfirmationOpen}>
                Delete Project
              </Button>
            </CardBody>
          </Card>
          {isDeleteConfirmationOpen && owner && projectSlug && project?.project_name && (
            <ProjectDeleteModal
              owner={owner}
              projectSlug={projectSlug}
              projectName={project?.project_name}
              deleteHandler={onDeleteProject}
              closeHandler={onDeleteConfirmationClose}
              open={isDeleteConfirmationOpen}
            />
          )}
        </Stack>
      )}
    </Page>
  )
}

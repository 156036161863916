import { Box, Icon, IconButton, useMultiStyleConfig } from '@chakra-ui/react'

import ContentCopyIcon from '@material-design-icons/svg/sharp/content_copy.svg?react'

import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton'

import { PRE_STYLE } from './codeHighlightTheme'

export function CodeBlock({ children, fontSize, maxHeight }: { children: string; fontSize?: string; maxHeight?: number }) {
  const styles = useMultiStyleConfig('CodeBlock')

  const preStyle = { ...PRE_STYLE, maxHeight }

  return (
    <Box position="relative" border="1px" borderColor="inherit" borderRadius={5} fontSize={fontSize}>
      <CopyToClipboardButton aria-label="copy to clipboard" text={children} __css={styles.copyToClipboard}>
        <IconButton __css={styles.button} aria-label="copy to clipboard" icon={<Icon as={ContentCopyIcon} />} />
      </CopyToClipboardButton>
      <Box>
        <pre style={preStyle}>{children}</pre>
      </Box>
    </Box>
  )
}

import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['jira', 'defect'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getJiraSettings: build.query<GetJiraSettingsApiResponse, GetJiraSettingsApiArg>({
        query: () => ({ url: `/api/v2/jira/settings` }),
        providesTags: ['jira']
      }),
      putJiraSettings: build.mutation<PutJiraSettingsApiResponse, PutJiraSettingsApiArg>({
        query: (queryArg) => ({ url: `/api/v2/jira/settings`, method: 'PUT', body: queryArg.putJiraSettings }),
        invalidatesTags: ['jira']
      }),
      getJiraProjects: build.query<GetJiraProjectsApiResponse, GetJiraProjectsApiArg>({
        query: () => ({ url: `/api/v2/jira/projects` }),
        providesTags: ['jira']
      }),
      getJiraField: build.query<GetJiraFieldApiResponse, GetJiraFieldApiArg>({
        query: (queryArg) => ({ url: `/api/v2/jira/field`, params: { q: queryArg.q, search_url: queryArg.searchUrl } }),
        providesTags: ['jira']
      }),
      getJiraAutocomplete: build.query<GetJiraAutocompleteApiResponse, GetJiraAutocompleteApiArg>({
        query: (queryArg) => ({ url: `/api/v2/jira/autocomplete`, params: { q: queryArg.q, field: queryArg.field } }),
        providesTags: ['jira']
      }),
      postJiraIssue: build.mutation<PostJiraIssueApiResponse, PostJiraIssueApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/defect/${queryArg.defectNumber}/jira-issue`,
          method: 'POST',
          body: queryArg.createJiraIssue
        }),
        invalidatesTags: ['defect']
      }),
      deleteJiraIssue: build.mutation<DeleteJiraIssueApiResponse, DeleteJiraIssueApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/defect/${queryArg.defectNumber}/jira-issue`,
          method: 'DELETE'
        }),
        invalidatesTags: ['defect']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetJiraSettingsApiResponse = /** status 200
    Returns the Jira authentication settings
     */ GetJiraSettings
export type GetJiraSettingsApiArg = void
export type PutJiraSettingsApiResponse = /** status 200
    Updated the Jira authentication settings
     */ GetJiraSettings
export type PutJiraSettingsApiArg = {
  putJiraSettings: PutJiraSettings
}
export type GetJiraProjectsApiResponse = /** status 200
    Returns the Jira projects and issue types
     */ JiraProject[]
export type GetJiraProjectsApiArg = void
export type GetJiraFieldApiResponse = /** status 200
    Proxies a call to Atlassian's APIs to search for valid field values.
     */ JiraUser[]
export type GetJiraFieldApiArg = {
  q: string
  searchUrl: string
}
export type GetJiraAutocompleteApiResponse = /** status 200
    Proxies a call to Atlassian's API to search for autocomplete results.
    See https://confluence.atlassian.com/jiracoreserver0901/advanced-searching-fields-reference-1157491692.html
     */ JiraAutocompleteResult[]
export type GetJiraAutocompleteApiArg = {
  q: string
  field?: ('component' | 'sprint' | 'priority' | 'fixVersion' | 'epic link' | 'affectedVersion' | 'assignee' | 'textarea' | 'parent' | null) | null
}
export type PostJiraIssueApiResponse = /** status 200
    Creates a Jira issue and links it to the specified defect.
     */ CreatedJiraIssue
export type PostJiraIssueApiArg = {
  defectNumber: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  createJiraIssue: CreateJiraIssue
}
export type DeleteJiraIssueApiResponse = /** status 200
    Removes the link between the Jira issue of the specified defect.
     */ Ok
export type DeleteJiraIssueApiArg = {
  defectNumber: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetJiraSettings = {
  created_at?: string
  updated_at?: string
  atlassian_enabled?: boolean
  atlassian_oauth_client_id?: string | null
}
export type ApiError = {
  message?: string
}
export type PutJiraSettings = {
  created_at?: string
  updated_at?: string
  atlassian_enabled?: boolean
  atlassian_oauth_client_secret?: string | null
  atlassian_oauth_client_id?: string | null
}
export type JiraIssueType = {
  created_at?: string
  updated_at?: string
  name: string
  subtask: boolean
  iconUrl?: string
}
export type JiraProject = {
  created_at?: string
  updated_at?: string
  key: string
  avatar: string
  issue_types?: JiraIssueType[]
}
export type JiraAvatar = {
  created_at?: string
  updated_at?: string
  '16x16'?: string
  '24x24'?: string
  '32x32'?: string
  '48x48'?: string
}
export type JiraUser = {
  created_at?: string
  updated_at?: string
  accountId: string
  accountType: string
  active: boolean
  avatarUrls: JiraAvatar
  displayName: string
  emailAddress: string
  locale: string
  self: string
  timeZone: string
}
export type JiraAutocompleteResult = {
  created_at?: string
  updated_at?: string
  value?: string
  display_name: string
}
export type CreatedJiraIssue = {
  created_at?: string
  updated_at?: string
  issue_id: string
  url: string
}
export type JiraIssue = {
  created_at?: string
  updated_at?: string
  project: string
  summary: string
  description?: string
  issuetype: string
}
export type CreateJiraIssue = {
  created_at?: string
  updated_at?: string
  issue: JiraIssue
}
export type Ok = {
  result?: string
}

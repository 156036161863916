import { useRoute } from 'wouter'

import { MenuItem, MenuSection } from './WorkspaceSidebar'

export const getSelectedRoute = (sections: MenuSection[], jumpToSection?: string, scrollSection?: string): MenuItem | null => {
  const matches = sections
    .flatMap((section) => section.routes)
    .map((route) => {
      if (!route.path) {
        return { route, match: false, isPrimary: false }
      }

      // Check primary path match
      const [pathMatch] = useRoute(route.path)

      // Check additional path matches
      const additionalMatches =
        route.extraPathMatches?.map((pattern) => {
          const [extraMatch] = useRoute(pattern)
          return extraMatch
        }) || []
      const additionalMatch = additionalMatches.some(Boolean)

      // Handle scroll matches
      const jumpToMatch = route.scrollTo && jumpToSection === route.scrollTo
      const scrollMatch = route.scrollTo && scrollSection === route.scrollTo

      // Determine the final match and whether it's primary
      const finalMatch = scrollSection
        ? scrollMatch || pathMatch || additionalMatch
        : jumpToSection
          ? jumpToMatch || pathMatch || additionalMatch
          : pathMatch || additionalMatch

      const isPrimary = !!pathMatch

      return {
        route,
        match: finalMatch,
        isPrimary
      }
    })
    .filter(({ match }) => match)

  // Step 2: Prioritize primary matches
  const primaryMatch = matches.find(({ isPrimary }) => isPrimary)

  return primaryMatch ? primaryMatch.route : matches[0]?.route || null
}

import React from 'react'

import { Switch, useColorMode, Icon, HStack } from '@chakra-ui/react'
import LightModeIcon from '@material-design-icons/svg/sharp/light_mode.svg?react'
import DarkModeIcon from '@material-design-icons/svg/sharp/dark_mode.svg?react'

export default function DarkModeToggle(): JSX.Element {
  const { setColorMode } = useColorMode()
  const [theme, setTheme] = React.useState(localStorage.getItem('theme'))

  React.useEffect(() => {
    if (theme) {
      localStorage.setItem('theme', theme)
      document.documentElement.setAttribute('data-theme', theme)
    }
  }, [theme])

  return (
    <HStack spacing={4}>
      <Icon as={LightModeIcon} />
      <Switch
        size="lg"
        isChecked={theme === 'dark'}
        onChange={(event) => {
          const mode = event.target.checked ? 'dark' : 'light'
          // set semantic's theme
          setTheme(mode)
          // set chakra's color mode
          setColorMode(mode)
        }}
      />
      <Icon as={DarkModeIcon} />
    </HStack>
  )
}

import { Flex, Stack, Text } from '@chakra-ui/react'

import { DefectsTag } from './DefectsTag'

export interface DefectsBySeverityStackProps {
  high: number
  medium: number
  low: number
}

export function DefectsBySeverityStack({ high, medium, low }: DefectsBySeverityStackProps) {
  return (
    <Stack>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Text fontSize="sm" m={0}>
          High
        </Text>
        <DefectsTag severity="High" count={high} />
      </Flex>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Text fontSize="sm" m={0}>
          Med
        </Text>
        <DefectsTag severity="Medium" count={medium} />
      </Flex>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Text fontSize="sm" m={0}>
          Low
        </Text>
        <DefectsTag severity="Low" count={low} />
      </Flex>
    </Stack>
  )
}

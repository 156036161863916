import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Flex, Heading, Stack, Text } from '@chakra-ui/react'

import { getWorkspacePageTitle } from '../../titles'
import { Page } from '../../components/Page'

import { CreateApiRunCard } from '../workspace-zerostatedashboard/CreateApiRunCard'
import { CreateCodeRunCard } from '../workspace-zerostatedashboard/CreateCodeRunCard'
import { CreateDSBOMCard } from '../workspace-zerostatedashboard/CreateDSBOMCard'

import { setMruWorkspace } from './mru.slice'

type Props = { workspace: string }

export function WorkspaceGettingStartedPage({ workspace }: Props) {
  const dispatch = useDispatch()
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Getting Started')
    dispatch(setMruWorkspace(workspace))
  }, [workspace, dispatch])

  return (
    <Page>
      <Stack spacing={16}>
        <Heading>Dashboard</Heading>
        <Stack>
          <Heading>Get Started with Mayhem</Heading>
          <Text maxWidth="5xl">
            Mayhem uses runtime intelligence to give you accurate, actionable vulnerability reports on your Apps and APIs. Build a runtime profile and
            use it to filter down SCA and SBOM results, or use self-learning behavior testing for zero-false positive vulnerability detection.
          </Text>
        </Stack>

        <Flex gap={8} direction={{ base: 'column', '2xl': 'row' }}>
          <Stack spacing={4} width={{ base: 'full', xl: 'auto' }}>
            <Heading size="md">Find Reachable Vulnerabilities</Heading>
            <CreateDSBOMCard workspace={workspace} width={{ base: 'full', xl: 'sm', '3xl': 'lg' }} />
          </Stack>
          <Stack spacing={4}>
            <Heading size="md">Find Exploitable Vulnerabilities</Heading>
            <Flex gap={4} direction={{ base: 'column', xl: 'row' }}>
              <CreateApiRunCard workspace={workspace} width={{ base: 'full', xl: 'sm', '3xl': 'lg' }} />
              <CreateCodeRunCard workspace={workspace} width={{ base: 'full', xl: 'sm', '3xl': 'lg' }} />
            </Flex>
          </Stack>
        </Flex>
      </Stack>
    </Page>
  )
}

import { useState } from 'react'
import { Flex, Text, useRadioGroup } from '@chakra-ui/react'

import { RadioCard } from '../../components/RadioCard'
import { DurationInput } from '../../components/DurationInput'

export type ApiDuration = 'auto' | number
export interface ApiDurationOptionsProps {
  value?: ApiDuration
  onChange: (value: ApiDuration) => void
}

export const ApiDurationOptions = ({ value, onChange }: ApiDurationOptionsProps) => {
  const [type, setType] = useState(typeof value === 'number' ? 'custom' : 'auto')
  const [customValue, setCustomValue] = useState(typeof value === 'number' ? value : 0)
  const onTypeChange = (type: 'auto' | 'custom') => {
    switch (type) {
      case 'auto':
        setType('auto')
        onChange('auto')
        break
      default:
      case 'custom':
        setType('custom')
        onChange(customValue)
        break
    }
  }
  const onCustomChange = (value?: number) => {
    setType('custom')
    setCustomValue(value || 0)
    onChange(value || 0)
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    value: type,
    defaultValue: 'auto',
    onChange: onTypeChange
  })
  const root = getRootProps()

  return (
    <Flex direction="row" alignItems="stretch" gap={4} flexWrap="wrap" {...root}>
      <RadioCard title="Auto" flex="1 0 min-content" radio={getRadioProps({ value: 'auto' })}>
        <Text fontSize="md">Try each endpoint up to 100 times</Text>
      </RadioCard>
      <RadioCard title="Custom" flex="1 0 min-content" radio={getRadioProps({ value: 'custom' })}>
        <DurationInput value={customValue} onChange={onCustomChange} />
      </RadioCard>
    </Flex>
  )
}

import * as Sentry from '@sentry/react'
import { ErrorBoundaryProps } from '@sentry/react'

import { ResponseError } from '../api'

import { ErrorPage } from './layout/ErrorPage'

export function ErrorBoundary(props: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => {
        // If a response error, pull the code from the status
        const code = (error as ResponseError<unknown>)?.response?.status

        return <ErrorPage errorMessage={(error as Error)?.message} errorCode={code} resetError={resetError} goBackLocation="/" />
      }}
      {...props}
    />
  )
}

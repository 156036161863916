import { Box, CircularProgressLabel } from '@chakra-ui/react'

import { PercentProgressStatusIndicator } from '@/components/PercentProgressStatusIndicator/PercentProgressStatusIndicator'

interface Props {
  nTestcaseReports: number
  nCrashReports: number
}

export function RunCodeTestIndicator({ nTestcaseReports, nCrashReports }: Props) {
  const nPassingTestReports = nTestcaseReports - nCrashReports

  const percent = Math.round((nPassingTestReports / nTestcaseReports) * 100)

  if (!nTestcaseReports) {
    return '-'
  }

  return (
    <PercentProgressStatusIndicator percent={percent} size={20}>
      <CircularProgressLabel fontSize={12} marginTop={-2}>
        {nPassingTestReports}
      </CircularProgressLabel>
      <CircularProgressLabel>
        <Box border="1px solid gray" width={6} margin="0 auto" />
      </CircularProgressLabel>
      <CircularProgressLabel fontSize={12} marginTop={2}>
        {nTestcaseReports}
      </CircularProgressLabel>
    </PercentProgressStatusIndicator>
  )
}

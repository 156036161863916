import { useEffect } from 'react'

import { getRunPageTitle } from '../../titles'
import { RunApiPage } from '../run-api/RunApiPage'
import MayhemTableLoader from '../../components/MayhemTableLoader'
import { ErrorPanel } from '../../components/ErrorPanel'
import { useGetTargetQuery } from '../../redux/api/targets'
import { RunCodePage } from '../run-code/RunCodePage'
import { useGetRunQuery } from '../../redux/api/runs'

interface Props {
  workspace: string
  project: string
  target: string
  runNumber: number
}

export function RunPage({ workspace, project: projectSlug, target: targetSlug, runNumber }: Props) {
  const { isLoading: isTargetLoading, data: target } = useGetTargetQuery({ owner: workspace, projectSlug, targetSlug })
  const { isLoading: isRunLoading, data: run } = useGetRunQuery({ owner: workspace, projectSlug, targetSlug, runNumber })

  useEffect(() => {
    document.title = getRunPageTitle(workspace, projectSlug, targetSlug, runNumber.toString())
  }, [workspace, projectSlug, targetSlug, runNumber])

  if (isTargetLoading || isRunLoading) {
    return <MayhemTableLoader />
  }

  if (!target || !run) {
    return <ErrorPanel msg="We can't find that run for you. Sorry about that!" />
  }

  if (target.type === 'code') {
    return <RunCodePage owner={workspace} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />
  }

  if (target.type === 'api') {
    return <RunApiPage owner={workspace} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} runId={run.run_id} />
  }
}

import { useEffect } from 'react'
import { Link } from 'wouter'
import { Box, Spinner, Text } from '@chakra-ui/react'

import { getResetPasswordPageTitle } from '../../titles'
import { useCheckPasswordResetTokenQuery } from '../../redux/api/token'
import { useAppSelector } from '../../hooks'

import AuthLayout from './AuthLayout'
import { ResetPasswordForm } from './ResetPasswordForm'

type Params = {
  token: string
}

export function ResetPasswordPage({ token }: Params) {
  const { data: resetPasswordCheckSuccess } = useCheckPasswordResetTokenQuery({ resetToken: token })
  const passwordReset = useAppSelector((state) => state.resetPassword.passwordReset)

  useEffect(() => {
    document.title = getResetPasswordPageTitle()
  })

  let content = <Spinner />

  if (resetPasswordCheckSuccess !== undefined || passwordReset) {
    if (passwordReset) {
      content = (
        <Box>
          <Box>Password successfully updated!</Box>
          <Text>
            Please <Link to="/-/sign-in">Sign In</Link>.
          </Text>
        </Box>
      )
    } else if (resetPasswordCheckSuccess) {
      content = <ResetPasswordForm token={token} />
    } else {
      content = (
        <Box>
          <Text>
            Unable to set password, please <Link to="/-/forgot-password">request a password reset</Link> and try again.
          </Text>
          <Text>Otherwise, contact your administrator.</Text>
        </Box>
      )
    }
  }

  return <AuthLayout title="Set Your Password">{content}</AuthLayout>
}

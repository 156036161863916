import { Avatar, GridItem, HStack, Icon, Text, Tooltip } from '@chakra-ui/react'

import ClockIcon from '@material-design-icons/svg/sharp/timer.svg?react'

import { parseApiDate, prettyTimeAgo, prettyFullTimeString } from '../../util/time'

import RunRowCIDisplay from '../runs/RunRowCIDisplay'

import { formatRunDuration } from './utils'

export interface RunHeaderBylineProps {
  createdAt: string
  startedAt: string | null
  startedBy: string
  endedAt: string | null
  duration: number | null
  branchName: string | undefined
  revision: string | undefined
  isCIRun: boolean
  ciUrl: string | undefined
  isApiRun?: boolean
}

export const RunHeaderByline = ({
  createdAt,
  startedAt,
  startedBy,
  endedAt,
  duration,
  branchName,
  revision,
  isCIRun,
  ciUrl,
  isApiRun = false
}: RunHeaderBylineProps) => {
  // time display
  const createdDate = parseApiDate(createdAt)
  const endedDate = parseApiDate(endedAt)
  const dateToDisplay = endedAt ? endedDate : createdDate
  const dateLabelToDisplay = endedAt ? 'Completed' : 'Started'
  const timeDisplay = `${dateLabelToDisplay} ${prettyTimeAgo(dateToDisplay)}`
  const durationDisplay = formatRunDuration(startedAt, endedAt, isApiRun ? 'api' : 'code', duration)
  const timeTooltipText = `Started ${prettyFullTimeString(createdAt || '')}\n${
    endedAt ? `Completed ${prettyFullTimeString(endedAt || '')}` : ''
  }\nDuration: ${durationDisplay}`

  return (
    <GridItem gridArea="info">
      <HStack gap={4} divider={<Text>&middot;</Text>} wrap="wrap">
        <HStack gap={1}>
          <Avatar name={startedBy} bg="gray.200" color="gray.900" size="xs" />
          <Text fontSize="sm">{startedBy === 'mayhem-bot' ? 'Mayhem Bot' : startedBy}</Text>
        </HStack>
        <Tooltip label={timeTooltipText} maxW="96" whiteSpace="pre-line">
          <HStack gap={1}>
            <Icon as={ClockIcon} />
            <Text fontSize="sm">{timeDisplay}</Text>
          </HStack>
        </Tooltip>
        {isCIRun && <RunRowCIDisplay ciUrl={ciUrl} branchName={branchName || ''} revision={revision || ''} color="primaryText" />}
      </HStack>
    </GridItem>
  )
}

import { Icon, IconProps } from '@chakra-ui/react'

export function CheckIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 18" {...props}>
      <path
        d="M7.47217 17.5456L0.322169 9.74558C-0.10739 9.27697 -0.10739 8.51718 0.322169 8.04852L1.87777 6.35146C2.30733 5.8828 3.00385 5.8828 3.43341 6.35146L8.24999 11.6059L18.5666 0.351457C18.9961 -0.117152 19.6927 -0.117152 20.1222 0.351457L21.6778 2.04852C22.1074 2.51713 22.1074 3.27693 21.6778 3.74558L9.02781 17.5456C8.59821 18.0142 7.90173 18.0142 7.47217 17.5456Z"
        fill="currentColor"
      />
    </Icon>
  )
}

import { useEffect, useMemo, useState } from 'react'

import { useLocation } from 'wouter'

import dayjs from 'dayjs'

import { FilterDropdown, FilterDropdownItem } from '@/components/Combobox/FilterDropdown'
import { useQuery } from '@/hooks'
import { setQueryParams } from '@/util/location'

import { DATE_PERIODS } from '@/util/time'

const DATE_PERIOD_OPTIONS = DATE_PERIODS.map((option) => ({ id: option.replaceAll(' ', '_').toLowerCase(), value: option }))

interface Props {
  defaultInputPlaceholder: string
  inputItems?: FilterDropdownItem[]
}

export default function DatePeriodFilterDropdown({ defaultInputPlaceholder, inputItems = DATE_PERIOD_OPTIONS }: Props) {
  const [location, setLocation] = useLocation()
  const query = useQuery()

  const startDateParam = query.get('startDate')
  const endDateParam = query.get('endDate')

  const [selectedItems, setSelectedItems] = useState<FilterDropdownItem[]>([])

  const selectedPeriod = useMemo(() => selectedItems?.[0]?.value, [selectedItems])

  useEffect(() => {
    if (startDateParam == endDateParam && dayjs(startDateParam).isSame(dayjs().startOf('day'))) {
      setSelectedItems(inputItems.filter(({ value }) => value === 'Today'))
    } else {
      const parsedStartDateParam = dayjs(startDateParam)
      const parsedEndDateParam = dayjs(endDateParam)

      if (parsedEndDateParam.isSame(dayjs().startOf('day'))) {
        if (parsedEndDateParam.subtract(7, 'days').startOf('week').isSame(parsedStartDateParam)) {
          setSelectedItems(inputItems.filter(({ value }) => value === 'Past Week'))
        } else if (parsedEndDateParam.subtract(1, 'month').startOf('month').isSame(parsedStartDateParam)) {
          setSelectedItems(inputItems.filter(({ value }) => value === 'Past Month'))
        }
      }
    }
  }, [startDateParam, endDateParam, inputItems])

  useEffect(() => {
    let startDate
    let endDate

    if (selectedPeriod === 'Today') {
      startDate = endDate = dayjs()
    } else if (selectedPeriod === 'Past Week') {
      startDate = dayjs().subtract(7, 'days').startOf('week')
      endDate = dayjs()
    } else if (selectedPeriod === 'Past Month') {
      startDate = dayjs().subtract(1, 'month').startOf('month')
      endDate = dayjs()
    }

    setLocation(
      setQueryParams({
        location,
        queryParams: query,
        params: [
          { param: 'page', value: undefined },
          { param: 'startDate', value: startDate?.format('YYYY-MM-DD') },
          { param: 'endDate', value: endDate?.format('YYYY-MM-DD') }
        ]
      }),
      { replace: true }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedPeriod, setLocation])

  return (
    <FilterDropdown
      selectedItems={selectedItems}
      onSelectedItemsChange={(items) => setSelectedItems(items)}
      inputItems={inputItems}
      defaultInputPlaceholder={defaultInputPlaceholder}
    />
  )
}

import { extendTheme, withDefaultColorScheme, theme as baseTheme } from '@chakra-ui/react'

import { brand, purple, gray, error, success, warning, severityMedium, severityLow } from './primaryColors'
import {
  blue,
  blueDark,
  blueLight,
  cyan,
  fuchsia,
  green,
  greenLight,
  indigo,
  moss,
  orange,
  orangeDark,
  pink,
  rose,
  teal,
  violet,
  yellow
} from './secondaryColors'
import { Autocomplete } from './components/autocomplete'
import { Badge } from './components/badge'
import { Button } from './components/button'
import { CardRow } from './components/cardRow'
import { Card } from './components/card'
import { CodeBlock } from './components/codeBlock'
import { FuzzingLoader } from './components/fuzzingLoader'
import { semanticTokens } from './semanticTokens'
import { Heading } from './components/heading'
import { HoverTableRow } from './components/hoverTableRow'
import { Menu } from './components/menu'
import { ProgressIndicator } from './components/progressIndicator'
import { Search } from './components/search'
import { Stat } from './components/stat'
import { Alert } from './components/alert'
import { Table } from './components/table'
import { Tabs } from './components/tabs'
import { fonts } from './fonts'
import { Link } from './components/link'
import { styles } from './styles'
import { DefectTrendChart } from './components/defectTrendChart'
import { Modal } from './components/modal'
import { SearchResultRow } from './components/searchResultRow'
import { Chart } from './components/chart'
import { Stepper } from './components/stepper'

const components = {
  Alert,
  Autocomplete,
  Badge,
  Button,
  Card,
  CardRow,
  Chart,
  CodeBlock,
  DefectTrendChart,
  FuzzingLoader,
  Heading,
  HoverTableRow,
  Link,
  Menu,
  ProgressIndicator,
  Search,
  Stat,
  Table,
  Tabs,
  Modal,
  SearchResultRow,
  Stepper
}

const colors = {
  brand,
  purple,
  gray,
  error,
  success,
  warning,
  moss,
  greenLight,
  green,
  teal,
  cyan,
  blueLight,
  blue,
  blueDark,
  indigo,
  violet,
  fuchsia,
  pink,
  rose,
  orangeDark,
  orange,
  yellow,
  severityLow,
  severityMedium,
  severityHigh: error,
  good: success,
  bad: error
}

// adds 3xl and 4xl sizes for big screens
const breakpoints = {
  base: '0em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
  '3xl': '120em',
  '4xl': '144em'
}

export const theme = extendTheme(
  {
    semanticTokens,
    components,
    colors,
    fonts,
    styles,
    breakpoints,
    initialColorMode: 'light',
    useSystemColorMode: true
  },
  withDefaultColorScheme({
    colorScheme: 'brand',
    components: Object.keys(baseTheme.components || {}).filter((name) => name !== 'Link')
  })
)

import { ComponentStyleConfig } from '@chakra-ui/react'

import { chartAnatomy } from '../../components/chart/Chart/anatomy'

export const Chart: ComponentStyleConfig = {
  parts: chartAnatomy.keys,
  baseStyle: {
    tooltip: {
      bg: 'chakra-body-bg',
      borderRadius: 'md',
      border: 1,
      padding: 2
    }
  }
}

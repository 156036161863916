import { Heading, Stack } from '@chakra-ui/react'

import { CardDefectTrend } from '../../components/CardDefectTrend'

import { TargetRunsList } from './TargetRunsList'
import { TargetDefects } from './TargetDefects'
import { TargetOverview } from './TargetOverview'

interface Props {
  workspace: string
  project: string
  target: string
}

export function TargetOverviewPage({ workspace, project, target }: Props) {
  return (
    <Stack>
      <Stack>
        <TargetOverview owner={workspace} projectSlug={project} targetSlug={target} />
        <CardDefectTrend workspace={workspace} project={project} target={target} />
      </Stack>

      <Heading size="md" margin={4} marginTop={12}>
        Defects
      </Heading>
      <TargetDefects owner={workspace} projectSlug={project} targetSlug={target} />

      <Heading size="md" margin={4} marginTop={12}>
        Runs
      </Heading>
      <TargetRunsList owner={workspace} projectSlug={project} targetSlug={target} />
    </Stack>
  )
}

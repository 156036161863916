import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'

import { CardRow } from '../../components/Card/CardRow'
import PassFailCheck, { Result } from '../../components/PassFailCheck'

interface Props {
  type: string
  checked?: boolean
  action: React.ReactNode
  description: React.ReactNode
  taskFailed: boolean
}

export function ExploitabilityFactorsListRow({ type, checked, action, description, taskFailed }: Props) {
  const result = !taskFailed && checked !== undefined ? (checked ? Result.PASS : Result.FAIL) : Result.NONE

  return (
    <CardRow disabled padding={0}>
      <Grid templateColumns={{ base: '100%', md: '20% 25% 25% 30%' }} alignItems="center" justifyItems="center" gap={{ base: 4, md: 0 }}>
        <GridItem width="full" textAlign={{ base: 'center', md: 'left' }} paddingLeft={4}>
          <Text>{type}</Text>
        </GridItem>
        <GridItem>
          <PassFailCheck result={result} size="big" />
        </GridItem>
        <GridItem width="full" textAlign={{ base: 'center', md: 'left' }}>
          <Text>{action}</Text>
        </GridItem>
        <GridItem p={2}>
          <Text>{description}</Text>
        </GridItem>
      </Grid>
    </CardRow>
  )
}

import { useMemo } from 'react'
import { Card, SimpleGrid, Stat, StatLabel, StatNumber, HStack, Tag, SkeletonText } from '@chakra-ui/react'

import TooltippedStatNumber from '../../components/TooltippedStatNumber'
import { useGetDefectTrendQuery } from '../../redux/api/defects'
import { useGetProjectInfoQuery } from '../../redux/api/projects'
import { DefectsBySeverityStack } from '../defect-tag/DefectsBySeverityStack'

type Props = { owner: string; projectSlug: string }

export function ProjectOverviewStats(props: Props) {
  const { owner, projectSlug } = props

  const { data: projectStats, isFetching: isProjectStatsFetching } = useGetProjectInfoQuery({ owner: owner, projectSlug: projectSlug })
  const timestamp = useMemo(() => {
    const timestampInSeconds = Math.floor(new Date().getTime() / 1000)
    return timestampInSeconds - (timestampInSeconds % 15)
  }, [])

  const { isFetching: isDefectsStatsFetching, data: trend } = useGetDefectTrendQuery({
    owner: owner,
    project: projectSlug,
    windowStartTimestampSeconds: timestamp,
    windowEndTimestampSeconds: timestamp,
    // step shouldn't matter here, we are trying to get exactly 1 point
    windowStepSeconds: 1
  })
  const defectStats = (trend?.trend || [])[0]

  const areAllTargetsApi = projectStats?.target_counts?.code === 0

  const isLoaded = !(isProjectStatsFetching || isDefectsStatsFetching)
  return (
    <SimpleGrid columns={{ base: 1, sm: 2 }} gap={8}>
      <Card p={8}>
        <SkeletonText isLoaded={isLoaded} noOfLines={4} skeletonHeight={2} spacing={5}>
          <Stat border={0}>
            <StatLabel>Fixed Defects</StatLabel>
            {defectStats && (
              <DefectsBySeverityStack high={defectStats.fixed_high || 0} medium={defectStats.fixed_medium || 0} low={defectStats.fixed_low || 0} />
            )}
          </Stat>
        </SkeletonText>
      </Card>
      <Card p={8}>
        <SkeletonText isLoaded={isLoaded} noOfLines={4} skeletonHeight={2} spacing={5}>
          <Stat border={0}>
            <StatLabel>Remaining Defects</StatLabel>
            {defectStats && (
              <DefectsBySeverityStack high={defectStats.active_high || 0} medium={defectStats.active_medium || 0} low={defectStats.active_low || 0} />
            )}
          </Stat>
        </SkeletonText>
      </Card>
      <Card p={8}>
        <SkeletonText isLoaded={isLoaded} noOfLines={4} skeletonHeight={2} spacing={5}>
          <Stat border={0}>
            <StatLabel>Passing Tests</StatLabel>
            {areAllTargetsApi ? (
              <StatLabel>N/A</StatLabel>
            ) : (
              <>
                <HStack>
                  <StatNumber>{projectStats?.latest_targets_passing_tests}</StatNumber>
                  <StatLabel> out of {projectStats?.targets} targets</StatLabel>
                </HStack>
                <Tag>
                  {projectStats &&
                    (projectStats.targets == 0
                      ? 100
                      : Math.round(((projectStats.latest_targets_passing_tests || 0) / projectStats.targets) * 100.0)) + '%'}
                </Tag>
              </>
            )}
          </Stat>
        </SkeletonText>
      </Card>
      <Card p={8}>
        <SkeletonText isLoaded={isLoaded} noOfLines={4} skeletonHeight={2} spacing={5}>
          <Stat border={0}>
            <StatLabel>Total targets</StatLabel>
            <TooltippedStatNumber value={projectStats?.targets} />
          </Stat>
        </SkeletonText>
      </Card>
    </SimpleGrid>
  )
}

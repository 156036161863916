import { useEffect, useMemo, useState } from 'react'
import { Divider, Grid, Heading, Skeleton, Stack, Text } from '@chakra-ui/react'
import { Area } from 'recharts'

import dayjs from 'dayjs'

import { Chart } from '../../components/chart/Chart/Chart'
import { chartAxisTime, formatTimeAxisTick } from '../../components/chart/Chart/chartAxisTime'
import { chartAxisPercentage } from '../../components/chart/Chart/chartAxisPercentage'
import { chartTooltip } from '../../components/chart/Chart/chartTooltip'
import { formatPercentage } from '../../util/numbers'

import { useWorkerUsageReport, WorkerUsageReport } from './useWorkerUsageReport'

export function WorkerUsageChart() {
  // Current unix timestamp (in seconds) aligned to 10-minute intervals.
  const [endTimestamp, setEndTimestamp] = useState(() => getAlignedEnd(600))

  //
  const startTimestamp = useMemo(() => {
    return Math.floor(
      dayjs(endTimestamp * 1000)
        .startOf('day')
        .add(-30, 'day')
        .valueOf() / 1000
    )
  }, [endTimestamp])

  // Attempt to update the 'end' variable every second.
  // The actual value changes every 10 minutes which will trigger
  // an HTTP request and a chart update.
  useEffect(() => {
    const interval = setInterval(() => {
      setEndTimestamp(getAlignedEnd(600))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [setEndTimestamp])

  const { isLoading, data, pools } = useWorkerUsageReport({
    sampleFromSeconds: startTimestamp,
    sampleToSeconds: endTimestamp,
    sampleIntervalSeconds: 60 * 60
  })

  if (isLoading) {
    return <Skeleton height={4} />
  }

  return (
    <Chart records={data}>
      {chartAxisTime({ field: 'timestamp', start: startTimestamp * 1000, end: endTimestamp * 1000, unit: 'day', step: 1 })}
      {chartAxisPercentage({
        field: 'up_percent',
        step: 0.005,
        base: 1,
        max: Math.min(1, data.reduce((max, record) => Math.max(max, record.global.up_percent), 0) * 1.2)
      })}
      <Area type="monotone" dataKey="default.global_up_percent" stackId="1" fill="var(--chakra-colors-chartSeries1)" />
      {pools.map((pool, i) => (
        <Area
          key={pool.worker_pool_id}
          type="monotone"
          dataKey={`pools.${pool.worker_pool_id}.global_up_percent`}
          stackId="1"
          fill={`var(--chakra-colors-chartSeries${((i + 1) % 2) + 1})`}
        />
      ))}
      {chartTooltip<WorkerUsageReport>(WorkerUsageChartTooltip)}
    </Chart>
  )
}

export interface WorkerUsageChartTooltipProps {
  record: WorkerUsageReport
}
export function WorkerUsageChartTooltip({ record }: WorkerUsageChartTooltipProps) {
  return (
    <Stack gap={2}>
      <Heading fontSize="md">{formatTimeAxisTick(record.timestamp, 'day')}</Heading>
      <Divider />
      <Grid gridTemplateColumns="1fr auto" gap={4}>
        <Text as="strong">Total:</Text>
        <Text as="span" sx={{ textAlign: 'right' }}>
          {formatPercentage(record.global.up_percent, { base: 1 })}
        </Text>

        <Text as="strong">Manual:</Text>
        <Text as="span" sx={{ textAlign: 'right' }}>
          {formatPercentage(record.global.up_manual_percent, { base: 1 })}
        </Text>

        <Text as="strong">CI:</Text>
        <Text as="span" sx={{ textAlign: 'right' }}>
          {formatPercentage(record.global.up_ci_percent, { base: 1 })}
        </Text>

        <Text as="strong">Background:</Text>
        <Text as="span" sx={{ textAlign: 'right' }}>
          {formatPercentage(record.global.up_background_percent, { base: 1 })}
        </Text>
      </Grid>
    </Stack>
  )
}

function getAlignedEnd(refreshRateSeconds = 600) {
  const now = new Date()
  const nowSeconds = Math.floor(now.getTime() / 1000)
  return nowSeconds - (nowSeconds % refreshRateSeconds)
}

import { useEffect } from 'react'
import { Link as WouterLink } from 'wouter'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Stack } from '@chakra-ui/react'

import { skipToken } from '@reduxjs/toolkit/query'

import { getTeamPageTitle } from '../../titles'

import { Page } from '../../components/Page'

import { useGetOrgMemberQuery, useWhoamiQuery } from '../../redux/api/workspace'

import { TeamMembersCard } from './TeamMembersCard'
import { AddMembersCard } from './AddMembersCard'

type Props = {
  workspace: string
  team: string
}

export function TeamPage({ workspace, team: teamSlug }: Props) {
  const { data: profile } = useWhoamiQuery()
  const { data: currentMember } = useGetOrgMemberQuery(
    !profile
      ? skipToken
      : {
          owner: workspace,
          userSlug: profile.slug as string
        }
  )

  const userCanAddMembersToTeam = !!profile?.is_admin || !!currentMember?.owns

  useEffect(() => {
    document.title = getTeamPageTitle(workspace, teamSlug)
  }, [workspace, teamSlug])

  return (
    <Page>
      <Stack spacing={8}>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={WouterLink} to={`/${workspace}/-/settings`}>
              Workspace Settings
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{teamSlug}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        {userCanAddMembersToTeam && <AddMembersCard workspace={workspace} team={teamSlug} />}
        <TeamMembersCard workspace={workspace} teamSlug={teamSlug} />
      </Stack>
    </Page>
  )
}

import { ComponentStyleConfig } from '@chakra-ui/react'
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

export const SearchResultRow: ComponentStyleConfig = {
  baseStyle: (props: StyleFunctionProps) => ({
    width: '100%',
    padding: '3',
    marginY: '2',
    border: '2px',
    borderRadius: 'md',
    _hover: {
      cursor: 'pointer'
    },
    borderColor: mode('gray.200', 'whiteAlpha.300')(props),
    _highlighted: {
      borderColor: mode(`brand.500`, `blue.300`)(props)
    }
  })
}

import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'

import { useEffect } from 'react'

import { useAppDispatch } from '../../hooks'

import { CreateOrganizationForm } from '../organization/CreateOrganizationForm'

import { getCreateWorkspacePageTitle } from '../../titles'

export const CreateWorkspacePage = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    document.title = getCreateWorkspacePageTitle()
  }, [dispatch])

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Create a new Workspace</Heading>
      </CardHeader>
      <CardBody p={4}>
        <CreateOrganizationForm />
      </CardBody>
    </Card>
  )
}

import { Avatar, AvatarProps } from '@chakra-ui/react'
import { md5 } from 'js-md5'

export type UserInfo = { email?: string; username: string }

type GravatarProps = AvatarProps & { userinfo?: UserInfo }

/**
 * Augments Chakra's Avatar component with Gravatar images.
 */
export function Gravatar(props: GravatarProps): JSX.Element {
  const { userinfo } = props
  const { email, username } = userinfo || {}
  // the Gravatar api expects emails to be hashed
  // see https://en.gravatar.com/site/implement/images/
  const hashedEmail = email && md5(email.trim())
  const gravatarUrl = hashedEmail && `https://www.gravatar.com/avatar/${hashedEmail}?d=404`

  return <Avatar name={username} src={gravatarUrl} {...props} />
}

import { Box, GridItem, SimpleGrid, Spinner, Text } from '@chakra-ui/react'

function StatValue({ value, smallValues }: { value?: string | number | JSX.Element; smallValues?: boolean }) {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'undefined' ? (
    <Text noOfLines={1} fontSize={smallValues ? '1.5rem' : '2.25rem'}>
      {value || 0}
    </Text>
  ) : (
    value
  )
}

export function MayhemStats({ stats = [], isFetching = false, smallValues = false, padded = false, stackedIcons = false }: Props) {
  return (
    <SimpleGrid
      columns={{ base: 2, md: stats?.length >= 4 ? 4 : stats.length }}
      padding={padded ? 4 : 0}
      flexDirection={stackedIcons ? 'column' : 'row'}
    >
      {stats.map((stat) => (
        <GridItem key={stat.key || `stats_${stat.label}_${stat.value}`} display="flex" alignItems="center" color={stat.color} data-testid={stat.key}>
          {stat.icon}
          <Box>
            {isFetching ? <Spinner /> : <StatValue value={stat.value} smallValues={smallValues} />}
            <Text>{stat.label}</Text>
          </Box>
        </GridItem>
      ))}
    </SimpleGrid>
  )
}

export interface MHStatType {
  label: string | JSX.Element
  value?: string | number | JSX.Element
  color?: string
  icon?: JSX.Element
  key?: string
}

export interface Props {
  stats: Array<MHStatType>
  isFetching?: boolean
  smallValues?: boolean
  padded?: boolean
  stackedIcons?: boolean
}

export function getGraphColors(): Array<string> {
  return [
    'var(--chakra-colors-brand-900)',
    'var(--chakra-colors-brand-800)',
    'var(--chakra-colors-brand-700)',
    'var(--chakra-colors-brand-600)',
    'var(--chakra-colors-brand-500)',
    'var(--chakra-colors-brand-400)',
    'var(--chakra-colors-brand-300)',
    'var(--chakra-colors-brand-200)',
    'var(--chakra-colors-brand-100)',
    'var(--chakra-colors-brand-50)',
    'var(--chakra-colors-brand-25)'
  ]
}

import { Heading, HStack, Icon, Link } from '@chakra-ui/react'
import RightArrowIcon from '@material-design-icons/svg/sharp/keyboard_arrow_right.svg?react'

import { Link as WouterLink } from 'wouter'

import { getDefectIcon } from '../defect-report/utils'

interface Props {
  defectPageUrl: string
  cweNumber: number | null | undefined
  title: string
}

export function RunApiDefectPreviewTitle({ defectPageUrl, cweNumber, title }: Props) {
  return (
    <WouterLink to={defectPageUrl}>
      <Link maxW="90%">
        <HStack>
          <Icon as={getDefectIcon(cweNumber ?? undefined)} boxSize={8} />
          <HStack gap={0}>
            <Heading variant="cardHeading" display="inline-block" noOfLines={1} wordBreak="break-all" textOverflow="ellipsis">
              {title}
            </Heading>
            <Icon as={RightArrowIcon} boxSize={8} />
          </HStack>
        </HStack>
      </Link>
    </WouterLink>
  )
}

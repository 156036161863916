import { Badge, Tooltip } from '@chakra-ui/react'

export type SeverityLevel = 'HIGH' | 'MEDIUM' | 'LOW'

export type SeverityLevelLowerCase = 'low' | 'medium' | 'high'

interface DefectSeverityBadgeProps {
  severityLevel?: SeverityLevelLowerCase
  severity?: number | null
}

export function DefectSeverityBadge({ severityLevel = 'low', severity }: DefectSeverityBadgeProps) {
  const severityColor = severityLevel === 'high' ? 'severityHigh' : severityLevel === 'medium' ? 'severityMedium' : 'severityLow'
  return (
    <Tooltip
      label={severity === null ? 'Unknown Severity Rating' : `Severity Level: ${severityLevel}, Severity Rating: ${severity}`}
      textTransform="capitalize"
    >
      <Badge variant="solid" colorScheme={severityColor} zIndex={1}>
        {severityLevel} ({severity !== null ? severity : '*'})
      </Badge>
    </Tooltip>
  )
}

import {
  Button,
  Link as ChakraLink,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalHeader,
  useDisclosure,
  Image,
  Text,
  ModalFooter
} from '@chakra-ui/react'

export function SSOButton(props: { logoSrc: string; ssoProvider: string }) {
  const { logoSrc, ssoProvider } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant="outline" width="100%" mb={4} onClick={onOpen} leftIcon={<Image src={logoSrc} alt={ssoProvider} height={4} />}>
        {`Continue with ${ssoProvider}`}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms of Service</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              By proceeding with {ssoProvider} auth, you agree to our{' '}
              <ChakraLink
                textDecoration="underline"
                href="https://www.mayhem.security/saas-license-agreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </ChakraLink>
            </Text>
          </ModalBody>
          <ModalFooter>
            <a href={`/api/v2/oauth2/${ssoProvider.toLowerCase()}/login`}>
              <Button variant="outline" leftIcon={<Image src={logoSrc} alt={ssoProvider} height={4} mr={2} />}>
                {`Login with ${ssoProvider}`}
              </Button>
            </a>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

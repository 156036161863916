import { DockerImageConfig, DockerImageMetadata } from './DockerImageManifest'

export function parseDockerImageConfig(imageConfig: Partial<DockerImageConfig>): DockerImageMetadata {
  return {
    command: parseCommand(imageConfig),
    network: parseNetwork(imageConfig)
  }
}

function parseCommand(imageConfig: Partial<DockerImageConfig>) {
  return imageConfig.config?.Cmd?.join(' ')
}

function parseNetwork(imageConfig: Partial<DockerImageConfig>) {
  const exposedPort = Object.keys(imageConfig.config?.ExposedPorts || {}).find(() => true)
  if (exposedPort) {
    const [port, protocol = 'tcp'] = exposedPort.split('/')
    return {
      url: `${protocol}://localhost:${port}`
    }
  }

  return undefined
}

import { Box, Icon, Tooltip } from '@chakra-ui/react'
import CancelIcon from '@material-design-icons/svg/sharp/cancel.svg?react'
import CheckCircleIcon from '@material-design-icons/svg/sharp/check_circle.svg?react'
import ErrorIcon from '@material-design-icons/svg/sharp/error.svg?react'

export enum Result {
  PASS = 'pass',
  FAIL = 'fail',
  NONE = 'none'
}

export type SizeProp = 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive'

const SIZE_VALUES = {
  mini: 2,
  tiny: 3,
  small: 4,
  large: 6,
  big: 8,
  huge: 16,
  massive: 32
}

export default function PassFailCheck({ result = Result.NONE, size }: { result: Result; size?: SizeProp }): JSX.Element {
  return (
    <Tooltip label={result === Result.PASS ? 'Check Passed' : result === Result.FAIL ? 'Check Failed' : 'Check Not Performed'}>
      <Box>
        <Icon
          as={result === Result.PASS ? CheckCircleIcon : result === Result.FAIL ? CancelIcon : ErrorIcon}
          color={result === Result.PASS ? 'success.500' : result === Result.FAIL ? 'error.500' : undefined}
          boxSize={size ? SIZE_VALUES[size] : SIZE_VALUES['small']}
        />
      </Box>
    </Tooltip>
  )
}

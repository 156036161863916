import { ReactNode } from 'react'

import { Text } from '@chakra-ui/react'

export interface ProjectListingErrorProps {
  message?: ReactNode
}

export const ProjectListingError = ({ message = 'There was an error fetching projects.' }: ProjectListingErrorProps) => {
  return <Text>{message}</Text>
}

import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react'

import { RootState } from '../store'
import { TSFixMe } from '../../types'

interface PostSourceReportApiArg {
  file: File
  imageReference: string
  workspace?: string
}

interface PostSourceReportResponse {
  data: {
    createSourceReport: {
      sourceReport: {
        id: string
        isSca: boolean
        isSbom: boolean
      }
    }
  }
  errors: TSFixMe[] | null
}

interface CreateDerivedReportApiArg {
  sbomId: string
  scaId: string
  workspace: string
}

interface CreateDerivedReportResponse {
  data: {
    createDerivedReports: {
      sbomDerivedReport: {
        id: string
        derivedReportSet: {
          id: string
        } | null
      } | null
      scaDerivedReport: {
        id: string
        countVulnerabilities: number
        observedVulnCount: number
        derivedReportSet: {
          id: string
        } | null
      } | null
    }
  }
  errors: TSFixMe[] | null
}

// TODO(george): We can use the graphql-request library to make the request as documented in
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#graphql-basequery. This is
// a work in progess due to errors with the library types. This would also allow us to use
// rtk-query built-in error handling.
const mdsbomApiBase = fetchBaseQuery({
  baseUrl: import.meta.env.PROD ? '/api/v2/dsbom' : '/',
  credentials: 'same-origin',
  prepareHeaders: async (headers, { getState }) => {
    const state = getState() as RootState

    if (state.auth.mdsbomJwtToken) {
      headers.set('Authorization', `Bearer ${state.auth.mdsbomJwtToken}`)
    }
  }
})

export const mdsbomApi = createApi({
  baseQuery: mdsbomApiBase,
  reducerPath: 'mdsbomApi',
  endpoints: (build) => ({
    // TODO this can all be autogenerated using https://the-guild.dev/graphql/codegen/plugins/typescript/typescript-rtk-query
    postSourceReport: build.mutation<PostSourceReportResponse, PostSourceReportApiArg>({
      query: (queryArg) => {
        const bodyForm = new FormData()
        // TODO handle docker.io prefix and add it to the selected image reference
        bodyForm.append(
          'operations',
          JSON.stringify({
            query: `mutation createReportMutation($file: Upload!) 
            {
              createSourceReport(input: {
                imageReference: "${queryArg.imageReference}",
                file: $file
              }){
                sourceReport {id, isSca, isSbom }
              }
            }`,
            variables: { file: null }
          })
        )
        bodyForm.append('map', JSON.stringify({ 0: ['variables.file'] }))
        bodyForm.append('0', queryArg.file)

        return {
          url: `/graphql`,
          method: 'POST',
          body: bodyForm,
          formData: true,
          headers: {
            'X-Mayhem-Workspace': queryArg.workspace
          }
        }
      }
    }),
    createDerivedReport: build.mutation<CreateDerivedReportResponse, CreateDerivedReportApiArg>({
      query: (queryArg) => {
        const query = JSON.stringify({
          query: `
            mutation CreateDerivedReportsMutation {
                createDerivedReports(input: {
                  sbomSourceReport: "${queryArg.sbomId}",
                  scaSourceReport: "${queryArg.scaId}"
                }) {
                    sbomDerivedReport {
                        id
                        derivedReportSet {
                          id
                        }
                    }
                    scaDerivedReport {
                        id
                        countVulnerabilities
                        observedVulnCount: countVulnerabilities(filter: {observedOnly: true})        
                        derivedReportSet {
                          id
                        }
                    }
                }
            }                    
          `
        })

        return {
          url: `/graphql`,
          method: 'POST',
          body: query,
          headers: {
            'X-Mayhem-Workspace': queryArg.workspace
          }
        }
      }
    })
  })
})

export const { usePostSourceReportMutation, useCreateDerivedReportMutation } = mdsbomApi

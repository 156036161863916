import { useEffect } from 'react'

import { getLoginPageTitle } from '../../titles'

import { AuthForm } from './AuthForm'
import AuthLayout from './AuthLayout'
import { RedirectIfAuthenticated } from './RedirectIfAuthenticated'

export function LoginPage() {
  useEffect(() => {
    document.title = getLoginPageTitle()
  })

  return (
    <RedirectIfAuthenticated>
      <AuthLayout title="Sign In" data-selenium-id="authForm">
        <AuthForm />
      </AuthLayout>
    </RedirectIfAuthenticated>
  )
}

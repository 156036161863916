import { Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Stack, useToast } from '@chakra-ui/react'

import isURL from 'validator/lib/isURL'

import { FormProvider, useForm } from 'react-hook-form'

import { useEffect } from 'react'

import { useGetUserSettingQuery, usePutUserSettingMutation } from '../../redux/api/workspace'

type Form = {
  domains: string
}

const validateDomains = (value?: string) => {
  return value !== undefined &&
    (value === '' ||
      value
        .replace(/\s/g, '')
        .split(',')
        .every((v) => isURL(v)))
    ? undefined
    : 'Invalid domains. Please make sure all domains are valid URLs.'
}

export function AllowedDomainsForm() {
  const toast = useToast()
  const { data: userSetting, isLoading } = useGetUserSettingQuery()
  const [putUserSetting] = usePutUserSettingMutation()

  const methods = useForm<Form>({ defaultValues: { domains: userSetting?.enrollment_domain_allowlist } })
  const {
    handleSubmit,
    register,
    resetField,
    setError,
    formState: { errors, isValid, isSubmitting }
  } = methods

  const onSubmit = async ({ domains }: Form) => {
    try {
      await putUserSetting({ userSetting: { enrollment_domain_allowlist: domains } })
      toast({
        title: 'Workspace Updated',
        description: `Allowed domains have been updated`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (err: unknown) {
      const message = (err as Error)?.message || 'An error occurred. Please try again.'
      setError('domains', { type: 'custom', message: message })
    }
  }

  useEffect(() => {
    if (!isLoading && userSetting) {
      resetField('domains', { defaultValue: userSetting.enrollment_domain_allowlist })
    }
  }, [resetField, userSetting, isLoading])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.domains}>
            <FormLabel>Only allow authentication for accounts from the following domains</FormLabel>
            <Input type="text" {...register('domains', { validate: validateDomains })} />
            <FormHelperText>If providing multiple, please provide as a comma separated list</FormHelperText>
            {errors?.domains && <FormErrorMessage>{errors.domains.message}</FormErrorMessage>}
          </FormControl>
          <Flex justify="flex-end">
            <Button type="submit" isDisabled={!isValid} isLoading={isSubmitting || isLoading}>
              Save
            </Button>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  )
}

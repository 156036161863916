import { Card, CardBody, CardHeader, Container, Heading } from '@chakra-ui/react'
import { useEffect } from 'react'

import { getBecomeUserPageTitle } from '../../titles'

import { BecomeUserForm } from './BecomeUserForm'

export function BecomeUserPage() {
  useEffect(() => {
    document.title = getBecomeUserPageTitle()
  }, [])

  return (
    <Container>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Remote Access</Heading>
        </CardHeader>
        <CardBody p={4}>
          <BecomeUserForm />
        </CardBody>
      </Card>
    </Container>
  )
}

import { useFormContext } from 'react-hook-form'
import { Box, Stack, Text } from '@chakra-ui/react'

import { CodeBlock } from '../../components/CodeBlock/CodeBlock'

import { FormCodeRunConfig, parseCommands } from './utils'
import { CodeRunConfigCommand } from './CodeRunConfigCommand'

export function CodeRunConfigCommands() {
  const { getValues } = useFormContext<FormCodeRunConfig>()

  const { cmds, cmdSettings } = getValues()
  const commands = parseCommands(cmds)

  return (
    <Stack spacing={4}>
      <Text>CMD Specific Settings</Text>
      {commands.map((cmd: string, cmdIndex: number) => (
        <Stack key={cmd}>
          <CodeBlock>{cmd}</CodeBlock>
          <CodeRunConfigCommand
            cmdIndex={cmdIndex}
            environmentVariableCount={(cmdSettings && cmdSettings.length > cmdIndex && cmdSettings[cmdIndex].env?.length) || 0}
          />
        </Stack>
      ))}
      <Box>
        {commands.length === 0 && (
          <Text textAlign="center" fontWeight="semibold">
            No commands entered. Go back to the &quot;Basic&quot; tab in order to add commands to this list.
          </Text>
        )}
      </Box>
    </Stack>
  )
}

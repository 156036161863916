import { HStack, GridItem } from '@chakra-ui/react'

import { useGetRunQuery } from '../../redux/api/runs'

import { RunOptionsButton } from '../run-code/RunOptionsButton'

import { RunStatusSpinner } from './RunStatusSpinner'
import { RunStatusText } from './RunStatusText'

export interface RunStatusProps {
  workspace: string
  project: string
  target: string
  runNumber: number
}

export function RunStatus({ workspace, project, target, runNumber }: RunStatusProps) {
  const { isLoading, data: run } = useGetRunQuery({
    owner: workspace,
    projectSlug: project,
    targetSlug: target,
    runNumber
  })

  if (isLoading || !run) {
    return <></>
  }

  const { ended } = run || {}

  return (
    <GridItem gridArea="status" display="flex" alignSelf="start" justifyContent="right" marginLeft={8}>
      <HStack spacing={4}>
        <RunStatusText owner={workspace} projectSlug={project} targetSlug={target} runNumber={runNumber} />
        <RunStatusSpinner indicatorSize="md" owner={workspace} projectSlug={project} targetSlug={target} runNumber={runNumber} />
        {ended !== undefined && !ended && <RunOptionsButton owner={workspace} projectSlug={project} targetSlug={target} runNumber={runNumber} />}
      </HStack>
    </GridItem>
  )
}

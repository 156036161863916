import { Button, Modal, ModalOverlay, ModalCloseButton, ModalContent, ModalBody, ModalHeader, ModalFooter, Text } from '@chakra-ui/react'

const RunDeleteModal = (props: Props): JSX.Element => {
  const { open, deleteHandler, closeHandler, owner, projectSlug, targetSlug, runNumber } = props

  return (
    <Modal isOpen={open} onClose={() => closeHandler()} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Run</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to delete this run?</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={() => closeHandler()}>
            No
          </Button>
          <Button
            colorScheme="green"
            onClick={() => {
              deleteHandler(owner, projectSlug, targetSlug, runNumber)
              closeHandler()
            }}
            marginLeft={4}
          >
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number | string

  open: boolean

  deleteHandler: (owner: string, project: string, target: string, runNumber: number | string) => void
  closeHandler: () => void
}

export default RunDeleteModal

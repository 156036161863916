import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text
} from '@chakra-ui/react'
import { useLocation } from 'wouter'
import AddIcon from '@material-design-icons/svg/sharp/add.svg?react'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { link } from '../workspace/utils'
import { MapiUrlExample, selectNewApiRun, setUrl } from '../workspace/create-api-run.slice'

type RunCardProps = CardProps & { workspace: string }

export const CreateApiRunCard = ({ workspace, ...cardProps }: RunCardProps) => {
  const [_, setLocation] = useLocation()

  const dispatch = useAppDispatch()
  const { apiUrl } = useAppSelector(selectNewApiRun)
  const onUrlChange = (newUrl: string) => {
    dispatch(setUrl(newUrl.trim()))
  }
  const handleSubmit = () => {
    setLocation(link(workspace, '-', 'create-api-run'))
  }
  return (
    <Card {...cardProps}>
      <CardHeader>
        <Heading variant="cardHeading">API Testing</Heading>
      </CardHeader>
      <CardBody padding={4}>
        <Stack spacing={8}>
          <Text>AI-generated behavioral testing of your APIs</Text>

          <FormControl>
            <FormLabel fontSize="md" fontWeight="semibold">
              URL
            </FormLabel>
            <Input placeholder={MapiUrlExample} value={apiUrl || ''} onChange={(event) => onUrlChange(event.target.value)} />
            <FormHelperText fontSize="md">Leave the default value if you want to see Mayhem run on an example.</FormHelperText>
          </FormControl>
        </Stack>
      </CardBody>
      <CardFooter justifyContent="end">
        <Button leftIcon={<AddIcon />} onClick={handleSubmit}>
          Add API
        </Button>
      </CardFooter>
    </Card>
  )
}

import { Card, Skeleton, Stack, Stat, StatGroup, StatLabel } from '@chakra-ui/react'

import TooltippedStatNumber from '@/components/TooltippedStatNumber'
import { getCurrentUserInfo } from '@/features/auth/utils'
import { useAppSelector } from '@/hooks'
import { useGetRunStatsByUserQuery } from '@/redux/api/runs'

import { AdminRunsList } from '@/features/admin-runs/AdminRunsList'

export function AdminRunsPage() {
  const { userSlug: currentUserSlug } = useAppSelector((state) => getCurrentUserInfo(state)) || {}

  const { data: runsData, isFetching } = useGetRunStatsByUserQuery({ userSlug: currentUserSlug })

  const { n_active_and_pending_runs: nActiveAndPendingRuns = 0, n_active_runs: nActiveRuns = 0, n_total_runs: nTotalRuns = 0 } = runsData || {}

  return (
    <Stack gap={4}>
      <Skeleton isLoaded={!isFetching}>
        <StatGroup gap={4} justifyContent="start" alignItems="center">
          <Card p={4}>
            <Stat border={0}>
              <StatLabel>Total Runs</StatLabel>
              <TooltippedStatNumber value={nTotalRuns} />
            </Stat>
          </Card>
          <Card p={4}>
            <Stat border={0}>
              <StatLabel>Active Runs</StatLabel>
              <TooltippedStatNumber value={nActiveRuns} />
            </Stat>
          </Card>
          <Card p={4}>
            <Stat border={0}>
              <StatLabel>Pending Runs</StatLabel>
              <TooltippedStatNumber value={nActiveAndPendingRuns - nActiveRuns} />
            </Stat>
          </Card>
        </StatGroup>
      </Skeleton>

      <AdminRunsList />
    </Stack>
  )
}

import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Grid,
  HStack,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  VStack
} from '@chakra-ui/react'

import { ReactNode, memo, useRef } from 'react'

import PageAccentBackgroundLight from '../../images/page-accent-bg.svg'
import PageAccentBackgroundDark from '../../images/page-accent-bg-darkmode.svg'

import { NavbarSearch } from '../../features/workspace/NavbarSearch'
import { UserProfileWidget } from '../../features/workspace-sidebar/UserProfileWidget'

import { MobileNavbar } from './MobileNavbar'

const ShellInner = ({ navbar, sidebar, children, submenuOpen = false, showPageAccentBackground = false }: ShellProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { fallback: 'lg' })
  const BackgroundAccentImage = useColorModeValue(PageAccentBackgroundLight, PageAccentBackgroundDark)

  const containerRef = useRef<HTMLDivElement>(null)

  const backgroundProps = showPageAccentBackground
    ? {
        style: { backgroundImage: `url("${BackgroundAccentImage}")` },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundSize: 'cover'
      }
    : {
        background: 'pageBg'
      }

  return (
    <Grid
      as="section"
      height="100vh"
      bg="bg-canvas"
      templateColumns={{ base: '1fr', lg: `${submenuOpen ? '17rem' : '12.5rem'} 1fr` }}
      templateAreas={{
        base: `
          "mobile-navbar"
          "page"
        `,
        lg: `
          "sidebar page"
        `
      }}
      css={{
        '@media print': {
          // prevents a blank page at the start when printing from Firefox
          display: 'block'
        }
      }}
    >
      {isDesktop ? (
        <Box
          gridArea="sidebar"
          display="flex"
          flexDirection="column"
          height="100vh"
          overflowY="auto" // Scrollable sidebar
          bg="bg-sidebar"
          position="fixed"
        >
          {sidebar}
        </Box>
      ) : (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} isFullHeight preserveScrollBarGap>
          <DrawerOverlay />
          <DrawerContent>{sidebar}</DrawerContent>
        </Drawer>
      )}
      {!isDesktop && <MobileNavbar isOpen={isOpen} onToggle={onToggle} />}
      {navbar}
      <Container
        padding="0"
        flex="1"
        maxW={{ base: 'full', lg: 'unset' }}
        minW={0}
        gridArea="page"
        {...backgroundProps}
        css={{
          '@media print': {
            overflow: 'visible',
            breakInside: 'avoid'
          }
        }}
      >
        <VStack gap={0}>
          <HStack ref={containerRef} justifyContent="flex-end" width="100%">
            <NavbarSearch />
            <UserProfileWidget containerRef={containerRef} />
          </HStack>

          <Box py={4} px={{ base: 4, lg: 8 }} width="100%">
            {children}
          </Box>
        </VStack>
      </Container>
    </Grid>
  )
}

export const Shell = memo(ShellInner)
export interface ShellProps {
  navbar?: JSX.Element
  sidebar: JSX.Element
  alert?: JSX.Element
  children?: ReactNode
  showPageAccentBackground?: boolean
  submenuOpen?: boolean
}

import { Box, Flex, Progress, Text } from '@chakra-ui/react'

interface Usage {
  current_usage: number // This should be less than 'total_allowed"
  current_description: string
  total_allowed: number | null // null means there is no limit
}

export default function BillingUsageViz({ current_usage, current_description, total_allowed }: Usage) {
  const insideLimit = !(total_allowed !== null && current_usage > total_allowed)
  const maxValue = total_allowed === null ? 1_000_000_000_000 : total_allowed
  const limitText = total_allowed === null ? '(No limit)' : total_allowed >= current_usage ? total_allowed : `${total_allowed} (Over limit)`

  return (
    <Flex flexGrow="1" flexShrink="1" flexBasis="auto">
      <Text minWidth={32} fontSize="xs" as="div">
        {current_usage} {current_description}
      </Text>
      <Box flexGrow="1" paddingX={2} paddingY={1} minWidth={32} maxWidth={48}>
        <Progress colorScheme={insideLimit ? 'good' : 'bad'} value={current_usage} min={0} max={maxValue} />
      </Box>
      <Text fontSize="xs" as="div">
        Limit {limitText}
      </Text>
    </Flex>
  )
}

import * as React from 'react'

export enum Category {
  LANGUAGE = 1,
  CICD,
  BUILDTOOL,
  REPORTING,
  NOTIFICATION,
  API,
  OTHER
}

export interface Integration {
  id: number | undefined
  name: string
  icon: React.FunctionComponent<React.ComponentProps<'svg'> & { title?: string }>
  description: string
  href: string
  href_button_text: string
  categories: Category[]
}

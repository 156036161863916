import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'

interface Props {
  children: React.ReactNode
  actions: React.ReactNode
  title: string
}

export function TableCard({ actions, children, title }: Props) {
  return (
    <Card>
      <CardHeader paddingBottom={0} paddingLeft={5}>
        <Heading variant="cardHeading" textTransform="capitalize">
          {title}
        </Heading>
      </CardHeader>
      <CardHeader padding={4} gap={3} paddingY={6}>
        {actions}
      </CardHeader>
      <CardBody paddingTop={4}>{children}</CardBody>
    </Card>
  )
}

import { WebhookFormatType } from './types'
export function getCurlCommandForWebhook(url: string, webhookName: string, format: WebhookFormatType): string {
  // TODO: We should ensure any values interpolated here are properly shell-escaped
  if (format === 'slack') {
    return [
      'curl -v',
      "-H 'User-Agent: mayhem-by-forallsecure/1.0.0 (support@forallsecure.com) python-requests/2.21.0 (curl; test request)'",
      "-H 'Accept: */*'",
      "-H 'Connection: keep-alive'",
      "-H 'Content-Type: application/json; charset=UTF-8'",
      '-d',
      `'${JSON.stringify({
        blocks: [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `>:microscope: Test Message 1\n>:microscope: Test Message 2`
            }
          },
          {
            type: 'context',
            elements: [
              {
                type: 'plain_text',
                text: `Test Webhook from cURL\nWebhook: ${webhookName || 'Name not defined'}`
              }
            ]
          }
        ]
      })}'`,
      `'${url}'`
    ].join(' ')
  }

  if (format === 'json_v1') {
    return [
      'curl -v',
      "-H 'User-Agent: mayhem-by-forallsecure/1.0.0 (support@forallsecure.com) python-requests/2.21.0 (curl; test request)'",
      "-H 'Accept: */*'",
      "-H 'Connection: keep-alive'",
      "-H 'Content-Type: application/json; charset=UTF-8'",
      '-d',
      `'${JSON.stringify({
        message: 'test',
        triggered_at: '1970-01-01T00:00:00'
      })}'`,
      `'${url}'`
    ].join(' ')
  }

  if (format === 'gchat') {
    return [
      'curl -v',
      "-H 'User-Agent: mayhem-by-forallsecure/1.0.0 (support@forallsecure.com) python-requests/2.21.0 (curl; test request)'",
      "-H 'Accept: */*'",
      "-H 'Connection: keep-alive'",
      "-H 'Content-Type: application/json; charset=UTF-8'",
      '-d',
      `'${JSON.stringify({
        cards: [
          {
            sections: [
              {
                widgets: [
                  {
                    keyValue: {
                      topLabel: 'Test Webhook from cURL',
                      content: '🔬 Test Message 1\n🔬 Test Message 2',
                      contentMultiline: true,
                      bottomLabel: `Webhook: ${webhookName || 'Name not defined'}`
                    }
                  }
                ]
              }
            ]
          }
        ]
      })}'`,
      `'${url}'`
    ].join(' ')
  }

  return ''
}

import { DependencyList, useCallback, useEffect, useRef } from 'react'

import { Validate } from 'react-hook-form/dist/types/validator'
import { useFormContext } from 'react-hook-form'

/**
 * react-hook-form does not update when the `validate` callback
 *  has changes, this is not an issue but rather an optimization.
 *
 * This hook creates a `validate` callback that delegates to a
 *  ref. This allows us to create validations that depend on api results.
 *
 * When the dependencies are changed, form validation is triggered.
 */
export function useFormControlValidation<TFieldValues, TFormValues>(
  validate: Validate<TFieldValues, TFormValues>,
  deps: DependencyList
): Validate<TFieldValues, TFormValues> {
  const { trigger } = useFormContext()
  const validatorRef = useRef<Validate<TFieldValues, TFormValues>>(validate)

  useEffect(() => {
    // Dependencies have changed, update validator ref.
    validatorRef.current = validate
    // Trigger form validation to make sure the new validator is used.
    trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return useCallback((...args) => {
    return validatorRef.current(...args)
  }, [])
}

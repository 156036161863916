import { Heading, SimpleGrid, Stack } from '@chakra-ui/react'

import { Integration } from './integration'
import { IntegrationCard } from './IntegrationCard'

interface IntegrationListProps {
  title: string
  integrations: Integration[]
}

export function IntegrationList({ title, integrations }: IntegrationListProps) {
  const items = integrations.map((integration) => <IntegrationCard key={integration.id} integration={integration}></IntegrationCard>)

  return (
    <Stack spacing={4} marginBottom={24}>
      <Heading size="md">{title}</Heading>
      <SimpleGrid spacing={4} templateColumns="repeat(auto-fill, minmax(200px, 1fr))">
        {items}
      </SimpleGrid>
    </Stack>
  )
}

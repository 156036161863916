import { useFormContext } from 'react-hook-form'
import { FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'

import { FormCodeRunConfig } from './utils'

export interface CodeRunConfigCommandOtherProps {
  cmdIndex: number
}

export const CodeRunConfigCommandOther = ({ cmdIndex }: CodeRunConfigCommandOtherProps) => {
  const { register } = useFormContext<FormCodeRunConfig>()

  return (
    <Stack>
      <FormControl>
        <FormLabel>Fuzzing Input Max Length (bytes)</FormLabel>
        <Input type="number" {...register(`cmdSettings.${cmdIndex}.max_length`)} placeholder="Maximum length in bytes" />
      </FormControl>

      <FormControl>
        <FormLabel>Starting Directory</FormLabel>
        <Input {...register(`cmdSettings.${cmdIndex}.cwd`)} placeholder="Current working directory" />
      </FormControl>

      <FormControl>
        <FormLabel>Target Input Path</FormLabel>
        <Input {...register(`cmdSettings.${cmdIndex}.filepath`)} placeholder="Input file name" />
      </FormControl>

      <FormControl>
        <FormLabel>Memory Limit (MB)</FormLabel>
        <Input type="number" {...register(`cmdSettings.${cmdIndex}.memory_limit`)} placeholder="Maximum memory limit" />
      </FormControl>

      <FormControl>
        <FormLabel>Mayhem Timeout</FormLabel>
        <Input type="number" {...register(`cmdSettings.${cmdIndex}.mayhem_timeout`)} placeholder="Timeout in seconds" />
      </FormControl>

      <FormControl>
        <FormLabel>Dictionary Path</FormLabel>
        <Input {...register(`cmdSettings.${cmdIndex}.dictionary`)} placeholder="Path to AFL dictionary file" />
      </FormControl>
    </Stack>
  )
}

import { useCombobox, UseComboboxState, UseComboboxStateChange, UseComboboxStateChangeOptions } from 'downshift'
import {
  List,
  ListItem,
  ListItemProps,
  ListProps,
  StylesProvider,
  forwardRef,
  useMultiStyleConfig,
  useStyles,
  InputProps,
  Box,
  BoxProps,
  Portal,
  IconButton
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'

type ComboboxListProps = { isOpen: boolean } & ListProps
const ComboboxList = forwardRef<ComboboxListProps, 'ul'>(({ isOpen, ...props }, ref) => {
  const styles = useStyles()
  return <List display={isOpen ? 'block' : 'none'} sx={styles.list} {...props} ref={ref} />
})
ComboboxList.displayName = 'ComboboxList'

const Progress = forwardRef<BoxProps, 'div'>(({ ...props }, ref) => {
  const styles = useStyles()
  return <Box sx={styles.progress} {...props} ref={ref} />
})
Progress.displayName = 'Progress'

const ErrorMessage = forwardRef<BoxProps, 'div'>(({ ...props }, ref) => {
  const styles = useStyles()
  return <Box sx={styles.errorMessage} {...props} ref={ref} />
})
ErrorMessage.displayName = 'ErrorMessage'

type ComboboxItemProps = { itemIndex: number; highlightedIndex: number } & ListItemProps
const ComboboxItem = forwardRef<ComboboxItemProps, 'li'>(({ itemIndex, highlightedIndex, ...props }, ref) => {
  const isActive = itemIndex === highlightedIndex
  const styles = useStyles()

  return <ListItem data-highlighted={isActive ? true : undefined} className={isActive ? 'active' : undefined} sx={styles.item} {...props} ref={ref} />
})
ComboboxItem.displayName = 'ComboboxItem'

export type SelectProps<Item> = {
  /** A cancellable promise that takes a search string fetches combo box items. */
  items: Item[]
  /** Initializes the input with a value when first rendered. */
  initialValue?: string | null
  /** Used to render the input text of an item after selection. */
  itemToString: (item: Item | null) => string
  /** A callback triggered when the search item selection updates. */
  onSelectedItemChange?: (item: Item | null) => void
  /** Renders the input component.  Usually this is a Chakra component such as Input or InputGroup. */
  renderInput: (params: InputProps) => JSX.Element
  /** Renders results. */
  renderComboBoxItem: (item: Item | null) => JSX.Element
  /** The item selected by the search. This puts the input into controlled mode.  Use with onSelectedItemChange. */
  selectedItem?: Item | null
  multiple?: boolean
}

function SelectInner<Item>(props: SelectProps<Item>, ref: React.ForwardedRef<HTMLInputElement>) {
  const { initialValue, itemToString, onSelectedItemChange, selectedItem, renderInput, renderComboBoxItem, items, multiple = false } = props

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [inputItems, setInputItems] = useState<Item[]>(items)

  useEffect(() => {
    setInputItems(items)
  }, [items])

  let resetCombobox: () => void = () => {
    /* no op until useComboBox is called */
  }

  const styles = useMultiStyleConfig('Search', props)

  const handleInputValueChange = (change: UseComboboxStateChange<Item>) => {
    if (!change.inputValue && change.selectedItem) {
      resetCombobox()
      return
    }
    if (!change.inputValue) {
      return
    }
    setInputItems(
      items.filter((item) =>
        itemToString(item)
          .toLowerCase()
          .includes((change?.inputValue || '').toLowerCase())
      )
    )
  }

  const multiSelectStateReducer = (state: UseComboboxState<Item>, actionAndChanges: UseComboboxStateChangeOptions<Item>) => {
    const { changes, type } = actionAndChanges
    switch (type) {
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick:
        return {
          ...changes,
          isOpen: true, // keep menu open after selection.
          highlightedIndex: state.highlightedIndex,
          inputValue: '' // don't add the item string as input value at selection.
        }
      case useCombobox.stateChangeTypes.InputBlur:
        return {
          ...changes,
          inputValue: '' // don't add the item string as input value at selection.
        }
      default:
        return changes
    }
  }

  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, reset, getToggleButtonProps } = useCombobox({
    selectedItem: selectedItem,
    initialInputValue: initialValue || '',
    inputValue: selectedItem ? itemToString(selectedItem) : undefined,
    items: inputItems,
    itemToString: itemToString,
    onInputValueChange: handleInputValueChange,
    onSelectedItemChange: (changes) => onSelectedItemChange?.(changes.selectedItem || null),
    stateReducer: (state, actionAndChanges) => {
      if (multiple) {
        return multiSelectStateReducer(state, actionAndChanges)
      }
      // Nothing to do for the default case
      const { changes } = actionAndChanges
      return changes
    }
  })

  // bind reset handler
  // must do this here since it is called by handleInputValueChange, which is passed
  // to useCombobox
  resetCombobox = () => reset()

  const renderItem = renderComboBoxItem
  const inputProps = {
    ...styles.input,
    ...getInputProps({
      // forward the ref, allowing the search to work with react-hook-form
      ref,
      onChange: (e) => {
        const inputValue = (e.target as HTMLInputElement).value
        if (inputValue === '') {
          reset()
          setInputItems(items)
          return
        }
        setInputItems(items.filter((item) => itemToString(item).includes(inputValue.toLowerCase())))
      }
    })
  }
  const input = renderInput(inputProps as InputProps)

  // The popups are rendered in a Portal component to avoid z-index usage and have the
  // popups rendered on top of all the other elements.
  // The absolute position of the popup then has to be computed relative to the input.
  const inputRef = useRef<HTMLDivElement>(null)
  const popupTop = `${(inputRef.current?.offsetTop || 0) + (inputRef.current?.offsetHeight || 0)}px`
  const popupLeft = `${inputRef.current?.offsetLeft || 0}px`

  return (
    <StylesProvider value={styles}>
      <Box ref={containerRef}>
        <Box ref={inputRef} display="flex" flexDirection="row" gap="0.5" style={{ width: inputRef.current?.offsetWidth || 'auto' }}>
          {input}
          <IconButton variant="outline" {...getToggleButtonProps()} aria-label="toggle menu" icon={isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />} />
        </Box>
        <Portal containerRef={containerRef}>
          <ComboboxList
            isOpen={isOpen}
            flex={1}
            overflowY="auto"
            flexDirection="column"
            data-testid="dropdown-list"
            top={popupTop}
            left={popupLeft}
            style={{ width: inputRef.current?.offsetWidth || 'auto' }}
            // suppress the ref error from downshift
            // that occurs because the menu is inside the Portal element
            {...getMenuProps({}, { suppressRefError: true })}
          >
            {isOpen &&
              inputItems.map((item, index) => {
                return (
                  <ComboboxItem {...getItemProps({ item, index })} itemIndex={index} highlightedIndex={highlightedIndex} key={index}>
                    {renderItem(item)}
                  </ComboboxItem>
                )
              })}
          </ComboboxList>
        </Portal>
      </Box>
    </StylesProvider>
  )
}

export const Select = React.forwardRef(SelectInner) as <T>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof SelectInner>

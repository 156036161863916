import { Box, Text } from '@chakra-ui/react'

export interface ProjectListingEmptyProps {
  workspace: string
  isOrg?: boolean
}

export const ProjectListingEmpty = ({ workspace, isOrg = false }: ProjectListingEmptyProps) => {
  return (
    <Box>
      No projects found!{' '}
      {isOrg && (
        <>
          To add a project, perform a run with the “project” field set to “{workspace}/&lt;project name&gt;” in the Mayhemfile.
          <Text as="i">Note: the &quot;version&quot; field in the Mayhemfile must be set to 1.3 or greater.</Text>
        </>
      )}
    </Box>
  )
}

export const brand = {
  50: '#EDEFFF',
  100: '#DCE0FF',
  200: '#B9C0FF',
  300: '#97A1FF',
  400: '#7481FF',
  500: '#5162FF',
  600: '#414ECC',
  700: '#313B99',
  800: '#202766',
  900: '#101433'
}

export const purple = {
  25: '#F6F3FF',
  50: '#F6F3FF',
  100: '#E6DDFF',
  200: '#CDBAFF',
  300: '#B498FF',
  400: '#9B75FF',
  500: '#8253FF',
  600: '#6842CC',
  700: '#4E3299',
  800: '#342166',
  900: '#1A1133'
}

/**
 * Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form
 * fields, backgrounds, dividers — are usually gray.
 */
export const gray = {
  25: '#FCFCFD',
  50: '#F4F6FF',
  100: '#EFF1F9',
  200: '#DEE3F2',
  300: '#CED6EC',
  400: '#BDC8E5',
  500: '#ADBADF',
  600: '#8A95B2',
  700: '#687086',
  800: '#454A59',
  825: '#343a47',
  900: '#23252D',
  950: '#121317'
}

/**
 * Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative
 * action, such as removing a user from your team.
 */
export const error = {
  25: '#FFFBFA',
  50: '#FFFBFA',
  100: '#FEF3F2',
  200: '#FEE4E2',
  300: '#FECDCA',
  400: '#FDA29B',
  500: '#F97066',
  600: '#F04438',
  700: '#D92D20',
  800: '#B42318',
  900: '#912018'
}

/**
 * Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly
 * used in confirmations to grab the users' attention.
 */
export const warning = {
  25: '#FFFCF5',
  50: '#FFFCF5',
  100: '#FFFAEB',
  200: '#FEF0C7',
  300: '#FEDF89',
  400: '#FEC84B',
  500: '#FDB022',
  600: '#F79009',
  700: '#DC6803',
  800: '#B54708',
  900: '#93370D'
}

/**
 * Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green
 * as your primary color, it can be helpful to introduce a different hue for your success green.
 */
export const success = {
  25: '#F6FEF9',
  50: '#F6FEF9',
  100: '#ECFDF3',
  200: '#D1FADF',
  300: '#A6F4C5',
  400: '#6CE9A6',
  500: '#32D583',
  600: '#12B76A',
  700: '#039855',
  800: '#027A48',
  900: '#05603A'
}

export const severityMedium = {
  // Define 25 and 50 so severity-medium is part of the colorScheme theme declaration.
  25: undefined,
  50: undefined,
  100: '#FFECCC',
  200: '#FFDFAD',
  300: '#FDD291',
  400: '#FFBB63',
  500: '#FFA037',
  600: '#F68511',
  700: '#E46F00',
  800: '#CB5D00',
  900: '#B15C00'
}

export const severityLow = {
  // Define 25 and 50 so severity-medium is part of the colorScheme theme declaration.
  25: undefined,
  50: undefined,
  100: '#FBF198',
  200: '#F8E750',
  300: '#F8D904',
  400: '#E8C600',
  500: '#D7B300',
  600: '#C49F00',
  700: '#B09C00',
  800: '#9B7800',
  900: '#856600'
}

import { Box, Icon, Link, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react'
import InfoIcon from '@material-design-icons/svg/sharp/info.svg?react'

export function DefectSeverityExplainer() {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Box>
          <Icon as={InfoIcon} boxSize={6} />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Text>
            Mayhem calculates the severity for a defect by taking the average{' '}
            <Link isExternal href="https://nvd.nist.gov/vuln-metrics/cvss" textDecoration="underline">
              CVSS
            </Link>{' '}
            score for the defect&apos;s CWE type from the last year of vulnerabilities seen in the{' '}
            <Link isExternal href="https://nvd.nist.gov/" textDecoration="underline">
              National Vulnerability Database
            </Link>
            .
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

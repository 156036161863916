import { Link } from '@chakra-ui/react'
import React from 'react'

interface OutsideLinkProps {
  href: string
  children: React.ReactNode
  inline?: boolean
}

export default function OutsideLink({ href, children, inline = true }: OutsideLinkProps) {
  return (
    <Link textDecoration={inline ? 'underline' : undefined} rel="noopener noreferrer" target="_blank" href={href}>
      {children}
    </Link>
  )
}

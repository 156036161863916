import { FormProvider, useForm } from 'react-hook-form'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useToast
} from '@chakra-ui/react'
import { useCallback } from 'react'

import { skipToken } from '@reduxjs/toolkit/query'

import GitBranchIcon from '../../images/git-branch.svg?react'
import { useGetProjectMemberPermissionQuery, useGetProjectQuery, usePutProjectMutation } from '../../redux/api/projects'
import { useWhoamiQuery } from '../../redux/api/workspace'

type Form = {
  defaultBranch?: string
}

type Props = {
  owner: string
  projectSlug: string
}

export function DefaultBranchForm({ owner, projectSlug }: Props) {
  const { data: project } = useGetProjectQuery({ owner, projectSlug })
  const { isLoading: isLoadingWhoami, data: currentUser } = useWhoamiQuery()
  const { data: projectPermission } = useGetProjectMemberPermissionQuery(
    isLoadingWhoami || !currentUser?.slug
      ? skipToken
      : {
          owner,
          projectSlug,
          userSlug: currentUser.slug
        }
  )

  const methods = useForm<Form>({
    defaultValues: { defaultBranch: project?.default_branch ?? undefined }
  })
  const toast = useToast()
  const [putProject] = usePutProjectMutation()
  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { isSubmitting, isDirty, errors }
  } = methods

  const onSubmitDefaultBranch = useCallback(
    async (form: Form) => {
      try {
        await putProject({ owner, projectSlug, projectUpdate: { default_branch: form.defaultBranch } })
        reset({ defaultBranch: form.defaultBranch })
        toast({
          title: 'Project Updated',
          description: `${projectSlug} default branch updated`,
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        setError('defaultBranch', { type: 'custom', message: (err as Error)?.message || 'Something went wrong' })
      }
    },
    [toast, setError, reset, putProject, owner, projectSlug]
  )

  const isAtLeastWritePermission = currentUser?.is_admin || (projectPermission && projectPermission.permission !== 'READ')

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitDefaultBranch)}>
        <FormControl isInvalid={!!errors.defaultBranch}>
          <FormLabel>Default Branch</FormLabel>
          <InputGroup width="md">
            <InputLeftElement color="faded">
              <Icon as={GitBranchIcon} />
            </InputLeftElement>
            <Input placeholder="Set a Default Branch..." {...register('defaultBranch')} />
            <InputRightElement width="fit-content" paddingX={1}>
              <Button
                variant="outline"
                type="submit"
                size="sm"
                isDisabled={isSubmitting || !isDirty || !isAtLeastWritePermission}
                isLoading={isSubmitting}
              >
                Update
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormHelperText maxW={96}>A default branch is the baseline for computing differences between runs.</FormHelperText>
          <FormErrorMessage>{errors?.defaultBranch?.message}</FormErrorMessage>
        </FormControl>
      </form>
    </FormProvider>
  )
}

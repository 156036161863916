import { ReactNode, useEffect, useState } from 'react'

import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import { Center, Heading, Text, VStack, Divider, Stack, Box, Button, Icon } from '@chakra-ui/react'
import { HStack } from '@chakra-ui/layout'
import { useLocation } from 'wouter'

import ErrorImg from '../../images/404.svg?react'
import { getErrorPageTitle } from '../../titles'
import { APIWarning } from '../../api'
import { useQuery } from '../../hooks'
import { stringifyUrl } from '../../util/location'
import OutsideLink from '../OutsideLink'
import { Page } from '../Page'

export const GITHUB_PROJECT_MISSING = 'GITHUB_PROJECT_MISSING'

/**
 * If the error is a response error, redirects the user to the error page by setting
 * the window's state.
 *
 * Otherwise, the error is re-thrown and should be caught by the high level
 * Sentry.ErrorBoundary element.
 *
 * @param currentLocation Browser's current location
 * @param setLocation Set a browser's location to the provided URL
 * @param error An error to inspect for a response or rethrow
 */
export function handleAsyncResponseError(
  currentLocation: string,
  setLocation: (to: string, options?: { replace?: boolean | undefined } | undefined) => void,
  error: APIWarning | { response?: { status?: unknown; json?: { message?: string } } } | FetchBaseQueryError | SerializedError
) {
  const response = (error as APIWarning)?.response
  const status = response?.status || (error as FetchBaseQueryError).status || (error as SerializedError).code
  // TODO: is there a message on FetchBaseQueryError we can use?
  const message = (response?.json as { message?: string })?.message || (error as SerializedError)?.message

  if (response && status) {
    const searchParams = new URLSearchParams()
    searchParams.set('invalidUrl', 'true')
    searchParams.set('errorCode', status.toString())
    if (message) {
      searchParams.set('errorMessage', message)
    }
    setLocation(stringifyUrl(currentLocation, Object.fromEntries(searchParams)), { replace: true })
  } else if (!(error instanceof APIWarning)) {
    throw error
  }
}

type Props = {
  errorCode?: number
  errorMessage?: string
  resetError?: () => void
  goBackLocation?: string
}

export function ErrorPage({ errorCode = 404, errorMessage, resetError, goBackLocation }: Props) {
  const query = useQuery()
  const [location, setLocation] = useLocation()
  const [initialLocation] = useState(location)

  // store the error params as local state as the params will be deleted on mount
  const [errorMessageFromQuery] = useState(query.get('errorMessage'))
  const [errorCodeFromQuery] = useState(query.get('errorCode'))

  const clearQueryParams = () => {
    // remove the query params from the location, so a refresh causes the error
    // page to disappear
    query.delete('invalidUrl')
    query.delete('errorMessage')
    query.delete('errorCode')

    if (goBackLocation) {
      setLocation(goBackLocation)
    } else {
      setLocation(stringifyUrl(location, Object.fromEntries(query)), { replace: true })
    }
  }

  // remove the query params from the location on refresh
  if (window?.performance?.getEntriesByType) {
    if ((window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming)?.type === 'reload') {
      clearQueryParams()
    }
  }

  useEffect(() => {
    if (location !== initialLocation && resetError) {
      resetError()
    }
  }, [initialLocation, resetError, location])

  useEffect(() => {
    document.title = getErrorPageTitle()
    // makes sure invalidUrl doesn't survive improperly on page refresh
    window.history.replaceState({}, document.title)
  }, [])

  let displayMessage: ReactNode = errorMessageFromQuery || errorMessage
  const displayCode = errorCodeFromQuery || errorCode

  if (displayMessage === GITHUB_PROJECT_MISSING) {
    displayMessage = (
      <Stack>
        <Text>Unable to find project in Mayhem.</Text>
        <Text>
          If this is your project, please visit{' '}
          <OutsideLink href="https://github.com/ForAllSecure/mcode-action">https://github.com/ForAllSecure/mcode-action</OutsideLink> for instructions
          on how to integrate the Mayhem for Code GitHub action into your repo to enable Mayhem code scanning for your project!
        </Text>
      </Stack>
    )
  }

  return (
    <Page>
      <Center>
        <VStack spacing={8} marginTop="25vh">
          <HStack>
            <Icon as={ErrorImg} boxSize={32} aria-label="error" />
            <Heading size="4xl">{displayCode}</Heading>
          </HStack>
          <Box fontSize="xl">
            <VStack>
              <Text>Uh oh. You know what that means:</Text>
              <Text>We couldn&apos;t find the page you were looking for.</Text>
              <Text>Sorry about that!</Text>
            </VStack>
          </Box>
          {(errorCodeFromQuery || errorMessageFromQuery || goBackLocation) && <Button onClick={clearQueryParams}>Get Back On Track</Button>}
          {displayMessage && (
            <Stack>
              <Divider />
              <Heading size="md">Error Details: </Heading>
              <Text fontSize="xs" as="pre" data-selenium-id="errorMessage">
                {displayMessage}
              </Text>
            </Stack>
          )}
        </VStack>
      </Center>
    </Page>
  )
}

import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['user'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/account`, method: 'DELETE' }),
        invalidatesTags: ['user']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type DeleteUserApiResponse = /** status 202 Deletes user along with any PII.

    Returns:
        {"result": "ok"} -- On successful deletion.
     */ Ok
export type DeleteUserApiArg = {
  userSlug: string
}
export type Ok = {
  result?: string
}
export type ApiError = {
  message?: string
}

import { useEffect } from 'react'
import { Card, CardHeader, Heading, Button, CardBody, CardFooter } from '@chakra-ui/react'
import AddIcon from '@material-design-icons/svg/sharp/add.svg?react'

import { Link } from 'wouter'

import { getAdminSettingsPageTitle } from '../../titles'

import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetOrgsQuery } from '../../redux/api/workspace'
import { WorkspaceRow } from '../workspace/WorkspaceRow'
import { useQuery } from '../../hooks'
import { cleanInteger } from '../../util/numbers'
import { MayhemPagination } from '../../components/MayhemPagination'
import ListDefaults from '../../components/ListDefaults'
import { SearchBar } from '../../components/SearchBar'

export function AdminWorkspacesPage() {
  const query = useQuery()
  const page = cleanInteger(query.get('page'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)
  const q = query.get('q') ?? undefined

  const {
    isFetching,
    isError,
    currentData: workspaces
  } = useGetOrgsQuery({
    perPage,
    offset: (page - 1) * perPage,
    q
  })

  useEffect(() => {
    document.title = getAdminSettingsPageTitle('Workspaces')
  }, [])

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">All Shared Workspaces</Heading>
        <Button as={Link} to="/-/settings/create-workspace" leftIcon={<AddIcon />}>
          Create New Workspace
        </Button>
      </CardHeader>
      <CardHeader>
        <SearchBar placeholder="Search..." />
      </CardHeader>
      <CardBody>
        {workspaces?.orgs?.map((workspace) => <WorkspaceRow key={workspace.slug} workspace={workspace} />)}
        <ListDefaults isLoading={isFetching} nItems={workspaces?.orgs?.length} itemName="workspace" isFail={isError} />
      </CardBody>
      <CardFooter justifyContent="center">
        <MayhemPagination isLoading={isFetching} total={workspaces?.count || 0} pageSize={perPage} />
      </CardFooter>
    </Card>
  )
}

export type ProjectListType = 'all' | 'my' | 'owner'

export const ProjectRoles = [
  {
    key: 'Admin',
    text: 'Admin',
    value: 'ADMIN',
    desc: 'Manage project settings, such as adding new contributors, removing contributors, changing contributor permissions, along with all write abilities.'
  },
  {
    key: 'Write',
    text: 'Write',
    value: 'WRITE',
    desc: 'Ability to access projects and change project elements, such as creating targets and starting runs.'
  },
  {
    key: 'Read',
    text: 'Read',
    value: 'READ',
    desc: 'Ability to access projects and view project elements, such as targets, runs, and test cases.'
  }
]

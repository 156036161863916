import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getBillingSubscription: build.query<GetBillingSubscriptionApiResponse, GetBillingSubscriptionApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/billing/subscription` }),
        providesTags: ['internal']
      }),
      putBillingSubscription: build.mutation<PutBillingSubscriptionApiResponse, PutBillingSubscriptionApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/billing/subscription`,
          method: 'PUT',
          body: queryArg.accountSubscriptionUpsert
        }),
        invalidatesTags: ['internal']
      }),
      getBillingUsage: build.query<GetBillingUsageApiResponse, GetBillingUsageApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/billing/usage` }),
        providesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetBillingSubscriptionApiResponse = /** status 200 Get an Organization's subscription plan */ AccountSubscription
export type GetBillingSubscriptionApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
}
export type PutBillingSubscriptionApiResponse = /** status 200 Update an Organization's subscription plan */ AccountSubscription
export type PutBillingSubscriptionApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  accountSubscriptionUpsert: AccountSubscriptionUpsert
}
export type GetBillingUsageApiResponse = /** status 200 Fetch Subscription Usage for an account */ AccountUsage
export type GetBillingUsageApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
}
export type AccountSubscription = {
  plan_id: 'ENTERPRISE' | 'STANDARD' | 'BASIC'
  plan_description?: string
  is_trial: boolean
  expires_at: string | null
  scan_max_duration_sec: number | null
  scan_limit_per_month: number | null
  max_contributors: number | null
}
export type ApiError = {
  message?: string
}
export type AccountSubscriptionUpsert = {
  plan_id: 'ENTERPRISE' | 'STANDARD' | 'BASIC'
  is_trial: boolean
  expires_at: string | null
  scan_max_duration_sec?: number | null
  scan_limit_per_month?: number | null
  max_contributors?: number | null
}
export type AccountUsage = {
  plan_description: string
  is_trial: boolean
  is_upgradable: boolean
  expires_at: string | null
  scans_used_in_month: number
  scans_allowed_in_month: number | null
  contributors_last_90days: number
  contributors_allowed: number | null
}

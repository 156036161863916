import { Flex, Skeleton } from '@chakra-ui/react'

import { useGetMdsbomWorkspaceStatsQuery } from '../../redux/api/mdsbom'

import { DsbomReductionViz } from './DsbomReductionViz'
import { DsbomSeverityBreakdown } from './DsbomSeverityBreakdown'

interface Props {
  workspace: string
}

export function DsbomWorkspaceBigStats({ workspace }: Props) {
  const { data: stats, isFetching } = useGetMdsbomWorkspaceStatsQuery({ workspace })
  if (!stats) {
    return <></>
  }

  const {
    n_defects_observed: nDefectsObserved,
    n_defects_reported: nDefectsReported,
    n_defects_observed_high: nDefectsObservedHigh,
    n_defects_observed_medium: nDefectsObservedMedium,
    n_defects_observed_low: nDefectsObservedLow,
    n_defects_reported_high: nDefectsReportedHigh,
    n_defects_reported_medium: nDefectsReportedMedium,
    n_defects_reported_low: nDefectsReportedLow
  } = stats

  return (
    <Skeleton isLoaded={!isFetching}>
      <Flex key={`${workspace}-bigstats`} gap={4} wrap="wrap">
        <DsbomReductionViz observed={nDefectsObserved} reported={nDefectsReported} />
        <DsbomSeverityBreakdown
          observedHigh={nDefectsObservedHigh}
          observedMedium={nDefectsObservedMedium}
          observedLow={nDefectsObservedLow}
          reportedHigh={nDefectsReportedHigh}
          reportedMedium={nDefectsReportedMedium}
          reportedLow={nDefectsReportedLow}
        />
      </Flex>
    </Skeleton>
  )
}

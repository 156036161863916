import { Tooltip } from '@chakra-ui/tooltip'
import { StatNumber } from '@chakra-ui/stat'

import { formatNumber } from '../util/numbers'

// shows a StatNumber with the shortened number as the display value and the unshortened number as the tooltip
// if a custom tooltip is provided, it uses that instead
// disables the tooltip if the number is < 1000 (since no special formatting happens for smaller numbers) and if no custom tooltip was provided
export default function TooltippedStatNumber({ value, tooltip }: { value: number | undefined; tooltip?: React.ReactNode }) {
  const displayTooltip = tooltip || `${formatNumber(value ?? 0, { shorten: false })}`
  return (
    <Tooltip label={displayTooltip} placement="bottom-start" isDisabled={(value || 0) < 1000 && !tooltip}>
      <StatNumber>{formatNumber(value ?? 0)}</StatNumber>
    </Tooltip>
  )
}

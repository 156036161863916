import { Box, HStack, Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react'

interface Props {
  totalCount: number
  apiCount: number
  codeCount: number
  children: React.ReactNode
}

export function RunTabs({ totalCount, apiCount, codeCount, children }: Props) {
  const tabs = Object.entries({ All: totalCount, Code: codeCount, API: apiCount })

  return (
    <Tabs variant="enclosed" isLazy width="full" defaultIndex={0} paddingX={4}>
      <TabList>
        {tabs.map(([tabName, count]) => (
          <Tab key={tabName}>
            <HStack gap={1}>
              <Box fontWeight={600}>{tabName}</Box>
              <Box color="gray.500" fontWeight={500}>
                ({count})
              </Box>
            </HStack>
          </Tab>
        ))}
      </TabList>
      <TabPanels>{children}</TabPanels>
    </Tabs>
  )
}

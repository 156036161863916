import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Stack } from '@chakra-ui/react'

import { Link as WouterLink } from 'wouter'

import { DsbomReportHeader } from './DsbomReportHeader'

import { DsbomReportPackages } from './DsbomReportPackages'
import { DsbomReportBigStats } from './DsbomReportBigStats'

import { DsbomDefectsList } from '@/features/dsbom-defects/DsbomDefectsList'

import { imageRepoIdToName } from '@/util/mdsbom'

import { Page } from '@/components/Page'
import { DataTab, DataTabs } from '@/components/DataTabs'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'
import { DsbomReportDownloadMenuButton } from '@/features/dsbom-reports/DsbomReportDownloadMenuButton'

interface Props {
  workspace: string
  imageRepoId: string
  reportId: string
}

export function DsbomReportPage({ workspace, imageRepoId, reportId }: Props) {
  const imageRepoName = imageRepoIdToName(imageRepoId)
  const tabData: DataTab[] = [
    {
      label: 'Defects',
      content: <DsbomDefectsList workspace={workspace} imageRepoId={imageRepoId} reportId={reportId} />,
      route: `/${workspace}/-/dynamic-sbom/${imageRepoId}/${reportId}`
    },
    {
      label: 'Packages',
      content: <DsbomReportPackages workspace={workspace} imageRepoId={imageRepoId} reportId={reportId} />,
      route: `/${workspace}/-/dynamic-sbom/${imageRepoId}/${reportId}/-/packages`
    }
  ]

  return (
    <Page>
      <Stack spacing={4}>
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/-/dynamic-sbom`}>
              <BreadcrumbLink>Dynamic SBOM</BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/-/dynamic-sbom/${imageRepoId}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={imageRepoName} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Dynamic SBOM Report</BreadcrumbLink>
            <Box ml={2}>
              <DsbomReportDownloadMenuButton workspace={workspace} reportId={reportId} />
            </Box>
          </BreadcrumbItem>
        </Breadcrumb>
        <DsbomReportHeader workspace={workspace} reportId={reportId} />
        <Flex gap={4} wrap="wrap">
          <DsbomReportBigStats workspace={workspace} imageRepoId={imageRepoId} reportId={reportId} />
        </Flex>
        <DataTabs isLazy variant="line" width="full" tabs={tabData} />
      </Stack>
    </Page>
  )
}

import { HStack, Heading, Icon, Text, VStack } from '@chakra-ui/react'
import SettingsIcon from '@material-design-icons/svg/sharp/search.svg?react'

import { getDocsUrl } from '../../urls'
import OutsideLink from '../../components/OutsideLink'

import CodeInProgressViz from '../../images/codeanalysisinprogress.svg?react'

export function RunCodeDefectsInProgress() {
  return (
    <VStack margin={8}>
      <Heading>
        <HStack>
          <Icon as={SettingsIcon} />
          <Text>Testing in Progress</Text>
        </HStack>
      </Heading>
      <CodeInProgressViz />
      <Text>Defects will appear here as soon as we find any!</Text>
      <Text>
        Visit our docs to learn more about{' '}
        <OutsideLink inline href={getDocsUrl('code-testing/tutorials/beginner/basic-concepts/#what-exactly-is-mayhem')}>
          {' '}
          how Mayhem finds defects.
        </OutsideLink>
      </Text>
    </VStack>
  )
}

import { ChangeEvent, useCallback, useState } from 'react'

import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack, Switch, useToast } from '@chakra-ui/react'

import { useGetProjectQuery, usePutProjectMutation } from '../../redux/api/projects'

type Props = {
  isAdmin: boolean
  projectSlug: string
  workspace: string
}

export function ProjectVisibilityToggle({ isAdmin, workspace, projectSlug }: Props) {
  const toast = useToast()
  const [visibilityError, setVisibilityError] = useState<string | undefined>()

  const { isLoading: projectIsLoading, data: project, refetch: refetchProject } = useGetProjectQuery({ owner: workspace, projectSlug })
  const [putProject, { isLoading: isPuttingProject }] = usePutProjectMutation()

  const isPrivate = project?.access_level === 'PRIVATE'

  const description = isPrivate
    ? 'Only contributors to this project (listed below) have access to this project.'
    : 'All authenticated Mayhem users have Read access to this project. To give a user Write or Admin access, add them with the desired permission below.'

  const onAccessLevelChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const newAccessLevel = e.target.checked ? 'PRIVATE' : 'AUTHENTICATED'
        setVisibilityError(undefined)
        await putProject({ owner: workspace, projectSlug, projectUpdate: { access_level: newAccessLevel } })
        await refetchProject()
        toast({
          title: 'Project Updated',
          description: (
            <>
              {`${projectSlug} visibility set to`}&nbsp;<strong>{newAccessLevel.toLowerCase()}</strong>
            </>
          ),
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        setVisibilityError((err as Error)?.message || 'Something went wrong')
      }
    },
    [toast, refetchProject, putProject, workspace, projectSlug]
  )

  return (
    <Stack spacing={4}>
      <FormControl display="grid" width="max-content" isInvalid={!!visibilityError}>
        <FormLabel gridRow={1}>Access Level: {isPrivate ? 'Private' : 'Authenticated'}</FormLabel>
        <Switch gridRow={1} isChecked={isPrivate} onChange={onAccessLevelChange} disabled={!isAdmin || isPuttingProject || projectIsLoading} />
        <FormHelperText maxW={96} gridRow={2}>
          {description}
        </FormHelperText>
        <FormErrorMessage>{visibilityError}</FormErrorMessage>
      </FormControl>
    </Stack>
  )
}

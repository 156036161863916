import { Container } from '@chakra-ui/react'

import { DataTab, DataTabs } from '../../components/DataTabs'
import { RunCodeDefects } from '../run-code-defects/RunCodeDefects'
import { ExploitabilityFactorsList } from '../run-code-exploitabilityfactors/ExploitabilityFactorsList'
import { RunCodeTests } from '../run-code-tests/RunCodeTests'
import { RunCodeCoverage } from '../run-code-coverage/RunCodeCoverage'
import { RunCodeLog } from '../run-code-log/RunCodeLog'
export interface RunCodePageTabsProps {
  workspace: string
  project: string
  target: string
  runNumber: number
}

export const RunCodePageTabs = ({ workspace, project, target, runNumber }: RunCodePageTabsProps) => {
  const tabs: DataTab[] = [
    {
      label: 'Defects',
      content: <RunCodeDefects owner={workspace} projectSlug={project} targetSlug={target} runNumber={runNumber} />,
      route: `/${workspace}/${project}/${target}/${runNumber}`
    },
    {
      label: 'Tests',
      content: <RunCodeTests owner={workspace} projectSlug={project} targetSlug={target} runNumber={runNumber} />,
      route: `/${workspace}/${project}/${target}/${runNumber}/tests`
    },
    {
      label: 'Coverage',
      content: <RunCodeCoverage owner={workspace} projectSlug={project} targetSlug={target} runNumber={runNumber} />,
      route: `/${workspace}/${project}/${target}/${runNumber}/coverage`
    },
    {
      label: 'Exploitability Factors',
      content: <ExploitabilityFactorsList owner={workspace} projectSlug={project} targetSlug={target} runNumber={runNumber} />,
      route: `/${workspace}/${project}/${target}/${runNumber}/exploitability`
    },
    {
      label: 'Log',
      content: <RunCodeLog owner={workspace} projectSlug={project} targetSlug={target} runNumber={runNumber} />,
      route: `/${workspace}/${project}/${target}/${runNumber}/log`
    }
  ]

  return (
    <Container width="full" maxW="full" padding={0}>
      <DataTabs variant="line" isLazy width="full" tabs={tabs} />
    </Container>
  )
}

export const helpSections: HelpSectionsType = {
  image: {
    title: ['Container Image'],
    description: [
      'The image can contain the targets (if using Docker packaging) or they may be contained in a root/ folder, in which case they are overlayed into the base image (see mayhem package command).',
      'If an image is not specified, ForAllSecure’s default Docker image based on Debian buster (forallsecure/debian-buster) will be used.'
    ],
    other: {
      default: ['forallsecure/debian-buster'],
      patchNotes: ['Default value changed in v1.1', 'Parameter introduced in v0.5']
    }
  },

  docker_tag: {
    title: ['Container Tag'],
    description: ['The tag used to identify which image you are going to fuzz.']
  },

  organization: {
    title: ['Organization'],
    description: [
      'The name of the organization which the project will belong to.  If this is left empty, the run will be generated under your personal organization. Similarly, if you enter your personal organization for this field, it will be omitted from the generated Mayhemfile.',
      'You must have access to the organization entered here, or the run will fail.'
    ]
  },

  project: {
    title: ['Project'],
    description: ['The unique name of the project which the target will belong to.', 'You must have access to this project, or the run will fail.']
  },

  target: {
    title: ['Target'],
    description: ['The unique name of the target which the run will belong to.']
  },

  duration: {
    title: ['Run Duration'],
    description: ['Time in seconds to run the application.']
  },

  cmds: {
    title: ['CMDs'],
    description: [
      'A list of commands to be run in order to test the packaged application.  Additional settings for each command can be found under the "Advanced" tab.',
      'The special string "@@" signifies the main input source of the fuzzer.  If you omit the "@@" and do not specify the command as a networking target in Advanced Settings, Mayhem will assume that standard input (/dev/stdin) is the main input source.'
    ],
    other: {
      example: ['/usr/bin/objdump -x @@']
    }
  },

  advanced_triage: {
    title: ['Advanced Triage'],
    description: ['Enable to attempt extra test case processing to help identify more defects and extend the total time of the run.']
  },

  uid: {
    title: ['UID'],
    description: [
      'An optional numeric UNIX user id to use as the target uid. If unset, an unprivileged UNIX user id will be used that could cause issues accessing files. If a uid other than 0 is used, the target will generally not be able to perform privileged operations such as bind to a port lower than 1024. Docker defaults this to 0, although it can be overridden by the USER command. If using the default base image, this is 1000.'
    ],
    other: {
      default: ['derived from image']
    }
  },

  gid: {
    title: ['GID'],
    description: [
      'An optional numeric UNIX group id to use as the target gid. If unset, an unprivileged UNIX group id will be used that could cause issues accessing files. If unspecified, the gid is inherited from the base image. Docker defaults this to 0, although it can be overridden by the USER command. If using the default base image, this is 1000.'
    ],
    other: {
      default: ['derived from image']
    }
  },

  // key and value should stay the same
  key: {
    title: ['Environment Variables'],
    description: [
      'You can specify arbitrary environment variables which will be set when your program is run. Additionally, special environment variables can be specified if your program is not compatible with some performance enhancements used by Mayhem.',
      'While this is often not necessary, some multi-threaded programs can be problematic to analyze. If Mayhem has trouble fuzzing your program, you can set "MFUZZ_COMPAT_LEVEL": "1" which will disable some of Mayhem\'s performance optimizations (specifically deferred execution of the fork server).',
      'If this does not resolve the issue, a more extreme "MFUZZ_COMPAT_LEVEL": "2" can be used to completely disable optimizations that may interfere with multithreaded programs. This is the equivalent of setting "AFL_NO_FORKSRV": "1" and "LD_BIND_LAZY": "1" which together disable the fork server and prevent loading all shared libraries immediately when the target is launched.',
      'Standard AFL / libfuzzer options passed through the environment will also work.'
    ],
    other: {
      default: ['derived from image']
    }
  },
  // key and value should stay the same
  value: {
    title: ['Environment Variables'],
    description: [
      'You can specify arbitrary environment variables which will be set when your program is run. Additionally, special environment variables can be specified if your program is not compatible with some performance enhancements used by Mayhem.',
      'While this is often not necessary, some multi-threaded programs can be problematic to analyze. If Mayhem has trouble fuzzing your program, you can set "MFUZZ_COMPAT_LEVEL": "1" which will disable some of Mayhem\'s performance optimizations (specifically deferred execution of the fork server).',
      'If this does not resolve the issue, a more extreme "MFUZZ_COMPAT_LEVEL": "2" can be used to completely disable optimizations that may interfere with multithreaded programs. This is the equivalent of setting "AFL_NO_FORKSRV": "1" and "LD_BIND_LAZY": "1" which together disable the fork server and prevent loading all shared libraries immediately when the target is launched.',
      'Standard AFL / libfuzzer options passed through the environment will also work.'
    ],
    other: {
      default: ['derived from image']
    }
  },

  url: {
    title: ['Network URL'],
    description: [
      'For Mayhem, localhost means any local interface, IPv4 or IPv6 and will attempt to perform auto-detection on which protocol should be tested. If you need to use specifically one or the other, you may instead write 127.0.0.1 for IPv4 or [::1] for IPv6. ',
      'For example tcp://127.0.0.1:80 will feed input to an IPv4 connection to port 80.',
      'Make sure you are aware how your target is communicating. In particular, using localhost inside of a container may require a hostname lookup for localhost which may not resolve to what you expect. If possible, stick to an IP address for binding and connecting in internal target configurations to avoid ambiguity.'
    ],
    other: {
      supportedProtocols: ['"tcp", "udp", "unix+stream", "unix+dgram" and "unix+seqpacket"'],
      format: ['<protocol>://<host>:<port>'],
      default: ['empty'],
      patchNotes: ['Added unix protocols in 1.6']
    }
  },

  timeout: {
    title: ['Network Timeout'],
    description: [
      'The time (in seconds) for Mayhem to wait for the target to accept network input during triage. If the target does not accept network input within this time limit, the testcase will be discarded. If the time is too low, the testcase may report back as normal. Targets that take a while to start may need a longer timeout. This field is part of the network object.'
    ],
    other: {
      default: ['2']
    }
  },

  client: {
    title: ['Is Client'],
    description: [
      'Set to true if the executable to be tested is a client. A client is expected to initiate the network connection, while a server is expected to wait for Mayhem.'
    ],
    other: {
      default: ['false']
    }
  },

  sanitizer: {
    title: ['Sanitizer'],
    description: ['Set to true only if the target executable is compiled with a sanitizer.'],
    other: {
      default: ['automatic'],
      patchNotes: ['Added in 1.4']
    }
  },

  libfuzzer: {
    title: ['Libfuzzer'],
    description: ['Set to true only if the target executable is compiled with libfuzzer.'],
    other: {
      default: ['automatic'],
      patchNotes: ['Default changed in 1.1']
    }
  },

  afl: {
    title: ['AFL'],
    description: ['Set to true only if the target executable is compiled with AFL instrumentation.'],
    other: {
      default: ['automatic'],
      patchNotes: ['Default changed in 1.1']
    }
  },

  honggfuzz: {
    title: ['Honggfuzz'],
    description: ['Set to true only if the target executable is compiled with honggfuzz.'],
    other: {
      default: ['automatic'],
      patchNotes: ['Introduced in 0.8', 'Default changed in 1.1']
    }
  },

  disable_se: {
    title: ['Disable Symbolic Execution'],
    description: ['Set to true to ensure symbolic execution is not going to run for this target, but other fuzzers will process this command.'],
    other: {
      default: ['false']
    }
  },

  disable_mayhem_fuzz: {
    title: ['Mayhem Fuzz'],
    description: [
      'Set to true to force Mayhem to choose a different cmd to fuzz. This may be useful if the users wants to provide a cmd that is used for triage, but not for fuzzing. Setting to true will still allow other fuzzers (like symbolic execution) to process this command.'
    ],
    other: {
      default: ['false']
    }
  },

  dictionary: {
    title: ['Dictionary'],
    description: [
      'The path to an AFL-compatible dictionary file. The path should be relative to the root of the container. For instance, if the dictionary is under /etc/foo/bar/http.dict inside the image, the dictionary path in the Mayhemfile should be /etc/foo/bar/http.dict.'
    ],
    other: {
      default: ['empty']
    }
  },

  max_length: {
    title: ['Fuzzing Input Max Length (bytes)'],
    description: [
      'Maximum length (in bytes) that will be provided as input to the fuzz target. The field is supported for libFuzzer and uninstrumented targets. On libFuzzer, it will be mapped to the -max_len argument.'
    ],
    other: {
      default: ['8192 (libfuzzer)', 'N/A (uninstrumented)']
    }
  },

  cwd: {
    title: ['Starting Directory'],
    description: ['The path Mayhem should switch to prior to executing the target. (Also known as the current working directory - CWD)'],
    other: {
      default: ['derived from image']
    }
  },

  filepath: {
    title: ['Target Input Path'],
    description: ['The name that Mayhem should give the input file.'],
    other: {
      default: ['empty']
    }
  },

  memory_limit: {
    title: ['Memory Limit (MB)'],
    description: [
      'The maximum amount of memory (in MB) to allow the target to allocate. Mayhem will kill a test run that goes above this memory limit and reports it as a crash.'
    ],
    other: {
      default: ['automatic']
    }
  },

  mayhem_timeout: {
    title: ['Mayhem Timeout'],
    description: ['The time (in seconds) for Mayhem to wait for a single test to complete.'],
    other: {
      default: ['automatic']
    }
  },

  preview: {
    title: ['Default Fields'],
    description: ['Note: Default values will be used for the tasks and corpus fields in the Mayhemfile if they are omitted.']
  }
}

export const defaultHelpSection: HelpSectionType = {
  title: ['Mayhemfile'],
  description: [
    'When packaging a target for Mayhem, a configuration file called Mayhemfile will be created. This file contains all of the information Mayhem needs to execute and analyze the executable.',
    'The initial configuration file should work automatically for simple applications, but most software will require some changes to ensure the inputs are specified correctly.',
    'If editing the Mayhemfile for an existing run, any environment variables or overrides used when starting that run will be shown.'
  ]
}

interface HelpSectionType {
  title: string[]
  description: string[]
  other?: {
    [key: string]: string[]
  }
}

interface HelpSectionsType {
  [key: string]: HelpSectionType
}

import dayjs, { Dayjs, ManipulateType } from 'dayjs'

/** Resample records for the specified period and unit. */
export function resample<TIn, TOut>(
  records: TIn[],
  timestampFn: (record: TIn) => Dayjs,
  resampleFn: (timestamp: Dayjs, records: TIn[]) => TOut,
  unit: ManipulateType = 'day',
  period = 1,
  reference?: Dayjs
): TOut[] {
  if (!reference) {
    // Use the earliest timestamp as reference
    reference = records.map(timestampFn).reduce(dayjsMin)
  }
  const groups = new Map<number, TIn[]>()
  for (const record of records) {
    const timestamp = timestampFn(record)
    const key = resampleKey(timestamp, period, unit, reference).valueOf()
    if (!groups.has(key)) {
      groups.set(key, [record])
    } else {
      groups.get(key)?.push(record)
    }
  }

  return Array.of(...groups.entries()).map((group) => {
    const [timestamp, items] = group
    return resampleFn(dayjs(timestamp), items)
  })
}

function resampleKey(timestamp: Dayjs, period: number, unit: ManipulateType, reference: Dayjs) {
  const referenceAligned = reference.startOf(unit)
  const timestampAligned = timestamp.startOf(unit)
  const diff = referenceAligned.diff(timestampAligned, unit)

  return timestampAligned.add(diff % period, unit)
}

function dayjsMin(a: Dayjs, b: Dayjs): Dayjs {
  return a.isBefore(b) ? a : b
}

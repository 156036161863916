import { ComponentStyleConfig } from '@chakra-ui/react'

import { fuzzingLoaderAnatomy } from '../../components/FuzzingLoader/anatomy'
import { rotate } from '../animations'

export const FuzzingLoader: ComponentStyleConfig = {
  parts: fuzzingLoaderAnatomy.keys,
  baseStyle: {
    header: {},
    icon: {
      transformOrigin: 'center center',
      transformBox: 'fill-box',
      animation: `${rotate} 1.5s ease infinite`
    },
    randomText: {
      textColor: 'faded',
      color: 'faded',
      fontFamily: 'mono'
    }
  }
}

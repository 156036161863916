import {
  Button,
  Icon,
  Modal,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  useToast,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Stack,
  Text,
  Skeleton,
  ButtonGroup
} from '@chakra-ui/react'
import SaveIcon from '@material-design-icons/svg/sharp/save.svg?react'
import UndoIcon from '@material-design-icons/svg/sharp/undo.svg?react'

import { FormProvider, useForm } from 'react-hook-form'

import { useCallback, useEffect } from 'react'

import { useGetUserQuery, useGetUserSettingQuery, usePutUserQuotaMutation } from '../../redux/api/workspace'
import { FormattedInput } from '../../components/input'
import { prettyTimeElapsedString } from '../../util/time'

interface Props {
  userSlug: string
  onClose?: () => void
}

interface Form {
  maxActiveRuns: number
  maxRunDuration: number
}

export function UserResourceLimitsModal({ userSlug, onClose }: Props) {
  const toast = useToast()

  const { data: config, isFetching: isConfigFetching } = useGetUserSettingQuery()
  const { data: userSetting, isFetching: isUserSettingFetching } = useGetUserQuery({ userSlug }, { skip: !userSlug })
  const [putUserQuota] = usePutUserQuotaMutation()

  const displayName =
    userSetting && userSetting.first_name && userSetting.last_name
      ? `${userSetting.first_name} ${userSetting.last_name} (${userSetting.email_address || 'Missing Email Address'})`
      : userSetting?.email_address

  const methods = useForm<Form>()

  const {
    handleSubmit,
    register,
    setError,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting, isDirty, isValid }
  } = methods

  const [maxActiveRuns, maxRunDuration] = watch(['maxActiveRuns', 'maxRunDuration'])

  const isDefault = config?.max_active_runs === maxActiveRuns && config?.max_run_duration === maxRunDuration

  const close = () => {
    reset({ maxActiveRuns: undefined, maxRunDuration: undefined })
    if (onClose) {
      onClose()
    }
  }

  const onSubmit = async ({ maxActiveRuns, maxRunDuration }: Form) => {
    try {
      await putUserQuota({ userSlug, userQuota: { max_active_runs: maxActiveRuns ?? 0, max_run_duration: maxRunDuration ?? 0 } })
      close()
      toast({
        title: 'Resource Limits Updated',
        description: `${displayName || 'User'}'s resource limits have been updated`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (err: unknown) {
      const message = (err as Error)?.message || 'An error occurred. Please try again.'
      setError('root', { type: 'custom', message: message })
    }
  }

  useEffect(() => {
    if (userSetting && config) {
      console.log('resetting form: ', {
        maxActiveRuns: userSetting.max_active_runs ?? config.max_active_runs ?? 0,
        maxRunDuration: userSetting.max_run_duration ?? config.max_run_duration ?? 0
      })
      reset({
        maxActiveRuns: userSetting.max_active_runs ?? config.max_active_runs ?? 0,
        maxRunDuration: userSetting.max_run_duration ?? config.max_run_duration ?? 0
      })
    }
  }, [reset, userSetting, config])

  const formatActiveRuns = useCallback((value: string) => formatMaxActiveRuns(parseHtmlInputValue(value), config?.max_active_runs ?? 0), [config])
  const formatRunDuration = useCallback((value: string) => formatMaxRunDuration(parseHtmlInputValue(value), config?.max_run_duration ?? 0), [config])

  return (
    <Modal isOpen={!!userSlug} onClose={() => close()} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Set Resource Limits</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Skeleton isLoaded={!isConfigFetching && !isUserSettingFetching}>
                <Stack spacing={4}>
                  {displayName && <Text>Resource limits for {displayName}</Text>}

                  <FormControl isInvalid={!!errors.maxActiveRuns}>
                    <FormLabel>Max Active Runs</FormLabel>
                    <FormattedInput type="number" {...register('maxActiveRuns', { min: 0 })} format={formatActiveRuns} />
                    {errors?.maxActiveRuns && <FormErrorMessage>{errors.maxActiveRuns.message}</FormErrorMessage>}
                    <FormHelperText>Workspace Default is {config?.max_active_runs}. Set to 0 for unlimited runs.</FormHelperText>
                  </FormControl>

                  <FormControl isInvalid={!!errors.maxRunDuration}>
                    <FormLabel>Default/Max Run Duration</FormLabel>
                    <FormattedInput type="number" {...register('maxRunDuration', { min: 0 })} format={formatRunDuration} />
                    {errors?.maxRunDuration && <FormErrorMessage>{errors.maxRunDuration.message}</FormErrorMessage>}
                    <FormHelperText>Workspace Default is {config?.max_run_duration}. Set to 0 for unlimited duration.</FormHelperText>
                  </FormControl>
                </Stack>
              </Skeleton>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  variant="outline"
                  isDisabled={isDefault}
                  leftIcon={<Icon as={UndoIcon} />}
                  onClick={() => {
                    setValue('maxActiveRuns', config?.max_active_runs || 0, { shouldDirty: true })
                    setValue('maxRunDuration', config?.max_run_duration || 0, { shouldDirty: true })
                  }}
                >
                  Restore Defaults
                </Button>
                <Button type="submit" variant="solid" isDisabled={!isDirty || !isValid} isLoading={isSubmitting} leftIcon={<Icon as={SaveIcon} />}>
                  Save
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}

function parseHtmlInputValue(value: string): number {
  // <input type="number" value="" /> is equal to value=0
  if (!value) {
    return 0
  }
  return parseInt(value, 10)
}

const infinityCharacter = '\u221E'

function formatMaxActiveRuns(value: number | null, defaultValue: number) {
  console.log(`formatting runs :${value}`)
  if (value == null || isNaN(value)) {
    value = defaultValue
  }
  const formatted = value === 0 ? infinityCharacter : value.toString()
  if (value === defaultValue) {
    return `${formatted} (Default)`
  }
  return formatted.toString()
}

function formatMaxRunDuration(value: number | null, defaultValue: number) {
  console.log(`formatting duration :${value}`)
  if (value == null || isNaN(value)) {
    value = defaultValue
  }
  const formatted = value === 0 ? infinityCharacter : prettyTimeElapsedString(value, true)
  if (value === defaultValue) {
    return `${formatted} (Default)`
  }
  return formatted
}

import { Box } from '@chakra-ui/react'

import { ToggleButton } from './ToggleButton'

// the bar that appears on mobile to open the sidenav
export const MobileNavbar = ({ isOpen, onToggle }: MobileNavbarProps) => {
  return (
    <Box width="full" py={6} px={{ base: '4', md: '8' }} gridArea="mobile-navbar">
      <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
    </Box>
  )
}

export interface MobileNavbarProps {
  isOpen: boolean
  onToggle: () => void
}

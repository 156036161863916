/**
 * Returns the number to display for the Coverage stat for API runs.
 *
 * @param run the run to get the coverage stat for
 * @returns the primary coverage stat to display for an API run
 */
export function getEndpointCoverageDisplayStat(
  nTotalEndpoint: number | undefined,
  nUnsuccessfulEndpoint: number | undefined,
  nUnauthedEndpoint: number | undefined
): number {
  const coveredEndpoints = (nTotalEndpoint || 0) - (nUnsuccessfulEndpoint || 0) - (nUnauthedEndpoint || 0)
  return ((coveredEndpoints * 1.0) / (nTotalEndpoint || 1.0)) * 100
}

import { Alert, AlertIcon, AlertTitle, Button, Flex, FormControl, FormErrorMessage, Input, InputGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'

import { useGetAnnouncementQuery, usePostClearAnnouncementMutation, usePostStoreAnnouncementMutation } from '../../redux/api/admin'
import { getMessageFromRtkError } from '../../util/errors'

type Form = {
  announcement: string
}

export function SetAnnouncementForm() {
  const toast = useToast()
  const [postStoreAnnouncement] = usePostStoreAnnouncementMutation()
  const [postClearAnnouncement] = usePostClearAnnouncementMutation()
  const { data: announcement } = useGetAnnouncementQuery()

  const methods = useForm<Form>()
  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors, isValid, isSubmitting }
  } = methods

  const onSubmit = async ({ announcement }: Form) => {
    try {
      if (!announcement) {
        try {
          localStorage.removeItem('announcement')
          await postClearAnnouncement({ adminClearAnnouncement: {} }).unwrap()
          toast({
            title: 'Announcement Cleared',
            isClosable: true,
            status: 'success',
            duration: 5000
          })
        } catch (e) {
          toast({
            title: 'There was an error clearing the announcement.',
            status: 'error',
            duration: 9000,
            isClosable: true
          })
        }
      } else {
        try {
          await postStoreAnnouncement({ adminStoreAnnouncement: { announcement } }).unwrap()
          toast({
            title: 'Announcement Set',
            isClosable: true,
            status: 'success',
            duration: 5000
          })
        } catch (e) {
          toast({
            title: 'Failed to set announcement.',
            status: 'error',
            duration: 9000,
            isClosable: true
          })
        }
        reset()
      }
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      setError('announcement', { type: 'custom', message: message })
    }
  }

  return (
    <Stack spacing={4}>
      <Text>Site wide announcement all users will see when they log in to Mayhem.</Text>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            {!announcement?.success && (
              <FormControl isInvalid={!!errors.announcement}>
                <InputGroup>
                  <Input type="text" placeholder="Announcement" autoComplete="announcement" {...register('announcement', { maxLength: 512 })} />
                </InputGroup>
                <FormErrorMessage>{errors.announcement?.message}</FormErrorMessage>
              </FormControl>
            )}
            {announcement && announcement.success && (
              <Alert status="info" colorScheme="brand" mb={4}>
                <AlertIcon />
                <AlertTitle>{announcement.success}</AlertTitle>
              </Alert>
            )}

            <Flex justify="flex-end">
              {announcement && announcement.success !== '' ? (
                <Button type="submit" isLoading={isSubmitting}>
                  Clear Announcement
                </Button>
              ) : (
                <Button type="submit" isLoading={isSubmitting} isDisabled={!isValid}>
                  Set Announcement
                </Button>
              )}
            </Flex>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  )
}

import {
  FormLabel,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text
} from '@chakra-ui/react'
import HelpIcon from '@material-design-icons/svg/sharp/help.svg?react'

import { ProjectRoles } from '../project/utils'

export function ProjectPermissionLabelWithPopover() {
  return (
    <FormLabel>
      <HStack>
        <Text>Permission</Text>
        <Popover>
          <PopoverTrigger>
            <IconButton icon={<HelpIcon />} aria-label="permission help" size="xs" variant="ghost" />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader>Project Permissions</PopoverHeader>
            <PopoverArrow />
            <PopoverBody>
              <Stack spacing={4}>
                {ProjectRoles.map((role) => (
                  <Text key={role.text}>
                    <Text as="b">{role.text}</Text> - {role.desc}
                  </Text>
                ))}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
    </FormLabel>
  )
}

import { ScrollableSettingsPage } from '../settings/ScrollableSettingsPage'

import { UserSettingsPersonalInfoPage } from './UserSettingsPersonalInfoPage'
import { UserSettingsAPITokensPage } from './UserSettingsApiTokensPage'
import { UserWorkspacesPage } from './UserWorkspacesPage'

export function UserSettingsPage(): JSX.Element {
  const sections = [
    {
      id: 'user-personal',
      component: <UserSettingsPersonalInfoPage />
    },
    {
      id: 'user-workspaces',
      component: <UserWorkspacesPage />
    },
    {
      id: 'user-api-tokens',
      component: <UserSettingsAPITokensPage />
    }
  ]

  return <ScrollableSettingsPage title="Account Settings" sections={sections} />
}

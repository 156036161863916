import { HStack } from '@chakra-ui/react'

import { SearchBar } from './SearchBar'

interface Props {
  children: React.ReactNode
  placeholder: string
}

export default function FiltersWithSearch({ children, placeholder }: Props) {
  return (
    <>
      <HStack flexWrap="wrap">{children}</HStack>
      <SearchBar placeholder={placeholder} InputGroupProps={{ flex: '1 1' }} />
    </>
  )
}

import { Box, useMultiStyleConfig } from '@chakra-ui/react'
import { ComponentClass, createElement, FunctionComponent } from 'react'
import { Tooltip } from 'recharts'

export function chartTooltip<Record>(tooltip: FunctionComponent<{ record: Record }> | ComponentClass<{ record: Record }>) {
  return (
    <Tooltip
      content={({ payload }) => {
        const styles = useMultiStyleConfig('Chart')

        if (!payload || payload.length === 0) {
          return null
        }

        const record = payload[0].payload as Record
        return <Box sx={styles.tooltip}>{createElement(tooltip, { record })}</Box>
      }}
    />
  )
}

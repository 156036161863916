import { AlertProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { alertAnatomy } from '@chakra-ui/anatomy'
import { transparentize } from '@chakra-ui/theme-tools'

const configHelpersAlert = createMultiStyleConfigHelpers(alertAnatomy.keys)

const outlinedAlert = configHelpersAlert.definePartsStyle((props: AlertProps) => {
  const { status } = props
  const baseContainer = {
    borderWidth: '1px',
    borderRadius: '12'
  }
  const opacity = 0.25
  switch (status) {
    case 'success':
      return {
        container: {
          ...baseContainer,
          backgroundColor: 'green.25',
          color: 'green.700',
          borderColor: 'green.300',
          _dark: {
            background: transparentize('good.800', opacity),
            color: 'green.200'
          }
        }
      }
    case 'warning':
      return {
        container: {
          ...baseContainer,

          background: 'warning.25',
          color: 'warning.700',
          borderColor: 'warning.300',
          _dark: {
            background: transparentize('warning.800', opacity),
            color: 'warning.200'
          }
        }
      }
    case 'error':
      return {
        container: {
          ...baseContainer,

          background: 'bad.25',
          color: 'bad.700',
          borderColor: 'bad.300',
          _dark: {
            background: transparentize('bad.800', opacity),
            color: 'bad.200'
          }
        }
      }
    default:
    case 'info':
      return {
        container: {
          ...baseContainer,

          background: 'brand.25',
          color: 'brand.700',
          borderColor: 'brand.300',

          _dark: {
            background: transparentize('brand.800', 0.2),
            color: 'brand.200'
          }
        }
      }
  }
})

export const Alert = configHelpersAlert.defineMultiStyleConfig({
  variants: { outlined: outlinedAlert }
})

import { Tag, Tooltip } from '@chakra-ui/react'

import { formatNumber } from '../../util/numbers'

export interface DefectsTagProps {
  count: number
  label?: string
  severity?: 'High' | 'Medium' | 'Low'
}

export const DefectsTag = ({ count, label, severity = 'High' }: DefectsTagProps) => {
  const formattedLongCount = formatNumber(count, { shorten: false })
  const labelWithCount = label ? `${formattedLongCount} ${label}` : `${formattedLongCount}`

  return (
    <Tooltip label={labelWithCount}>
      <Tag colorScheme={`severity${severity}`} width="3.5rem" justifyContent="flex-end">
        {formatNumber(count)}
      </Tag>
    </Tooltip>
  )
}

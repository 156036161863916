import { formatPercentage } from '../../../util/numbers'

import { chartAxis } from './chartAxis'

export interface ChartPercentAxisProps {
  field: string
  min?: number
  max?: number
  step?: number
  ticks?: number
  base?: number
  axis?: 'x' | 'y'
}

export function chartAxisPercentage({ field, min = 0, max = 1, step = 0.05, ticks, base = 100, axis = 'y' }: ChartPercentAxisProps) {
  const minAligned = min - (min % step)
  const maxAligned = max + step - (max % step)
  return chartAxis({
    axis,
    dataKey: field,
    domain: [minAligned, maxAligned],
    ticks: getTicks(minAligned, maxAligned, step, ticks),
    tickFormatter: (value) => formatPercentage(value, { base })
  })
}

function getTicks(min: number, max: number, step: number, tickCount?: number) {
  if (tickCount) {
    const newStep = (max - min) / tickCount
    step = newStep - (newStep % step)
  }
  const ticks: number[] = []
  for (let p = min; p <= max; p += step) {
    ticks.push(p)
  }

  return ticks
}
